<template>
  <div class="form-field">
    <label v-if="label" :for="'textarea-modal' + uid" class="field-textarea-label">{{
      label
    }}</label>
    <textarea
      :placeholder="placeholder"
      :id="'textarea-modal' + uid"
      class="field-textarea"
      v-model="message"
      maxlength="256"
    ></textarea>
  </div>
</template>
<script>
import { getCurrentInstance, computed } from "vue"

export default {
  name: "BaseModalTextarea",
  props: {
    modelValue: String,
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const uid = getCurrentInstance().uid
    const message = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    })

    return {
      uid: uid,
      message,
    }
  },
}
</script>

<style lang="scss" scoped>
.field-textarea-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #a4a4a4;
  margin-bottom: 2px;
}

.field-textarea {
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 14px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #0a2333;

  &.error {
    border-color: red;
  }
}
</style>
