<template>
  <Datepicker
    v-model="date"
    locale="ru"
    month-name-format="long"
    :enable-time-picker="false"
    position="left"
    auto-apply
    :format="customFormatting"
    @update:modelValue="changeDate"
    :class="classes"
    :teleport="teleportSelector"
    :altPosition="teleportSelector !== 'body'"
    :placeholder="placeholder"
    :disabled="isDisabled"
  />

  <div class="input-errors_wrap">
    <div class="input-errors" v-for="error of errors" :key="error.$uid">
      <div class="error-msg">{{ error.$message }}</div>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, watch } from 'vue'
import { format } from 'date-fns'

export default {
  name: 'BaseDatepicker',
  components: { Datepicker },
  emits: ['changeValue'],
  props: {
    dateValue: {
      type: String,
    },
    errors: {
      type: [Array],
      default: () => [],
    },
    customFormatting: {
      type: String,
      default: 'dd.LL.yyyy',
    },
    inputClass: {
      type: [Object, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    teleportSelector: {
      type: String,
      default: 'body'
    },
    placeholder: {
      type: String,
      default: null
    },
  },
  computed: {
    classes() {
      return {
        error: this.inputClass.error,
        disabled: this.disabled,
      }
    },
    isDisabled() {
      return this.disabled;
    }
  },
  setup(props) {
    let date = ref(null);

    watch(() => props.dateValue, (newValue) => {
      if (!newValue) {
        date.value = undefined;
        return;
      }

      if (props.customFormatting === 'dd.LL.yyyy') {
        const [day, month, year] = newValue.split('.');
        const formattedDateString = `${year}-${month}-${day}`;
        date.value = new Date(formattedDateString);
        return;
      }

      if (props.customFormatting === 'yyyy-LL-dd') {
        const [year, month, day] = newValue.split('-');
        const formattedDateString = `${year}-${month}-${day}`;
        date.value = new Date(formattedDateString);
      }
    }, { immediate: true });

    return {
      date,
    }
  },

  methods: {
    changeDate() {
      if (!this.date) {
        this.$emit('changeValue', '')
        return;
      }

      this.$emit('changeValue', format(this.date, this.customFormatting))
    },
  },
}
</script>

<style lang="scss">
.input-errors {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d72028;

  &_wrap {
    padding-right: 4px;
    margin-top: 4px;
  }
}

.dp__theme_light {
  --dp-border-color: #efefef;
}

.dp__input {
  border-radius: 6px;
  font-size: 12px;
  line-height: normal;
  height: 40px;
}

.error {
  .dp__input {
    border-color: red;
  }
}

.dp__input_icon {
  fill: #0055E8;
}
</style>
