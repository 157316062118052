<template>
  <button
      class='button'
      :class="classes"
      @click="clickEvent"
  >
    <div class="button__body">
      <div class="button__slot">
        <slot/>
      </div>
    </div>
  </button>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";
import {computed} from "vue";
import oneRequest from "@/hooks/oneRequest";

/**
 * @displayName BaseButton
 */
export default {
  name: 'BaseButton',
  components: {SvgIcon},
  props: {
    oneClickEvent: null,
    /** Желтая кнопка */
    successBtn: Boolean,
    widthAuto: Boolean,
    medium: Boolean
  },
  setup(props, { emit }) {
    const hasBg = computed(() => {
      return !props.successBtn
    })

    const classes = computed(() => {
      return {
        'button__default': hasBg.value,
        'button__success-btn': props.successBtn,
        'button__auto': props.widthAuto,
        'button__medium': props.medium,
      }
    })

    const clickPostfix = () => {
      emit('postfix')
    }

    const { requestFn } = oneRequest()

    const clickEvent = () => {
      if (props.oneClickEvent != null) {
        requestFn(props.oneClickEvent, {one: true})
      }
    }

    return {
      classes,
      hasBg,
      clickPostfix,
      clickEvent
    }
  }
}
</script>

<style scoped lang="scss">
  .button {
    border-radius: 8px;
    height: 52px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: #ffffff;
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }

    &__default {
      color: #0A2333;
      border: 1px solid #EFEFEF;
    }

    &__success-btn {
      background: #ffa500;
    }

    &__medium {
      height: 48px;
      font-size: 12px;
    }

    &__auto {
      width: fit-content;
      padding: 0 20px;
    }
  }
</style>
