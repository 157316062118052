<template>
  <div class="map map-active-full">
    <div ref="map" id="map" class="map-big"></div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line
  name: 'Map',
  async mounted() {
    let externalScript = document.createElement('script');
    await externalScript.setAttribute(
      'src',
      `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${process.env.VUE_APP_YANDEX_MAP_API_KEY}`
    );
    await document.head.appendChild(externalScript);

    await setTimeout(async () => {
      /* eslint-disable */
      await ymaps.ready(() => {
        const myMap = new ymaps.Map('map', {
          center: [55.76, 37.64],
          zoom: 10,
          controls: [],
        });
        /* eslint-enable */

        const arrRoute = [];
        const transitId = [];
        let counter = 0;

        const routeObject = this.$route.query;

        for (let point in routeObject) {
          arrRoute.push(routeObject[point])

          if (point !== 'load_address' && point !== 'unload_address') {
            transitId.push(counter)
          }
          counter++;
        }

        // eslint-disable-next-line
        var multiRoute = new ymaps.multiRouter.MultiRoute(
          {
            referencePoints: arrRoute,
            params: {
              results: 2,
              viaIndexes: transitId
            },
          },
          {
            boundsAutoApply: true,
          }
        );

        myMap.geoObjects.add(multiRoute);
      });
    }, 1000);
  },
};
</script>

<style lang="scss">
.map {
  position: relative;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 10px;
  margin-right: 10px;
  height: 98vh;
  z-index: 9;
  width: 100%;

  &-active-full {
    position: absolute;
    .map-big {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
