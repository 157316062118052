<template>
  <BaseSearch
    @on-submit="updateSearch"
    ref="search"
    :erase-tooltip="eraseTooltip"
    is-dashboard-search
  />
</template>

<script setup>
import BaseSearch from '@/components/base/BaseSearch.vue';
import { ref, computed } from 'vue';

const emits = defineEmits(['update-search']);
defineProps({
  eraseTooltip: {
    type: String,
    default: ''
  },
})
const search = ref(null);

const updateSearch = (searchValue) => {
  emits('update-search', { q: searchValue.length ? searchValue : undefined });
}

const searchValue = computed(() => {
  return search.value.searchValue
})

defineExpose({ searchValue });

</script>
