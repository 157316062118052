<template>
  <BaseModal
    :is-open="isOpen"
    @close="closeModal"
    :ClientsMiniModal="true"
    class="clients-modal"
  >
    <div class="modal-container">
      <div class="clients-mini-modal active-height">
        <div>
          <h3 class="clients-modal__title active-padding">
            Сменить ответственного
          </h3>

          <div class="clients-mini-modal__content">
            <BaseDropdown
              placeholder="Выберите ответственного"
              v-model="currentManager"
              :list="list"
            />
          </div>

        </div>
        <div class="clients-modal__buttons active-padding">
          <button class="button clients-modal__button" @click="changeResponsible">Да</button>
          <button class="button clients-modal__button-repeal" @click="closeModal">
            Нет
          </button>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup>
import BaseDropdown from '@/components/base/BaseDropdown'
import BaseModal from '@/components/base/BaseModal'
import { nextTick, ref, watch } from 'vue'
import { useStore } from 'vuex';

const store = useStore();
const currentManager = ref(null);

const props = defineProps({
  isOpen: Boolean,
  list: {
    type: Array,
    default: () => []
  },
  currentId: [Number, String],
  currentResponsibleId: [Number, undefined],
  urlRequest: String,
})

const emits = defineEmits(['responsibleModal:close'])

const closeModal = (isTriggerDataUpdate = false) => emits('responsibleModal:close', isTriggerDataUpdate)

const changeResponsible = async () => {
  await store.dispatch(props.urlRequest, {
    id: props.currentId,
    managerId: { user_id: currentManager.value }
  })

  await closeModal(true);
}

watch(() => props.isOpen, async (value) => {
  if (value === false) return;
  await nextTick();
  currentManager.value = props.currentResponsibleId || null;
})


</script>

<style scoped lang="scss">
.ps {
  max-height: 90vh;
}

.clients-mini-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 90vh;
  padding-bottom: 20px;
  padding-top: 20px;

  &__content {
    padding: 0 24px 10px;
  }
}

.active-height {
  height: 100%;
  padding: 0;
}

.active-padding {
  padding: 0 24px;
}

.active-margin {
  margin: 0 32px;
}

.modal-container {
  width: 100%;
}

.clients-modal__title {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #102f43;
  margin-bottom: 20px;
  padding-top: 8px;
}

.clients-modal__subtitle {
  font-family: "Manrope";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #102f43;
  margin-bottom: 22px;
}

.clients-modal__buttons {
  display: flex;
  margin-top: 10px;
}

.clients-modal__button {
  font-family: "Manrope";
  background: #ffa500;
  border-radius: 8px;
  height: 44px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  margin-right: 6px;
  font-size: 13px;
}

.clients-modal__button-repeal {
  background: #ffffff;
  border-radius: 8px;
  height: 52px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #0a2333;
  border: 1px solid #efefef;
  margin-left: 6px;
}

.client-modal__search {
  outline: none;
  width: 100%;

  &-wrap {
    margin: 0 32px;
    border: 1px solid #efefef;
    border-radius: 6px;
    padding: 16px 12px;
    width: 278px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}

.search::placeholder {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #102f43;
}

.button {
  border-radius: 8px;
  height: 52px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-right: 6px;
  font-size: 16px;
}
</style>
