import { helpers, maxLength, required } from "@vuelidate/validators";
import { contactsService } from '@/services/index.js';

export const normalizePhone = (phoneString) => {
  if (typeof phoneString !== 'string') return '';
  return phoneString.replace(/^\+7|\D/g, '')
}

export const getAddressTemplate = (incrementValue) => {
  return {
    number: '',
    extension_number: '',
    telephone_type: '',
    id: null,
    contactError: false,
    isRepeat: false,
    autoIncrement: incrementValue.value
  }
}

export const contactValidationRules = (emailValidation) => {
  return {
    name: {
      required: helpers.withMessage('Поле обязательно для заполнения', required),
      maxLength: helpers.withMessage('Поле может содержать не более 128 символов', maxLength(128)),
    },
    position: {
      required: helpers.withMessage('Поле обязательно для заполнения', required),
      maxLength: helpers.withMessage('Максимальная длина email - 128 символов', maxLength(128))
    },
    email: {
      email: helpers.withMessage('Введите корректный email', emailValidation),
      maxLength: helpers.withMessage('Максимальная длина email - 128 символов', maxLength(128))
    },
    comment: {
      maxLength: helpers.withMessage('Максимальная длина email - 256 символов', maxLength(256))
    },
  }
}

export const formPhoneString = (number) => {
  return `${number.number}|${number.extension_number}|${number.telephone_type}`
}

export const contactSaveAdapter = (rawCollection, type, pageId, contactNumbers = []) => {
  if (!rawCollection) return {}
  const payload = {
    name: rawCollection.name || '',
    position: rawCollection.position || '',
    email: rawCollection.email || '',
    comment: rawCollection.comment || '',
  }

  switch (type){
    case 'quarry':
      payload.quarry_id = pageId
      break;
    case 'seaport':
      payload.sea_port_id = pageId
      break;
    case 'client':
      payload.client_id = pageId
      break;
  }

  payload.contact_numbers = contactNumbers.map(number => {
    return {
      id: number.id || null,
      number: normalizePhone(number.number),
      extension_number: number?.extension_number || null,
      telephone_type: number.telephone_type,
    }
  })
  return payload;
}

export const queryExistanceCheck = async (query) => {
  const response = await contactsService.existenceCheckContact(query);
  return response?.data?.data?.success;
}
