<template>
  <div class="submit-input">
    <Input
      :type="type"
      :disabled="isDisabled"
      class="submit-input__input"
      v-model="newValue"
    />
    <button class="submit-input__button" @click="submitValue">
      <SvgIcon name="light-done-icon" width="24" height="24" />
    </button>
  </div>
</template>

<script setup>
import Input from '@/components/Specifications/Input.vue';
import { ref } from 'vue';
import SvgIcon from '@/components/base/SvgIcon.vue';

defineProps({
  type: {
    type: String,
    default: 'text'
  },
  isDisabled: Boolean,
})

const emit = defineEmits(['trigger:submit'])
const newValue = ref(null)

const submitValue = () => {
  emit('trigger:submit', newValue.value)
}
</script>

<style lang="scss" scoped>

.submit-input {
  width: 100%;
  display: flex;
  position: relative;

  &__input {
    height: 40px;
    width: 100%;
    margin: 0;
    z-index: 1;

    :deep(.input-wrap) {
      margin: 0;
    }

    :deep(.modal-input-style) {
      border-radius: 8px;
      background-color: #FFFFFF;
    }
  }

  &__button {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #0055E8;

    &:hover {

    }
  }
}
</style>