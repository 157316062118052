<template>
  <div>
    <h1 class="header-styles mb-3">
      Авторизоваться
    </h1>
    <form id="" action="" class="authorization-form" @submit.prevent="submit">
      <label for="tel" class="authorization-label">Логин</label>
      <BaseInput
        id="tel"
        name="login"
        v-model="form.email"
        placeholder="Введите логин"
        type="email"
        :is-error="v$.form.email.$error"
        :class="{ error: v$.form.email.$error}"
        :error="[
          {
            text: 'E-mail обязателен',
            status: v$.form.email.required.$invalid,
          },
          {
            text: 'Некорректный E-mail',
            status: v$.form.email.email.$invalid,
          }
        ]"
      />
      <label class="authorization-label">Пароль</label>
      <div class="authorization-password">
        <BaseInput
          name="password"
          v-model="form.password"
          placeholder="Введите пароль"
          :type="showPass ? 'password' : 'text'"
          :is-error="v$.form.password.$error"
          :error="[
            {
              text: 'Пароль обязателен',
              status: v$.form.password.required.$invalid,
            }
          ]"
        />
        <SvgIcon
          class="authorization-password-hide"
          name="hide-password"
          @click="showPass = !showPass"
        />
      </div>
      <BaseButton type="submit" success-btn>Войти в систему</BaseButton>
    </form>
    <AlertModal
      ref="alert"
      nameButton="Понятно"
    />

  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput';
import BaseButton from '@/components/base/BaseButton';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { required, email } from '@vuelidate/validators';
import AlertModal from '@/components/AlertModal';
import router from '@/router';
import SvgIcon from '@/components/base/SvgIcon';
import TablePortsPage from "@/views/TablePortsPage.vue";

export default {
  name: 'AuthorizationComponent',
  data() {
    return {
      inputPassword: 'Введите пароль',
      signInError: null,
      showPass: true,
      form: {
        email: '',
        password: '',
      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  components: {
    SvgIcon,
    AlertModal,
    BaseInput,
    BaseButton,
  },
  computed: {
    ...mapGetters({
      error: 'auth/error',
      isAuth: 'auth/isAuth'
    })
  },
  created() {
    this.pushToSpecs()
  },
  methods: {
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        await this.$store.dispatch('auth/signIn', this.form);
        this.pushToSpecs()
      } catch (e) {
        this.$refs.alert.show('Ошибка', e, {
          type: 'error',
          nameButton: 'Понятно',
          color: '#FFA500'
        })
      }
    },
    async pushToSpecs() {
      if (this.isAuth) {
        await this.$router.replace({ name: 'SpecificationsTablePage' });
      }
    }
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        password: {
          required,
        },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button--slot {
  color: #ffffff;
}

.authorization-password {
  position: relative;

  :deep(.authorization-input) {
    padding: 0 38px 0 14px;
  }
}

.authorization-password-hide {
  position: absolute;
  right: 16px;
  top: 12px;
  cursor: pointer;
  transform: translateY(0);
}

.button__forgot-password {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #102F43;
}

</style>
