import { helpers, maxLength, required } from '@vuelidate/validators'
import { validateInn } from '@/utils/helper.js'

export const portsValidationRules = {
  comment: {},
  name: {
    required: helpers.withMessage(
      'Поле обязательно для заполнения',
      required
    ),
    maxLength: helpers.withMessage('Максимальная допустимая длина - 255 символов',
      maxLength(255)
    ),
  },
  inn: {
    required: helpers.withMessage(
      'Поле обязательно для заполнения',
      required
    ),
    validateInn: helpers.withMessage(
      'Введите корректное значение',
      validateInn
    ),
  },
  ogrn: {},
  kpp: {},
  legal_address_symbol: {},
  holding: {},
  holding_id: {},
  registration_date: {},
  head: {},
  responsible: {},
  name_goods: {}
}

export const initialState = (state, company) => {
  state.inn = company.value.inn
  state.ogrn = company.value.ogrn
  state.kpp = company.value.kpp
  state.legal_address_symbol =
    company.value.legal_address_symbol
  state.client_name = company.value.name
  state.name = company.value.short_name
  state.registration_date = company.value?.registration_date
  state.head = company.value?.head

  if (state.client_name.length < 1) {
    state.name = company.value.short_name
    state.client_name = company.value.name
  }

  return state
}
