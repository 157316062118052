<template>
  <div class="map" :class="{ 'map-active-full': isFullScreenMap }">
    <button
      v-if="isFullScreenMap"
      @click="backFullScreen"
      class="map-zoom__btn-full_back"
    >
      <SvgIcon name="arrow_map_back" class="map-zoom__icon"/>
    </button>

    <a
      v-if="specification?.route?.url"
      class="map__link"
      target="_blank"
      :href="specification?.route?.url"
    >
      Показать на Карте
    </a>

    <SpecificationAddresses
      v-if="!isEditorDisabled"
      :is-edit-mode="isEdit"
      :address="address"
      :specification="specification"
      :address-types="addressTypes"
      :is-sync-mode="isSyncMode"
      :distance="distance"
      :is-full-screen-map="isFullScreenMap"
      @updated:address="emitAddressChange"
      @updated:address-debounced="emitAddressChangeDebounced"
      @trigger:build-route="buildRoute"
      @trigger:open-link="$emit('trigger:open-link')"
      ref="address"
    />

    <button @click="toogleFullScreen" class="map-zoom__btn-full">
      <SvgIcon name="map_full_screen" class="map-zoom__icon"/>
    </button>
    <div class="map-zoom__btns">
      <button @click="zoomPlus" class="map-zoom__btn">
        <SvgIcon name="map_plus" class="map-zoom__icon"/>
      </button>
      <button @click="zoomMinus" class="map-zoom__btn">
        <SvgIcon name="map_minus" class="map-zoom__icon"/>
      </button>
    </div>
    <div ref="map" id="map" class="map-big"></div>
    <MapMode />
  </div>
</template>

<script>
import SpecificationAddresses from '@/components/Specifications/SpecificationAddresses.vue';
import SpecificationDistance from './SpecificationDistance.vue';
import { loadYandexMaps } from '@/services/yandexMapLoader';
import SvgIcon from '@/components/base/SvgIcon';
import MapMode from '../InteractiveMap/MapMode.vue';
import { mapGetters } from 'vuex';
import { nextTick } from 'vue';
import { createPolylineFromRoute, debounce } from '@/utils/helper';
import { setWayPointPlacemark } from "@/utils/map/map";

let yandexMap = null;
let multiRoute = null;

export default {
  name: 'SpecificationMap',
  components: {
    SpecificationAddresses,
    SpecificationDistance,
    SvgIcon,
    MapMode,
  },
  props: {
    address: Array,
    viaPoints: Array,
    addressTypes: [Object, null],
    routeLine: [Array, null],
    isSyncMode: Object,
    isEditorDisabled: {
      type: Boolean,
      default: false
    },
    isEdit: Boolean,
    distance:  [String, Number],
  },
  data() {
    return {
      isFullScreenMap: false,
      isHistoryRouteRendered: false,
      addressUpdateDebounce: null,
      multirouteInstance: null,
    };
  },
  computed: {
    ...mapGetters({
      specification: 'specifications/specificationDetail',
      activeMapMode:'interactiveMap/getActiveMapMode'
    }),
  },
  methods: {
    emitAddressChange(updateEvent) {
      this.$emit('updated:address', updateEvent)
    },
    emitAddressChangeDebounced(updateEvent) {
      this.$emit('updated:address-debounced', updateEvent)
    },
    emitPolyline(polyline) {
      this.$emit('updated:polyline', polyline);
    },
    emitDistance (distance) {
      this.$emit('updated:distance', distance);
    },
    zoomPlus() {
      if (!yandexMap) return;
      yandexMap.setZoom(yandexMap.getZoom() + 1, { duration: 300 });
    },
    zoomMinus() {
      if (!yandexMap) return;
      yandexMap.setZoom(yandexMap.getZoom() - 1, { duration: 300 });
    },
    async toogleFullScreen() {
      this.isFullScreenMap = !this.isFullScreenMap;
      await nextTick();
      await yandexMap?.container?.fitToViewport();
    },
    async backFullScreen() {
      this.isFullScreenMap = false;
      await nextTick();
      await yandexMap?.container?.fitToViewport();
    },
    renderStaticRoute() {
      if (!this.routeLine || this.routeLine.length <= 1 || this.isHistoryRouteRendered) return
      const myPolyline = new ymaps.Polyline(this.routeLine, {}, {
        strokeColor: '#8119C2',
        strokeWidth: 4,
        strokeOpacity: 0.6
      });

      yandexMap.geoObjects.add(myPolyline);
      const polylineBounds = myPolyline.geometry.getBounds();

      const firstPoint = setWayPointPlacemark(ymaps, this.routeLine[0], 'A', '/icons/way-point-start.svg',)
      const lastPoint = setWayPointPlacemark(ymaps, this.routeLine[this.routeLine.length - 1], 'B', '/icons/way-point-end.svg',)
      yandexMap.geoObjects.add(firstPoint).add(lastPoint);

      if (polylineBounds) {
        yandexMap.setBounds(polylineBounds);
      }

      this.isHistoryRouteRendered = true;
    },
    buildRoute(paramBuild, isOpenLinkMap) {
      try {
        if (!yandexMap) return;

        // если мультирут уже был инициализирован, удаляем его с карты, обнуляем
        if (multiRoute) {
          yandexMap?.geoObjects?.remove(multiRoute);
          multiRoute = null;
        }

        // затираем статический рут
        yandexMap.geoObjects.removeAll()

        // рисуем статичный рут (сохраненный), если он есть
        this.renderStaticRoute();

        if (!this.address || !this.address.length) return;

        const addresses = this.address.map((address) => {
          if ([this.addressTypes.LOAD, this.addressTypes.UNLOAD].includes(address.type)) {
            return address.coords ? address.coords : address.address?.trim()
          }
          return address.address;
        });

        // если адресов меньше 2, выходим из функции
        const addressesFiltered = addresses.filter(Boolean);
        if (addressesFiltered.length < 2) return;

        if (this.viaPoints?.length) {
          addresses.splice(1, 0, ...this.viaPoints.map(item => item.coordinates.join(',')))
        }
        
        const multiRouteModel = new ymaps.multiRouter.MultiRouteModel(addresses, {
          viaIndexes: this.viaPoints.map((item, index) => index + 1)
        });

        multiRoute = new ymaps.multiRouter.MultiRoute(multiRouteModel, {
          editorDrawOver: false,
          preventDragUpdate: true,
          editorMidPointsType: 'via',
          viaPointIconRadius: 4,
          boundsAutoApply: true,
          zoomMargin: 20,
          viaPointIconFillColor: '#4d4d4d',
          viaPointActiveIconFillColor: '#4d4d4d',
          pinIconFillColor: "#494949",
          wayPointFinishIconColor: "#FFFFFF",
          wayPointFinishIconFillColor: "#000000",
        });

        yandexMap.geoObjects.add(multiRoute);
        multiRoute.events.add('update', (event) => this.addressUpdateDebounce(event, multiRoute));
        multiRoute.events.add('activeroutechange', (event) => this.addressUpdateDebounce(event, multiRoute));
        multiRoute.editor.start();

        if (isOpenLinkMap) {
          this.$emit('trigger:open-link');
        }
      } catch(e) {
        this.$emit('fire:exception', 'Ошибка построения маршрута, пожалуйста, перезагрузите страницу');
        if (multiRoute) {
          yandexMap?.geoObjects?.remove(multiRoute);
          multiRoute = null;
        }
      }
    },
    updateAddress(changeEvent, multiRouteInstance) {
      try {
        const oldWaypoints = this.address.map(item => item.coords);
        const wayPoints = multiRouteInstance.getWayPoints();
        const viaPoints = multiRouteInstance.getViaPoints();
        const wayPointsData = []
        const viaPointsData = [];
        wayPoints.each((waypoint) => {
          wayPointsData.push(waypoint.geometry.getCoordinates()?.join(','))
        });

        viaPoints.each((viaPoint) => {
          viaPointsData.push({
            coordinates: viaPoint.geometry.getCoordinates(),
            pathIndex: viaPoint.properties._data.pathIndex
          });
        });

        this.emitAddressChange({
          value: viaPointsData,
          index: 0,
          type: this.addressTypes.VIA_POINT,
        })

        let index = 0;
        while(index < oldWaypoints.length) {
          if (oldWaypoints[index] !== wayPointsData[index]) break;
          index++
        }

        const isNotChanged = index === oldWaypoints.length;

        // преобразуем маршрут в ломаную для последующего сохранения
        const routePolyline = createPolylineFromRoute(multiRouteInstance);
        const distance = multiRouteInstance.getActiveRoute().properties.get('distance').text;
        this.emitPolyline(routePolyline);
        this.emitDistance(distance);

        if (isNotChanged) return;

        if (this.address[index].type === this.addressTypes.LOAD || this.address[index].type === this.addressTypes.UNLOAD) {
          this.emitAddressChange({ value: wayPointsData[index], type: `${this.address[index].type}|coords` })
          // отправляем данные в компонент адреса, на случай, если синхронизация данных отключена
          this.$refs.address.recalculateLocalAddress(wayPointsData[index], this.address[index].type)
          return;
        }

        this.emitAddressChange({
          value: wayPointsData[index],
          index: index,
          type: `${this.address[index].type}|coords`
        })
      } catch(error) {
        this.$emit('fire:exception', 'Ошибка построения маршрута');
        if (multiRoute) {
          yandexMap?.geoObjects?.remove(multiRoute);
          multiRoute = null;
        }
        throw new Error(error)
      }
    },
    recalculateLocalAddress({ coords, type }) {
      this.$refs.address.recalculateLocalAddress(coords, type)
    },
    buildArrowBySpecification (route) {
      if (!route) return;

      // если если мультирут, удаляем его с карты
      if (multiRoute) {
        yandexMap?.geoObjects?.remove(multiRoute);
        multiRoute = null;
      }

      const routePolyline = new ymaps.Polyline(route?.route_line, {}, {
        strokeColor: '#000000',
        strokeWidth: 4,
        strokeOpacity: 0.6
      })
      yandexMap.geoObjects.add(routePolyline);
      const polylineBounds = routePolyline?.geometry?.getBounds() || null;
      if (polylineBounds) {
        yandexMap?.setBounds(polylineBounds);
      }
    },
  },
  watch: {
    isFullScreenMap(val) {
      this.$emit('toggleFull', val)
    },
    routeLine() {
      this.renderStaticRoute()
    },
    activeMapMode () {
      yandexMap.setType(this.activeMapMode.code)
    },
  },
  async mounted() {
    await loadYandexMaps({ apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY });
    this.addressUpdateDebounce = debounce(this.updateAddress, 1000)
    yandexMap = new ymaps.Map('map', {
      center: [55.76, 37.64],
      zoom: 10,
      controls: [],
    }, {
      autoFitToViewport: 'always',
      suppressMapOpenBlock: true,
    });

    yandexMap.setType(this.activeMapMode.code)

    await nextTick();
    this.renderStaticRoute();
  },
  async unmounted() {
    if (!yandexMap) return;
    yandexMap = null;
  }
};
</script>

<style lang="scss" scoped>
.input-errors_wrap {
  margin-bottom: 5px;
}

.map {
  position: relative;
  margin: 14px 10px;
  height: 100vh;
  width: calc(100% - 20px);
  z-index: 9;

  &__link {
    position: absolute;
    transform: translateY(-100%);
    background-color: rgba(255,255,255,.85);
    bottom: 12px;
    left: 5px;
    z-index: 2;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    padding: 3px 12px 3px 24px;
    text-transform: none;
    color: #0A2333;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0Y0MyIgZD0iTTcgMGE3IDcgMCAwIDAtNC45NSAxMS45NWMxLjI3IDEuMjcgNC4yNSAzLjEgNC40MiA1LjAzLjAzLjI4LjI0LjUyLjUzLjUyLjI5IDAgLjUtLjI0LjUzLS41Mi4xNy0xLjkzIDMuMTUtMy43NiA0LjQyLTUuMDNBNyA3IDAgMCAwIDcgMHoiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNyA5LjQ1YTIuNDUgMi40NSAwIDEgMCAwLTQuOSAyLjQ1IDIuNDUgMCAwIDAgMCA0Ljl6Ii8+PC9zdmc+);
    background-position: 6px center;
    background-repeat: no-repeat;
  }

  &.map-active-full {
    .map__link {
      transform: translateY(0);
      bottom: 5px;
    }

  }

  &-transit__btn {
    color: #0a2333;
    font-weight: 600;
    font-size: 13px;
    margin-top: 7px;
    margin-right: 12px;

    &:disabled {
      color: #b4b4b4;
    }
  }

  .no_active {
    display: none;
  }

  .fake-placeholder {
    display: none;
  }

  .specification-input-address::placeholder {
    font-size: 0;
  }

  .specification-input-address:placeholder-shown + .fake-placeholder {
    display: block;
    padding-left: 18px;
    background-image: url("@/assets/img/address-black.svg");
    background-repeat: no-repeat;
    background-position: 0 50%;
    position: absolute;
    font-size: 14px;
    top: 16px;
    left: 15px;
    z-index: 2;
    pointer-events: none;
  }

  .specification-input-address:placeholder-shown {
    padding-left: 30px;
  }

  .specification-input-address {
    border: 1px solid transparent;
    width: 100%;

    &.error {
      border-color: red;
    }

    &[disabled] {
      cursor: not-allowed;
    }

    &_wrap {
      position: relative;

      .error-text {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #d72028;
        margin-bottom: 5px;
      }

      input {
        padding-right: 26px;
      }

      .remove-transit-point {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        right: 7px;
        top: 16px;
        z-index: 10;
        cursor: pointer;
      }
    }
  }

  &-inputs__wrap {
    z-index: 10;
    position: absolute;
    background: #ffffff;
    border-radius: 12px;
    padding: 15px;
    left: 30px;
    top: 100px;
  }

  &-active-full {
    position: absolute;
    height: calc(100% - 28px);
    z-index: 9;

    .map-big {
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
  }

  &-big {
    height: calc(100vh - 28px);
    width: 100%;
  }

  &-zoom__btns {
    z-index: 10;
    position: absolute;
    right: 38px;
    top: 120px;
    display: flex;
    flex-direction: column;
  }

  &-zoom__icon {
    width: 24px;
    height: 24px;
    margin: 10px;
  }

  &-zoom__btn {
    background: white;
    color: #0a2333;

    &-full {
      z-index: 10;
      border-radius: 6px;
      position: absolute;
      background: white;
      top: 31px;
      right: 38px;
      width: 44px;
      height: 44px;
    }

    &-full_back {
      z-index: 10;
      border-radius: 6px;
      position: absolute;
      background: white;
      top: 10px;
      left: 30px;
      width: 44px;
      height: 44px;
    }

    &:first-child {
      border-radius: 6px 6px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 6px 6px;
    }
  }
}

.map-inputs__wrap_invisible {
  display: none;
}
</style>
