<template>
  <div class="filter-templates-block">
    <p :class="['filter-templates-block__title', {filters: 'edit'}]">
      {{ templateFilterId ? 'Шаблоны' : 'Создание нового шаблона' }}
      <button v-if="templateFilterId" @click="openEditModal">
        <SvgIcon name="settings"/>
      </button>
    </p>

    <BaseDropdown
      placeholder="Название шаблона"
      v-model="templateFilterId"
      :list="list"
      special-btn-title="Создать новый шаблон"
      item-icon="trash"
      @click-item="openDeleteModal"
      @special-click="createOpenModal"
      @update:modelValue="updateFilter"
      class="dropdown-filters"
      dropdown-icon="arrow-top-bottom"
      :is-rotate="false"
    />

    <FilterModalCreate
      ref="templates"
      @close="closeModal"
      @create="createTemplate"
      @edit="updateFilterServer"
    />

    <AlertModal
      ref="warning"
      :close-button="true"
      name-button="Нет"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import FilterModalCreate from '@/components/filter-templates/FilterModalCreate.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import AlertModal from '@/components/AlertModal.vue';
import SvgIcon from '@/components/base/SvgIcon.vue';

export default {
  name: 'FilterTemplates',
  components: { SvgIcon, AlertModal, FilterModalCreate, BaseDropdown },
  emits: ['update', 'select'],
  props: {
    filterPage: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter()
    const templateFilterId = ref(null)

    const list = computed(() => {
      if (props.filterPage === 'specifications') {
        return store.getters['filter/filterSpecList']
      }
      if (props.filterPage === 'clients') {
        return store.getters['filter/filterClientsList']
      }
      if (props.filterPage === 'quarry') {
        return store.getters['filter/filterQuarryList']
      }
      if (props.filterPage === 'ports') {
        return store.getters['filter/filterPortList']
      }
      return []
    })

    const warning = ref(null)
    const templates = ref(null)

    const filterCurrent = computed(() =>
      store.getters['filter/currentFilterStr']
    )

    const openDeleteModal = (id) => {
      warning.value.show(
        'Внимание!',
        'Вы уверены, что хотите удалить шаблон фильтров?',
        {
          type: 'error',
          nameButton: 'Да',
          color: '#FFA500',
        }, () => {
          deleteItem(id)
          warning.value.closeModal()
        }
      )
    }

    const deleteItem = async (id) => {
      if (props.filterPage === 'specifications') {
        try {
          await store.dispatch('filter/deleteSpecItem', id)
          closeModal();
          templateFilterId.value = null
          await store.dispatch('filter/getFilterSpecList')
        } catch (e) {
          console.log(e)
        }
      }
      if (props.filterPage === 'clients') {
        try {
          await store.dispatch('filter/deleteClientsItem', id)
          closeModal();
          templateFilterId.value = null
          await store.dispatch('filter/getFilterClientsList')
        } catch (e) {
          console.log(e)
        }
      }
      if (props.filterPage === 'quarry') {
        try {
          await store.dispatch('filter/deleteQuarryItem', id)
          closeModal();
          templateFilterId.value = null
          await store.dispatch('filter/getFilterQuarryList')
        } catch (e) {
          console.log(e)
        }
      }

      if (props.filterPage === 'ports') {
        try {
          await store.dispatch('ports/deletePortTemplateFilter', id)
          closeModal();
          templateFilterId.value = null
          await store.dispatch('filter/getPortsFilters')
        } catch (e) {
          console.log(e)
        }
      }
    }

    const updateFilter = (value) => {
      let select = list.value.find((item) => item.id === value)
      let url = select.url.replace(/['"]+/g, '')
      router.replace(`${url}`)
      emit('select', value)
    }

    const updateFilterServer = async (payload) => {
      if (!payload) return;
      try {
        if (props.filterPage === 'specifications') {
          emit('update')
          await store.dispatch('filter/updateSpecItem', payload)
          await store.dispatch('filter/getFilterSpecList')
          templates.value.toggleModal(false)
        }
        if (props.filterPage === 'clients') {
          emit('update')
          await store.dispatch('filter/updateClientsItem', payload)
          await store.dispatch('filter/getFilterClientsList')
          templates.value.toggleModal(false)
        }
        if (props.filterPage === 'quarry') {
          emit('update')
          await store.dispatch('filter/updateQuarryItem', payload)
          await store.dispatch('filter/getFilterQuarryList')
          templates.value.toggleModal(false)
        }
        if (props.filterPage === 'ports') {
          emit('update')
          await store.dispatch('filter/updatePortsItem', payload)
          await store.dispatch('filter/getPortsFilters')
          templates.value.toggleModal(false)
        }
      } catch (error) {
        warning.value.show(
          'Внимание!',
          'Ошибка: ' + error?.message || 'Серверная ошибка',
          { type: 'error', nameButton: 'Да', color: '#FFA500' }, () => {
            warning.value.closeModal();
            closeModal();
          }
        )
      }
    }

    const createTemplate = async (val) => {
      if (!filterCurrent.value) return;
      try {
        if (props.filterPage === 'specifications') {
          await store.dispatch('filter/createSpecFilter', val)
          closeModal();
          await store.dispatch('filter/getFilterSpecList')
        }
        if (props.filterPage === 'clients') {
          await store.dispatch('filter/createClientsFilter', val)
          closeModal();
          await store.dispatch('filter/getFilterClientsList')
        }
        if (props.filterPage === 'quarry') {
          await store.dispatch('filter/createQuarryFilter', val)
          closeModal();
          await store.dispatch('filter/getFilterQuarryList')
        }
        if (props.filterPage === 'ports') {
          await store.dispatch('filter/createPortsFilters', {
            name: val,
            url: filterCurrent.value
          })
          closeModal();
        }

      } catch (error) {
        warning.value.show(
          'Внимание!',
          'Ошибка: ' + error?.message || 'Серверная ошибка',
          { type: 'error', nameButton: 'Да', color: '#FFA500' }, () => {
            warning.value.closeModal();
            closeModal();
          }
        )
      }
    }

    const createOpenModal = () => {
      emit('update')
      templates.value.toggleModal(true)
    }

    const openEditModal = () => {
      emit('update')
      const current = list.value.find(template => template.id === templateFilterId.value)
      templates.value.toggleModal(true, true, current)
    }

    const closeModal = () => {
      templates.value.toggleModal(false)
    }
    const clearValue = () => {
      templateFilterId.value = null
    }

    onMounted(() => {
      if (router.currentRoute.value.href.split('?')[1]) {
        const index = list.value.findIndex(item => item.url.replace(/[/?]|=null|"/g, '') === router.currentRoute.value.href.split('?')[1])

        if (index !== -1) {
          templateFilterId.value = list.value[index].id
        }
      }
    })

    return {
      templateFilterId,
      list,
      warning,
      templates,
      deleteItem,
      createOpenModal,
      closeModal,
      createTemplate,
      updateFilter,
      openDeleteModal,
      updateFilterServer,
      openEditModal,
      clearValue
    }
  }
}
</script>

<style scoped lang="scss">
.filter-templates-block {
  width: 100%;
  margin-bottom: 24px;

  &__title {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
}
</style>
