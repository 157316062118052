import ApiUtil from '@/utils/api'

export default {
  // получить данные по мета маршруту
  calculateMetaRoute: (data) =>
    ApiUtil({
      url: '/api/v1/meta-routes/calculate',
      method: 'post',
      requiresAuth: true,
      data,
    }),
  // создать мета-маршрут
  createMetaRoute: (data) =>
    ApiUtil({
      url: '/api/v1/meta-routes',
      method: 'post',
      requiresAuth: true,
      data,
    }),
  // получить инфу о мета маршруте
  getMetaRoute: (slug) =>
    ApiUtil({
      url: `/api/v1/meta-routes/${slug}`,
      method: 'get',
      requiresAuth: true,
    }),
  // редактировать мета маршрут
  updateMetaRoute: (data) =>
    ApiUtil({
      url: `/api/v1/meta-routes/${data.id}`,
      method: 'patch',
      requiresAuth: true,
      data,
    }),
}
