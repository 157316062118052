<template>
  <div>
    <v-snackbar
      v-model="isSnackbarActive"
      :timeout="1500"
      location="top-right"
      :color="snackbarColor"
      absolute
    >
      {{ snackbarText }}
    </v-snackbar>
    <BackgroundComponent class="new-specification">
      <div class="card__header display-f__jc-sb py-25-px-0">
        <div class="display-f__jc-sb-ai-c new-specification__header">
          <SvgIcon
            name="arrow-back"
            @click="goBack"
            class="ml-n4 mr-2 arrow-back"
          />
          <h1 class="card__title">
            {{ isEditSpec ? "Редактировать спецификацию" : "Новая спецификация" }}
          </h1>
          <div v-if="isEditSpec" class="card__id">{{ formState.id }}</div>
        </div>
        <div v-if="isEditSpec" class="date-time-map">
          <div class="card__date-time">{{ date.date }}</div>
          <div class="card__date-time">{{ date.time }}</div>
        </div>
      </div>
      <div class="specifications-scroll">
        <div class="form-wrap">
          <div>
            <v-row class="new-specification__info mb-5">
              <v-col v-if="isEditSpec" class="new-specification__input">
                <Input
                  id="recommended_price"
                  class="specification-input-logistic specification-input-logistic_id"
                  name="recommended_price"
                  label="ID спецификации"
                  v-model.number="formState.id"
                  disabled
                  placeholder="Введите"
                  type="number"
                  :errors="v$.formState.id?.$errors"
                  :inputClass="{
                    'dark-border': true,
                  }"
                />
              </v-col>

              <v-col class="specification__select">
                <span class="specification__label">Тип запроса</span>
                <BaseDropdown
                  class="mt-1"
                  :isWidth="true"
                  placeholder="Выберите"
                  :is-disabled="checkIfIsDisabled('request_type')"
                  :list="requestType"
                  :errors="v$.formState.request_type_id?.$errors"
                  v-model="formState.request_type_id"
                  :isError="v$.formState.request_type_id.$error"
                />
              </v-col>

              <v-col class="specification__select">
                <span class="specification__label">Статус запроса</span>
                <BaseDropdown
                  class="mt-1"
                  :isWidth="true"
                  placeholder="Выберите"
                  v-model="formState.request_status_id"
                  :is-disabled="checkIfIsDisabled('request_status')"
                  :list="requestStatus"
                  :errors="v$.formState.request_status_id?.$errors"
                  :isError="v$.formState.request_status_id.$error"
                />
              </v-col>

              <v-col
                class="specification__select"
              >
                <span class="specification__label">Статус спецификации</span>
                <BaseDropdown
                  class="mt-1"
                  :isWidth="true"
                  placeholder="Выберите"
                  v-model="formState.specification_status_id"
                  :is-disabled="checkIfIsDisabled('specification_status')"
                  :list="specificationStatus"
                  :errors="v$.formState.specification_status_id?.$errors"
                  :isError="v$.formState.specification_status_id.$error"
                />
              </v-col>
            </v-row>
            <h2 class="specification-subtitle">Клиент</h2>
            <v-row class="new-specification__search-checkbox">
              <v-col class="input-type-number specification-input__search v-col-6">
                <Input
                  id="search"
                  class="new-specification-input"
                  name="search"
                  label="ИНН"
                  v-model="searchCompanyInn"
                  :disabled="searchCompany.disabledINN || checkIfIsDisabled('company_unload')"
                  placeholder="Поиск по ИНН"
                  :errors="v$.formState.company_unload?.inn?.$errors"
                  :inputClass="{ error: searchCompany.error || v$.formState.company_unload.inn?.$error }"
                  v-maska="{
                    mask: 'HHHHHHHHHHHH',
                    tokens: { H: { pattern: /[0-9]/ } },
                  }"
                />
              </v-col>
              <v-col class="display-flex v-col-6">
                <button
                  @click="sendSearchCompany"
                  type="button"
                  class="search-button"
                  :disabled="checkIfIsDisabled('company_unload')"
                >
                  <SvgIcon name="search"/>
                  <BaseTooltip text="Поиск" />
                </button>
                <div class="specification__checkbox specification__checkbox-person">
                  <input
                    type="checkbox"
                    id="type-person"
                    :disabled="searchCompany.disabledType || checkIfIsDisabled('company_unload')"
                    name="type-person"
                    :class="['custom-checkbox', {'custom-checkbox_disabled': isEditSpec }]"
                    v-model="searchCompany.type"
                  />
                  <label for="type-person">
                    <span class="checkbox-name specification-checkbox-name">Физическое лицо</span>
                  </label>
                </div>
              </v-col>

            </v-row>
            <div class="attention-block">
              <div
                v-if="searchCompany.errorType.success"
                class="attention-item"
              >
                <SvgIcon name="attention" class="attention-img"/>
                Организация не найдена
              </div>
              <div
                v-if="searchCompany.errorType.notAvailable"
                class="attention-item"
              >
                <SvgIcon name="attention" class="attention-img"/>
                Сервис "Контур" временно недоступен
              </div>
            </div>

            <v-row class="specifications__flex-block mb-5">
              <v-col class="mt-n1 p-r">
                <Input
                  id="name"
                  class="specification-input__search new-specification-input"
                  name="name"
                  label="Контрагент"
                  :disabled="
                    searchCompany.disabledClients ||
                      checkIfIsDisabled('company_unload')
                  "
                  v-model="nameCounterparty"
                  type="text"
                  placeholder="Введите название контрагента"
                  :errors="v$.formState.company_unload?.name?.$errors"
                  :inputClass="{
                    error: v$.formState.company_unload?.name?.$error,
                    'input-bold': true
                  }"
                />
                <div class="search-drop-down" v-click-outside="checkOutside">
                  <div
                    v-for="item of counterpartes"
                    :key="item.id"
                    @click="searchClientCompany(item)"
                    class="search-drop-down__item"
                  >
                    <span class="company-name">{{ item.name }}</span>
                    <span class="company-address">
                      {{ item.adress }}
                    </span>
                  </div>
                </div>
              </v-col>

              <v-col
                v-if="formState.company_unload?.client_id"
                class="specifications__clients-card"
              >
                <div class="specifications__clients-link"
                     @click="sendClient"
                >
                  <SvgIcon name="person" class="attention-img" />
                  Карточка клиента
                </div>
              </v-col>
            </v-row>

            <h2 class="specification-subtitle">Даты работ</h2>

            <v-row class="specifications__flex-block mb-5">
              <v-col class="specifications__flex-block-date-start position-relative" id="selector-date-start">
                <span class="specification__label">Начало</span>
                <BaseDatepicker
                  @changeValue="changeDateStart($event)"
                  :date-value="dateStart"
                  teleport-selector="#selector-date-start"
                  :errors="v$.formState.start?.$errors"
                  placeholder="дд.мм.гггг"
                  customFormatting="dd.LL.yyyy"
                  :disabled="checkIfIsDisabled('start')"
                  :inputClass="{ error: v$.formState.start?.$error }"
                />
              </v-col>

              <v-col class="specifications__flex-block-date-finish position-relative" id="selector-date-end">
                <span class="specification__label">Конец</span>
                <BaseDatepicker
                  @changeValue="changeDateEnd($event)"
                  :date-value="dateEnd"
                  teleport-selector="#selector-date-end"
                  :errors="v$.formState.end?.$errors"
                  placeholder="дд.мм.гггг"
                  customFormatting="dd.LL.yyyy"
                  :disabled="checkIfIsDisabled('end')"
                  :inputClass="{ error: v$.formState.end?.$error }"
                />
              </v-col>
            </v-row>

            <h2 class="specification-subtitle">Груз</h2>

            <v-row class="specification-cargo mb-5">
              <v-col class="specification__select">
                <span class="specification__label">Тип материала</span>
                <BaseDropdown
                  :isWidth="true"
                  placeholder="Выберите"
                  v-model="formState.material_type"
                  :is-disabled="checkIfIsDisabled('material_type')"
                  :isError="v$.formState.material_type.$error"
                  :errors="v$.formState.material_type?.$errors"
                  :list="[
                    { name: 'Нерудная продукция', id: true },
                    { name: 'Сельскохозяйственная продукция', id: false },
                  ]"
                  @update:modelValue="clearCargo"
                />
              </v-col>

              <v-col class="specification__select">
                <span class="specification__label">Наименование груза</span>
                <BaseDropdown
                  :isWidth="true"
                  placeholder="Выберите"
                  :is-disabled="checkIfIsDisabled('name_good')"
                  :errors="v$.formState.name_good_id?.$errors"
                  v-model="formState.name_good_id"
                  :isError="v$.formState.name_good_id.$error"
                  :list="nameGoodFiltered"
                />
              </v-col>

              <v-col
                v-if="formState.material_type === true"
                class="specification__select"
              >
                <span class="specification__label">Марка прочности</span>
                <BaseDropdown
                  :isWidth="true"
                  placeholder="Выберите"
                  :is-disabled="checkIfIsDisabled('strength_grade') || formState.material_type === false"
                  v-model="formState.strength_grade_id"
                  :errors="v$.formState.strength_grade_id?.$errors"
                  :isError="v$.formState.strength_grade_id.$error"
                  :list="strengthGrade"
                />
              </v-col>

              <v-col
                v-if="formState.material_type === true"
                class="specification__select"
              >
                <span class="specification__label">Фракция</span>
                <BaseDropdown
                  :isWidth="true"
                  placeholder="Выберите"
                  :is-disabled="checkIfIsDisabled('fraction')"
                  :errors="v$.formState.fraction_id?.$errors"
                  v-model="formState.fraction_id"
                  :isError="v$.formState.fraction_id.$error"
                  :list="fraction"
                />
              </v-col>
            </v-row>

            <SpecificationAddressForm
              :is-edit="isEditSpec"
              :address="addressInstance?.routeList || []"
              :is-sync-mode="isSyncMode"
              :working-schedule="addressInstance?.getWorkingSchedule() || []"
              :address-types="addressTypes"
              :validation-errors="v$.formState.route"
              :address-load-list="portsList"
              @toggled:is-sync-mode="updateIsSyncMode"
              @updated:address-debounced="debouncedAddressUpdate"
              @updated:address="updateAddress"
              @updated:entity="updateEntity"
              @updated:working-schedule="updateWorkingSchedule"
              @trigger:share-route="shareCurrentRoute"
              @trigger:build-route="(paramBuild, isOpenLinkMap) => $refs.mapSpecification.buildRoute(paramBuild, isOpenLinkMap)"
              @trigger:update-local-address="(event) => $refs.mapSpecification.recalculateLocalAddress(event)"
              @update:adress-coords="(event) => updateAddressCoords(event)"
              @trigger:open-link="openLink"
              :specification="specification"
              :permissions="addressFormPermissions"
            />

            <h2 class="specification-subtitle">Логистика</h2>

            <v-row class="flex-block mb-5">
              <v-col class="position-relative">
                <div class="specification__label">Плечо, км</div>
                <div
                  class="cost-material flex-input-bold"
                  :class="{
                    'text-error-wrap': v$.formState?.distance?.$errors.length,
                  }"
                >
                  <span>{{ formState.distance }}</span>
                </div>
                <div v-if="v$.formState?.distance?.$error" class="text-error p-a mt-1">
                  <span> {{ v$.formState?.distance?.$errors[0].$message }}</span>
                </div>
              </v-col>
              <v-col>
                <Input
                    id="flights"
                    class="specification-input-logistic"
                    name="flights"
                    label="Кол.план. рейсов"
                    v-model.number="formState.flights"
                    placeholder="Введите"
                    type="number"
                    :errors="v$.formState.flights?.$errors?.slice(0, 1)"
                    :inputClass="{
                      error: v$.formState.flights?.$error,
                      'input-bold': true
                    }"
                    :disabled="checkIfIsDisabled('flights')"
                >
                  <template #input-icon>
                    <button class="synchronize-btn" :disabled="!formState?.distance || !formState?.cost_transportation_ADT || !formState?.coefficient" @click="getFlightsCount" @mouseenter="flightsCalcHover = true" @mouseleave="flightsCalcHover = false">
                      <SvgIcon name="synchronize-hover" width="36" height="36" class="synchronize-btn__icon" v-if="flightsCalcHover" />
                      <SvgIcon name="synchronize" width="36" height="36" class="synchronize-btn__icon" v-else />
                      <BaseTooltip text="Сделать перерасчет количества рейсов" />
                    </button>
                  </template>
                </Input>
              </v-col>
              <v-col>
                <Input
                  id="cars"
                  class="specification-input-logistic"
                  name="cars"
                  label="Кол. машин"
                  v-model.number="formState.cars"
                  placeholder="Введите"
                  disabled
                  type="number"
                  :errors="v$.formState.cars?.$errors"
                  :inputClass="{
                    error: v$.formState.cars?.$error,
                    'input-bold': true
                  }"
                />
              </v-col>
            </v-row>

            <h2 class="specification-subtitle">Условия оплаты</h2>

            <div class="flex-block mb-2">
              <RadioComponent
                id="1"
                name="payment_type"
                v-model="paymentType"
                :class="{
                  'input-search-error': v$.formState.payment_type?.$error,
                }"
                RadioName="Предоплата"
                :disabled="checkIfIsDisabled('payment_type')"
              />
              <RadioComponent
                id="0"
                name="payment_type"
                v-model="paymentType"
                RadioName="Постоплата"
                :class="{
                  'input-search-error': v$.formState.payment_type?.$error,
                }"
                class="ml-6"
                :disabled="checkIfIsDisabled('payment_type')"
              />
            </div>

            <v-row v-show="paymentType === '0'" class="flex-block mb-5">
              <v-col class="specification__select mt-n1">
                <span class="specification__label">Тип документа</span>
                <BaseDropdown
                  :isWidth="true"
                  placeholder="Выберите"
                  :errors="v$.formState.document_type_id?.$errors"
                  v-model="formState.document_type_id"
                  :isError="v$.formState.document_type_id.$error"
                  :is-disabled="checkIfIsDisabled('document_type')"
                  :list="documentType"
                />
              </v-col>
              <v-col>
                <Input
                  id="postponement_days"
                  class="specification-input-logistic"
                  name="postponement_days"
                  label="Количество дней"
                  v-model.number="formState.postponement_days"
                  placeholder="Введите"
                  type="number"
                  :disabled="checkIfIsDisabled('postponement_days')"
                  :errors="v$.formState.postponement_days?.$errors"
                  :inputClass="{
                    error: v$.formState.postponement_days?.$error,
                    'input-bold': true
                  }"
                  v-maska="{
                    mask: 'HHHHHHH',
                    tokens: { H: { pattern: /[0-9]/ } },
                  }"
                />
              </v-col>
              <v-col>
                <div class="display-flex">
                  <Input
                    v-show="formState.prepayment_type === 'amount'"
                    id="prepaid_expense"
                    class="specification-input-logistic"
                    name="prepaid_expense"
                    label="Аванс"
                    v-model="formState.prepaid_expense"
                    :disabled="checkIfIsDisabled('prepaid_expense')"
                    placeholder="Введите"
                    :errors="v$.formState.prepaid_expense?.$errors"
                    :inputClass="{
                      error: v$.formState.prepaid_expense?.$error,
                      'input-bold': true,
                      'input-start': true
                    }"
                    :is-currency="false"
                    price-format
                  />
                  <Input
                    v-show="formState.prepayment_type !== 'amount'"
                    id="prepaid_expense"
                    class="specification-input-logistic"
                    name="prepaid_expense"
                    label="Аванс"
                    v-model="formState.prepaid_expense"
                    :disabled="checkIfIsDisabled('prepaid_expense')"
                    placeholder="Введите"
                    :errors="v$.formState.prepaid_expense?.$errors"
                    :inputClass="{
                      error: v$.formState.prepaid_expense?.$error,
                      'input-bold': true,
                      'input-start': true
                    }"
                    is-left
                  />

                  <div class="form-field prepaid-type-block">
                    <!-- TODO разобраться с этим типом данных -->
                    <BaseDropdown
                      :isWidth="true"
                      placeholder="Выберите"
                      :is-disabled="checkIfIsDisabled('prepaid_expense')"
                      v-model="formState.prepayment_type"
                      :isError="v$.formState.prepayment_type.$error"
                      :errors="v$.formState.prepayment_type?.$errors"
                      :list="prepaidTypeList"
                      is-right
                      no-search
                    />
                  </div>
                </div>
              </v-col>
            </v-row>

            <h2 class="specification-subtitle">Условия поставки</h2>

            <v-row class="flex-block mb-5">
              <v-col class="specification__select mt-n1">
                <span class="specification__label">Норма загрузки, тонн</span>
                <BaseDropdown
                  :isWidth="true"
                  placeholder="Выберите"
                  v-model="formState.loading_rate_id"
                  :is-disabled="checkIfIsDisabled('loading_rate')"
                  :list="loadingRate"
                  :isError="v$.formState.loading_rate_id.$error"
                  :errors="v$.formState.loading_rate_id?.$errors"
                />
              </v-col>
              <v-col>
                <Input
                  id="daily_rate"
                  class="specification-input-logistic"
                  name="daily_rate"
                  label="Сут. норма клиента, тонн"
                  v-model.number="formState.daily_rate"
                  :disabled="checkIfIsDisabled('daily_rate')"
                  placeholder="Введите"
                  type="number"
                  :errors="v$.formState.daily_rate?.$errors"
                  :inputClass="{
                    error: v$.formState.daily_rate?.$error,
                    'input-bold': true
                  }"
                  v-maska="{
                    mask: 'HHHHHHH',
                    tokens: { H: { pattern: /[0-9]/ } },
                  }"
                />
              </v-col>
              <v-col>
                <Input
                  id="total_weight"
                  class="specification-input-logistic"
                  name="total_weight"
                  :disabled="checkIfIsDisabled('total_weight')"
                  label="Кол. груза, тонн"
                  v-model.number="formState.total_weight"
                  placeholder="Введите"
                  type="number"
                  :errors="v$.formState.total_weight?.$errors"
                  :inputClass="{
                    error: v$.formState.total_weight?.$error,
                    'input-bold': true
                  }"
                  v-maska="{
                    mask: 'HHHHHHH',
                    tokens: { H: { pattern: /[0-9]/ } },
                  }"
                />
              </v-col>
            </v-row>

            <h2 class="specification-subtitle">Стоимость поставки</h2>

            <div class="supply-row supply-row--first">
              <v-col>
                <Input
                  id="cost_material"
                  class="specification-input-logistic"
                  name="cost_material"
                  label="Стоимость материала"
                  v-model="formState.cost_material"
                  :disabled="checkIfIsDisabled('cost_material')"
                  placeholder="Введите"
                  :errors="v$.formState.cost_material?.$errors"
                  :inputClass="{
                    'cost-transportation-input': true,
                    'input-bold': true,
                    error: v$.formState.cost_material?.$error,
                  }"
                  price-format
                />
              </v-col>

              <v-col>
                <span class="specification__label d-b mb-0">Стоимость перевозки 1 тонны</span>
                <div class="display-flex">
                  <Input
                    id="cost_transportation_ADT"
                    name="cost_transportation_ADT"
                    input-bold
                    v-model="formState.cost_transportation_ADT"
                    :disabled="costTransportationADTDisabled || checkIfIsDisabled('cost_transportation_ADT')"
                    before-text="АДТ"
                    @input="transportationADTDebounce"
                    :errors="v$.formState.cost_transportation_ADT?.$errors"
                    :inputClass="{
                      'cost-transportation-input': true,
                      'cost-transportation-left': true,
                      'input-bold': true,
                      error: v$.formState.cost_transportation_ADT?.$error,
                    }"
                    price-format
                    is-left
                  />
                  <Input
                    id="cost_transportation_client"
                    name="cost_transportation_client"
                    v-model="formState.cost_transportation_client"
                    :disabled="checkIfIsDisabled('cost_transportation_client')"
                    before-text="Клиент"
                    :errors="v$.formState.cost_transportation_client?.$errors"
                    :inputClass="{
                      'cost-transportation-input': true,
                      'cost-transportation-right': true,
                      'input-bold': true,
                      error: v$.formState.cost_transportation_client?.$error,
                    }"
                    is-right
                    price-format
                  />
                </div>
              </v-col>
              <v-col>
                <div>
                  <Input
                    id="recommended_price"
                    class="specification-input-logistic specification-input-logistic__recommendation"
                    name="recommended_price"
                    label="Рекомендуемая цена"
                    v-model="formState.recommended_price"
                    placeholder="Введите"
                    :errors="v$.formState.recommended_price?.$errors"
                    :inputClass="{
                      'cost-transportation-input': true,
                      'input-bold': true,
                      error: v$.formState.recommended_price?.$error,
                    }"
                    :disabled="checkIfIsDisabled('recommended_price')"
                    price-format
                  />
                </div>
              </v-col>
              <v-col>
                <Input
                  id="coefficient"
                  class="specification-input-logistic"
                  name="coefficient"
                  label="Коэффициент"
                  v-model.number="formState.coefficient"
                  :disabled="coefficientDisabled || checkIfIsDisabled('coefficient')"
                  @input="sendCoefficientDebounce"
                  placeholder="Введите"
                  type="number"
                  :errors="v$.formState.coefficient?.$errors"
                  :inputClass="{
                    'cost-transportation-input': true,
                    'input-bold': true
                  }"
                />
              </v-col>
            </div>
            <div class="supply-row supply-row--second">
              <v-col>
                <FakeInput
                  title="Дневной вал (материал)"
                  v-model="capitalMaterialDaily"
                  :errors="v$.formState.capitalMaterialDaily?.$errors"
                />
              </v-col>
              <v-col>
                <FakeInput
                  title="Общий вал (материал)"
                  v-model="capitalMaterialTotal"
                  :errors="v$.formState.capitalMaterialTotal?.$errors"
                />
              </v-col>
              <v-col>
                <FakeInput
                  title="Дневной вал (услуги)"
                  v-model="capitalServiceDaily"
                  :errors="v$.formState.capitalServiceDaily?.$errors"
                />
              </v-col>
              <v-col>
                <FakeInput
                  title="Общий вал по спецификации (услуги)"
                  v-model="capitalServiceTotal"
                  :errors="v$.formState.capitalServiceTotal?.$errors"
                />
              </v-col>
            </div>

            <div v-if="isEditSpec">
              <h2 class="specification-subtitle">Согласование ЛПР:</h2>
              <BaseDropdown
                style="margin-bottom: 16px"
                :isWidth="true"
                :placeholder="formState.lpr_status?.title ? formState.lpr_status?.title : ''"
                :is-disabled="checkIfIsDisabled('status_lpr')"
                :list="lprStatuses"
                @update:modelValue="updateLprStatus"
              />
            </div>
            <div>
              <h2 class="specification-subtitle">
                Ответственный
              </h2>
              <div class="specification__select mb-3 width-100">
                <BaseDropdown
                  class="width-100"
                  :placeholder="account.name && account.family_name ?
                    `${account.name} ${account.family_name}` :
                    'ФИО менеджера'"
                  v-model="managerValue"
                  :required="true"
                  :is-disabled="checkIfIsDisabled('user')"
                  :list="users"
                  :errors="v$.formState.user_id?.$errors"
                  :isError="v$.formState.user_id.$error"
                />
              </div>
            </div>

            <h2 class="specification-subtitle">Комментарий</h2>
            <div class="input-wrap mb-38">
              <textarea
                :disabled="checkIfIsDisabled('comment')"
                class="specification-input specification-textarea"
                v-model="formState.user_comment"
                placeholder="Комментарий"
              ></textarea>
            </div>

            <div class="display-flex">
              <BaseButton
                :one-click-event="sendForm"
                width-auto
                success-btn
                medium
              >
                Отправить на согласование
              </BaseButton>
              <BaseButton @click="goBack" width-auto medium>
                Отменить
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </BackgroundComponent>
    <AlertModal ref="alert" />
    <AlertModal
      ref="warning"
      :close-button="true"
      name-button="Нет"
    />
    <AlertModal
      class="is-existing"
      ref="isSpecialisationExist"
      :close-button="true"
    />
    <MapSpecification
      :address="addressInstance?.routeList"
      :viaPoints="addressInstance?.viaPoints"
      :distance="formState?.distance"
      :routeLine="routePolyline"
      :address-types="addressTypes"
      :is-sync-mode="isSyncMode"
      @updated:address="updateAddress"
      @updated:polyline="updatePolyline"
      @updated:address-debounced="debouncedAddressUpdate"
      @updated:distance="updateDistance"
      @changed:Transit="triggerLogistWip"
      @fire:exception="fireYandexMapExeptionModal"
      @trigger:open-link="openLink"
      ref="mapSpecification"
      :is-edit="isEditSpec"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import specificationService from '@/services/specificationService'
import BackgroundComponent from '@/components/BackgroundComponent'
import RadioComponent from '@/components/RadioComponent'
import BaseDropdown from '@/components/base/BaseDropdown'
import SvgIcon from '@/components/base/SvgIcon'
import AlertModal from '@/components/AlertModal'
import MapSpecification from '@/components/Specifications/MapSpecification'
import { format } from 'date-fns'
import { LOGISTIAN, ADMINISTRATOR, ROP, MANAGER } from '@/main'
import useVuelidate from '@vuelidate/core'
import BaseDatepicker from '@/components/base/BaseDatepicker'
import { copyToClipboard, debounce, dumbCopy, getShareYandexLink } from '@/utils/helper'
import BaseButton from '@/components/base/BaseButton';
import { validationRules, checkIfIsDisabled } from '@/utils/specification/specification';
import FakeInput from '@/components/Specifications/FakeInput.vue';
import _get from 'lodash.get';
import SpecificationAddressForm from '@/components/Specifications/SpecificationAddressForm.vue';
import Address from '@/utils/specification/Address';
import Input from './Input.vue'
import { specificationTemplate, prepaidTypeList } from '@/utils/specification/specification';
import BaseTooltip from '@/components/base/BaseTooltip.vue';

export default {
  name: 'NewSpecification',
  components: {
    BaseTooltip,
    SpecificationAddressForm,
    BaseButton,
    SvgIcon,
    BackgroundComponent,
    RadioComponent,
    BaseDropdown,
    AlertModal,
    FakeInput,
    MapSpecification,
    BaseDatepicker,
    Input,
  },
  data: () => {
    return {
      debouncedAddressUpdate: null,
      isSaveData: false,
      LOGISTIAN: LOGISTIAN,
      MANAGER: MANAGER,
      ROP: ROP,
      ADMINISTRATOR: ADMINISTRATOR,
      addressInstance: null,
      addressTypes: null,
      routePolyline: null,
      isSnackbarActive: false,
      snackbarColor: 'success',
      snackbarText: '',
      workingSchedule: null,
      dateEnd: null,
      dateStart: null,
      searchCompany: {
        error: false,
        type: null,
        disabledClients: false,
        disabledINN: false,
        disabledType: false,
        errorType: {
          success: false,
          notAvailable: false,
        },
      },
      formState: dumbCopy(specificationTemplate),
      companyLocal: null,
      nameCounterparty: '',
      isSearchCounterparty: false,
      isSearchInn: false,
      searchCompanyInn: null,
      counterpartes: [],
      managerValue: null,
      isChangedCoefficient: false,
      isChangedCostTransportationADT: false,
      payment_percentage: null,
      coefficientDisabled: false,
      costTransportationADTDisabled: false,
      paymentType: null,
      isCapitalMaterialDailyError: false,
      isCapitalMaterialTotalError: false,
      isCapitalServiceDailyError: false,
      isCapitalServiceTotalError: false,
      transportationADTDebounce: null,
      sendCoefficientDebounce: null,
      prepaidTypeList: prepaidTypeList,
      flightsCalcHover: false,
    }
  },
  setup: () => ({ v$: useVuelidate({}) }),
  validations() {
    return validationRules(this);
  },
  computed: {
    ...mapGetters({
      specification: 'specifications/specificationDetail',
      specificationDetails: 'specifications/specificationDetail',
      fraction: 'dict/fraction',
      documentType: 'dict/documentType',
      holding: 'dict/holding',
      loadingRate: 'dict/loadingRate',
      nameGood: 'dict/nameGood',
      requestStatus: 'dict/requestStatus',
      requestType: 'dict/requestType',
      specificationStatus: 'dict/specificationStatusSpecial',
      strengthGrade: 'dict/strengthGrade',
      company: 'company/company',
      calculate: 'specifications/specificationsCalculate',
      users: 'users/users',
      account: 'account/account',
      clients: 'clients/clientsList',
      lprStatuses: 'dict/lprStatuses',
      generalPermissions: 'account/getPermissions',
      portsList: 'specifications/portsList',
    }),
    isSyncMode() {
      if (!this.addressInstance) return
      return this.addressInstance.getSyncMode();
    },
    date() {
      return this.formatDate(this.specification.created_at, true)
    },
    isEditSpec() {
      return !!this.$route.params.id
    },
    isDublicate() {
      if (this.$route.params.id) {
        return false
      }

      return !!Object.entries(this.specification).length
    },
    capitalMaterialDaily() {
      // TODO тут сайдээффекты в компьютеде, надо отрефакторить
      const loadingRateValue = this.loadingRate.find(
        (item) => +item.id === +this.formState.loading_rate_id
      )?.text

      let capitalMaterialDaily =
        parseFloat(loadingRateValue) *
        Number(this.formState.flights) *
        Number(this.formState.cost_material)

      capitalMaterialDaily = isNaN(capitalMaterialDaily) ? 0 : capitalMaterialDaily;
      this.formState.capital_material_daily = capitalMaterialDaily;
      return capitalMaterialDaily ? Math.round(capitalMaterialDaily * 100) / 100 : 0;
    },
    addressFormPermissions() {
      if (!this.generalPermissions || !this.formState) return {}
      if (!this.isEditSpec) {
        return this.generalPermissions?.specs?.fields || {}
      }
      return this.formState?.dashboard_permissions?.fields || {};
    },
    capitalMaterialTotal() {
      const capitalMaterialTotal =
        Number(this.formState.total_weight) *
        Number(this.formState.cost_material)
      this.formState.capital_material_total = capitalMaterialTotal || capitalMaterialTotal === 0
        ? capitalMaterialTotal
        : 0

      if (this.isCapitalMaterialTotalError && capitalMaterialTotal) {
        this.isCapitalMaterialTotalError = false
      }

      return capitalMaterialTotal ? Math.round(capitalMaterialTotal * 100) / 100 : 0
    },
    capitalServiceDaily() {
      const loadingRateValue = this.loadingRate.find(
        (item) => +item.id === +this.formState.loading_rate_id
      )?.text
      let capitalServiceDaily =
        parseFloat(loadingRateValue) *
        Number(this.formState.flights) *
        Number(this.formState.cost_transportation_ADT)

      capitalServiceDaily = isNaN(capitalServiceDaily) ? 0 : capitalServiceDaily;
      this.formState.capital_service_daily = capitalServiceDaily;
      return capitalServiceDaily ? Math.round(capitalServiceDaily * 100) / 100 : 0
    },
    capitalServiceTotal() {
      const capitalServiceTotal =
        Number(this.formState.total_weight) *
        Number(this.formState.cost_transportation_ADT)
      this.formState.capital_service_total = capitalServiceTotal
        ? capitalServiceTotal
        : undefined

      if (this.isCapitalServiceTotalError && capitalServiceTotal) {
        this.isCapitalServiceTotalError = false
      }

      return capitalServiceTotal ? Math.round(capitalServiceTotal * 100) / 100 : 0
    },
    nameGoodFiltered() {
      if (!this.nameGood) return [];
      if (this.formState.material_type === true) { // formState.material_type - true - нерудная / false - СХ
        return this.nameGood.filter(item => item.material_type === 1)
      }
      return this.nameGood.filter(item => item.material_type === 0);
    }
  },
  methods: {
    updateAddressCoords (specification) {
      if (this.isEditSpec) return
      this.$refs.isSpecialisationExist.show(
        'Использовать готовый маршрут из спецификации?',
        `ID<a target="_blank" style="margin-left: 5px; color: #0055e8; text-decoration: none;" href="${specification.id}">${specification.id}</a>`,
        {
          type: 'warning',
          nameButton: 'Да',
          nameButtonClose: 'Нет',
          color: '#FFA500',
        },
        async () => {
          delete specification?.id
          // await this.createState(specification)
          const route = specification?.route || null
          this.formState.distance = specification?.distance || '';
          this.$refs.mapSpecification.buildArrowBySpecification(route);
        }
      )
    },
    updatePolyline(polyline) {
      this.addressInstance.updatePolyline(polyline)
    },
    async updateAddress(event) {
      await this.addressInstance.handleChange(event);
    },
    updateEntity(data) {
      const { entity_id, entity_type, type } = data
      this.addressInstance.handleEntityChange(entity_id, entity_type, type)
    },
    updateWorkingSchedule({ value, type }) {
      this.addressInstance.handleScheduleChange(value, type)
    },
    updateIsSyncMode(type) {
      this.addressInstance.handleSyncModeChange(type);
    },
    parseTime(t) {
      let d = new Date();
      if (t != null) {
        let time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
        d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
        d.setMinutes(parseInt(time[2]) || 0);
      }
      return d;
    },
    checkOutside() {
      if (this.counterpartes?.length && this.nameCounterparty?.length) {
        this.isSearchCounterparty = false
        this.counterpartes = []
        this.formState.company_unload = {
          name: this.nameCounterparty,
          type: false
        };
        this.searchCompany.type = true
        this.searchCompany.disabledINN = true
        this.searchCompany.disabledType = true
        this.searchCompany.error = false
        this.searchCompany.errorType.success = false
        this.formState.company_unload.kontur = false
      }

      if (this.counterpartes?.length && !this.nameCounterparty?.length) {
        this.counterpartes = []
      }

      if (!this.counterpartes?.length && this.nameCounterparty?.length && !this.formState.company_unload?.client_id && !this.formState.company_unload.inn) {
        this.formState.company_unload = {
          name: this.nameCounterparty,
          type: false,
          id: _get(this.specificationDetails, 'company_unload.id', null),
        };
        this.searchCompany.disabledINN = true
        this.searchCompany.disabledType = true
        this.searchCompany.error = false
        this.searchCompany.errorType.success = false
        this.formState.company_unload.kontur = false
        this.searchCompany.type = true
      }
    },
    checkPrepaid(value) {
      if (this.formState.prepayment_type === 'percent') {
        if (+value > 100) {
          this.$nextTick(() => {
            this.formState.prepaid_expense = 100;
          })
        }
        return true
      }
      return true;
    },
    checkWeight(value) {
      return value <= this.formState.total_weight;
    },
    clearCargo() {
      this.formState.name_good_id = null;
      this.formState.strength_grade_id = null;
      this.formState.fraction_id = null;
    },
    changeDateStart(value) {
      this.dateStart = value
    },
    changeDateEnd(value) {
      this.dateEnd = value
    },
    async shareCurrentRoute() {
      try {
        const shareLink = getShareYandexLink(this.addressInstance.getRouteFormattedForBackend());
        await copyToClipboard(shareLink);
        this.snackbarColor = 'success';
        this.snackbarText = 'Успешно скопировано';
        this.isSnackbarActive = true;
      } catch (error) {
        this.snackbarColor = 'red';
        this.snackbarText = 'Не удалось скопировать';
        this.isSnackbarActive = true;
      }
    },
    openLink() {
      const shareLink = getShareYandexLink(this.addressInstance.getRouteFormattedForBackend());
      window.open(shareLink, '_blank')
    },
    async getSpecificationDetail() {
      try {
        await this.$store.dispatch(
          'specifications/getSpecificationDetail',
          this.$route.params.id
        )
      } catch (e) {
        this.$refs.alert.show('Ошибка', 'Убедитесь в правильности всех полей', {
          type: 'error',
          nameButton: 'Понятно',
        })
      }
    },
    fireYandexMapExeptionModal(text) {
      if (!this.$refs.alert) return;
      this.$refs.alert.show(
        'Ошибка',
        text ? text : 'При загрузке данных яндекс карты произошла ошибка. Пожалуйста, перезагрузите страницу',
        { nameButton: 'Понятно' },
      )
    },
    goBack() {
      if (!this.isSaveData) {
        this.$refs.warning.show(
          'Внимание!',
          'Вы не до конца заполнили поля, ваши данные не сохранятся. Уверены, что хотите выйти?',
          {
            type: 'error',
            nameButton: 'Да',
            nameButtonClose: 'Нет',
            color: '#FFA500',
          },
          () => {
            if (window.history.length > 2) {
              this.$router.go(-1)
            } else {
              this.$router.replace({ name: 'SpecificationsTablePage' })
            }

            this.isSaveData = true
            localStorage.removeItem('formState')
            localStorage.removeItem('formStateAddresses')
            localStorage.removeItem('routeLine')
          }
        )
      } else {
        this.$router.go(-1)
      }
    },
    async updateDistance(value) {
      try {
        this.formState.distance = parseInt(value.replace(/\s/g, ''));

        if (!this.formState.distance || !this.formState.cost_transportation_ADT || ! this.formState.coefficient) {
          return
        }

        await this.getFlightsCount()
      } catch(e) {
        this.$refs.alert.show(
          'Ошибка',
          'Не удалось рассчитать дистанцию или коэффициент',
          { nameButton: 'Понятно' },
        )
      }
    },
    async getFlightsCount() {
      if (!this.formState?.distance || !this.formState?.cost_transportation_ADT || !this.formState?.coefficient) return

      const time = [this.addressInstance?.getWorkingSchedule()?.unload.start, this.addressInstance?.getWorkingSchedule()?.unload.end]
      const isAllDay = time[0] === time[1] && time[0] === '24:00'
      const { data } = await specificationService.getSpecificationsCalculate({
        distance: this.formState.distance,
        cost_transportation_ADT: this.formState.cost_transportation_ADT,
        coefficient: this.formState.coefficient,
        time: time.join(',') ?? '',
        is_all_time: isAllDay,
      })
      this.formState.flights = data?.data?.flights
    },
    async createInn() {
      if (this.specification.company_unload) {
        this.searchCompanyInn = this.specification.company_unload?.inn
        await this.sendSearchCompany();
      }
    },
    updateLprStatus(newLprStatusId) {
      this.formState.lpr_status = this.lprStatuses.find(status => status.id === newLprStatusId);
    },
    checkIfIsDisabled(fieldName) {
      const permissions = !this.isEditSpec ?
        this.generalPermissions :
        { specs: this.specification?.dashboard_permissions };

      if (!permissions || permissions.specs === undefined) return false;

      const permissionType = this.isEditSpec ? 'edit' : 'create';
      return checkIfIsDisabled(permissions, fieldName, permissionType);
    },
    async createState(specificationData) {
      const specification = specificationData? specificationData : this.specification
      if (specification.company_unload) {
        this.managerValue = this.specification.user?.id
        this.isSearchCounterparty = true
        this.searchCompanyInn = specification.company_unload?.inn
        this.nameCounterparty = specification.company_unload?.name
        this.searchCompany.type = !specification.company_unload?.type
        this.formState.start = specification.start
        this.formState.end = specification.end

        if (this.specification.start) {
          this.dateStart = specification.start;
        }

        if (this.specification.end) {
          this.dateEnd = specification.end;
        }

        this.formState.name_good_id = this.nameGood.find(
          (item) => item.name === specification.name_good
        )?.id
        this.formState.strength_grade_id = this.strengthGrade.find(
          (item) => item.name === specification.strength_grade
        )?.id
        this.formState.fraction_id = this.fraction.find(
          (item) => item.name === specification.fraction
        )?.id
        this.formState.request_type_id = this.requestType.find(
          (item) => item.name === specification.request_type
        )?.id
        this.formState.request_status_id = this.requestStatus.find(
          (item) => item.name === specification.request_status
        )?.id
        this.formState.document_type_id = this.documentType.find(
          (item) => item.name === specification.document_type
        )?.id
        this.formState.loading_rate_id = this.loadingRate.find(
          (item) => item.name === specification.loading_rate
        )?.id

        this.paymentType = specification.payment_type ? '1' : '0'

        this.formState.prepayment_type = specification?.prepayment_type ? specification.prepayment_type : 'amount';

        this.formState.postponement_days = specification?.postponement_days ? specification.postponement_days : null

        this.formState.material_type = specification.material_type;
      }

      if (this.isDublicate) {
        this.formState.specification_status_id = null;
        this.formState.user_comment = '';

        if (specification.company_unload?.inn) {
          await this.sendSearchCompany();
        } else {
          this.searchCompany.disabledINN = true;
          this.searchCompany.type = true
        }
      }
      if (!specificationData && specification.route) {
        this.addressInstance = new Address(this.specification.route);
        this.addressTypes =  this.addressInstance.getAddressTypes();
        this.routePolyline = this.addressInstance.getRoutePolyline();
      }
    },
    async createSpecification(params) {
      try {
        const specification = {
          ...params,
          route: {
            ...params.route,
            url: getShareYandexLink(this.addressInstance.getRouteFormattedForBackend())
          }
        }
        const data = await this.$store.dispatch(
          'specifications/createSpecification',
          specification
        )
        if (data?.data.data.success) {
          localStorage.removeItem('formState')
          localStorage.removeItem('formStateAddresses')

          this.$refs.alert.show(
            'Успешно',
            data?.data.data?.message,
            {
              type: 'error',
              nameButton: 'Окей',
              color: '#FFA500',
            },
            () => {
              this.$router.push({ name: 'SpecificationsTablePage' })
            },
            () => {
              this.$router.push({ name: 'SpecificationsTablePage' })
            }
          )
        }
      } catch (error) {
        this.fireErrorPopup(error)
      }
    },
    async editSpecification(params) {
      try {
        const { data } = await this.$store.dispatch(
          'specifications/editSpecification',
          { ...params, route: { ...params.route, url: getShareYandexLink(this.addressInstance.getRouteFormattedForBackend()) } }
        )
        if (data.data.success) {
          this.$refs.alert.show(
            'Успешно',
            data?.data?.message,
            {
              type: 'error',
              nameButton: 'Окей',
              color: '#FFA500',
            },() => {
              this.$router.push({ name: 'SpecificationsTablePage' })
            }
          )
        }
      } catch (error) {
        this.fireErrorPopup(error)
      }
    },
    fireErrorPopup(error) {
      this.$refs.alert.show(
        'Ошибка', error, {
          type: 'error',
          nameButton: 'Окей',
          color: '#FFA500',
        }
      )
    },
    async getCalcutale(params) {
      try {
        await this.$store.dispatch(
          'specifications/getSpecificationsCalculate',
          params
        )
        await this.getDataCalulate()
      } catch (error) {
        this.coefficientDisabled = false;
        this.costTransportationADTDisabled = false;
        this.formState.coefficient = null;
        this.formState.cost_transportation_ADT = null;
        this.fireErrorPopup(error?.message);
      }
    },
    async getDataCalulate() {
      this.formState.cost_transportation_ADT =
        this.calculate.data.cost_transportation_ADT;
      this.formState.coefficient = this.calculate.data.coefficient
      this.formState.flights = this.calculate.data.flights
    },
    sendClient() {
      const id = this.formState.company_unload?.client_id

      let routeData = this.$router.resolve({
        name: 'ClientCardTablePage',
        params: { id },
      })
      window.open(routeData.href, '_blank')
    },
    async sendCoefficient(value) {
      if (value === '') {
        this.isChangedCoefficient = false
        this.costTransportationADTDisabled = false
        return false
      }

      if (this.formState.distance) {
        await this.getCalcutale({
          cost_transportation_ADT: null,
          coefficient: Number(value).toFixed(2),
          distance: Number(this.formState.distance),
        })
      }
    },
    async sendCostTransportationADT(value) {
      if (value === '') {
        this.isChangedCostTransportationADT = false
        this.coefficientDisabled = false
        return false
      }

      if (this.formState.distance) {
        await this.getCalcutale({
          cost_transportation_ADT: Number(value).toFixed(1),
          coefficient: null,
          distance: Number(this.formState.distance),
        })
      }
    },
    formatDate(date, typeApi = true) {
      const editDate = new Date(date)
      if (date !== undefined && editDate.toString() !== 'Invalid Date') {
        return {
          date: typeApi
            ? format(editDate, 'dd.MM.yyyy')
            : format(editDate, 'yyyy-MM-dd'),
          time: format(editDate, 'HH:mm'),
        }
      } else {
        return {
          date: typeApi ? 'dd.MM.yyyy' : 'dd-MM-yyyy',
          time: 'HH:mm',
        }
      }
    },
    async sendForm() {
      let userId = this.managerValue

      if ((this.account.role === this.ADMINISTRATOR || this.account.role === this.ROP) && !userId) {
        userId = this.account.id
      }

      // this.formState.payment_percentage = this.payment_percentage
      this.formState.user_id = userId

      if (this.formState?.payment_type) {
        this.formState.prepayment_type = null;
        this.formState.prepaid_expense = null;
        this.formState.postponement_days = null;
      }

      this.formState.start = this.dateStart;
      this.formState.end = this.dateEnd;

      if (this.formState.flights === null) {
        this.formState.flights = 1;
      }

      this.formState.route = this.addressInstance.getRouteFormattedForBackend();

      const result = await this.v$.$validate();

      if (!result ) {
        this.isSaveData = false
        this.fireErrorPopup('Не все поля заполнены корректно')
        return;
      }

      this.isSaveData = true
      if (!this.isEditSpec) {
        await this.createSpecification(this.formState)
      } else if (this.formState.id) {
        await this.editSpecification(this.formState)
      }
    },
    async getDataCompany() {
      if (this.company) {
        this.searchCompany.disabledINN = false
        this.formState.company_unload = this.company
        this.searchCompany.disabledClients = true
        this.searchCompany.errorType.success = false
        this.nameCounterparty = this.company.name
        this.searchCompany.type = this.company.type === '1' ? this.company.type : this.searchCompany.type;
        this.searchCompany.disabledType = true
      }
    },
    async getCompany(params) {
      try {
        await this.$store.dispatch('company/getCompany', params)
        await this.getDataCompany()
      } catch (e) {
        if (e.status === 422) {
          this.searchCompany.errorType.success = true
          this.searchCompany.disabledClients = false
        } else {
          this.searchCompany.errorType.notAvailable = true
          this.searchCompany.disabledClients = false
          this.searchCompany.errorType.success = false
        }
      }
    },
    async getSearchClients(params) {
      this.searchCompany.type = false
      this.searchCompany.disabledType = false
      this.searchCompany.disabledINN = false
      this.searchCompanyInn = null
      this.formState.company_unload = {}
      try {
        await this.$store.dispatch('clients/getClientsSearchNative', params)
        await this.getDataClientsCompany()
      } catch (e) {
        console.error(e, 'Ошибка поиска');
      }
    },
    async getDataClientsCompany() {
      this.counterpartes = []
      if (this.clients.length > 0) {
        this.clients?.map((item) => {
          let company = {}
          item.companies.map((child) => {
            if (child.general) {
              company = { ...child }
            }
          })

          if (item.id && item.name) {
            company.client = item.id
            company.name = item.name
          }

          this.counterpartes.push(company)
        })
      } else {
        this.formState.company_unload.name = this.companyLocal;
        this.formState.company_unload.type = this.searchCompany?.type;
        this.formState.company_unload.kontur = false;
      }
    },
    async sendSearchCompany() {
      const length = String(this.searchCompanyInn).length
      this.searchCompany.type = length === 12;
      if (length === 10 || length === 12) {
        this.searchCompany.error = false
        this.counterpartes = []
        this.isSearchInn = true
        await this.getCompany({ inn: this.searchCompanyInn })
      } else {
        this.searchCompany.error = true
      }
    },
    searchClientCompany(value) {
      let temp = { ...value }
      this.isSearchCounterparty = true
      this.formState.company_unload = temp

      if (temp.client) {
        this.formState.company_unload.client_id = temp.client
      }
      this.searchCompanyInn = value.inn
      this.searchCompany.error = false
      this.searchCompany.errorType.success = false
      this.searchCompany.type = value.type
      this.searchCompany.disabledINN = true
      this.searchCompany.disabledType = true
      this.counterpartes = []
      this.nameCounterparty = value.name

      if (value?.inn?.length > 10 || !value?.inn?.length) {
        this.searchCompany.type = true
      } else {
        this.searchCompany.type = false
      }
    },
    async triggerLogistWip() {
      if (this.formState.specification_status === 'В работе' || this.account.role !== LOGISTIAN) return;

      try {
        const data = await specificationService.logisticianHiredSpecification(this.$route.params.id)

        this.formState.specification_status = 'В работе';
        this.formState.specification_status_id = 2;
        this.formState.dashboard_permissions = data.data.dashboard_permissions;
      } catch (error) {
        throw new Error(error)
      }
    },
    createLocalState() {
      const localFormState = localStorage.getItem('formState')

      if (localFormState) {
        const parsedState = JSON.parse(localFormState)
        this.formState = parsedState

        if (parsedState?.company_unload) {
          this.isSearchCounterparty = true
          this.nameCounterparty = parsedState?.company_unload?.name
          this.searchCompanyInn = parsedState?.company_unload?.inn
          this.searchCompany.type = parsedState?.company_unload?.type

          this.formState.company_unload.kontur = false
        }

        this.managerValue = parsedState?.user_id

        this.paymentType = parsedState?.payment_type ? '1' : '0'

        if (parsedState?.payment_type) {
          this.paymentType = '1'

          this.formState.document_type_id = null
          this.formState.postponement_days = null
          this.formState.prepaid_expense = null
        } else {
          this.paymentType = '0'
          this.payment_percentage = null
        }

        if (parsedState.start) {
          const start = parsedState.start;
          const startArray = start.split('.').reverse().join('-');

          this.dateStart = startArray;
        }

        if (parsedState.end) {
          const end = parsedState.end;
          const endArray = end.split('.').reverse().join('-');

          this.dateEnd = endArray;
        }
      }
    },
    async getSpecificationStatusSpecial() {
      try {
        await this.$store.dispatch('dict/getSpecificationStatusSpecial', this.$route.params.id ? this.$route.params.id : '')
      } catch (e) {
        console.warn('Ошибка', e)
      }
    },
    calculateCargo() {
      if (this.formState?.loading_rate_id && this.formState?.total_weight) {
        let weight = this.loadingRate.filter(item => this.formState?.loading_rate_id === item.id)
        this.formState.cars = Math.ceil(this.formState?.total_weight / parseFloat(weight[0].name));
      }
    },
  },
  watch: {
    paymentType(value) {
      this.formState.payment_type = Number(value)
    },
    payment_percentage(value) {
      this.payment_percentage = value
      if (value > 100) {
        this.payment_percentage = 100
      }
    },
    searchCompanyInn: {
      handler(newValue, prevValue) {
        if ((!this.isSearchCounterparty && newValue !== null) || (prevValue?.toString().length > newValue?.toString().length)) {
          this.searchCompany.disabledClients = false
          this.searchCompany.disabledType = false
          this.nameCounterparty = ''
        }
        if (this.isSearchInn) {
          this.formState.company_unload = {}
          this.isSearchInn = false
        }
      },
    },
    nameCounterparty(value, prevValue) {
      if (
        this.isSearchCounterparty &&
        this.searchCompanyInn !== null &&
        value !== ''
      ) {
        setTimeout(() => {
          this.isSearchCounterparty = false
        }, 100)
      }

      this.companyLocal = value
      if (
        value?.length >= 5 &&
        !this.searchCompany.disabledClients &&
        !this.isSearchCounterparty
      ) {
        this.getSearchClients(value)
      }
      if (prevValue?.toString().length > value?.toString().length || !value?.length) {
        this.formState.company_unload = {}
      }

      if (value?.length === 0) {
        this.counterpartes = []
        this.nameCounterparty = value
        this.searchCompany.disabledINN = false
        this.searchCompany.type = false
        this.searchCompanyInn = ''
      }
    },
    specification(value) {
      this.formState = { ...value }

      if (this.account.role === this.LOGISTIAN) {
        this.formState.user_comment = this.formState?.logist_comment || ''
      }

      this.createState()
    },
    formState: {
      handler(value) {
        if (!this.isEditSpec) {
          const newValue = {}

          for (const key in value) {
            if (typeof value[key] !== 'object' && value[key]) {
              newValue[key] = value[key]
            } else if (typeof value[key] === 'object') {
              newValue[key] = {}

              for (const nestedKey in value[key]) {
                if (value[key][nestedKey]) {
                  newValue[key][nestedKey] = value[key][nestedKey]
                }
              }

              if (!Object.keys(newValue[key]).length) {
                delete newValue[key]
              }
            }
          }

          if (this.managerValue) {
            newValue.user_id = this.managerValue
          }
        }
      },
      deep: true
    },
    'formState.loading_rate_id': {
      handler() {
        this.calculateCargo();
      }
    },
    'formState.total_weight': {
      handler() {
        this.calculateCargo();
      }
    },
    'formState.distance': {
      handler() {
        if (this.formState?.cost_transportation_ADT && this.coefficientDisabled) {
          this.transportationADTDebounce(this.formState.cost_transportation_ADT);
        }

        if (this.costTransportationADTDisabled && this.formState?.coefficient) {
          this.sendCoefficientDebounce(this.formState.coefficient);
        }
      },
    },
    'formState.flights': {
      async handler(newValue, oldValue) {
        if (!oldValue) return;
        await this.triggerLogistWip();
      }
    },
  },
  async created() {
    await this.getSpecificationStatusSpecial()
    if (this.isEditSpec) {
      await this.getSpecificationDetail()
      await this.$nextTick();

      // если у пользователя нет прав на редактирование спеки, делаем редирект на странцу просмотра
      if(!_get(this.specification, 'dashboard_permissions.edit', false)) {
        this.$router.replace({ name: 'SpecificationCardPage', id: this?.specification?.id });
      }

      if (this.searchCompanyInn) {
        await this.sendSearchCompany()
      } else {
        this.searchCompany.disabledINN = true;
        this.searchCompany.disabledType = true;
      }
    }
    this.transportationADTDebounce = debounce(this.sendCostTransportationADT, 400)
    this.sendCoefficientDebounce = debounce(this.sendCoefficient, 1000)
    this.debouncedAddressUpdate = debounce(this.updateAddress, 700);
  },
  async mounted() {
    // TODO пофиксить ошибки линтера для этого колбека
    /* eslint-disable */
    await this.$store.dispatch('dict/getLprStatuses');
    await this.$store.dispatch('specifications/getPortsList')
    this.$router.beforeEach((to) => {
      // если нет прав на открытие страницы, редиректимся без подтверждения
      const isCurrentPageAllowed = _get(this.specification, 'dashboard_permissions.edit', false);
      if (!isCurrentPageAllowed) return;
      if (!this.isSaveData && this.$refs?.warning) {
        this.$refs.warning.show(
          'Внимание!',
          'Вы не до конца заполнили поля, ваши данные не сохранятся. Уверены, что хотите выйти?',
          {
            type: 'error',
            nameButton: 'Да',
            color: '#FFA500',
          },() => {
            if (to.name === 'AuthorizationPage') {
              this.$store.dispatch('auth/signOut');
            }
            this.$router.push({ name: to.name })
            this.isSaveData = true
          }
        )
        return false
      }
    })
    if (this.isEditSpec || this.isDublicate && !this.$route.path.includes('create')) {
      this.formState = { ...this.specification }
      await this.createState()
    } else {
      await this.createInn();
      this.createLocalState()

      this.addressInstance = new Address();
      this.addressTypes =  this.addressInstance.getAddressTypes();
    }
    await this.triggerLogistWip()
  },
  unmounted() {
    this.$store.dispatch('specifications/clearSpecificationDetail', {})
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.supply-row {
  display: grid;
  margin-bottom: 20px;

  &--first {
    grid-template-columns: 20% 40% 20% 20%;
  }

  &--second {
    grid-template-columns: 20% 20% 20% 40%;
  }

  .v-col {
    overflow: hidden;
  }
}

.input-wrap {
  position: relative;
  margin-bottom: 5px;
}

.flex-input-bold span:first-child {
  font-weight: 700;
}

.form-wrap {
  .flex-block {
    align-items: flex-start;
    position: relative;
  }

  .search-button {
    margin-bottom: auto;
  }
}

.input-errors {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d72028;

  &_wrap {
    // position: absolute;
  }
}

option {
  width: 250px;
  border-radius: 12px;
}

.arrow-back {
  cursor: pointer;
}

.form-wrap {
  padding-right: 26px;
}

.p-a {
  position: absolute;
}

.new-specification {
  &__route {
    display: flex;
    padding-left: 14px;
    position: relative;

    @include lg {
      display: grid;
    }

    &-addresses {
      width: 100%;
      margin-bottom: 20px;
    }

    .route-arrow {
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      left: 14px;

      &:before,
      &:after {
        display: block;
        position: absolute;
        content: "";
      }

      &--top {
        top: 39px;

        &:after {
          width: 5px;
          height: 5px;
          background-color: #0055e8;
          left: -7px;
          transform: rotate3d(0, 0, 1, 45deg);
        }

        &:before {
          width: 7px;
          background-color: transparent;
          border-left: 1px solid #0055e8;
          border-top: 1px solid #0055e8;
          bottom: -70px;
          top: 2px;
          left: -14px;
          border-top-left-radius: 5px;

          @include lg {
            bottom: -120px;
          }
        }
      }

      &--bottom {
        top: 36px;

        &:after {
          left: -8px;
          top: -1px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 3.5px 0 3.5px 7px;
          border-color: transparent transparent transparent #0055e8;
        }

        &:before {
          width: 7px;
          background-color: transparent;
          border-left: 1px solid #0055e8;
          border-bottom: 1px solid #0055e8;
          bottom: 3px;
          top: -70px;
          left: -14px;
          border-bottom-left-radius: 5px;

          @include lg {
            top: -120px;
          }
        }
      }
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .ps {
    max-height: 100vh;
    padding-left: 2px;
  }

  .card__header {
    padding-right: 18px;
  }

  .synchronize-btn {
    position: absolute;
    top: 2px;
    right: 2px;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  font-family: "Manrope";
  padding: 17px 0 24px 26px;
  margin: 14px 0 14px 10px;
  width: 59%;

  .specification-input-address {
    min-width: 150px;
    position: relative;

    &.inactive {
      background-color: #f0f0f0;
    }

    &_wrap {
      min-width: 150px;
      //height: 40px;
      margin-top: 4px;
      width: 100%;
      position: relative;
    }

    &_value {
      background: white;
      top: -11px;
      left: 15px;
      width: 100%;
      position: absolute;
      min-width: max-content;
      margin-top: 4px;
      font-size: 12px;
    }

    &__arrow {
      position: absolute;
      left: -14px;
      top: 16px;
      height: 166px;
    }
  }

  .cost-material {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #efefef;
    border-radius: 6px;
    font-size: 12px;
    background: #f0f0f0;

    &.error {
      border-color: red;
    }
  }

  .new-specification__header {
    margin-left: 12px;
  }

  .new-specification-input {
    min-width: 115px;

    :hover {
      border: none;
    }
  }

  .new-specification-input-cargo {
    width: 100%;
  }

  .specification-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0a2333;
    margin-bottom: 16px;
  }

  .specification-checkbox-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;
  }

  .checkbox-name {
    font-size: 12px;
  }

  .new-specification__input input {
    display: flex;
    align-items: center;
    padding: 14px;
    // width: 150px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #afafaf;
  }

  .new-specification__info {
    display: flex;
    background: #f4f4f8;
    border-radius: 12px;
    padding: 14px 8px;
    margin-top: 16px;
    margin-bottom: 40px;

    .specification__label {
      display: block;
    }

    &.v-row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .search-button {
    width: 40px;
    height: 40px;
    background: #0055e8;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    svg {
      fill: white;
    }
  }

  .plus-route {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #0055e8;
    margin-bottom: 40px;
    margin-left: 10px;
  }

  .specification__checkbox {
    margin-left: 12px;
    position: relative;

    .custom-checkbox_disabled:checked + label::before,
    .custom-checkbox:checked + label::before {
      border-color: #0055e8;
      background-color: #0055e8;
      background-image: url("~@/assets/img/check-mark.svg");
      background-size: 83%;
    }
  }

  .specifications__clients-card {
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0055e8;
    margin-left: 16px;
    margin-top: 20px;
    padding-top: 10px;
  }

  .specifications__clients-link {
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #0055e8;
    display: flex;
    align-items: center;
  }

  .specification__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a4a4a4;
    display: block;
    margin-bottom: 4px;
  }

  .specifications__flex-block {
    display: flex;
    margin-bottom: 40px;
  }

  .specification__select:after {
    top: 39px;
  }

  .specification__select-conditions:after {
    top: 34px;
  }

  .specification-input {
    width: 100%;
  }

  .new-specification__search-checkbox {
    display: flex;
    align-items: flex-start;
  }


  .specification-input {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #efefef;
    border-radius: 6px;
  }

  .specification-input-start {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #efefef;
    border-radius: 6px 0px 0px 6px;
  }

  .specification-input-finish {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #efefef;
    border-radius: 0px 6px 6px 0px;
  }

  .specification-input::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;
  }

  .specification-subtitle-img {
    margin-left: 14px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .specification-textarea {
    width: 100%;
    padding: 10px 14px;
    height: 92px;

    &.error {
      border-color: red;
    }

    &[disabled] {
      background-color: #f0f0f0;
      pointer-events: none;
    }
  }

  .mb-38 {
    margin-bottom: 38px;
  }

  .specification-textarea {
    font-size: 12px;
  }

  .specification-textarea::placeholder {
    color: #afafaf;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  .specifications__cargo-select:after {
    right: 28px;
  }

  .specification-cargo {
    margin-bottom: 40px;
    display: flex;
  }

  select {
    padding-right: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
  }

  .custom-checkbox + label::before {
    border: 2px solid #8ba2b1;
  }

  .attention-block {
    display: flex;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .copy_input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .attention-item {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #808191;
    padding: 12px 14px;
    background: #f7f7f7;
    margin-right: 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
  }

  .specifications__cargo-select select {
    //width: 116px;
    margin-right: 12px;
  }

  .attention-img {
    margin-right: 6px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    opacity: 1;
    background: url("@/assets/img/calendar.svg") no-repeat center;
    background-size: contain;
  }

  .invalid-value {
    border: 2px solid #d72028;
    color: #d72028;
  }

  .invalid-value:focus,
  .invalid-value:hover,
  .invalid-value:active {
    border: 2px solid #d72028;
    color: #d72028;
  }

  .cost-transportation {
    display: flex;
  }

  .cost-transportation-input {
    border: 1px solid #efefef;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    height: 40px;
    width: 185px;
    padding: 0 14px;
  }

  .cost-transportation-block {
    display: flex;
  }

  .cost-transportation-right {
    border-radius: 0px 6px 6px 0px;
  }

  .cost-transportation-left {
    border-radius: 6px 0px 0px 6px;
  }

  .shaft-block input {
    color: #afafaf;
  }

  .shaft-block input::placeholder {
    color: #afafaf;
  }

  .responsible-select {
    width: 100%;
  }

  .responsible-select:after {
    right: 0;
    top: 10px;
  }

  .specifications__flex-block-date-start input,
  .specifications__flex-block-date-finish input {
    width: 100%;
  }

  .specification-input__name {
    width: 345px;
    font-size: 14px;
  }

  .specification-input__name::placeholder {
    font-size: 14px;
  }

  .fake-placeholder {
    display: none;
  }

  .specification-input-address::placeholder {
    font-size: 0;
  }

  .specification-input-address:placeholder-shown {
    padding-left: 28px;
  }

  .specification-input-address:placeholder-shown + .fake-placeholder {
    display: block;
    padding-left: 18px;
    background-image: url("@/assets/img/address.svg");
    background-repeat: no-repeat;
    background-position: 0 50%;
    position: absolute;
    font-size: 12px;
    top: 12px;
    left: 12px;
    z-index: 2;
    pointer-events: none;
  }

  .search-drop-down {
    background: #ffffff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 310px;
    position: absolute;
    top: calc(100% - 20px);
    z-index: 20;
    max-height: 400px;
    overflow-y: scroll;
  }

  .search-drop-down__item {
    padding: 12px;
    cursor: pointer;
    transition: .3s;

    &:hover {
      background: #f4f4f8;
    }
  }

  .company-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #808191;
  }

  .company-address {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #afafaf;
    margin-left: 12px;
  }

  .input-search-error {
    border: 1px solid red;
  }

  .text-error {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #d72028 !important;

    &-wrap {
      border: 1px solid red;
    }

    &_cost-material {
      margin-top: 5px;
      // max-width: 136px;
    }
  }

  input {
    font-size: 12px;
  }
}

.p-r {
  position: relative;
}

.specifications-scroll {
  overflow: auto;
  max-height: calc(100% - 54px);
  min-height: 200px;
  padding-bottom: 30px;
}

.v-row {
  margin: 0 -6px;
}

.v-col {
  padding: 0 6px;
}

.d-b {
  display: block;
}

.mb-0 {
  margin-bottom: 0;
}

.input-errors_wrap {
  margin-top: 5px;
}

:global(.position-relative .dp__menu) {
  top: 74px !important;
  left: 50px !important;
}

:global(.position-relative .dp__arrow_bottom),
:global(.position-relative .dp__arrow_top) {
  display: none;
}

.build-route-btn {
  color: #0a2333;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 16px;
}

.route-field-offset {
  margin-left: 12px;

  &_hours {
    margin-left: 18px;
    @include lg {
      margin-left: 0;
    }
  }
}

.prepaid-type-block {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 80px;
}

.specification-input-logistic {
  flex-grow: 1;
}

.specification__checkbox-person {
  position: relative;
}

:deep(.is-existing .alert__header) {
  padding: 20px 20px 0;
}

:deep(.is-existing .icon-center) {
  display: none;
}

</style>
