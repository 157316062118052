<template>
  <div class="map-mode">
    <div class="map-mode__burger">
    </div>
    <div class="map-mode__body">
      <div
        class="map-mode__item"
        v-for="item in mapModeItems"
        :key="item.code"
        @click="updatePageMode(item.code)"
      >
        <span
          :class="{
            active: activeMapMode.code === item.code
          }"
        >{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const mapModeItems = store.getters['interactiveMap/getMapModes']
const activeMapMode = computed(() => store.getters['interactiveMap/getActiveMapMode'])

const updatePageMode = (mode) => {
  if (!mode) return
  store.dispatch('interactiveMap/updateMapMode', mode);
}

</script>

<style scoped lang="scss">
.map-mode {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  right: 55px;
  bottom: 55px;

  max-width: 40px;
  width: 100%;
  min-height: 40px;
  max-height: 40px;

  background: #0055E8;
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  transition: all .2s;

  &:hover {
    max-width: 300px;
    padding: 10px;

    .map-mode__burger {
      display: flex;

      margin-right: 12px;
    }

    .map-mode__body {
      display: flex;
      align-items: center;
      pointer-events: all;

      opacity: 1;
    }
  }

  &__burger {
    display: inline-block;

    width: 20px;
    height: 20px;

    background-image: url('@/assets/img/burger.svg');
    background-size: contain;
    flex-shrink: 0;
    pointer-events: none;
  }

  &__body {
    display: none;
    align-items: center;
    min-width: 245px;

    opacity: 0;
    transition: all .5s;

    &:hover {
      opacity: 1;
    }
  }

  &__item {
    cursor: pointer;

    &:last-child {
      span::after {
        display: none;
      }
    }
  }

  span {
    display: block;
    position: relative;
    padding: 5px 14px;
    flex-shrink: 0;
    color: #fff;

    &.active {
      background: #fff;
      color: #0055E8;
      border-radius: 6px;

      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      display: block;

      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(50%);

      width: 1px;
      height: 18px;

      background: #D9D9D9;
    }
  }
}
</style>
