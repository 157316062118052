<template>
  <BaseSpinner
    :isLoading="isLoading"
    backgroundColor="#F4F4F8"
  />
  <div v-if="isDataHidden">Чтобы получить данные, рассчитайте маршрут</div>
  <div class="detailed" v-else>
    <template v-for="(item, itemKey) in humanifiedData">
      <div class="detailed-item" v-if="item">
        <div class="detailed-item__title">{{ itemKey }}</div>
        <div class="detailed-item__data">{{ item }}</div>
      </div>
    </template>
  </div>
</template>
<script setup>
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { isEmptyObject } from '@/utils/helper';

const store = useStore();

const props = defineProps({
  isShareMode: Boolean,
  multirouteDetailedData: {
    type: Object,
    default: () => {}
  },
  isQuickMetaMode: {
    type: Boolean,
    default: false,
  }
})

const quickMetaRoute = computed(() => store.getters['interactiveMap/getQuickMetaRoute'])

const isDataHidden = computed(() => {
  return isEmptyObject(humanifiedData.value) || (props.isQuickMetaMode && (!quickMetaRoute.value || quickMetaRoute.value.length < 2 || quickMetaRoute.value.filter(item => !item).length))
})

const humanifiedData = computed(() => {
  if (!props.multirouteDetailedData) return [];
  const res = {};
  let data = props.multirouteDetailedData;
  const distanceFormater = new Intl.NumberFormat('ru', {
    style: 'unit',
    unit: 'kilometer',
  });
  const moneyFormater = new Intl.NumberFormat('ru', {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0
  });

  for (const key in data) {
    if (['distance', 'mileage_goods', 'mileage_empty'].includes(key)) {
      res[getKey(key)] = distanceFormater.format(data[key])
    } else if (['capital_services', 'cost_price'].includes(key)) {
      res[getKey(key)] = moneyFormater.format(data[key])
    } else if (key === 'mileage_percent') {
      res[getKey(key)] = `${Math.round(data[key] * 100)} %`;
    }
  }

  return res;
})

const getKey = (key) => {
  const dictionary = {
    capital_services: 'Общий вал',
    cost_price: 'Себестоимость',
    distance: 'Километраж маршрута',
    mileage_empty: 'Пробег без груза',
    mileage_goods: 'Пробег с грузом',
    mileage_percent: 'Пробег с грузом, %'
  }
  return dictionary[key] ? dictionary[key] : key;
}

const isLoading = computed(() => {
  return store.getters['interactiveMap/getIsMultirouteDetailedDataLoading'];
})
</script>

<style lang="scss" scoped>
.detailed {
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;

  &-item {
    width: 50%;
    margin-bottom: 12px;
    padding-right: 10px;

    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #A4A4A4;
      margin-bottom: 8px;
    }

    &__data {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #102F43;
    }
  }
}
</style>
