<template>
  <div class="extended-meta">
    <MetaRouteDataWrapper 
      :meta-route-specifications="metaRouteSpecifications"
      :main-spec="mainSpec"
      :multiroute-detailed-data="multirouteDetailedData"
      :is-edit-disabled="true"
      :is-quick-meta-mode="true"
      @update:specs-order="updateSpecsOrder"
    />
  </div>
</template>

<script setup>
import MetaRouteDataWrapper from './MetaRouteDataWrapper.vue';
import { useStore } from 'vuex';
import { computed } from 'vue'

const store = useStore()

const mainSpec = computed(() => store.getters['interactiveMap/getBaseSpecification']?.id)

const metaRouteSpecifications = computed(() => {
  return store.getters['interactiveMap/getQuickMetaRoute']
})

const multirouteDetailedData = computed(() => {
  return store.getters['interactiveMap/getMultirouteDetailedData'];
})

const updateSpecsOrder = (specs) => {
  if (!specs?.length) return

  store.dispatch('interactiveMap/setBaseSpecification', specs[0] ?? null)
  store.dispatch('interactiveMap/setQuickSecondSpecification', specs[1] ?? null)
}
</script>

<style lang="scss" scoped>
.extended-meta {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 357px;
  width: 100%;
  z-index: 10;
  padding: 18px;
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;
  overflow: visible;

  :deep(.panel, .panel-title) {
    background-color: transparent;
  }
}
</style>