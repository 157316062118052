import quarryService from '@/services/quarryService.js'
import { handleError } from '@/utils/api/handleError.js'
import { filterOrders } from '@/utils/helper.js';
import { getDataRecursively } from '@/utils/map/map.js'

export default {
  namespaced: true,
  state: {
    quarryList: [],
    quarryTableList: [],
    quarryHeaderList: [],
    selectedClient: null,
    clientDetail: [],
    // clientDetailGeneral: [],
    quarrySearchResult: true,
    quarryFilterParams: null,
    quarryPagesCount: null,
    quarryHeaderListFull: [],
    isSearchMode: false,
    quarrySchema: {},
    quarrySchemaFull: {},
    quarryPerPage: null,
    tempQuarryTableHeader: [],
    isQuarryLoading: false,
  },
  actions: {
    async getQuarry({ commit }, params) {
      try {
        commit('SET_IS_QUARRY_LOADING', true)
        const data = await quarryService.getQuarry(params)

        commit('SET_QUARRY_LIST', data)
        commit('SET_QUARRY_TABLE_LIST', data)
        commit('SET_QUARRY_SEARCH_RESULT_SHOW', true)
        commit('SET_QUARRY_PAGES_COUNT', data);
        commit('SET_QUARRY_PER_PAGE', data);
        commit('SET_IS_SEARCH_MODE', false);
      } catch (e) {
        handleError(e)
      } finally {
        commit('SET_IS_QUARRY_LOADING', false)
      }
    },
    async getQuarryHeader({ commit }, params) {
      try {
        const data = await quarryService.getQuarryHeader()
        commit('SET_QUARRY_HEADER_LIST', {
          data: data,
          params: params,
        })
      } catch (e) {
        handleError(e)
      }
    },
    async setSelectedQuarry({ commit }, data) {
      commit('SET_SELECTED_QUARRY', data)
    },
    async setQuarryFilterParams({ commit }, data) {
      commit('SET_QUARRY_FILTER_PARAMS', data)
    },
    async getQuarryDetail({ commit }, id) {
      try {
        const data = await quarryService.getQuarryDetail(id)
        commit('SET_QUARRY_DETAIL', data)
      } catch (e) {
        handleError(e)
      }
    },
    async getQuarrySearch({ commit }, value) {
      try {
        const data = await quarryService.getQuarrySearch(value.search, {
          page: value.page,
          per_page: value.perPage
        })

        commit('SET_QUARRY_LIST', data);
        commit('SET_QUARRY_TABLE_LIST', data);
        commit('SET_QUARRY_SEARCH_RESULT', data);

        commit('SET_QUARRY_PAGES_COUNT', data);
        commit('SET_QUARRY_PER_PAGE', data);

        commit('SET_IS_SEARCH_MODE', true);
      } catch (e) {
        handleError(e)
      }
    },
    async updateColumnWidth({ commit }, params) {
      try {
        await quarryService.quarryAddColumns(params)
        commit('SET_TEMP_QUARRY_TABLE_HEADER', params.columns)
      } catch (e) {
        handleError(e)
      }
    },
    async updateResponseQuarry(_, payload) {
      try {
        await quarryService.updateResponseQuarry(payload)
      } catch (e) {
        handleError(e)
      }
    },
  },
  getters: {
    quarryList(state) {
      return state.quarryList
    },
    quarryById: (state) => (id) => {
      return state.quarryList.find(quarry => quarry.id === id)
    },
    getIsSearchMode(state) {
      return state.isSearchMode
    },
    quarryTableList(state) {
      return state.quarryTableList
    },
    quarryHeaderList(state) {
      return state.quarryHeaderList
    },
    quarrySearchResult(state) {
      return state.quarrySearchResult
    },
    getSelectedQuarry(state) {
      return state.selectedQuarry
    },
    getQuarryFilterParams(state) {
      return state.quarryFilterParams
    },
    quarryDetail(state) {
      return state.quarryDetail
    },
    quarryPagesCount(state) {
      return state.quarryPagesCount;
    },
    quarryHeaderListFull(state) {
      return state.quarryHeaderListFull;
    },
    quarryPerPage(state) {
      return state.quarryPerPage;
    },
    tempQuarryTableHeader(state) {
      return state.tempQuarryTableHeader;
    },
    getIsQuarryLoading(state) {
      return state.isQuarryLoading;
    }
  },
  mutations: {
    SET_QUARRY_LIST(state, value) {
      state.quarryList = value.data.data;
    },
    SET_IS_SEARCH_MODE(state, value) {
      state.isSearchMode = value;
    },
    SET_QUARRY_SEARCH_RESULT(state, value) {
      let data = value.data.data

      if (data && data[0]) {
        state.quarrySearchResult = true
      } else {
        state.quarrySearchResult = false
      }
    },
    SET_QUARRY_SEARCH_RESULT_SHOW(state, value) {
      state.quarrySearchResult = value
    },
    SET_QUARRY_FILTER_PARAMS(state, value) {
      state.quarryFilterParams = value
    },
    SET_QUARRY_TABLE_LIST(state, value) {
      let dataLocal = value.data.data

      const dataAPI = dataLocal.map(item => {
        let data = {
          id: item.id,
          cells: []
        }
        for (let i = 0; i < Object.keys(state.quarrySchema).length; i++) {
          const keySchema = Object.keys(state.quarrySchema)[i];

          if(keySchema === 'status') {
            data.cells.push({ name: keySchema, value: item.status === 'Действующий' ? 'Действителен' : 'Недействителен' })
          } else if (keySchema === 'name_good') {
            data.cells.push({ name: keySchema, value: getDataRecursively(item, 'name') })
          } else if(keySchema === 'fractions') {
            data.cells.push({ name: keySchema, value: getDataRecursively(item, 'fractions') })
          } else if(keySchema === 'comment') {
            data.cells.push({ name: keySchema, value: item.comment ? item.comment : '' })
          } else if(keySchema === 'address') {
            data.cells.push({ name: keySchema, value: item.fact_addresses[0]?.address })
          } else if(keySchema === 'holding') {
            data.cells.push({ name: keySchema, value: item.holding ? item.holding : '' })
          } else {
            data.cells.push({ name: keySchema, value: item[keySchema] })
          }
        }
        return data
      })


      if (dataLocal) {
        state.quarryTableList = dataAPI
      }

    },
    SET_QUARRY_PAGES_COUNT(state, value) {
      if (value.data?.meta?.last_page) {
        state.quarryPagesCount = value.data.meta.last_page
      }
    },
    SET_QUARRY_HEADER_LIST(state, value) {
      let { schemas } = value.data.data.data
      let temp = []

      if (schemas._order?.length) {
        schemas._order = schemas._order.map(item => {
          // проверяем первая загрузка или обновление колонок, если повторная загрузка, то проверяем наличие такой колоноки ранее и пушим значение в стейт, если она была ранее
          if (state.tempQuarryTableHeader) {
            let index = state.tempQuarryTableHeader.findIndex(el => el.includes(item))

            if (index === -1) {
              temp.push(item)
            } else {
              temp.push(state.tempQuarryTableHeader[index])
            }
          } else {
            temp.push(item)
          }

          return item.split('?')[0]
        })
      }

      const schemasFull = schemas

      if (schemas._order?.length > 0) {
        const schemasOrders = schemas._order

        schemas = filterOrders(schemasOrders, schemas)
      }

      state.quarrySchema = schemas
      state.quarrySchemaFull = schemasFull

      const headerTable = []
      const headerTableFull = []

      function headerConstruct(vSchema, vTable) {
        for (let i = 0; i < Object.keys(vSchema).length; i++) {
          const key = Object.keys(vSchema)[i];

          if (key !== '_order' && key !== '_filters') {
            vTable.push(
              {
                id: key,
                value: vSchema[key],
                sort:
                  key === value.params.sort
                    ? 'ASC'
                    : `-${key}` === value.params.sort
                      ? 'DESC'
                      : 'default'
              });
          }
        }
      }

      headerConstruct(schemas, headerTable)
      headerConstruct(schemasFull, headerTableFull)

      const quarryHeaderList = {
        success: value.data.data.data.success,
        schemas: headerTable,
      }

      const quarryHeaderListFull = {
        success: value.data.data.data.success,
        schemas: headerTableFull
      }

      state.tempQuarryTableHeader = temp;
      state.quarryHeaderList = quarryHeaderList
      state.quarryHeaderListFull = quarryHeaderListFull;
    },
    SET_SELECTED_QUARRY(state, value) {
      state.selectedQuarry = value
    },
    SET_QUARRY_DETAIL(state, value) {
      state.quarryDetail = value.data.data
    },
    SET_QUARRY_PER_PAGE(state, value) {
      if (value.data?.meta?.per_page) {
        state.quarryPerPage = value.data.meta.per_page
      }
    },
    SET_TEMP_QUARRY_TABLE_HEADER(state, value) {
      state.tempQuarryTableHeader = value
    },
    SET_IS_QUARRY_LOADING(state, value) {
      state.isQuarryLoading = value
    },
  },
}
