<template>
  <div class="interactive-map__header">
    <h1 class="interactive-map__title">
      Интерактивные&nbsp;карты
    </h1>

    <div class="interactive-map__controls">
      <div
        class="pagination-wrapper"
        v-if="!isMetaRouteMode"
      >
        <v-pagination
          v-if="totalPages"
          v-model="currentPage"
          :length="totalPages"
          :total-visible="4"
        />
      </div>
      <SpecificationHistory
        @update:history="updateIsHistory"
        :get-specification="false"
        is-interactive-map
      />
      <PerPageSelect
        v-if="!isMetaRouteMode"
        :items="PER_PAGE_OPTIONS"
        @update-select="updatePerPage"
        :current-per-page="perPage"
      />
    </div>
  </div>
</template>

<script setup>
import SpecificationHistory from '@/components/Specifications/SpecificationHistory.vue';
import PerPageSelect from '@/components/Dashboard/PerPageSelect.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const totalPages = computed(() => {
  return store.getters['interactiveMap/getSpecificationTotalPages'];
})

const PER_PAGE_OPTIONS = [20, 50, 100];

const perPage = computed(() => {
  if (!store.getters['interactiveMap/getSpecificationPerPage']) return PER_PAGE_OPTIONS[1];
  return store.getters['interactiveMap/getSpecificationPerPage'];
})

const currentPage = computed({
  get:() => {
    return store.getters['interactiveMap/getSpecificationPage'];
  },
  set:(page) => {
    updatePaginationPage(page);
  }
})

const isMetaRouteMode = computed(() => {
  return store.getters['interactiveMap/getIsMetaRouteMode'];
})

const updatePaginationPage = (page) => {
  store.dispatch('interactiveMap/updateMapPagination', { currentPage: page })
}
const updatePerPage = (perPage) => {
  store.dispatch('interactiveMap/updateMapPagination', { perPage: perPage })
}
const updateIsHistory = (isHistory) => {
  store.dispatch('interactiveMap/updateMapPagination', { isHistory })
}

</script>

<style scoped lang="scss">
.interactive-map {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    max-height: 72px;
    height: 72px;
  }

  &__title {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 55px;
    color: #102f43;
  }

  &__mode {
    display: flex;
    align-items: center;
    margin: 0 20px;

    p {
      margin-right: 10px;
    }
  }

  &__controls {
    display: flex;
    flex: 1;

    .pagination-wrapper {
      flex: 1 0;
      display: block;
    }

    .page-select {
      flex: 0 1;
    }
  }

  &__mode {
    max-width: 400px;
  }

  &__input-select {
    min-width: 105px;
    max-width: 105px;
    ::v-deep .v-field__input {
      height: 40px;
      min-height: 40px;
      padding: 0 0 0 14px;
      display: flex;
      align-items: center;
    }
    ::v-deep .v-field__append-inner {
      padding-top: 0;
      display: flex;
      align-items: center;
    }
  }
}

</style>
