import { createRouter, createWebHistory } from 'vue-router'
import TryAgainPage from '@/views/TryAgainPage.vue';
import TableClientsPage from '@/views/TableClientsPage.vue';
import SpecificationsTablePage from '@/views/SpecificationsTablePage.vue';
import AuthorizationPage from '@/views/AuthorizationPage.vue';
import RegistrationPage from '@/views/RegistrationPage.vue';
import Map from '@/views/Map.vue';
import ClientCardTablePage from '@/views/ClientCardTablePage.vue';
import QuarryCardPage from '@/views/QuarryCardPage.vue';
import NewSpecificationPage from '@/views/NewSpecificationPage.vue';
import SpecificationCardPage from '@/views/SpecificationCardPage.vue';
import TableQuarryPage from '@/views/TableQuarryPage.vue';
import ClientCreatePage from '@/views/ClientCreatePage.vue';
import QuarryCreatePage from '@/views/QuarryCreatePage.vue';
import InteractiveMapsPage from '@/views/InteractiveMapsPage.vue';
import TablePortsPage from '@/views/TablePortsPage.vue';
import CreatePortPage from '@/views/CreatePortPage.vue';
import DetailsPortPage from '@/views/DetailsPortPage.vue';

const routes = [
  {
    path: '/',
    name: 'AuthorizationPage',
    component: AuthorizationPage,
    meta: { layout: null }
  },
  {
    path: '/try-again',
    name: 'TryAgainPage',
    component: TryAgainPage,
    meta: { layout: 'AppLayoutApp' }
  },
  {
    path: '/table-clients-page',
    name: 'TableClientsPage',
    component: TableClientsPage,
    meta: { layout: 'AppLayoutApp', title: 'Клиенты' }
  },
  {
    path: '/specifications-table-page',
    name: 'SpecificationsTablePage',
    component: SpecificationsTablePage,
    meta: { layout: 'AppLayoutApp', title: 'Спецификации' }
  },
  {
    path: '/registration-page',
    name: 'RegistrationPage',
    component: RegistrationPage,
    meta: { layout: 'AppLayoutApp' }
  },
  {
    path: '/table-clients-page/:id',
    name: 'ClientCardTablePage',
    component: ClientCardTablePage,
    meta: { layout: 'AppLayoutApp', title: 'Клиенты' }
  },
  {
    path: '/table-clients-page/create',
    name: 'ClientCreatePage',
    component: ClientCreatePage,
    meta: { layout: 'AppLayoutApp', title: 'Клиенты' }
  },
  {
    path: '/table-quarry-page/:id',
    name: 'QuarryCardPage',
    component: QuarryCardPage,
    meta: { layout: 'AppLayoutApp', title: 'Карьеры' }
  },
  {
    path: '/table-quarry-page/create',
    name: 'QuarryCreatePage',
    component: QuarryCreatePage,
    meta: { layout: 'AppLayoutApp', title: 'Карьеры' }
  },
  {
    path: '/specification-card-page/create',
    name: 'CreateSpecificationPage',
    component: NewSpecificationPage,
    meta: { layout: 'AppLayoutApp', title: 'Спецификации' }
  },
  {
    path: '/specification-card-page/edit/:id',
    name: 'EditSpecificationPage',
    component: NewSpecificationPage,
    meta: { layout: 'AppLayoutApp', title: 'Спецификации' }
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta: { layout: 'AppLayoutApp' }
  },
  {
    path: '/specification-card-page/:id',
    name: 'SpecificationCardPage',
    component: SpecificationCardPage,
    meta: { layout: 'AppLayoutApp', title: 'Спецификации' }
  },
  {
    path: '/table-quarry-page',
    name: 'TableQuarryPage',
    component: TableQuarryPage,
    meta: { layout: 'AppLayoutApp', title: 'Карьеры' }
  },
  {
    path: '/interactive-map',
    name: 'InteractiveMapsPage',
    component: InteractiveMapsPage,
    meta: { layout: 'AppLayoutApp', title: 'Интерактивные карты' }
  },
  {
    path: '/ports-page',
    name: 'PortsPage',
    component: TablePortsPage,
    meta: { layout: 'AppLayoutApp', title: 'Порты' },
  },
  {
    path: '/ports-page/:id',
    name: 'DetailsPortPage',
    component: DetailsPortPage,
    meta: { layout: 'AppLayoutApp', title: 'Порты' },
  },
  {
    path: '/ports-page/create',
    name: 'CreatePortPage',
    component: CreatePortPage,
    meta: { layout: 'AppLayoutApp', title: 'Создание порта' },
  },
]

const router = createRouter( {
  history: createWebHistory(process.env.NODE_ENV === 'development' ? '' : ''),
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes
})

const DEFAULT_TITLE = 'Альфа Дон Транс'
router.beforeEach((to, from, next) => {
  document.title = to?.meta?.title || DEFAULT_TITLE;
  next();
});

export default router
