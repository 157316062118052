import companyService from "@/services/companyService";
import {errorFormat} from "@/utils/helper";

export default {
  namespaced: true,
  state: {
    company: {},
  },
  actions: {
    async getCompany({ commit }, params) {
      try {
        const data = await companyService.getCompany(params);
        commit("SET_COMPANY", data.data);
      } catch (e) {
        throw e.response
      }
    },
  },
  getters: {
    company(state) {
      return state.company;
    },
  },
  mutations: {
    SET_COMPANY(state, value) {
      state.company = value.data;
    },
  },
};
