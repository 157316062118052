<script setup>
import AppLayoutDefault from './AppLayoutDefault.vue'
import { markRaw, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const layout = ref()
const route = useRoute()
const layoutLoading = ref(true);

watch(
  () => route.meta?.layout,
  async (metaLayout) => {
    try {
      layoutLoading.value = true
      const component = metaLayout && await import(/* @vite-ignore */ `./${metaLayout}.vue`)
      layout.value = markRaw(component?.default || AppLayoutDefault)
    } catch (e) {
      layout.value = markRaw(AppLayoutDefault)
    } finally {
      layoutLoading.value = false
    }
  },
)
</script>

<template>
  <component :is="layout" v-if="!layoutLoading"> <router-view /> </component>
</template>
