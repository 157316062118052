<template>
  <div
    v-click-outside="handleFocusOut" class="base-dropdown__wrapper" :class="{error: isError}">
    <p class="base-dropdown__caption" v-if="title">{{ title }}</p>
    <div class="base-dropdown">
      <div class="base-dropdown__block" :class="{ 'radius-left': radiusLeft, 'radius-right': radiusRight }" @click.stop="checkOpen">
        <input
          type="text"
          @input="filterList"
          v-model="searchText"
          :placeholder="placeholder"
          disabled
          class="base-dropdown__field">
        <div class="base-dropdown__btn" :class="{ rotate: isDropdown }">
          <SvgIcon name="black-arrow-bottom" class=""/>
        </div>
      </div>
      <div v-if="isDropdown" class="base-dropdown__list" :class="{'base-dropdown__list-width': isWidth}" @click.stop>
        <div
          class="base-dropdown__item"
          :class="{'base-dropdown__item-width': isWidth, 'base-dropdown__item-size': isDropdown}"
          v-for="item in filtered"
          :key="`dropdown_${item.id}`"
          @click="changeActive(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div v-if="isError" class="base-dropdown__input-error">{{ errorString }}</div>
    <div v-if="subDescription && !isError" class="base-dropdown__sub-description">{{ subDescription }}</div>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue';

export default {
  name: 'DropdownForModal',
  components: { SvgIcon },
  props: {
    subDescription: {
      type: String
    },
    placeholder: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    isWidth: {
      type: Boolean,
      default: false
    },
    radiusLeft: {
      type: Boolean,
      default: false
    },
    radiusRight: {
      type: Boolean,
      default: false
    },
    modelValue: [String, Number],
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
    isError: {
      type: Boolean,
      default: false
    },
  },
  created() {
    if (this.modelValue != null) {
      let selectItem = this.list.filter(value => value.id === this.modelValue);
      if (selectItem.length > 0)
        this.searchText = selectItem[0].name;
    }

    if (!this.placeholder) {
      this.changeActive(this.list[0]);
    }
  },
  data() {
    return {
      filtered: this.list,
      searchText: '',
      isDropdown: false
    }
  },
  watch: {
    list: function (val) {
      this.filtered = val;
    },
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.setTextAndValue(newValue);
      }
    }
  },
  methods: {
    handleFocusOut() {
      this.isDropdown = false;
    },
    setTextAndValue(value) {
      if (value != null) {
        let selectItem = this.list.filter(listItem => listItem.id === value);
        if (selectItem.length > 0)
          this.searchText = selectItem[0].name;
      }
    },
    filterList() {
      if (this.searchText.length) {
        let text = this.searchText.toLowerCase();
        this.filtered = this.list.filter(item => {
          let resText = item.name.toLowerCase();
          return resText.indexOf(text) !== -1
        })
      } else {
        this.filtered = this.list
      }
      // this.filtred = this.filtred.filter((item, index) => index < 5)
    },
    changeActive(val) {
      this.$emit('update:modelValue', val.id)
      this.setTextAndValue(this.modelValue);
      this.isDropdown = false;
      this.searchText = val.name
    },
    checkOpen() {
      this.isDropdown = !this.isDropdown
    }
  },
  computed: {
    checksStatus() {
      return this.error
        .filter((error) => error.status)
        .length > 0
    },
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join('. ')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-dropdown__wrapper {
  &.error .base-dropdown__block {
    border: 1px solid #EFEFEF;
  }

  .svg-icon--black-arrow-bottom {
    width: 11px;
    height: 7px;
  }

  .radius-left {
    border-radius: 8px 0 0 8px !important;
  }

  .radius-right {
    border-radius: 0 8px 8px 0 !important;
  }

  .base-dropdown {
    position: relative;

    &__input-error {
      color: #222222;
      margin-top: 8px;
    }

    &__sub-description {
      color: #222222;
      margin-top: 10px;
    }

    &__list {
      position: absolute;
      width: 100%;
      top: 48px;
      z-index: 111;
      border-radius: 12px;
      overflow: hidden;
      max-height: 345px;
      overflow-y: auto;
      box-shadow: 7px 8px 15px #a7a7a761;
      background: #FFFFFF;
      border: 1px solid #E3E3E3;
    }

    &__list-width {
      width: 160%;
    }

    &__btn {
      transform: rotate(0);
      transition: 0.3s;
      display: flex;
      align-items: center;

      &.rotate {
        transform: rotate(-180deg);
        transition: 0.3s;
      }
    }

    &__field {
      flex-grow: 1;
      padding: 0;
      width: 100%;
      font-size: 14px;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #0A2333;
      }
    }

    &__block {
      cursor: pointer;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 14px;
      border-radius: 6px;
      border: 1px solid #EFEFEF;
      background-color: #FFFFFF;
      //margin-top: 5px;
    }

    &__item {
      background-color: #FFFFFF;
      cursor: pointer;
      padding: 13px 20px;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }

    &__item-size {
      font-size: 14px;
    }
  }
}

.dropdown-filters {
  .base-dropdown {
    &__special-button,
    &__item {
      padding: 7px 20px;
      border-bottom: 1px solid rgba(224, 224, 224, 0.25);

      span {
        max-width: 100%;
        overflow: hidden;
        height: 20px;
      }

      &:hover {
        background-color: #F6F6F6;
      }
    }

    &__special-button {
      border-bottom: none;
    }

    &__btn {
      width: 10px;
      right: -6px;
      position: relative;
    }
  }
}

.bold {
  font-weight: 700;
}

</style>
