<template>
  <div>
    <v-app>
      <v-card>
        <v-layout>
          <v-main style="" class="sidebar-content">
            <div class="display-flex">
              <div class="client-card__wrap">
                <QuarryCard class="client-card_block"/>
              </div>
              <div class="table-specifications__wrap">
                <TableSpecifications
                  type="quarry"
                  class="table-specifications_block"
                />
              </div>
            </div>
          </v-main>
        </v-layout>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import TableSpecifications from '@/components/Clients/TableSpecifications'
import QuarryCard from '@/components/Quarry/QuarryCard'

export default {
  name: 'QuarryCardPage',
  components: {
    QuarryCard,
    TableSpecifications,
  },
}
</script>

<style lang="scss">
.client-card {
  font-family: "Manrope";

  .client-card__content {
    display: flex;
    justify-content: space-between;
  }
}
</style>
