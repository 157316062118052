import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './assets/fonts/stylesheet.css';
import vClickOutside from "click-outside-vue3"
import router from './router'
import store from './store'
import {loadFonts} from './plugins/webfontloader'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import vResizeColumn from '@/directives/vResizeColumn'

import "@/assets/styles/styles.scss";
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import Maska from 'maska';


export const ADMINISTRATOR = 'administrator'
export const MANAGER = 'manager'
export const ROP = 'rop'
export const MANAGER_QUARRY = 'quarry_manager'
export const LOGISTIAN = 'logistician'
export const COORDINATOR = 'coordinator'
// const app = createApp(App);
// app.use(VueGoogleMaps, {
//     load: {
//         key: 'YOUR_API_KEY_COMES_HERE',
//     },
// }).mount('#app')


loadFonts()

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .directive('resizes', vResizeColumn)
  .use(vClickOutside)
  .use(PerfectScrollbar)
  .use(Maska, {
    'T': {pattern: /[0-9]/, transform: (char) => String(Number(char) % 2)} // '1234567890' -> '1010101010'
  })
  .mount('#app');
