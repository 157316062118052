import disctService from "@/services/disctService";
import { handleError } from '@/utils/api/handleError';
import {errorFormat} from "@/utils/helper";

export default {
  namespaced: true,
  state: {
    documentType: [],
    fraction: [],
    holding: [],
    loadingRate: [],
    nameGood: [],
    requestStatus: [],
    requestType: [],
    specificationStatus: [],
    strengthGrade: [],
    specificationStatusSpecial: [],
    cargoQuarry: [],
    lprStatuses: [],
  },
  actions: {
    async getDocumentType({ commit }) {
      try {
        const data = await disctService.getDocumentType();
        commit("SET_DICT_DOCUMENT_TYPE", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getFraction({ commit }) {
      try {
        const data = await disctService.getFraction();
        commit("SET_DICT_FRACTION", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getHolding({ commit }) {
      try {
        const data = await disctService.getHolding();
        commit("SET_DICT_HOLDING", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getLoadingRate({ commit }) {
      try {
        const data = await disctService.getLoadingRate();
        commit("SET_DICT_LOADING_RATE", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getNameGood({ commit }) {
      try {
        const data = await disctService.getNameGood();
        commit("SET_DICT_NAME_GOOD", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getRequestStatus({ commit }) {
      try {
        const data = await disctService.getRequestStatus();
        commit("SET_DICT_REQUEST_STATUS", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getRequestType({ commit }) {
      try {
        const data = await disctService.getRequestType();
        commit("SET_DICT_REQUEST_TYPE", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getSpecificationStatus({ commit }) {
      try {
        const data = await disctService.getSpecificationStatus();
        commit("SET_DICT_SPECIFICATION_STATUS", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getSpecificationStatusSpecial({ commit }, id) {
      try {
        const data = await disctService.getSpecificationStatusSpecial(id ? `?id=${id}` : '');
        commit("SET_DICT_SPECIFICATION_STATUS_SPECIAL", data.data.data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getStrengthGrade({ commit }) {
      try {
        const data = await disctService.getStrengthGrade();
        commit("SET_DICT_STRENGTH_GRADE", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getCargoQuarry({ commit }) {
      try {
        const data = await disctService.getCargoQuarry();
        commit("SET_CARGO_QUARRY", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data);
      }
    },
    async getLprStatuses({ commit }) {
      try {
        const data = await disctService.getLprStatuses();
        commit("SET_LPR_STATUSES", data);
      } catch (e) {
        handleError(e);
        throw errorFormat(e.response.data.data);
      }
    },
  },
  getters: {
    documentType(state) {
      return state.documentType;
    },
    fraction(state) {
      return state.fraction;
    },
    holding(state) {
      return state.holding;
    },
    loadingRate(state) {
      return state.loadingRate;
    },
    nameGood(state) {
      return state.nameGood;
    },
    requestStatus(state) {
      return state.requestStatus;
    },
    requestType(state) {
      return state.requestType;
    },
    specificationStatus(state) {
      return state.specificationStatus;
    },
    specificationStatusSpecial(state) {
      return state.specificationStatusSpecial;
    },
    strengthGrade(state) {
      return state.strengthGrade;
    },
    cargoQuarry(state) {
      return state.cargoQuarry;
    },
    lprStatuses(state) {
      return state.lprStatuses;
    },
  },
  mutations: {
    SET_DICT_DOCUMENT_TYPE(state, value) {
      state.documentType = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_FRACTION(state, value) {
      state.fraction = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_HOLDING(state, value) {
      state.holding = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_LOADING_RATE(state, value) {
      state.loadingRate = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_NAME_GOOD(state, value) {
      state.nameGood = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_REQUEST_STATUS(state, value) {
      state.requestStatus = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_REQUEST_TYPE(state, value) {
      state.requestType = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_SPECIFICATION_STATUS(state, value) {
      state.specificationStatus = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_SPECIFICATION_STATUS_SPECIAL(state, value) {
      state.specificationStatusSpecial = value.statuses.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_DICT_STRENGTH_GRADE(state, value) {
      state.strengthGrade = value.data.map(item => {
        item.value = false
        item.text = item.name
        return item
      });
    },
    SET_CARGO_QUARRY(state, value) {
      state.cargoQuarry = value.data
    },
    SET_LPR_STATUSES(state, value) {
      state.lprStatuses = value.data
    },
  },
};
