<template>
  <div>
    <div
      v-for="(item, index) in state.collection"
      :key="`addres-${index}`"
    >
      <AddressWidget
        @update-address="($event) => updateAdress($event, index)"
        @remove-address="() => deleteAdress(index)"
        :default-address="state.collection[index]"
        :is-valid="v$.$error"
      />
    </div>
    <div
      v-if="state.collection.length < 5"
      class="add-button mb-2"
      @click.prevent="addAdress"
      role="button"
    >
      <SvgIcon name="plus" class="button-plus"/>
      Добавить фактический адрес
    </div>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, required } from '@vuelidate/validators'
import SvgIcon from '@/components/base/SvgIcon.vue';
import AddressWidget from '../Specifications/AddressWidget.vue';

export default {
  name: 'ClientAddAdress',
  emits: ['update-address'],
  components: {
    SvgIcon,
    AddressWidget,
  },
  setup(props) {
    const validateAddress = computed(() => {
      if (props.notValidate) {
        return {}
      }

      return {
        required: helpers.withMessage('Поле фактический адрес обязательно', required),
        maxLength: helpers.withMessage(
          ({
            $params,
          }) => `Длина поля фактический адреса должно быть меньше ${$params.max}`,
          maxLength(255)
        ),
      }
    })

    const state = props.addressList?.length > 0
      ? reactive({ collection: [] })
      : reactive({
        collection: [
          {
            address: '',
            coordinates: [],
            region: '',
            district: '',
            country: '',
            city_district: ''
          },
        ]
      })


    if (props.addressList) {
      props.addressList.map((item) => {
        state.collection.push({
          address: item?.address, id: item.id || null,
          coordinates: item?.coordinates || [],
          city_district: item?.city_district,
          region: item?.region,
          district: item?.district,
          country: item?.country
        })
      })
    }

    let v$ = useVuelidate({
      collection: {
        $each: helpers.forEach({
          address: validateAddress.value
        })
      }
    }, state, { $autoDirty: true })

    return {
      state,
      v$
    }
  },
  props: {
    addressList: {
      type: Array,
      required: false,
    },
    notValidate: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    addAdress() {
      const _this = this

      _this.v$.$touch();

      this.state.collection.push({
        address: '',
        coordinates: [],
        metaAddress: []
      })

      setTimeout(() => {
        _this.v$.$touch();
      }, 0)

    },
    async updateAdress(address, index) {
      const collection = this.state.collection
      collection[index] = address

      this.state.collection = [...collection]
      this.state.collection.filter(v => v.address !== '')

      this.$emit('update-address', this.state.collection)
    },
    deleteAdress(index) {
      this.state.collection = this.state.collection.filter((el, ind) => {
        return ind !== index
      })

      this.$emit('update-address', this.state.collection)
    }
  },
}
</script>

<style lang="scss" scoped>
.input-group-item {
  margin-bottom: 16px;
}

.add-button {
  margin-left: 0;
}

.icon-delete {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
