export default class Address {
  addressTypes = {
    LOAD: 'load',
    UNLOAD: 'unload',
    TRANSIT: 'transit',
    VIA_POINT: 'via_point'
  };
  routeList = [];
  workingSchedule = {};
  routeId = null;
  routePolyline = [];
  viaPoints = [];
  distance = null;
  apiKey = null;
  isUnloadAddressSync = true;
  isLoadAddressSync = true;

  constructor({
      id, route_line, is_load_coordinates_synchronized = true,
      is_unload_coordinates_synchronized = true,
      load_address, load_coordinates, load_region,
      load_country, unload_address, unload_coordinates,
      unload_region, unload_country, load_start, load_end,
      unload_start, unload_end, transit = [], load_entity_id,
      load_entity_type, unload_entity_id, unload_entity_type,
      via_points = []
    } = {}) {
    this.apiKey = process.env.VUE_APP_YANDEX_MAP_API_KEY;
    this.routeId = id;
    this.routePolyline = route_line;
    this.isLoadAddressSync = is_load_coordinates_synchronized;
    this.isUnloadAddressSync = is_unload_coordinates_synchronized;
    this.routeList = [
      this._createDefaultTemplate(
        load_address,
        load_coordinates,
        load_region,
        load_country,
        load_entity_id,
        load_entity_type,
        this.addressTypes.LOAD
      ),
      transit ? transit.map(transitPoint => {
        return this._createDefaultTemplate(
          transitPoint.address,
          transitPoint.coordinates,
          undefined,
          undefined,
          undefined,
          undefined,
          this.addressTypes.TRANSIT
        )
      }) : [],
      this._createDefaultTemplate(
        unload_address,
        unload_coordinates,
        unload_region,
        unload_country,
        unload_entity_id,
        unload_entity_type,
        this.addressTypes.UNLOAD
      )
    ].flat()
    this.workingSchedule[this.addressTypes.LOAD] = this._createDefaultTemplateTimetable(load_start, load_end);
    this.workingSchedule[this.addressTypes.UNLOAD] = this._createDefaultTemplateTimetable(unload_start, unload_end);
    this.viaPoints = via_points || [];
  }

  /**
   * @returns { Object }
   */
  getAddressTypes() {
    return this.addressTypes;
  }

  /**
   * @returns { Object }
   */
  getSyncMode() {
    return {
      [this.addressTypes.LOAD]: this.isLoadAddressSync,
      [this.addressTypes.UNLOAD]: this.isUnloadAddressSync
    }
  }

  /**
   * @returns { Object }
   */
  getWorkingSchedule() {
    return this.workingSchedule;
  }

  /**
   * @returns { Object } - возвращает данные в формате, в котором их ожидает бекенд
   */
  getRouteFormattedForBackend() {
    const load = this.routeList.find(address => address.type === this.addressTypes.LOAD);
    const unload = this.routeList.find(address => address.type === this.addressTypes.UNLOAD);
    const transit = this.routeList.filter(address => address.type === this.addressTypes.TRANSIT);
    const loadSchedule = this.workingSchedule[this.addressTypes.LOAD];
    const unloadSchedule = this.workingSchedule[this.addressTypes.UNLOAD];
    return {
      id: this.routeId,
      route_line: this.routePolyline,
      load_address: load.address,
      load_coordinates: this._prepareCoordsForBackend(load.coords),
      load_region: load.region,
      load_country: load.country,
      load_start: loadSchedule.start,
      load_end: loadSchedule.end,
      load_entity_id: load.entity_id,
      load_entity_type: load.entity_type,
      unload_address: unload.address,
      unload_coordinates: this._prepareCoordsForBackend(unload.coords),
      unload_region: unload.region,
      unload_country: unload.country,
      unload_start: unloadSchedule.start,
      unload_end: unloadSchedule.end,
      unload_entity_id: unload.entity_id,
      unload_entity_type: unload.entity_type,
      is_load_coordinates_synchronized: this.isLoadAddressSync,
      is_unload_coordinates_synchronized: this.isUnloadAddressSync,
      transit: transit,
      via_points: this.viaPoints,
    }
  }

  /**
   * @param { any } coords - строковое значение адреса
   * @returns { [string, string][]|string } - пустая строка для невалидных данных
   */
  _prepareCoordsForBackend(coords) {
    return Array.isArray(coords) ? coords : coords !== '' ? coords?.split(',') : ''
  }

  /**
   * @param { string|undefined } newValue - строковое значение адреса
   * @param { string } type - тип адресной точки
   * @param { number|null= } index - индекс транзитой точки
   * @param { boolean= } isFromMap - откуда передано изменение
   * @returns { void }
   */
  async handleChange({value, type, index = null, isFromMap = false}) {
    if (type.includes(this.addressTypes.TRANSIT)) {
      this._changeTransitPoints(value, index, type)
      return
    }
    if (type.includes(this.addressTypes.VIA_POINT)) {
      if (Array.isArray(value)) {
        this.viaPoints = value;
      } else {
        this.viaPoints.push(value);
      }
      return;
    }
    await this._changeAddressUnit(value, type, isFromMap)
  }

  getRoutePolyline() {
    return this.routePolyline
  }

  /**
   * @param { string } newValue - строковое значение для времени
   * @param { string } type - что конкретно меняем в формате 'load|start'
   * @returns { void }
   */
  handleScheduleChange(newValue, type) {
    if (!/\|/.test(type) || !newValue) {
      throw new Error('Passed invalid type');
    }
    const [ addressType, propcessType ] = type.split('|');

    if (propcessType === 'all-day') {
      for (const type in this.workingSchedule[addressType]) {
        this.workingSchedule[addressType][type] = newValue;
      }
      return;
    }

    this.workingSchedule[addressType][propcessType] = newValue;
  }

  handleEntityChange(entity_id, entity_type, type) {
    const address = this.routeList.find(item => item.type === type)

    address.entity_id = entity_id
    address.entity_type = entity_type
  }

  /**
   * @param { string } type - адрес погрузки / адрес выгрузки
   * @returns { void }
   */
  async handleSyncModeChange(type) {
    if (type === this.addressTypes.LOAD) {
      this.isLoadAddressSync = !this.isLoadAddressSync;
      await this._completeAddressObject(type, this.isLoadAddressSync);
      return;
    }
    this.isUnloadAddressSync = !this.isUnloadAddressSync;
    await this._completeAddressObject(type, this.isUnloadAddressSync);
  }

  /**
   * @param { string } type - адрес погрузки / адрес выгрузки
   * @param { boolean } isSyncOn - включена ли синхронизация
   * @returns { void }
   */
  async _completeAddressObject(type, isSyncOn) {
    if (!isSyncOn) return;
    const address = this.routeList.find(address => address.type === type);
    const hasCoords = !!address.coords;
    const { address: yaAddress, coords, region, country } = await this._getCoordinatesAddressMatch(
      hasCoords ? address.coords : address.address,
      hasCoords
    );

    address.address = yaAddress;
    address.coords = coords;
    address.region = region;
    address.region = country;
  }

  /**
   * @param { [number, number][] } polyline - массив с точками ломаной линии
   * @returns { void }
   */
  updatePolyline(polyline) {
    this.routePolyline = polyline;
  }

  /**
   * @param { string|undefined= } address - строковое значение адреса
   * @param { string|undefined= } coordinates - координаты в формате кортежа из двух чисел
   * @param { string|undefined= } region - строковое значение регион
   * @param { string|undefined= } country - строковое значение страна
   * @param { string|undefined= } type - тип адресной точки
   * @param { number|undefined= } entity_id - id адреса из коллекции заданных адресов
   * @param { string|null|undefined= } entity_type - тип выбора адреса (quary - карьер, seaport - порт)
   * @returns { Object }
   */
  _createDefaultTemplate(address, coordinates, region, country, entity_id, entity_type, type) {
    // воткнул сюда костыль так как в базе данные хранятся в строковом формате
    // а не в tuple формате [number, number], на всякий случай сделал проверку и обработку перед JSON.parse
    coordinates = !coordinates ? undefined : JSON.parse(`[${coordinates}]`);
    coordinates = Array.isArray(coordinates) ? coordinates.join(', ') : coordinates
    return {
      address: address,
      coords: coordinates,
      region: region,
      country: country,
      entity_id: entity_id,
      entity_type: entity_type,
      type: type,
    }
  }

  /**
   * @param { string|undefined= } startTime - строковое значение формата "hh:mm"
   * @param { string|undefined= } endTime - строковое значение
   * @returns { Object }
   */
  _createDefaultTemplateTimetable(startTime, endTime) {
    return {
      start: startTime || '',
      end: endTime || '',
    }
  }

  /**
   * @param { string } newValue - строковое значение адреса, либо же координаты
   * @param { string } type - тип адресной точки либо значение из addressTypes, либо addressTypes + |coord, если переданы координаты
   * @param { boolean } isFromMap - откуда было произведено изменение, из поля адреса в компоненте карты ?
   * @returns { void }
   */
  async _changeAddressUnit(newValue, type, isFromMap) {
    const isCoordinatesDataPassed = type.endsWith('|coords');
    const addressType = isCoordinatesDataPassed ? type.split('|')[0] : type;
    const addressItem = this.routeList.find(address => address.type === addressType);
    const isSyncActive = this.getSyncMode()[addressType];

    try {
      // в адрес в форме введено значение, синхронизация отключена
      // в таком случае не нужно геокодирование, тк это просто лейбл
      if (!isFromMap && !isSyncActive && !isCoordinatesDataPassed) {
        addressItem.address = newValue;
        return;
      }

      const { address, coords, region, country } = await this._getCoordinatesAddressMatch(newValue, isCoordinatesDataPassed);

      // режим, когда введены данные в поле карты
      if (isFromMap && !isSyncActive) {
        addressItem.coords = coords;
        addressItem.region = region;
        addressItem.country = country;
        return;
      }

      // найден адрес по координатам
      if (isCoordinatesDataPassed) {
        addressItem.region = region;
        addressItem.country = country;
        addressItem.address = isSyncActive ? address : addressItem.address;
        addressItem.coords = coords;
        return;
      }

      // найдены координаты по адресу
      addressItem.region = region;
      addressItem.country = country;
      addressItem.address = newValue;
      addressItem.coords = isSyncActive ? coords : addressItem.coords;

    // при геокодировании произошла ошибка
    // по координатам не найден адресс /
    // по адресу не найдены координаты
    } catch (error) {
      addressItem.address = isCoordinatesDataPassed ? '' : isSyncActive ? newValue : addressItem.address;
      addressItem.coords = isCoordinatesDataPassed ? newValue : isSyncActive ? '' : addressItem.coords;
    }
  }

  /**
   * @param { string|array|undefined= } newValue - строковое значение адреса
   * @param { number|null } index - индекс изменненой транзитной точки
   * @param { string|null } type - индекс изменненой транзитной точки
   * @returns { void }
   */
  async _changeTransitPoints(newValue, index, type) {
    // для ручной обработки после перетаскивания
    const isCoordinatesDataPassed = type.split('|').pop() === 'coords'
    if (isCoordinatesDataPassed) {
      const { address, coords, region, country } = await this._getCoordinatesAddressMatch(newValue, true);
      this.routeList[index].address = address;
      this.routeList[index].coords = coords;
      this.routeList[index].region = region;
      this.routeList[index].country = country;
      return;
    }
    // если в качестве значения передан пустой массив - удаляем все транзитные точки
    if (Array.isArray(newValue) && !newValue.length) {
      this._deleteAllTransitPoints();
      return;
    }

      // добавление транзитной точнки если, если есть index и newValue
      if (newValue && index === null) {
        this._addDefaultTransitPoint();
        this.routeList[this.routeList.length - 2].address = newValue;
        return
      }

    // если не передан индекс изменяемого элемента - создаем пустое поле под новую точку
    if (index === null) {
      this._addDefaultTransitPoint();
      return;
    }

    // если в качестве значения передан undefined - удаляем этот элемент
    if (newValue === undefined) {
      this.routeList.splice(index + 1, 1);
      return;
    }

    this.routeList[index + 1].address = newValue;
  }

  /**
   * @returns { void }
   */
  _deleteAllTransitPoints() {
    this.routeList = this.routeList.filter(address => address.type !== this.addressTypes.TRANSIT)
  }

  /**
   * @returns { number }
   */
  _transitPointsCount() {
    return this.routeList.filter(address => address.type === this.addressTypes.TRANSIT).length;
  }

  /**
   * @returns { void }
   */
  _addDefaultTransitPoint() {
    const transitPointsCount = this._transitPointsCount()
    let index = this.routeList.findIndex(address => address.type === this.addressTypes.LOAD) + 1;
    index = transitPointsCount ? index + transitPointsCount : index;
    this.routeList.splice(index, 0, this._createDefaultTemplate(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.addressTypes.TRANSIT
    ))
  }

  /**
   * @param { string } addressOrCoords - строковый адрес или координаты в строковом формате
   * @param { boolean= } isGetByCoordinates - ищем координаты по адресу, либо наоборот
   * @returns { object }
   */
  _getCoordinatesAddressMatch = async (addressOrCoords, isGetByCoordinates = false) => {
    if (!this.apiKey) {
      throw new Error('No api key available');
    }

    if (!addressOrCoords || !addressOrCoords.trim()) {
      return this._geocoderResponseAdapter();
    }

    const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${this.apiKey}&sco=latlong&format=json&geocode=${addressOrCoords}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const yaAddress = data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AddressLine;
      const yaRegion = data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.AdministrativeAreaName;
      const yaCountry = data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.CountryName;
      if (isGetByCoordinates) {
        return this._geocoderResponseAdapter(yaAddress, yaRegion, addressOrCoords, yaCountry);
      }

      return this._geocoderResponseAdapter(
        addressOrCoords,
        yaRegion,
        data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ').reverse().join(),
        yaCountry,
      )
    } catch (error) {
      throw new Error('Yandex geocoder failed to calculate coordinates');
    }
  }

  /**
   * @param { string= } address - адрес
   * @param { string= } region - регион
   * @param { string= } coords - координаты
   * @param { string= } country - страна
   * @returns { object }
   */
  _geocoderResponseAdapter(address = '', region = '', coords = '', country = '') {
    return {
      coords: coords,
      region: region,
      address: address,
      country: country,
    }
  }
}
