<template>
  <div v-if="permissions.view" class="form_radio">
    <input
      :id="id"
      type="radio"
      :name="name"
      :value="id"
      :disabled="isDisabled"
      :checked="modelValue == id"
      @change="(event) => $emit('update:modelValue', event.target.value)"
    />
    <label :for="id">
      <span class="radio-name">{{ RadioName }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioComponent',
  props: {
    RadioName: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 0,
    },
    name: {
      type: String,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    permissions: {
      type: Object,
      default: () => ({
        edit: true,
        view: true
      })
    }
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.permissions.edit;
    }
  }
}
</script>

<style lang="scss">
.form_radio {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  input[type="radio"] {
    display: none;

    &:disabled {
      & + label {
        pointer-events: none;

        &:before {
          background-image: url("~@/assets/img/gray_radio_button_unchecked.svg");
        }
      }

      & + label span,
      &:checked + label span {
        color: #ccc;
      }

      &:checked + label:before {
        background-image: url("~@/assets/img/gray_radio_button_checked.svg");
      }
    }
  }

  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    margin-right: 0;
    line-height: 18px;
    user-select: none;
  }

  label:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background: url("~@/assets/img/radio_button_unchecked.svg") 0 0 no-repeat;
    background-size: contain;
    margin-bottom: 2px;
  }

  input[type="radio"]:checked + label:before {
    background: url("~@/assets/img/radio_button_checked.svg") 0 0 no-repeat;
    background-size: contain;
  }

  label:hover:before {
    filter: brightness(120%);
  }

  .radio-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0a2333;
    // margin-left: 12px;
  }

  input[type="radio"]:checked + label span {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #0055e8;
  }
}
</style>
