<template>
  <div class="port-edit-modal">
    <div class="port-edit-modal__wrapper">
      <BigModal
        :is-open="isOpen"
        :ModalButtonShow="false"
        :Title="title"
        @close="closeModal"
        @submit-form="onSubmit"
      >
        <PortCardForm
          :fact_addresses="fact_addresses"
          :work_schedule="days"
          :company="company"
          :inn="inn"
          :isModalEditData="true"
          :port-id="portId"
          :port-name="portName"
          :comment="comment"
          :port-status="portStatus"
          ref="portCardForm"
          @update-days="workDays"
          @update-company="closeModal"
          @update-address="updateAddress"
        />
      </BigModal>
    </div>
  </div>
</template>

<script setup>
import PortCardForm from './PortCardForm.vue'
import BigModal from '@/components/BigModal.vue'
import {ref, watch} from 'vue'

const emit = defineEmits(['update-address', 'close-modal'])

const portCardForm = ref(null)
const title = 'О порте'

const onSubmit = () => {
  portCardForm.value.onSubmit
}

const days = ref('')

const workDays = (value) => {
  days.value = value
}

const updateAddress = (addresses) => {
  emit('update-address', addresses)
}

const props = defineProps({
  fact_addresses: {
    type: Array,
    default: () => []
  },
  company: {
    type: Object,
    default: () => {},
  },
  inn: {
    type: [String, Number],
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  portId: {
    type: Number
  },
  comment: {
    type: String,
  },
  portName: {
    type: String,
    default: ''
  },
  workSchedule: {
    type: String,
    default: ''
  },
  portStatus: {
    type: String,
    default: 'Действующий',
  }
})

const closeModal = () => {
  emit('close-modal')
}

watch(() => props.workSchedule, (newValue) => {
  days.value = newValue
}, {
  immediate: true
})
</script>

