import ApiUtil from '@/utils/api'

export default {
  getAccount: () =>
    ApiUtil({
      url: '/api/v1/account',
      method: 'get',
      requiresAuth: true
    }),
  getValidCells: () =>
    ApiUtil({
      url: '/api/v1/cells',
      method: 'get',
      requiresAuth: true
    }),
  getPermisionList: () => {
    return ApiUtil({
      url: '/api/v1/schemas/permissions/list',
      method: 'get',
      requiresAuth: true,
    })
  }
}
