<template>
  <div>
    <input class="js-copyInput hidden_input" readonly/>
    <div v-if="modalEditContactState">
      <ContactPersonModal
        :is-open="modalEditContactState"
        @close="modalEditContactState = false"
        :page-id="pageId"
        :is-modal-edit="true"
        contact-type="quarry"
        @routLinkPage="routLinkPage"
      />
    </div>
    <ContactPersonModal
      :is-open="modalContactState"
      @close="modalContactState = false"
      :page-id="pageId"
      contact-type="quarry"
      @routLinkPage="routLinkPage"
    />
    <div v-if="statePage">
      <BackgroundComponent class="client-card">
        <div class="card__header">
          <div class="card__arrow" @click="backToDashboard">
            <SvgIcon name="arrow-back"/>
          </div>
          <h1 class="card__title">Карьер: {{ quarryDetail.name }}</h1>
          <div class="card__id">{{ quarryDetail.id }}</div>
          <div
            v-if="account.role !== 'coordinator' && account.role !== 'logistician'"
            class="client-card__svg pointer"
            @click="showEdit"
          >
            <SvgIcon name="edit"/>
            <BaseTooltip text="Редактировать карьер" />
          </div>
        </div>
        <ScrollbarComponent>
          <div class="client-card__content">
            <div class="about-company">
              <div class="item-name">Статус карьера</div>
              <div class="item-content">
                {{ quarryDetail.status === "Действующий" ? "Действителен" : "Недействителен" }}
              </div>

              <div class="item-name">Ответственный</div>
              <div class="item-content">
                {{ quarryDetail.responsible?.name }}
                {{ quarryDetail.responsible?.family_name }}
              </div>

              <div class="item-name">Общая выручка (материалы)</div>
              <div class="item-content bold">
                {{ quarryDetail?.capital_material_total ?
                  quarryDetail?.capital_material_total.toLocaleString() + ' ₽' :
                  'Не указано' }}
              </div>

              <div class="client-card__info-block">
                <p class="client-card__info-row" v-if="quarryDetail.name">
                  <b>{{ quarryDetail?.company?.name ? `${quarryDetail.company.name}, ` : '' }}</b>
                  {{ `ИНН: ${quarryDetail.inn}` }}
                </p>
                <p class="client-card__info-row" v-if="quarryDetail?.company?.registration_date"><span>Дата регистрации: {{
                  quarryDetail?.company?.registration_date ? quarryDetail?.company?.registration_date : ''
                }},</span></p>
                <p class="client-card__info-row" v-if="quarryDetail?.company?.head">
                  <span>
                    Генеральный директор: {{ quarryDetail?.company?.head ? quarryDetail?.company?.head : '' }}
                  </span>
                </p>
                <p
                  class="client-card__info-row"
                  v-if="quarryDetail.legal_address_symbol !== 'plug' && quarryDetail.legal_address_symbol"
                >
                  Юридический адрес:
                  <span>{{ quarryDetail.legal_address_symbol }}</span>
                </p>
              </div>

              <!--      удален вывод ИНН / ОГРН / КПП / Юр адрес / Фактический адрес        -->
              <QuarryGoods :goods="quarryDetail.name_goods" />
              <div v-if="quarryDetail.fact_addresses.length > 0">
                <div class="item-name">Фактический адрес</div>
                <div class="item-content address-list">
                  <p v-for="(fact_addresses, index) in quarryDetail.fact_addresses" :key="index">
                    {{ fact_addresses.address }}</p>
                </div>
              </div>

              <div class="item-name">Комментарий</div>
              <div class="item-content">
                {{ quarryDetail.comment ? quarryDetail.comment : "Не указано" }}
              </div>

              <div class="item-name">Холдинг</div>
              <div class="item-content">
                {{ quarryDetail.holding ? quarryDetail.holding : "Не указано" }}
              </div>

              <div class="item-name">График работы</div>
              <div v-if="quarryDetail.work_schedule" class="item-content">
                <div
                  v-for="(day, index) of quarryDetail.work_schedule.split(',')"
                  :key="`${day}_${index}`"
                  class="mb-1"
                >
                  {{ weekDays[index] }} :
                  {{
                    day == "24:00-24:00"
                      ? "круглосуточно"
                      : day == ""
                        ? "выходной"
                        : day
                  }}
                </div>
              </div>
            </div>
            <div class="contact-faces">
              <div class="client-card__subheader">
                <h1 class="client-card__subtitle">Контактные лица</h1>
                <div
                  v-if="isAddContactAvailable && contactsList?.length < 5"
                  class="client-card__svg pointer"
                  @click="showModalContact"
                >
                  <SvgIcon name="contact-faces"/>
                </div>
              </div>

              <ContactFacesCard
                v-for="item of contactsList"
                :item="item"
                :key="item"
                :entity="quarryDetail"
                @copy="copyContactInfo"
                @delete="deleteContact"
                @edit="editContact"
                @add-favorites="addToFavorites"
                global
                favorite
              />
            </div>
          </div>
        </ScrollbarComponent>
      </BackgroundComponent>
      <AlertModal ref="alert" :nameButton="'Понятно'"></AlertModal>
    </div>
    <QuarryModal
      v-if="modalEditState"
      :is-open="modalEditState"
      @close="closeModalQuarry"
      @extra="quarryUpdatePage"
      :is-modal-edit="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BackgroundComponent from '@/components/BackgroundComponent.vue'
import AlertModal from '@/components/AlertModal.vue'
import QuarryModal from '@/components/QuarryModals/QuarryModal.vue'
import ContactPersonModal from '@/components/ClientsModals/ContactPersonModal.vue'
import contactsService from '@/services/contactsService.js'
import SvgIcon from '@/components/base/SvgIcon.vue'
import ScrollbarComponent from '@/components/ScrollbarComponent.vue'
import image from '@/assets/img/user.svg'
import QuarryGoods from '@/components/Quarry/QuarryGoods.vue'
import ContactFacesCard from '@/components/Contacts/ContactFacesCard.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import _get from 'lodash.get';

export default {
  name: 'QuarryCard',
  data() {
    return {
      image,
      height: 0,
      openMenuId: null,
      clientDetailCompany: '',
      modalEditState: false,
      modalEditContactState: false,
      statePage: false,
      modalContactState: false,
      pageId: this.$route.params.id,
      weekDays: [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ],
    }
  },
  components: {
    BaseTooltip,
    ContactFacesCard,
    ScrollbarComponent,
    SvgIcon,
    BackgroundComponent,
    AlertModal,
    QuarryGoods,
    QuarryModal,
    ContactPersonModal,
  },
  computed: {
    ...mapGetters({
      quarryDetail: 'quarry/quarryDetail',
      contactsList: 'contacts/contactsList',
      account: 'account/account',
    }),
    isAddContactAvailable() {
      if (!this.quarryDetail) return false;
      return _get(this.quarryDetail, 'dashboard_permissions.contacts_create', false);
    },
  },
  methods: {
    async routLinkPage(value) {
      const currentPage = value.split(',')
      const pageId = currentPage[1]

      await this.getQuarryDetail(pageId);
    },
    async quarryUpdatePage() {
      await this.getQuarryDetail()
      await this.getQuarryDetail(this.$route?.params?.id);
    },
    toggleMenu(id) {
      this.openMenuId = id
    },
    hideToggleMenu() {
      this.openMenuId = null
    },
    async copyContactInfo(id, text) {
      const contactCurrent = this.contactsList.find((item) => {
        if (item.id == id) {
          return item
        }
      })

      const number = contactCurrent?.contact_numbers[0]?.number
      const extensionNumber = contactCurrent?.contact_numbers[0]?.extension_number
      const numberType = contactCurrent?.contact_numbers[0]?.telephone_type

      text = `${numberType} телефон: +7${number}`

      if (extensionNumber) {
        text = text + `(${extensionNumber})`
      }

      const copyInput = document.querySelector('.js-copyInput')

      copyInput.value = text
      copyInput.focus()
      copyInput.select()
      document.execCommand('copy')
    },
    async deleteContact(id) {
      try {
        const params = {
          quarry_id: this.pageId
        }

        const contactCurrent = this.contactsList.find((item) => {
          if (item.id == id) {
            return item
          }
        })

        await contactsService.deleteContact(contactCurrent.id)
        await this.$store.dispatch('contacts/getContacts', params)
      } catch (error) {
        const { data } = error.response?.data

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            nameButton: 'Понятно',
            color: '#FFA500',
          })
        }
      }
    },
    async editContact(id) {
      const contactCurrent = this.contactsList.find((item) => {
        if (item.id == id) {
          return item
        }
      })

      await this.$store.dispatch('contacts/setSelectedContact', contactCurrent)

      this.modalEditContactState = true
    },
    async getQuarryDetail(pageUrl = this.pageId) {
      try {
        await this.$store.dispatch('quarry/getQuarryDetail', pageUrl)

        await this.$store.dispatch(
          'quarry/setSelectedQuarry',
          this.quarryDetail
        )

        this.statePage = true
      } catch (e) {
        this.$refs.alert.show('Ошибка', e, {
          nameButton: 'Понятно',
        })
      }
    },
    async getContacts() {
      try {
        const params = {
          quarry_id: this.pageId
        }

        await this.$store.dispatch('contacts/getContacts', params)
      } catch (e) {
        this.$refs.alert.show('Ошибка ', e, {
          nameButton: 'Понятно',
        })
      }
    },
    showEdit() {
      this.modalEditState = true
    },
    showModalContact() {
      this.modalContactState = true
    },
    async getCargoQuarry() {
      try {
        await this.$store.dispatch('dict/getCargoQuarry');
      } catch (error) {
        console.log(error)
      }
    },
    closeModalQuarry() {
      this.modalEditState = false;
    },
    async addToFavorites(id) {
      try {
        const params = {
          quarry_id: this.pageId
        }

        await this.$store.dispatch('contacts/addToFavorites', id);
        await this.$store.dispatch('contacts/getContacts', params);
      } catch (e) {
        console.log(e)
      }
    },
    async backToDashboard() {
      await this.$router.go(-1)
    },
  },

  created() {
    window.addEventListener('click', this.hideToggleMenu)
    this.getQuarryDetail()
    this.getContacts(this.pageId)
    this.getCargoQuarry();
  },

  unmounted() {
    window.removeEventListener('click', this.hideToggleMenu)
  },
}
</script>

<style lang="scss">
.client-card {
  margin: 14px 13px 14px 10px;
  font-family: "Manrope";
  padding: 17px 16px 16px 26px;
  min-width: 454px;
  display: grid;

  .item-content.address-list {
    p {
      padding: 8px 0;

      & + p {
        border-top: 1px solid #dedede;
      }
    }
  }

  .card__arrow {
    display: flex;
    margin-right: 8px;
    cursor: pointer;
  }

  .scrollbar__body,
  .scrollbar__contend {
    margin: 0;
    padding: 0;
  }

  .client-card__content {
    display: flex;
    justify-content: space-between;
  }

  .contact-faces__card-photo {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .contact-faces__info-name {
    font-weight: 500;
    color: #c5c5c5;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .about-company {
    margin-right: 10px;
  }

  /*-----------------Мини модалка------------------*/

  .client-card__mini-modal {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.pointer {
  cursor: pointer;
}

.company-group {
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
}

.contact-faces {
  .client-card__svg {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
}

.matrix-table {
  margin-bottom: 18px;

  &__header {
    display: grid;
    grid-template-columns: 1fr 88px 1fr;
    gap: 8px;
    border-bottom: 1px solid #DEDEDE;
    padding: 12px;

    p {
      color: #A4A4A4;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__strength {
    p + p {
      margin-top: 8px;
    }
  }

  &__fractions {
    display: flex;
    flex-wrap: wrap;

    p {
      max-width: 50%;
      min-width: 34%;

      &:nth-of-type(2n):before {
        content: "/ ";
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 88px 1fr;
    align-items: flex-start;
    gap: 8px;
    padding: 12px;
    border-bottom: 1px solid #DEDEDE;

    &:last-child {
      border-bottom: none;
    }

    div {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>
