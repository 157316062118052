<template>
  <div>
    <input class="js-copyInput hidden_input" readonly/>
    <div v-if="modalEditState">
      <AboutClientModal
        :is-open="modalEditState"
        @close="modalEditState = false"
        :is-modal-edit="true"
        @submitValue="submitClient"
      />
    </div>
    <div v-if="modalEditContactState">
      <ContactPersonModal
        :is-open="modalEditContactState"
        @close="modalEditContactState = false"
        :page-id="pageId"
        :is-modal-edit="true"
        @routLinkPage="routLinkPage"
        contact-type="client"
      />
    </div>
    <ContactPersonModal
      :is-open="modalContactState"
      @close="modalContactState = false"
      :page-id="pageId"
      @routLinkPage="routLinkPage"
      contact-type="client"
    />
    <div v-if="statePage">
      <BackgroundComponent class="client-card">
        <div class="card__header">
          <div class="card__arrow" @click="backToDashboard">
            <SvgIcon name="arrow-back"/>
          </div>
          <h1 class="card__title">Клиент: {{ clientDetail.name }}</h1>
          <div class="card__id">{{ clientDetail.id }}</div>
          <div
            v-if="account.role !== 'coordinator' && account.role !== 'logistician'"
            class="client-card__svg pointer"
            @click="showEdit"
          >
            <SvgIcon name="edit"/>
            <BaseTooltip text="Редактировать клиента"/>
          </div>
        </div>
        <div class="client-card__author">{{ author }}</div>
        <ScrollbarComponent>
          <div class="client-card__content">
            <div class="about-company">
              <div class="item-name">Статус клиента</div>
              <div class="item-content">
                {{ clientDetail.status == 1 ? "Действителен" : "Недействителен" }}
              </div>

              <div class="item-name">Ответственный</div>
              <div class="item-content">
                {{ clientDetail.responsible?.name }}
                {{ clientDetail.responsible?.family_name }}
              </div>

              <div class="item-name">Общая выручка клиента</div>
              <div class="item-content bold">
                {{ clientDetail.revenue ? clientDetail?.revenue.toLocaleString() + " ₽" : "Не указано" }}
              </div>

              <div
                  v-for="(company, index) of clientDetail.companies"
                  :key="index"
                  class="company-group"
              >
                <div class="client-card__info-block">
                  <p class="client-card__info-row" v-if="company.name"><b>{{ company.name }},</b> ИНН: {{ company.inn }}
                  </p>
                  <p class="client-card__info-row" v-if="company.registration_date">
                    <span>Дата регистрации: {{ company.registration_date ? company?.registration_date : '' }},</span>
                  </p>
                  <p class="client-card__info-row" v-if="company.head">
                    <span>Генеральный директор: {{ company?.head ? company?.head : '' }}</span></p>
                  <p class="client-card__info-row"
                     v-if="company.legal_address_symbol !== 'plug' && company.legal_address_symbol">Юридический адрес:
                    <span>{{ company.legal_address_symbol }}</span></p>
                </div>

                <!--      удален кусок кода выводящий статус компании / наименование организации / основная компании / физическое лицо / ИНН / ОГРН / КПП / ЮР Адрес        -->

                <div v-if="company.comment">
                  <div class="item-name">Комментарий</div>
                  <div class="item-content">
                    {{ company.comment ? company.comment : "Нет" }}
                  </div>
                </div>
                <!--      удален кусок кода - статус интеграции с контрфокусом        -->
              </div>

              <div v-if="clientDetail.fact_addresses.length > 0">
                <div class="item-name">Фактический адрес</div>
                <div class="item-content address-list">
                  <p v-for="(fact_addresses, index) in clientDetail.fact_addresses" :key="index">
                    {{ fact_addresses.address }}</p>
                </div>
              </div>
            </div>
            <div class="contact-faces">
              <div class="client-card__subheader">
                <h1 class="client-card__subtitle">Контактные лица</h1>
                <div
                  v-if="isAddContactAvailable && contactsList?.length < 5"
                  class="client-card__svg pointer"
                  @click="showModalContact"
                >
                  <SvgIcon name="contact-faces"/>
                </div>
              </div>
              <ContactFacesCard
                  v-for="item of contactsList"
                  :item="item"
                  :key="item"
                  :entity="clientDetail"
                  @copy="copyContactInfo"
                  @delete="deleteContact"
                  @edit="editContact"
                  @add-favorites="addToFavorites"
                  global
                  favorite
              />
            </div>
          </div>
        </ScrollbarComponent>
      </BackgroundComponent>
      <AlertModal ref="alert" :nameButton="'Понятно'"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BackgroundComponent from '@/components/BackgroundComponent.vue'
import AlertModal from '@/components/AlertModal.vue'
import AboutClientModal from '@/components/ClientsModals/AboutClientModal.vue'
import ContactPersonModal from '@/components/ClientsModals/ContactPersonModal.vue'
import contactsService from '@/services/contactsService.js'
import SvgIcon from '@/components/base/SvgIcon.vue'
import ScrollbarComponent from '@/components/ScrollbarComponent.vue'
import image from '@/assets/img/user.svg'
import ContactFacesCard from '@/components/Contacts/ContactFacesCard.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import _get from 'lodash.get';

export default {
  name: 'ClientCard',
  data() {
    return {
      image,
      height: 0,
      openMenuId: null,
      clientDetailCompany: '',
      modalEditState: false,
      modalEditContactState: false,
      statePage: false,
      modalContactState: false,
      pageId: this.$route.params.id,
    };
  },
  components: {
    BaseTooltip,
    ContactFacesCard,
    ScrollbarComponent,
    SvgIcon,
    BackgroundComponent,
    AlertModal,
    AboutClientModal,
    ContactPersonModal,
  },
  computed: {
    ...mapGetters({
      clientDetail: 'clients/clientDetail',
      contactsList: 'contacts/contactsList',
      account: 'account/account',
    }),
    author() {
      const createdBy = this.clientDetail?.created_by;
      const date = this.clientDetail?.created_at || '';
      const name = createdBy?.name || '';
      const familyName = createdBy?.family_name || '';
      const createdByString = name ? `Создал (а): ${name} ${familyName}` : '';
      const dateString = date ? `Дата создания: ${date}` : '';
      return `${createdByString} ${dateString}`.trim();
    },
    isAddContactAvailable() {
      if (!this.clientDetail) return false;
      return _get(this.clientDetail, 'dashboard_permissions.contacts_create', false);
    },
  },
  methods: {
    async submitClient() {
      await this.getClientDetail();
    },
    async routLinkPage(value) {
      const currentPage = value.split(',')
      const pageId = currentPage[1]
      const params = {
        client_id: pageId
      }

      await this.$store.dispatch('contacts/getContacts', params);
      await this.getClientDetail(pageId);
    },
    toggleMenu(id) {
      this.openMenuId = id;
    },
    hideToggleMenu() {
      this.openMenuId = null;
    },
    async copyContactInfo(id, text) {
      const contactCurrent = this.contactsList.find((item) => {
        if (item.id == id) {
          return item;
        }
      });

      const number = contactCurrent?.contact_numbers[0]?.number;
      const extensionNumber = contactCurrent?.contact_numbers[0]?.extension_number;
      const numberType = contactCurrent?.contact_numbers[0]?.telephone_type;

      text = `${numberType} телефон: +7${number}`;

      if (extensionNumber) {
        text = text + `(${extensionNumber})`;
      }

      const copyInput = document.querySelector('.js-copyInput');

      copyInput.value = text;
      copyInput.focus();
      copyInput.select();
      document.execCommand('copy');
    },
    async deleteContact(id) {
      try {
        const params = {
          client_id: this.pageId
        }

        const contactCurrent = this.contactsList.find((item) => {
          if (item.id == id) {
            return item;
          }
        });

        await contactsService.deleteContact(contactCurrent.id);
        await this.$store.dispatch('contacts/getContacts', params);
      } catch (error) {
        const {data} = error?.response?.data;

        if (data?.message) {
          this.$refs?.alert?.show('Ошибка', data?.message, {
            nameButton: 'Понятно',
            color: '#FFA500',
          });
        }
      }
    },
    async editContact(id) {
      const contactCurrent = this.contactsList.find((item) => {
        if (item.id == id) {
          return item;
        }
      });

      await this.$store.dispatch('contacts/setSelectedContact', contactCurrent);

      this.modalEditContactState = true;
    },
    async getClientDetail(pageUrl = this.pageId) {
      try {
        await this.$store.dispatch('clients/getClientDetail', pageUrl);
        await this.$store.dispatch('clients/setSelectedClient', this.clientDetail);
        this.statePage = true;
      } catch (e) {
        this.$refs?.alert?.show('Ошибка', e, {
          nameButton: 'Понятно',
        });
      }
    },
    async getContacts() {
      try {
        const params = {
          client_id: this.pageId
        }

        await this.$store.dispatch('contacts/getContacts', params);
      } catch (e) {
        this.$refs?.alert?.show('Ошибка ', e, {
          nameButton: 'Понятно',
        });
      }
    },
    showEdit() {
      this.modalEditState = true;
    },
    showModalContact() {
      this.modalContactState = true;
    },
    async addToFavorites(id) {
      try {
        const params = {
          client_id: this.pageId
        }

        await this.$store.dispatch('contacts/addToFavorites', id);
        await this.$store.dispatch('contacts/getContacts', params);
      } catch (e) {
        console.log(e)
      }
    },
    async backToDashboard() {
      await this.$router.go(-1)
    },
  },
  mounted() {
    window.addEventListener('click', this.hideToggleMenu);
    this.getClientDetail();
    this.getContacts(this.pageId);
  },

  unmounted() {
    window.removeEventListener('click', this.hideToggleMenu);
  },
};
</script>

<style lang="scss">
.client-card {
  margin: 14px 13px 14px 10px;
  font-family: "Manrope";
  padding: 17px 16px 16px 26px;
  min-width: 454px;
  display: grid;

  &__info-block {
    margin-bottom: 20px;
  }

  &__info-row {
    margin: 5px 0;
  }

  .card__arrow {
    display: flex;
    margin-right: 8px;
    cursor: pointer;
  }

  .scrollbar__body,
  .scrollbar__contend {
    margin: 0;
    padding: 0;
  }

  .client-card__content {
    display: flex;
    justify-content: space-between;
  }

  .contact-faces__card-block {
    .contact-faces {
      &__card-photo {
        margin-right: 10px;
        display: flex;
        align-items: center;
      }

      &__card-header {
        display: flex;
        align-items: center;
      }

      &__info-content {
        margin-left: 0;
      }

      &__info {
        margin-bottom: 8px;
        //justify-content: flex-end;

        &-name {
          font-weight: 500;
          color: #c5c5c5;

          &:first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .item-content {
    &.bold {
      font-weight: 700;
    }
  }

  .about-company {
    margin-right: 10px;
  }

  /*-----------------Мини модалка------------------*/

  .client-card__mini-modal {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.pointer {
  cursor: pointer;
}

.company-group {
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
}

.client-card__author {
  font-size: 12px;
  margin-top: 20px;
  line-height: 16px;
  color: #5A5A5A;
}

</style>
