import ApiUtil from '@/utils/api'

export default {
  getUsers: (params = {}) =>
    ApiUtil({
      url: '/api/v1/users',
      method: 'get',
      requiresAuth: true,
      params
    }),
  getUser: (data) =>
    ApiUtil({
      url: `/api/v1/users/${data.id}`,
      method: 'get',
      requiresAuth: true,
    }),
}
