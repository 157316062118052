<template>
  <div class="route-radio" v-if="!isEditDisabled">
    <input
      type="radio"
      :id="specification.id"
      :value="specification.id"
      @change="$emit('radio-selected', specification.id)"
      :checked="specification.id === mainSpec"
      :disabled="isShareMode"
    >
    <label :for="specification.id" class="route-radio__label">Основной маршрут</label>
  </div>
  <div class="route">
    <div class="route-item">
      <div class="route-item__location">
        <SvgIcon name="location"/>
      </div>
      <div>
        <div class="route-item__label">Погрузка</div>
        <div class="route-item__subtitle">
          {{ specification?.route?.load_address ? specification.route.load_address : 'Нет данных' }}
        </div>
        <div class="route-item__schedule">
          {{ getWorkingSchedule('load') }}
        </div>
      </div>
    </div>
    <template v-if="transitPoints.length">
      <div
        v-for="(point, id) in transitPoints"
        :key="point + id"
        class="flex-block mb-3"
      >
        <div class="route-item__location mt-n2">
          <SvgIcon name="trip_origin"/>
        </div>
        <div>
          <div class="route-item__label">
            Промежуточная точка
          </div>
          <div class="route-item__subtitle">
            {{ point.address }}
          </div>
        </div>
      </div>
    </template>
    
    <div class="route-item">
      <div class="route-item__location">
        <SvgIcon name="location"/>
      </div>
      <div>
        <div class="route-item__label">Выгрузка</div>
        <div class="route-item__subtitle">
          {{ specification?.route?.unload_address ? specification.route.unload_address : 'Нет данных' }}
        </div>
        <div class="route-item__schedule">
          {{ getWorkingSchedule('unload') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import SvgIcon from '@/components/base/SvgIcon.vue';

const props = defineProps({
  specification: Object,
  mainSpec: Number,
  isShareMode: Boolean,
  isEditDisabled: {
    type: Boolean,
    default: false,
  }
})
const transitPoints = ref([])

const emits = defineEmits(['radio-selected']);

const getWorkingSchedule = (type) => {
  if (!props?.specification?.route) return 'Нет данных';
  if (!props.specification?.route[`${type}_start`] || !props.specification?.route[`${type}_end`]) {
    return 'Нет данных'
  }

  if (props.specification.route[`${type}_start`] === props.specification.route[`${type}_end`]) {
    return 'Круглосуточно'
  }

  return `С ${props.specification.route[`${type}_start`]} до ${props.specification.route[`${type}_end`]}`
}

</script>

<style scoped lang="scss">
.route {
  &-radio {
    margin-bottom: 12px;
    display: flex;

    &__label {
      cursor: pointer;
      font-size: 12px;
      margin-left: 12px;
    }
  }

  &-item {
    display: flex;
    align-items: flex-start;
    position: relative;

    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #828282;
    }

    &__subtitle {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0a2333;
    }

    &__schedule {
      font-size: 12px;
      line-height: 16px;
      color: #0A2333;
    }

    &:first-child {
      margin-bottom: 12px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 25px;
        width: 1px;
        border-right: 1px dashed #0055e8;
        height: calc(100% + 12px);
      }
    }

    &__location {
      border: 1px solid #0055e8;
      border-radius: 12px;
      width: 49px;
      height: 49px;
      display: flex;
      flex: none;
      align-items: center;
      margin-right: 12px;
      justify-content: center;
      background: #ffffff;
      z-index: 11;
    }

    &:last-child::before {
      content: none;
    }
  }
}

</style>
