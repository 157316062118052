<template>
  <BaseModal
    :is-open="isOpen"
    @close="closeModal"
  >
    <div class="arrow-map-modal">
      <div class="arrow-map-modal__param">
        <p>Цвет</p>
        <input
          type="color"
          :value="arrowColor"
          @input="setArrowColor"
        >
      </div>
      <div class="arrow-map-modal__param">
        <p>Ширина</p>
        <input
          type="number"
          :value="arrowWidth"
          @input="setArrowWidth"
        >
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';

export default {
  name: 'ArrowMapModal',
  components: {
    BaseModal,
  },
  data() {
    return {
      isOpen: false,
      overTime: null,
    }
  },
  computed: {
    arrowColor () {
      return this.$store.getters['interactiveMap/getColorArrowsForSpecs']
    },
    arrowWidth () {
      return this.$store.getters['interactiveMap/getWidthArrowsForSpecs']
    },
  },
  methods: {
    show() {
      this.isOpen = !this.isOpen;
    },
    closeModal () {
      this.isOpen = false
    },
    setArrowColor (event) {
      if (this.overTime) {
        clearTimeout(this.overTime)
        this.overTime = null
      }

      this.overTime = setTimeout(() => {
        this.$store.commit('interactiveMap/SET_COLOR_ARROWS_FOR_SPECS', event.target.value)
      }, 1000)
    },
    setArrowWidth (event) {
      if (this.overTime) {
        clearTimeout(this.overTime)
        this.overTime = null
      }

      this.overTime = setTimeout(() => {
        this.$store.commit('interactiveMap/SET_WIDTH_ARROWS_FOR_SPECS', event.target.value)
      }, 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
.arrow-map-modal {
  display: flex;
  flex-direction: column;

  width: 100%;

  &__param {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 20px;
    margin-bottom: 20px;

    input {
      width: 190px;

      padding: 5px;
      border-bottom: 1px solid #000;
    }
  }
}
</style>
