<template>
  <div class="about-client-modal">
    <big-modal
      :Title="Title"
      :ModalButton="ModalButton"
      :is-open="isOpen"
      @close="closeModal"
      @submit-form="onSubmit"
      class="about-client"
    >
      <quarry-create-form
        ref="quarryForm"
        :is-quarry-page="isQuarryPage"
        @close="closeModal"
        is-contact-person
        is-modal-edit
        :is-submit-btn="false"
      />
    </big-modal>
    <alert-modal ref="alert" :nameButton="'Понятно'"/>
    <alert-modal ref="alertLinkInn" :close-button="true" close-text="Закрыть"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { ValidateEach } from "@vuelidate/components"
import BigModal from "@/components/BigModal"
import AlertModal from "@/components/AlertModal"
import SvgIcon from "@/components/base/SvgIcon"
import QuarryCreateForm from "@/components/Quarry/QuarryCreateForm";

export default {
  name: "QuarryModal",
  data() {
    return {
      isOpenAboutClient: false,
      Title: "О карьере",
      ModalButton: "Сохранить",
      disabledKontur: false,
    }
  },
  computed: {
    ...mapGetters({
      company: "company/company",
      nameGood: "dict/nameGood",
      holding: "dict/holding",
      strengthGrade: "dict/strengthGrade",
      quarryData: "clients/getSelectedClient",
      filterParams: "quarry/getQuarryFilterParams",
      fractionDict: "dict/fraction",
      cargoQuarry: "dict/cargoQuarry",
      getSelectedQuarry: "quarry/getSelectedQuarry",
      responseList: "users/responseList",
    }),
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isQuarryPage: {
      type: Boolean,
      default: true,
    },
    isModalEdit: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    QuarryCreateForm,
    SvgIcon,
    BigModal,
    ValidateEach,
    AlertModal,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    onSubmit() {
      console.log('submit')
      this.$refs.quarryForm.onSubmit();
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-item {
  margin-bottom: 16px;
}
.add-button {
  margin-left: 0;
}

.modal-form {
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.about-client {
  .clients-modal__title {
    margin-bottom: 5px;
  }
}

.modal-flex.align_start {
  align-items: flex-start;
}

//.modal-input-name {
//  font-weight: 400;
//  font-size: 12px;
//  line-height: 16px;
//  color: #a4a4a4;
//  margin-bottom: 2px;
//}

.holding-inputs {
  position: relative;

  .form-field + .form-field {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
}

.modal {
  &__info-block {
    margin-bottom: 20px;
  }

  &__info-row {
    display: block;
    margin: 5px 0;
  }
}
</style>
