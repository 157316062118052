<template>
  <div class="checkbox-component">
    <div class="checkbox-component__icon" v-if="extraIcon">
      <BaseTooltip v-if="tooltip" :text="tooltip" />
      <SvgIcon :name="extraIcon" />
    </div>
    <div class="checkbox-item">
      <input
        :type="type"
        :id="'checkbox' + uid"
        :name="name"
        class="custom-checkbox"
        @input="(event) => $emit('update:modelValue', event.target.checked)"
        :disabled="disabled"
        :checked="modelValue"
      />
      <label
        :for="'checkbox' + uid"
      ><span class="checkbox-name">{{ checkboxName }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import SvgIcon from '@/components/base/SvgIcon.vue';
import BaseTooltip from "@/components/base/BaseTooltip.vue";
export default {
  name: 'CheckboxComponent',
  components: {BaseTooltip, SvgIcon },
  data() {
    return {
      ex4: ['info'],
    }
  },
  setup() {
    const uid = getCurrentInstance().uid

    return {
      uid: uid,
    }
  },
  props: {
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    checkboxName: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Boolean, String],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extraIcon: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    }
  },
}
</script>

<style scoped lang="scss">
.checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.dragging {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f6ff;
  }

  &.burger {
    .checkbox-component__icon {
      opacity: 0;
    }

    &:hover {
      .checkbox-component__icon {
        opacity: 1;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .checkbox-component {
    &__icon {
      padding: 5px 0 0px 10px;
    }
  }

  .checkbox-item {
    flex-grow: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 0;
    padding-left: 3px;
  }
}

.checkbox-item-reverse {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}

.checkbox-name {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0a2333;
  margin-left: 2px;
  padding-top: 2px;
}

.checkbox-container {
  padding: 0;
}

.v-expansion-panel-title__overlay {
  height: 0;
}

.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-left: 5px;
}
</style>
