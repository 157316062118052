<template>
  <div class="no-result__wrapper">
    <div class="no-result__block">
      <SvgIcon name="auto-noresult"/>
      <div class="no-result__text">
        Здесь пока нет ни одной спецификации
      </div>
      <button
        class="add-button"
        @click="createSpecification"
        v-if="isCreateSpecificationPermission"
      >
        Добавить
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SvgIcon from '@/components/base/SvgIcon';
import { computed } from 'vue';

const store = useStore()
const router = useRouter()
const props = defineProps({
  type: String
})

const entityData = computed(() => {
  if (props.type === 'client') {
    const detail = store.getters['clients/clientDetail']
    return detail?.companies[0];
  }
  const detail = store.getters['quarry/quarryDetail']
  return detail?.company;
})

const createSpecification = async () => {
  await store.dispatch('specifications/setSpecificationDetail', {
    company_unload: {
      name: entityData.value?.name || '',
      inn: entityData.value?.inn || '',
      id: entityData.value?.id,
    },
  });
  await router.push({ name: 'CreateSpecificationPage' });
}

const isCreateSpecificationPermission = store.getters['account/getPermissions']?.specs?.create
</script>
