<template>
  <div class="pagination-wrapper">
    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
      :total-visible="totalVisible"
    />
  </div>
</template>

<script>
export default {
  name: 'PaginationComponent',
  data() {
    return {
      page: 1,
    }
  },
  emits: ['update-page'],
  props: {
    totalPages: Number,
    totalVisible: {
      default: 6,
      type: Number
    }
  },
  watch: {
    page(value) {
      const currentQuery = this.$route.query
      const queryPage = Number(currentQuery?.page)
      const newQuery = { ...currentQuery, page: value }

      if (value !== queryPage) {
        this.$router.replace({ query: newQuery })
        this.$emit('update-page', newQuery)
      }
    },
    $route (value) {
      const queryPage = Number(value.query?.page)

      if (queryPage !== this.page) {
        this.page = queryPage ? queryPage : 1
      }
    }
  },
  mounted() {
    const queryPage = Number(this.$route.query?.page)

    if (queryPage && this.page !== queryPage) {
      this.page = queryPage
    }
  },
}
</script>

<style lang="scss" scoped>
.v-btn--icon.v-btn--density-default {
  display: block;
}

.pagination-wrapper {
  width: 100%;
  display: flex;
}
</style>
