<template>
  <div class="goods-wrapper">
    <div class="goods" v-show="goods.length" v-for="item in goods">
      <div class="goods-item">
        <div class="goods-item__title">Тип породы</div>
        <div class="goods-item__name">{{ item.name }}</div>
      </div>
      <div class="goods-item">
        <div class="goods-item__title">Марка прочности</div>
        <div class="goods-item__name">
          {{ getData(item.strength_grades).join(' ') }}
        </div>
      </div>
      <div class="goods-item">
        <div class="goods-item__title">Фракция</div>
        <div class="goods-item__name">
          <div class="goods-item__fraction" v-for="fraction in getData(item.fractions)">
            {{ fraction }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  goods: Array,
})

const getData = (detailData) => {
  if (!detailData.length) return [];
  return detailData.map(item => item.name)
}
</script>

<style lang="scss" scoped>
.goods {

  &-wrapper {
    margin-bottom: 20px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #DEDEDE;
  }

  &:not(:first-child) {
    margin-top: 15px;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 15px;

    &__name {
      text-align: right;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 4px;
    }

    &__title {
      min-width: 40%;
      font-size: 14px;
      line-height: 18px;
      align-items: flex-start;
      white-space: nowrap;
      color: #A4A4A4;
    }

    &__fraction {
      padding: 2px 4px;
      gap: 10px;
      height: 20px;
      background: #0055E81E;
      color: #0055E8;
      display: flex;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
