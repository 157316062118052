<template>
  <div class="ports-header">
    <div class="content-header">
      <h1 class="content-title">Порты</h1>
      <button
        v-if="isCreatePermissions"
        class="add-button"
        @click="createPort"
      >
        <SvgIcon name="plus" class="button-plus" />
        Добавить порт
      </button>
    </div>
    <div class="ports-header__filters">
      <PortsFilters
        @toggle-filter="toggleFilter"
        @update-search="updateSearch"
        @search="search"
      />
      <div class="settings-select">
        <PerPageSelect
          :items="['20', '50', '100']"
          @update-select="changePerPage"
          :current-per-page="perPage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/components/base/SvgIcon.vue'
import PortsFilters from './PortsFilters.vue'
import PerPageSelect from '@/components/Dashboard/PerPageSelect.vue'
import { useRoute, useRouter } from 'vue-router'

const emit = defineEmits(['toggle-filter', 'update-search', 'update-per-page', 'search'])
defineProps({
  perPage: {
    type: [Number, String],
    default: '20',
  },
  isCreatePermissions: {
    type: Boolean,
    default: false
  }
})

const router = useRouter()
const route = useRoute()

const createPort = () => {
  router.push('/ports-page/create')
}

const toggleFilter = () => {
  emit('toggle-filter')
}

const updateSearch = () => {
  emit('update-search')
}

const search = (value) => {
  emit('search', value)
}

const changePerPage = async (value) =>  {
  let query = { ...route.query, per_page: value }
  delete query.page
  await router.push({ query })

  updateSearch()
}
</script>

<style lang="scss" scoped>

.ports-header {
  border-bottom: 1px solid #f4f4f8;

  &__info {
    display: flex;
    align-items: center;
  }

  &__filters {
    display: flex;
    align-items: center;
  }
}
</style>
