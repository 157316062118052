import ApiUtil from '@/utils/api'

export default {
  getWorkType: () =>
    ApiUtil(
      {
        url: '/api/work_type',
        method: 'get',
        requiresAuth: true,
      },
    ),
  getOptions: () =>
    ApiUtil(
      {
        url: '/api/options',
        method: 'get',
        requiresAuth: true,
      },
    ),
  getIndustries: () =>
    ApiUtil(
      {
        url: '/api/industries',
        method: 'get',
        requiresAuth: true,
      },
    ),
  getIndustriesById: (id) =>
    ApiUtil(
      {
        url: `/api/industries/${id}`,
        method: 'get',
        requiresAuth: true,
      },
    ),
  sendRegistrationData: (data) =>
    ApiUtil(
      {
        url: '/api/register',
        method: 'post',
        data
        // requiresAuth: true,
      },
    ),
  sendVerifyCode: (data) =>
    ApiUtil(
      {
        url: '/api/email/verify',
        method: 'post',
        data,
        requiresAuth: true,
      },
    ),
  sendUserProfile: (data) =>
    ApiUtil(
      {
        url: '/api/user/profile',
        method: 'post',
        data,
        requiresAuth: true,
      },
    ),
  sendUserProfileFile: (data) =>
    ApiUtil(
      {
        url: '/api/user/profile',
        method: 'post',
        data,
        requiresAuth: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    ),
  getCodeAgain: (data) =>
    ApiUtil(
      {
        url: '/api/email/verification-notification',
        method: 'get',
        data,
        requiresAuth: true,
      },
    ),
}
