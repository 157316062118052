import { helpers, maxLength, required } from '@vuelidate/validators';
import { validateInn } from '@/utils/helper';

export const quarryValidationRules = {
  comment: {},
  name: {
    required: helpers.withMessage(
      'Поле обязательно для заполнения',
      required
    ),
      maxLength: helpers.withMessage('Максимальная допустимая длина - 255 символов',
      maxLength(255)
    ),
  },
  client_name: {
    required: helpers.withMessage(
      'Поле обязательно для заполнения',
      required
    ),
  },
  inn: {
    required: helpers.withMessage(
      'Поле обязательно для заполнения',
      required
    ),
      validateInn: helpers.withMessage(
      'Введите корректное значение',
      validateInn
    ),
  },
  ogrn: {},
  kpp: {},
  legal_address_symbol: {},
  holding: {},
  holding_id: {},
  registration_date: {},
  head: {},
  responsible: {},
  name_goods: {}
}
