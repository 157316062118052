import clientsService from "@/services/clientsService"
import { handleError } from "@/utils/api/handleError"
import { filterOrders } from "@/utils/helper"

export default {
  namespaced: true,
  state: {
    clientsList: [],
    clientsTableList: [],
    clientsHeaderList: [],
    selectedClient: null,
    clientDetail: [],
    // clientDetailGeneral: [],
    clientsSearchResult: true,
    clientsFilterParams: null,
    clientsPagesCount: null,
    clientsHeaderListFull: [],
    clientsSchema: {},
    clientsSchemaFull: {},
    clientsPerPage: null,
    tempClientTableHeader: [],
    isSearchMode: false,
    isClientsLoading: false,
  },
  actions: {
    async getClients({ commit }, params) {
      try {
        commit("SET_IS_CLIENTS_LOADING", true);
        const data = await clientsService.getClients(params)
        commit("SET_CLIENTS_LIST", data)
        commit("SET_CLIENT_TABLE_LIST", data)
        commit("SET_CLIENTS_SEARCH_RESULT_SHOW", true)
        commit("SET_CLIENTS_PAGES_COUNT", data)
        commit("SET_CLIENTS_PER_PAGE", data);
        commit("SET_IS_SEARCH_MODE", false);
      } catch (e) {
        handleError(e)
      } finally {
        commit("SET_IS_CLIENTS_LOADING", false);
      }
    },
    async getClientsHeader({ commit }, params) {
      try {
        const data = await clientsService.getClientsHeader()
        commit("SET_CLIENTS_HEADER_LIST", {
          data: data,
          params: params,
        })
      } catch (e) {
        handleError(e)
      }
    },
    async setSelectedClient({ commit }, data) {
      commit("SET_SELECTED_CLIENT", data)
    },
    async setClientsFilterParams({ commit }, data) {
      commit("SET_CLIENTS_FILTER_PARAMS", data)
    },
    async getClientDetail({ commit }, id) {
      try {
        const data = await clientsService.getClientDetail(id)
        commit("SET_CLIENT_DETAIL", data)
        // commit("SET_CLIENT_DETAIL_GENERAL", data);
      } catch (e) {
        handleError(e)
      }
    },
    async getClientsSearch({ commit }, value) {
      try {
        const data = await clientsService.getClientsSearch(value.search, {
          page: value.page,
          per_page: value.perPage
        })
        commit("SET_CLIENTS_LIST", data)
        commit("SET_CLIENT_TABLE_LIST", data)
        commit("SET_CLIENTS_SEARCH_RESULT", data)
        commit("SET_IS_SEARCH_MODE", true);
        commit("SET_CLIENTS_PAGES_COUNT", data)
        commit("SET_CLIENTS_PER_PAGE", data);
      } catch (e) {
        handleError(e)
      }
    },
    async getClientsSearchNative({ commit }, value) {
      try {
        const data = await clientsService.getClientsSearch(value)
        commit("SET_CLIENTS_LIST", data)
        commit("SET_CLIENT_TABLE_LIST", data)
        commit("SET_CLIENTS_SEARCH_RESULT", data)
      } catch (e) {
        handleError(e)
      }
    },
    async updateColumnWidth({ commit }, params) {
      try {
        await clientsService.clientsAddColumns(params)
        commit("SET_TEMP_CLIENT_TABLE_HEADER", params.columns)
      } catch (e) {
        handleError(e)
      }
    },
    async updateResponseClient({ commit }, payload) {
      try {
        await clientsService.updateResponseClient(payload)
      } catch (e) {
        handleError(e)
      }
    },
  },
  getters: {
    clientsList(state) {
      return state.clientsList
    },
    clientsById: (state) => (id) => {
      return state.clientsList.find(client => client.id === id)
    },
    clientsTableList(state) {
      return state.clientsTableList
    },
    clientsHeaderList(state) {
      return state.clientsHeaderList
    },
    clientsSearchResult(state) {
      return state.clientsSearchResult
    },
    getSelectedClient(state) {
      return state.selectedClient
    },
    getIsSearchMode(state) {
      return state.isSearchMode
    },
    getClientsFilterParams(state) {
      return state.clientsFilterParams
    },
    clientDetail(state) {
      return state.clientDetail
    },
    // clientDetailGeneral(state) {
    //   return state.clientDetailGeneral;
    // },
    clientsPagesCount(state) {
      return state.clientsPagesCount
    },
    clientsHeaderListFull(state) {
      return state.clientsHeaderListFull
    },
    clientsPerPage(state) {
      return state.clientsPerPage;
    },
    tempClientTableHeader(state) {
      return state.tempClientTableHeader;
    },
    getIsClientsLoading(state) {
      return state.isClientsLoading;
    }
  },
  mutations: {
    SET_IS_SEARCH_MODE(state, value) {
      state.isSearchMode = value;
    },
    SET_CLIENTS_LIST(state, value) {
      state.clientsList = value.data.data;
    },
    SET_CLIENTS_SEARCH_RESULT(state, value) {
      let data = value.data.data

      if (data && data[0]) {
        state.clientsSearchResult = true
      } else {
        state.clientsSearchResult = false
      }
    },
    SET_CLIENTS_SEARCH_RESULT_SHOW(state, value) {
      state.clientsSearchResult = value
    },
    SET_CLIENTS_FILTER_PARAMS(state, value) {
      state.clientsFilterParams = value
    },
    SET_CLIENTS_PAGES_COUNT(state, value) {
      if (value.data?.meta?.last_page) {
        state.clientsPagesCount = value.data.meta.last_page
      }
    },
    SET_CLIENT_TABLE_LIST(state, value) {
      // let data = value.data.data
      let dataLocal = value.data.data

      const getCompanyInfo = (companies) => {
        let currentCompany

        if (companies.length < 1) {
          currentCompany = {
            type: "",
            inn: "",
            kpp: "",
            ogrn: "",
            legal_address_symbol: "",
            comment: "",
          }
        } else {
          currentCompany = companies.find((company) => {
            return company?.general == true ? company.general : companies[0]
          })
        }

        const { type, inn, kpp, ogrn, legal_address_symbol, comment } =
          currentCompany

        return [
          // {name: 'status', value: status ? "Действителен" : "Недействителен"},
          { name: "type", value: type ? "Да" : "Нет" },
          { name: "inn", value: inn },
          { name: "ogrn", value: ogrn },
          { name: "kpp", value: kpp },
          { name: "legal_address_symbol", value: legal_address_symbol },
          { name: "comment", value: comment ? comment : "" },
        ]
      }

      const dataAPI = dataLocal.map((item) => {
        let data = {
          id: item.id,
          cells: [],
        }

        const generalCompanyInfo = getCompanyInfo(item.companies)

        generalCompanyInfo.forEach((companyElem) => {
          item[companyElem.name] = companyElem.value
        })

        for (let i = 0; i < Object.keys(state.clientsSchema).length; i++) {
          const keySchema = Object.keys(state.clientsSchema)[i]

          data.cells.push({ name: keySchema, value: item[keySchema] })
        }

        return data
      })

      if (dataLocal) {
        state.clientsTableList = dataAPI
      }
    },
    SET_CLIENTS_HEADER_LIST(state, value) {
      let { schemas } = value.data.data.data
      let temp = [] // массив с размерами колонок

      if (schemas._order?.length) {
        schemas._order = schemas._order.map(item => {
          // проверяем первая загрузка или обновление колонок, если повторная загрузка, то проверяем наличие такой колоноки ранее и пушим значение в стейт, если она была ранее
          if (state.tempClientTableHeader) {
            let index = state.tempClientTableHeader.findIndex(el => el.includes(item))

            if (index === -1) {
              temp.push(item)
            } else {
              temp.push(state.tempClientTableHeader[index])
            }
          } else {
            temp.push(item)
          }

          return item.split('?')[0] // возвращаем всегда одно и то же, т.к. это набор полей для отрисовки таблицы
        })
      }

      const schemasFull = schemas

      if (schemas._order?.length > 0) {
        const schemasOrders = schemas._order

        schemas = filterOrders(schemasOrders, schemas)
      }

      state.clientsSchema = schemas
      state.clientsSchemaFull = schemasFull

      const headerTable = []
      const headerTableFull = []

      function headerConstruct(vSchema, vTable) {
        for (let i = 0; i < Object.keys(vSchema).length; i++) {
          const key = Object.keys(vSchema)[i];

          if (key !== '_order' && key !== '_filters') {
            vTable.push(
              {
                id: key,
                value: vSchema[key],
                sort:
                  key === value.params.sort
                    ? 'ASC'
                    : `-${key}` === value.params.sort
                      ? 'DESC'
                      : 'default'
              });
          }
        }
      }

      headerConstruct(schemas, headerTable)
      headerConstruct(schemasFull, headerTableFull)

      const clientsHeaderList = {
        success: value.data.data.data.success,
        schemas: headerTable,
      }

      const clientsHeaderListFull = {
        success: value.data.data.data.success,
        schemas: headerTableFull,
      }

      state.tempClientTableHeader = temp;
      state.clientsHeaderList = clientsHeaderList
      state.clientsHeaderListFull = clientsHeaderListFull
    },
    SET_SELECTED_CLIENT(state, value) {
      state.selectedClient = value
    },
    SET_CLIENT_DETAIL(state, value) {
      state.clientDetail = value.data.data
    },
    SET_TEMP_CLIENT_TABLE_HEADER(state, value) {
      state.tempClientTableHeader = value
    },
    SET_CLIENTS_PER_PAGE(state, value) {
      if (value.data?.meta?.per_page) {
        state.clientsPerPage = value.data.meta.per_page
      }
    },
    SET_IS_CLIENTS_LOADING(state, value) {
      state.isClientsLoading = value
    },
  },
}
