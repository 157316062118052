<template>
  <div class="dropdown-extended" :class="{ error: isError, 'single': isSingle, 'dropdown-extended_default-view': isDefault }">
    <Multiselect
      v-model="selected"
      :options="options"
      :multiple="isMultiple"
      :loading="isLoading"
      :taggable="isTaggable"
      :close-on-select="false"
      :clear-on-select="false"
      :label="label"
      :track-by="trackBy"
      :showNoOptions="false"
      @search-change="debounceSearchUpdate"
      ref="multiselect"
      :searchable="searchable"
      :internal-search="isInternalSearch"
      :placeholder="placeholder"
      :custom-label="searchByFields?.length ? createCustomLabel : undefined"
      :disabled="isDisabled"
    >
      <template #noResult>
        {{ noResultText }}
      </template>
      <template #option="{option}" v-if="hasCustomSlot">
        <slot name="customItem" v-bind="option"></slot>
      </template>
    </Multiselect>
    <div v-if="errors.length">
      <div class="input-errors" v-for="error of errors" :key="error.$uid">
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="dropdown-extended__result" v-if="isMultiple">
      <div class="dropdown-extended__result-item" v-for="item in resultTags">
        {{ item }}
        <SvgIcon class="close-btn" name="close" @click="() => deleteSelectedElement(item[label] ? item[label] : item)"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import Multiselect from 'vue-multiselect';
import SvgIcon from '@/components/base/SvgIcon.vue';
import { ref, computed } from 'vue';
import { debounce } from '@/utils/helper';

const props = defineProps({
  placeholder: { type: String, default: 'Поиск'}, // placeholder value displayed on search input
  isLoading: { type: Boolean, default: false },
  isMultiple: { type: Boolean, default: true },
  label: { type: [String, null], default: null }, // is used to display the option
  noResultText: { type: String, default: 'Ничего не найдено'}, // text to be shown if nothing matches the search query
  trackBy: { type: [String, null], default: null }, // string to identify the option within the options list
  modelValue: { type: Object },
  options: { type: Array, default: [] },
  isTaggable: { type: Boolean, default: false },//  adds an additional option at the beginning of the options list
  searchable: { type: Boolean, default: true },
  errors: { type: Array, default: () => [] },
  isError: { type: [Boolean, Number], default: false },
  isInternalSearch: { type: Boolean, default: true },
  isDefault: { type: Boolean, default: false },
  hasCustomSlot: { type: Boolean, default: false },
  isSingle: { type: Boolean, default: false },
  searchByFields: { type: Array, default: () => [] },
  isDisabled: { type: Boolean, default: false }
})
const emits = defineEmits(['update:modelValue', 'search-updated'])

const multiselect = ref(null)

const debounceSearchUpdate = debounce(() => {
  emits('search-updated', multiselect.value.search)
}, 500)

const resultTags = computed(() => {
  if (!props.label || !selected.value) return selected.value;
  return selected.value.map(item => (item && item[props.label]) ?? '');
})

const deleteSelectedElement = (dataToDelete) => {
  selected.value = selected.value.filter(item => {
    return item[props.label] ? item[props.label] !== dataToDelete : item !== dataToDelete
  })
}

const createCustomLabel = (option) => {
  if (!props.searchByFields?.length) return option[props.label]

  return props.searchByFields.reduce((acc, item) => {
    if (acc.length === 0) acc += option[item]
    else acc = `${acc} - ${option[item]}`

    return acc
  }, '')
}

const selected = computed({
  get: () => {
    return Array.isArray(props.modelValue) ? props.modelValue : props.modelValue;
  },
  set: (value) => {
    emits('update:modelValue', value)
  }
})
</script>

<style scoped lang="scss">
.dropdown-extended {
  position: relative;

  &.error {
    .multiselect__tags {
      border-color: red;
    }
  }

  &__label {
    position: absolute;
    top: 21px;
    left: 12.5px;
    transform: translateY(-50%);
    z-index: 11;
  }

  :deep(.multiselect--disabled) {
    .multiselect__tags {
      background: #f0f0f0;
    }
  }

  &:not(&.single) {
    :deep(.multiselect) {
      input {
        width: unset !important;
        position: static !important;
        padding: unset !important;
        font-size: 14px;
      }
    }

    :deep(.multiselect__placeholder) {
      display: none;
    }
  }

  ::v-deep .multiselect {
    margin-bottom: 8px;
    position: relative;
    z-index: 10;

    &--active {
      z-index: 20;
    }

    &__placeholder {
      font-size: 14px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 10px;
      right: 12px;
      z-index: 12;
      width: 24px;
      height: 24px;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('@/assets/img/search-grey.svg');
    }

    &__active + .dropdown-extended__label {
      display: none;
    }

    &.active {
      &__tags {
        padding: 0;
      }
    }

    &__tag {
      opacity: 0;
      font-size: 0;
      line-height: 0;
      position: absolute;
    }

    &__tags {
      min-height: 40px;
      display: block;
      border-radius: 6px;
      padding: 10px 40px 10px 12.5px;
      border: 1px solid #EFEFEF;
      background: #FFFFFF;
      cursor: pointer;

      input[type=text] {
        &:focus,
        &:focus-visible,
        &:focus-within {
          border: none !important;
        }
      }
    }

    &__content {
      width: 100%;
    }

    &__content-wrapper {
      width: 100%;
      position: absolute;
      border-radius: 6px;
      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      color: #808191;
      z-index: 14;
      overflow-y: scroll;
      box-shadow: 3px 2px 3px 2px rgb(224 224 224 / 25%)
    }

    li {
      width: 100%;
      list-style-type: none;
      cursor: pointer;
    }

    &__option {
      align-items: center;
      position: relative;
      padding: 11px 14px;
      font-size: 14px;
      justify-content: center;
      display: flex;

      &--highlight {
        background-color: #E0EBFC;
      }
    }

    &__element {
      .multiselect__option {
        justify-content: flex-start;
        &:before {
          display: block;
          content: '';
          background-position: 50%;
          background-image: url('@/assets/img/checkbox.svg');
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }

        &--selected::before {
          background-image: url('@/assets/img/chackbox-active.svg');
        }
      }
    }

    &__spinner {
      position: absolute;
      right: 25px;
      top: 3px;
      width: 48px;
      height: 35px;
      background: #fff;
      display: block;

      &::after,
      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border-color: #0055E8 transparent transparent;
        border-style: solid;
        border-width: 2px;
        box-shadow: 0 0 0 1px transparent
      }

      &::before {
        -webkit-animation: spinning 2.4s cubic-bezier(.41, .26, .2, .62);
        animation: spinning 2.4s cubic-bezier(.41, .26, .2, .62);
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite
      }

      &::after {
        -webkit-animation: spinning 2.4s cubic-bezier(.51, .09, .21, .8);
        animation: spinning 2.4s cubic-bezier(.51, .09, .21, .8);
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite
      }
    }
  }

  &__result {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &-item {
      padding: 0 2px 0 10px;
      background-color: #E0EBFC;
      color: #0055E8;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;

      .close-btn {
        padding: 7px;
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }

  &_default-view {
    :deep(.multiselect) {
      margin-bottom: 0;
      height: 40px;

      .multiselect__tags {
        padding: 7px 40px 7px 14px;
      }

      input, .multiselect__single {
        font-size: 14px;
        line-height: 20px;
      }

      .multiselect__single {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        display: block;
      }
    }
  }
}

.input-errors {
  display: none;
}

.error {
  .input-errors {
    display: block;
  }

  ::v-deep .multiselect__tags {
    border-color: red;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0)
  }
  to {
    transform: rotate(2turn)
  }
}

</style>
