import { ref } from 'vue';
import contactsService from '@/services/contactsService';

// eslint-disable-next-line
export default function validateForm(isLocal = false, localList = []) {
  const isCheckNumber = ref(false);
  const oldEmail = ref(null)

  const customCheckEmail = (value) => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value?.length) {
      isCheckNumber.value = true;
      return true
    }


    if (reg.test(value)) {
      isCheckNumber.value = false;
      return true
    } else {
      return false
    }
  }

  const checkUnique = async (value) => {
    if (oldEmail.value !== value && value !== null && value !== '') {
      if (customCheckEmail(value) && value.length) {
        const { data } = await contactsService.existenceCheckContact({ email: value });

        if (localList.length) {
          let index = localList.findIndex(item => item.email === value);

          if (index !== -1) {
            return false
          }
        }

        return data.data.success !== false
      }

      return false
    } else {
      return true
    }
  }

  return {
    isCheckNumber,
    oldEmail,
    customCheckEmail,
    checkUnique
  }
}
