<template>
  <div>
    <clients-modal
      :Title="Title"
      :ModalButton="ModalButton"
      :is-open="isOpen"
      @close="closeModal"
      @approved="onSubmit"
    >
      <div class="draggable-list">
        <div v-if="items">
          <draggable v-model="items">
            <template v-slot:item="{ item }">
              <div class="draggable-item" :class="{'disabled' : item.id == 'name'}">
                <CheckboxComponent
                  v-model="item.checkboxValue"
                  :checkbox-name="item.value"
                  is-dragging-column
                  :extra-icon="item.id === 'name' ? 'locked' : 'burger'"
                  tooltip="Перетащите, чтобы изменить порядок отображения столбцов в дашборде."
                  :class="{ burger: !(item.id === 'name') }"
                  class="mb-0 dragging"
                />
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </clients-modal>
  </div>
</template>

<script>
import ClientsModal from "@/components/ClientsModals/ClientsModal"
import SvgIcon from "@/components/base/SvgIcon"
import Draggable from "vue3-draggable"
import CheckboxComponent from "@/components/CheckboxComponent"
import { reactive } from "vue-demi"
import BaseTooltip from "@/components/base/BaseTooltip.vue";

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item)
}

export default {
  name: "BaseTableSettingsModal",
  data() {
    return {
      Title: "Настройки колонок",
      ModalButton: "Применить",
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    schemas: {
      type: [Object, Array],
      default: [],
    },
    schemasActive: {
      type: [Object, Array],
      default: [],
    },
  },
  components: {
    BaseTooltip,
    ClientsModal,
    SvgIcon,
    Draggable,
    CheckboxComponent,
  },
  setup() {
    let items = reactive([])

    return {
      items,
    }
  },
  watch: {
    schemas(currentValue) {
      this.items = currentValue

      // у активных элементов по умолчанию делаем true
      const schemasActiveList = this.schemasActive.map((item) => ({
        ...item,
        checkboxValue: true,
      }))

      // по id если не совпадают в списке ставим checkboxValue false, что б потом найти не активные элементы
      this.items = this.items.map((elem) => {
        const stateCkeckbox = schemasActiveList.find((item) => {
          return item.id == elem.id ? true : false
        })

        return {
          ...elem,
          checkboxValue: !!stateCkeckbox,
        }
      })

      // находим не активные элементы
      const sortNotActiveItems = this.items.filter((item) => {
        return item.checkboxValue == false
      })

      const sortedArray = sortNotActiveItems

      // ставим вначало массива активные элементы, сохраняя порядок который в таблице
      schemasActiveList.forEach((elem, index) => {
        sortedArray.insert(index, elem)
      })

      this.items = sortedArray
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },

    onSubmit() {
      const itemsId = []
      const itemsFiltered = this.items.filter((elem) => {
        return elem.checkboxValue !== false
      })

      itemsFiltered.forEach((item) => {
        itemsId.push(item.id)
      })

      this.$emit("onSubmit", itemsId)
    },
  },
}
</script>

<style lang="scss">
.draggable-list {
  overflow: auto;
  margin: 0 -32px;
  max-height: 450px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #999;
  }
}

.checkbox-item {
  padding: 10px 32px;

  &:hover {
    background-color: #f0f6ff;
  }
}

.isDragging {
  .dragging {
    border-top: 1px solid #0055e8;
    border-bottom: 1px solid #0055e8;
  }
}

</style>
