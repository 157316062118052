import { format } from 'date-fns';
import _get from 'lodash.get';

export const errorFormat = (error) => {
  if (error.message) return error.message
  if (typeof error === 'object' && !Array.isArray(error)) {
    let errorList = ''
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(error)) {
      errorList += value + ', '
    }
    if (errorList === '') return 'неизвестная ошибка'
    return errorList.slice(0, -2)
  }
  return 'неизвестная ошибка'
}

export const stringifyErrors = (errorObject) => {
  const errorsRaw = _get(errorObject, 'response.data.errors', null)
  if (!errorsRaw) return 'Неизвестная ошибка';
  const errorKeys = Object.keys(errorsRaw);
  return errorKeys.reduce((acc, item, index) => {
    if (index === errorsRaw.length || index === 0) return `${acc ? acc : acc + ' '}${errorsRaw[item]}`;
    return `${acc}, ${errorsRaw[item]}`
  }, '').trim()
}

export const isEmptyObject = (obj) => {
  return JSON.stringify(obj) === '{}'
}

export const validateInn = function (inn) {
  let result = false
  if (typeof inn === 'number') {
    inn = inn.toString()
  } else if (typeof inn !== 'string') {
    inn = ''
  }

  if (inn.length) {
    const checkDigit = function (inn, coefficients) {
      let n = 0
      for (let i = 0; i < coefficients.length; i++) {
        n += coefficients[i] * inn[i]
      }

      return parseInt((n % 11) % 10)
    }

    switch (inn.length) {
    case 10:
      let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8])
      if (n10 === parseInt(inn[9])) {
        result = true
      }
      break
    case 12:
      let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
      let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
      if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
        result = true
      }
      break
    }
  }
  return result
}

export const validateOgrn = function (chekedValue) {
  chekedValue += ''

  //для ОГРН в 13 знаков
  if (
    chekedValue.length == 13 &&
    chekedValue.slice(12, 13) ==
      ((chekedValue.slice(0, -1) % 11) + '').slice(-1)
  ) {
    return true

    //для ОГРН в 15 знаков
  } else if (
    chekedValue.length == 15 &&
    chekedValue.slice(14, 15) ==
      ((chekedValue.slice(0, -1) % 13) + '').slice(-1)
  ) {
    return true
  } else {
    return false
  }
}

export const validateKpp = function (kpp) {
  let result = false
  if (typeof kpp === 'number') {
    kpp = kpp.toString()
  } else if (typeof kpp !== 'string') {
    kpp = ''
  }
  if (kpp.length > 0 && kpp.length == 9) {
    result = true
  }

  if (kpp.length === 0) {
    result = true
  }
  return result
}

export const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export const positionContextMenu = (event, menuPosition) => {
  const menu = document.querySelector('.js-tableMenu');
  const menuWidth = menu.offsetWidth;
  const menuHeight = menu.offsetHeight;
  const positionX = event.clientX;
  const positionY = event.clientY;

  menuPosition.left = (positionX > window.innerWidth - menuWidth) ? `${positionX - menuWidth}px` : `${positionX}px`;

  menuPosition.top = (positionY > window.innerHeight - menuHeight) ? `${positionY - menuHeight}px` : `${positionY}px`;
}

export const formatDate = (date) => {
  if (date !== undefined) {
    const editDate = new Date(date);
    return {
      date: format(editDate, 'dd.MM.yyyy'),
      time: format(editDate, 'HH:mm'),
    };
  } else {
    return {
      date: 'Нет Даты',
      time: 'Нет Времени',
    };
  }
}

export const copyRouteLink = (start, end, transit) => {
  let text = `${window.location.origin}/map?load_address=${encodeURIComponent(start)}`
  // const transit = this.addresses.transitList

  if (transit?.length) {
    transit.forEach((item, index) => {
      let point = item.address ? encodeURIComponent(item.address) : item.coords;
      text += `&transit-${index}=${point}`
    })
  }

  text += `&unload_address=${encodeURIComponent(end)}`

  const copyInput = document.querySelector('.copy_input');
  copyInput.value = text;
  copyInput.focus();
  copyInput.select();
  window.navigator.clipboard.writeText(copyInput.value)
}

export const filterOrders = (orders, schemas) => {
  const filteredList = []
  // schemas объект, по этому приводим к массиву сначала
  const listToArray = Object.entries(schemas)

  orders.forEach((order) => {
    listToArray.forEach((schemasItem) => {
      if (order == schemasItem[0]) {
        filteredList.push(schemasItem)
      }
    })
  })

  // возвращаем с массива в объект
  return Object.fromEntries(filteredList)
}

export const parseAddress = async (address, prefix = '') => {
  const obj = {}

  address.forEach(item => {
    switch (item.kind) {
    case 'country':
      obj[`${prefix}country`] = item.name;
      break;
    case 'province':
      obj[`${prefix}region`] = item.name;
      break;
    case 'area':
      obj[`${prefix}city_district`] = item.name;
      break;
    }
  })

  return obj
}

export const createPolylineFromRoute = (multiRoute) => {
  let coordList = [];
  const activeRoute = multiRoute.getActiveRoute();

  activeRoute.getPaths().each(path => {
    path.getSegments().each(segment => {
      coordList.push(segment.geometry.getCoordinates())
    })
  })

  coordList = coordList.flat();
  // сокращаем количество точек для ломанной в 4 раза (оптимизация)
  coordList = coordList.filter((item, index) => index % 4 === 0)
  return coordList;
}

// только для несложных объектов, у этого метода клонирования есть существенные ограничения.
// Использовать с остарожностью, желательно сепрва ознакомтиься со статьей
// https://medium.com/@pmzubar/why-json-parse-json-stringify-is-a-bad-practice-to-clone-an-object-in-javascript-b28ac5e36521
export const dumbCopy = (targetObject) => {
  return JSON.parse(JSON.stringify(targetObject));
}

const formatterOptions = {
  distance: {
    style: 'unit',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    unit: 'kilometer'
  },
  currency: {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
  },
}

const getDataType = (amountType) => {
  const formatterDictionary = {
    distance: ['distance'],
    currency: ['cost_material', 'cost_transportation_ADT', 'cost_transportation_client', 'recommended_price', 'capital_service_daily'],
    weight: ['total_weight', 'daily_rate', 'loading_rate']
  }

  for (let type in formatterDictionary) {
    if (formatterDictionary[type].includes(amountType)) return type;
  }
  return false;
}

export const tableFormatter = (type, amount) => {
  const formatOption = getDataType(type);
  if (!formatOption) return amount;
  const formatter = new Intl.NumberFormat('ru-RU', formatterOptions[formatOption])
  if (type === 'loading_rate') amount = parseInt(amount);
  return formatOption === 'weight' ? `${formatter.format(amount)} т` : formatter.format(amount);
}

export const getShareYandexLink = (
  {
    load_coordinates = [],
    unload_coordinates = [],
    transit = [],
    via_points = [],
  }
) => {
  const YANDEX_URL = 'https://yandex.ru/maps/'
  const params = new URLSearchParams();
  load_coordinates = parseCoordinates(load_coordinates);
  unload_coordinates = parseCoordinates(unload_coordinates);
  const points = mergeViaAndWayPoints(transit, via_points)
  const route = [load_coordinates, ...points, unload_coordinates];
  const viaPointsIndexes = [];
  const routeString = route.reduce((accum, routePoint, index) => {
    if (!routePoint) return accum;
    if (index === route.length - 1) {
      return accum + routePoint.join(',');
    }

    // если содержится поле address - это транзитная точка
    if (routePoint?.address) {
      return accum + routePoint?.address + '~';
    }

    // добавляем индекс для путевых точек
    if (index !== 0 && index !== route.length - 1) {
      viaPointsIndexes.push(index)
    }

    return accum + routePoint.join(',') + '~';
  }, '')

  if (routeString) {
    params.append('rtext', routeString);
  }

  params.append('rtt', 'auto');

  if (viaPointsIndexes.length) {
    params.append('via', viaPointsIndexes.join('~'));
  }

  return `${YANDEX_URL}?${params.toString()}`;
}

export const mergeViaAndWayPoints = (wayPoints = [], viaPoints = []) => {
  if (!wayPoints?.length) return viaPoints.map(viaPoint => viaPoint.coordinates);
  let currentIndex;
  const result = [...wayPoints.map((wayPoint, index) => {
    currentIndex = index;
    const relativeViaPoints = viaPoints.filter(viaPoint => viaPoint.pathIndex === index);
    return [...relativeViaPoints.map(viaPoint => viaPoint.coordinates), wayPoint]
  })]
  const restOfViaPoints = viaPoints.filter(viaPoint => viaPoint.pathIndex > currentIndex).map(viaPoint => viaPoint.coordinates);
  return [...result, restOfViaPoints].flat();
}

// костыль, который пришлось вставить,так как
// старые данные могут приходить в неожиданном формате
// форматы можно подсмотреть в тестах
const parseCoordinates = (coords) => {
  if (Array.isArray(coords)) return coords;
  if (typeof coords === 'string') return JSON.parse(coords);
  return coords;
}

export const copyToClipboard = (text) => {
  return new Promise((resolve, reject) => {
    if (typeof navigator !== 'undefined' && typeof navigator.clipboard !== 'undefined' && navigator.permissions !== 'undefined') {
      const type = 'text/plain';
      const blob = new Blob([text], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      navigator.permissions.query({ name: 'clipboard-write' }).then((permission) => {
        if (permission.state === 'granted' || permission.state === 'prompt') {
          navigator.clipboard.write(data).then(resolve, reject).catch(reject);
        }
        else {
          reject(new Error('Permission not granted!'));
        }
      });
    }
    else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';
      textarea.style.width = '2em';
      textarea.style.height = '2em';
      textarea.style.padding = '0';
      textarea.style.border = 'none';
      textarea.style.outline = 'none';
      textarea.style.boxShadow = 'none';
      textarea.style.background = 'transparent';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        document.execCommand('copy');
        document.body.removeChild(textarea);
        resolve();
      }
      catch (e) {
        document.body.removeChild(textarea);
        reject(e);
      }
    }
    else {
      reject(new Error('None of copying methods are supported by this browser!'));
    }
  });
}

export const isValidUrl = urlString=> {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

export default {
  tableFormatter,
  errorFormat,
  validateInn,
  validateOgrn,
  validateKpp,
  debounce,
  positionContextMenu,
  formatDate,
  filterOrders,
  copyRouteLink,
  parseAddress,
  createPolylineFromRoute,
  dumbCopy,
  isValidUrl,
}
