<template>
  <div v-if="permissions.view" class="form-field modal-input" :class="classes">
    <div style="color: red">{{ errorFormat }}</div>
    <label
      v-if="label"
      class="modal-input-name"
      :for="'input-modal' + uid"
      :class="{
        'disabled': isDisabled
      }"
    >
      {{ label }}
    </label>
    <div class="input-wrap" :class="{ 'focus': isFocus, 'extra-disabled': isDisabled }">
      <input
        class="modal-input-style"
        autocomplete="off"
        ref="inputRef"
        v-model="formattedValue"
        v-maska="inputMask"
        :placeholder="placeholder"
        :id="'input-modal' + uid"
        :class="inputClass"
        :type="type"
        :min="min"
        :max="max"
        :maxLength="maxLength"
        :disabled="isDisabled"
        @input="handleInput"
        @blur="toggleClass(0)"
        @focus="toggleClass(1)"
        @wheel="$event.target.blur()"
      />
      <span v-if="beforeText" class="special-text__before" @click="changeFocus">{{ beforeText }}</span>
      <span v-if="afterText" class="special-text__after" @click="changeFocus">{{ afterText }}</span>
      <slot name="input-icon"></slot>
    </div>
    <template v-if="!isDisabled && errors.length > 0">
      <div class="input-errors_wrap">
        <div class="input-errors" v-for="error of errors" :key="error.$uid">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getCurrentInstance, ref, watch } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'BaseModalInput',
  props: {
    errors: {
      type: [Array],
      default: () => [],
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    inputClass: {
      type: [Object, String],
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    maxLength: {
      type: [Number, Boolean],
      default: 99999,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    inputMask: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    beforeText: {
      type: String,
      default: ''
    },
    afterText: {
      type: String,
      default: ''
    },
    isLeft: {
      type: Boolean,
      default: false
    },
    isRight: {
      type: Boolean,
      default: false
    },
    priceFormat: {
      type: Boolean,
      default: false
    },
    isCurrency: {
      type: Boolean,
      default: true
    },
    permissions: {
      type: Object,
      default: () => ({
        edit: true,
        view: true
      })
    }
  },
  emits: [
    'input',
    'update:modelValue',
    'change'
  ],
  computed: {
    classes() {
      return {
        'modal-input__default': this.hasBg,
        'modal-input__disable': this.isDisabled,
        'special-text': this.beforeText || this.afterText,
        'only-text': !this.beforeText && !this.afterText,
        'is-right': this.isRight,
        'is-left': this.isLeft,
        'error': this.errors.length > 0
      };
    },
    errorFormat() {
      if (this.priceFormat && this.type === 'number') {
        return 'Недопустимый формат. У денежного формата не может быть тип число'
      }

      return ''
    },
    isDisabled() {
      return this.disabled || !this.permissions.edit;
    }
  },
  data() {
    return {
      isFocus: false
    }
  },
  setup (props) {
    const uid = getCurrentInstance().uid;
    if (props.priceFormat) {
      const { inputRef, formattedValue, setValue } = useCurrencyInput({
        currency: 'RUB',
        currencyDisplay: props.isCurrency ? 'symbol' : 'hidden',
        precision: 0,
      })
      watch(
        () => props.modelValue,
        (value) => {
          setValue(value);
        }
      );
      return {
        uid: uid,
        inputRef,
        formattedValue
      };
    } else {
      const formattedValue = ref();
      watch(
        () => props.modelValue,
        (value) => {
          formattedValue.value = value;
        }
      );
      return {
        uid: uid,
        formattedValue
      };
    }
  },
  methods: {
    hasBg() {
      return !this.isDisabled;
    },
    handleInput(e) {
      this.$emit('input', e.target.value);
      if (!this.priceFormat) {
        this.$emit('update:modelValue', this.formattedValue);
      }
    },
    changeFocus() {
      this.$refs.inputRef.focus();
    },
    toggleClass(e) {
      this.isFocus = !!e;
    }
  },
};
</script>

<style lang="scss" scoped>
.input-errors_wrap {
  margin-top: 4px;
}

.modal-input {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &__disable {
    .modal-input-style {
      background: #f0f0f0;
      border: none;
      pointer-events: none;
    }
  }

  .cost-transportation-right {
    border-radius: 0px 6px 6px 0px;
  }

  .cost-transportation-left {
    border-radius: 6px 0px 0px 6px;
  }
}
.modal-input-name {
  display: block;
  white-space: nowrap;

  &.disabled {
    pointer-events: none;
  }
}

.modal-input-style {
  width: 100%;
  padding: 0 14px;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid #efefef;
  border-radius: 6px;
  font-size: 12px;
  background-color: transparent;

  &.error {
    border-color: red;
  }

  &.dark-border {
    border: 1px solid #d8d8d8;
  }

  &.input-bold {
    font-weight: 700;
  }
}

.modal-input-style:not(.modal-input-disabled):hover,
.modal-input-style:focus {
  border: 1px solid #a6a6a6;
}

.modal-input-style::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #808191;
}

.modal-input-disabled {
  background: #f0f0f0;
  border-radius: 8px;
}

.form-field {
  position: relative;
}

.input-errors {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d72028;

  &_wrap {
    padding-right: 4px;
  }
}

.input-wrap {
  position: relative;
  margin-top: 4px;

  &.extra-disabled {
    pointer-events: none;

    .special-text {
      &__after,
      &__before {
        background-color: #f0f0f0;
      }
    }
  }
}

.only-text.error {
  .input-wrap input {
    border-color: red;
  }
}
.form-field {
  .modal-input-style {
    border-radius: 6px;

    &.input-start {
      border-radius: 6px 0px 0px 6px;
    }

    &.input-finish {
      border-radius: 0px 6px 6px 0px;
    }
  }
}

.special-text {
  &.is-left {
    .input-wrap {
      border-radius: 6px 0px 0px 6px;
    }
  }

  &.is-right {
    .input-wrap {
      border-radius: 0px 6px 6px 0px;
    }
  }

  &.error {
    .input-wrap {
      border-color: red;
    }
  }

  .input-wrap {
    display: flex;
    align-items: stretch;
    border: 1px solid #efefef;
    overflow: hidden;
    height: 40px;

    &:hover {
      border: 1px solid #a6a6a6;
    }

    &.focus {
      border: 1px solid #0055e8;
      position: relative;
      z-index: 5;
    }

    &:focus-visible {
      border: 1px solid #0055e8;
    }


    .special-text {
      &__after,
      &__before {
        display: flex;
        align-items: center;
        order: 1;
        font-size: 12px;
        color: #AFAFAF;
        padding: 1px 0 0 15px;
      }

      &__after {
        order: 3;
        font-family: "Rouble";
        padding: 0 15px 2px 0;
        font-size: 16px;
      }
    }

    .modal-input-style {
      order: 2;
      border: none;
      border-radius: 0 !important;

      &:focus {
        outline: none;
        border-color: transparent!important;
        & + span,
        & + span + span {
          outline: none;
          border-color: transparent!important;
        }
      }
    }
  }
}
</style>
