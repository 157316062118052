<template>
  <div class="sea-ports-page">
    <BackgroundComponent class="table-clients-container">
      <PortHeader
        :perPage="perPage"
        :is-create-permissions="perrmissions.create"
        @toggle-filter="filterState = !filterState"
        @update-search="getPorts"
        @search="getPorts"
      />
      <div class="display-flex width-100 global-table">
        <FilterWidget
          v-if="filterState"
          filter-page="ports"
          @filter-submit="filter"
          @filter-reset="filterReset"
          @update-state="updateFilterState"
          :filters-array="filterModel"
        />
        <BaseTableMenu
          :is-open="isOpenDashboardMenu"
          :style="{
            left: menuPosition.left,
            top: menuPosition.top,
          }"
        >
          <DashboardDialogMenu
            current-entity="specs"
            :permissions="currentlyPort?.dashboard_permissions"
            @edit-entity="showEdit"
            @change-responsible="changeResponsible"
            @delete-entity="deletePort"
          />
        </BaseTableMenu>
        <BaseTable
          :key="`table-ports-${portTableHeader.length}`"
          :table-head="portHeaderList.schemas"
          :table-header-params="portTableHeader"
          :table-body="ports"
          :is-loading="isLoading"
          @sortBy="sortBy"
          @right-click="onTableRightClick"
          @left-click="onTableLeftClick"
          @update-width="updateWidth"
        >
          <template #item="{ value, name, specificationId }">
            <router-link
              :to="{ name: 'DetailsPortPage', params: { id: specificationId } }"
              v-if="name === 'name'"
              class="client-name"
            >
              {{ value }}
            </router-link>
            <div v-else-if="name === 'id'">
              <router-link
                v-if="isViewPermission(value)"
                :to="{ name: 'DetailsPortPage', params: { id: value } }"
                class="td-client-id"
              >
                {{ value }}
              </router-link>
              <span v-else>{{ value }}</span>
            </div>
            <div
              v-else-if="name === 'responsible'"
            >
              {{ value?.name }}
              {{ value?.family_name }}
            </div>
            <div v-else-if="name === 'fact_addresses'">
              {{ getFatAddress(value) }}
            </div>
            <div v-else>
              {{ name }}
            </div>
          </template>
        </BaseTable>
      </div>
    </BackgroundComponent>
    <PortEditModal
      v-if="isEditModal"
      :is-open="isEditModal"
      :company="currentlyPort.company"
      :inn="currentlyPort.company.inn"
      :port="currentlyPort"
      :fact_addresses="fact_addresses"
      :port-id="portDetail.id"
      :port-name="portName"
      :comment="currentlyPortComment"
      :port-status="currentlyPortStatus"
      :work-schedule="currentlyPort.work_schedule"
      @close-modal="closeModal"
      @update-address="updateAddress"
    />
    <AlertModal ref="modalDelete">
      <template v-slot:button>
        <BaseButton @click="() => modalDelete.closeModal()">
          Нет
        </BaseButton>
      </template>
    </AlertModal>
    <AlertModal ref="alert" nameButton="Понятно" />
  </div>
</template>

<script setup>
import BackgroundComponent from '@/components/BackgroundComponent.vue'
import PortHeader from '@/components/SeaPorts/PortsHeader.vue'
import BaseTable from '@/components/base/BaseTable.vue'
import FilterWidget from '@/components/filter-templates/FilterWidget.vue'
import DashboardDialogMenu from '@/components/Dashboard/DashboardDialogMenu.vue'
import BaseTableMenu from '@/components/base/BaseTableMenu.vue'
import PortEditModal from '@/components/SeaPorts/PortEditModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import AlertModal from '@/components/AlertModal.vue'
import portsService from '@/services/portsService.js'
import { positionContextMenu } from '@/utils/helper.js'
import { computed, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { watch } from 'vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

const filterModel = computed(() => store.getters['filter/filterModel'])
const portTableHeader = computed(() => store.getters['ports/tempPortTableHeader'])
const portHeaderList = computed(() => store.getters['ports/portHeaderList'])
const portSchema = computed(() => store.getters['ports/portSchema'])
const ports = computed(() => store.getters['ports/ports'])
const portDetail = computed(() => store.getters['ports/portDetail'] || {})
const meta = computed(() => store.getters['ports/meta'])
const page = computed(() => meta.value?.current_page || 1) || 1
const perPage = computed(() => route.query.per_page || store.getters['ports/getPerPage'])
const globalPerrmissions = computed(() => store.getters['account/getPermissions'] || {})
const perrmissions = computed(() => globalPerrmissions.value['sea-ports'] || {})
const isLoading = computed(() => store.getters['ports/getIsPortsLoading'])

const filterState = ref(false)
const isShowEdit = ref(false)
const isOpenDashboardMenu = ref(false)
const isOpenChangeResponsible = ref(false)
const isEditModal = ref(false)
const modalDelete = ref(null)
const currentlyPort = ref(null)
const fact_addresses = ref([])
const portName = ref('')
const alert = ref(null)
const currentlyPortComment = ref('')
const currentlyPortStatus = ref('')

const menuPosition = ref({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
})

const getPorts = async (params = { page: page.value, ...route.query, per_page: perPage.value }) => {
  if (params.per_page) await store.commit('ports/SET_PORTS_PER_PAGE', params.per_page)
  await store.dispatch('ports/getPorts', { ...route.query, ...params })
  await router.push({ query: { ...route.query, ...params } })
}

const getFatAddress = (addresses) => {
  if (!addresses?.length) {
    return ''
  }

  return addresses[0]?.address || ''
}

const showEdit = async () => {
  isShowEdit.value = !isShowEdit.value
  isOpenDashboardMenu.value = !isOpenDashboardMenu.value

  await store.dispatch('ports/getPortDetails', currentlyPort.value.id)
}

const updateWidth = async (params) => {
  await store.dispatch('ports/updateColumnWidth', params)
}

watch(portDetail, () => {
  fact_addresses.value = portDetail.value.fact_addresses
  isEditModal.value = !isEditModal.value
})

const updateAddress = (value) => {
  fact_addresses.value = value
}

const deletePort = () => {
  isOpenDashboardMenu.value = !isOpenDashboardMenu.value
  modalDelete.value.show(
    'Внимание!',
    'Вы уверены, что хотите удалить порт?',
    {
      nameButton: 'Удалить',
    },
    async () => {
      await portsService.deletePort(currentlyPort.value.cells[0].value)
      await getPorts()
    }
  )
}

const closeModal = async () => {
  isEditModal.value = false
  await getPorts()
}

const filter = async (filterList) => {
  await router.push({ query: { ...route.query, page: page.value, per_page: perPage.value, ...filterList } })
  await getPorts({ ...filterList, ...route.query, page: page.value, per_page: perPage.value })
  await updateFilterState(filterList)
}

const updateFilterState = async (filterList) => {
  await store.dispatch('filter/updateCurrentFilter', filterList)
}

const filterReset = async () => {
  await router.replace({
    name: 'PortsPage',
    query: {
      page: page.value,
      per_page: meta.value?.per_page
    }
  })

  await filter()
}

const sortBy = async (item) => {
  let sort = {}

  if (item.sort === 'default') {
    await router.push({ query: { ...route.query, sort: item.id } })
    sort['sort'] = item.id
  }
  if (item.sort === 'ASC') {
    await router.push({ query: { ...route.query, sort: `-${item.id}` } })
    sort['sort'] = `-${item.id}`
  }
  if (item.sort === 'DESC') {
    await router.replace({ name: 'PortsPage' })
  }

  await store.dispatch('ports/getPortHeader', sort)
  await getPorts({ ...route.query })
}

const isViewPermission = (portId) => {
  const port = ports.value.find((el) => el.id === portId)

  return port.dashboard_permissions.view
}

const onTableRightClick = ({ event, id: portId }) => {
  isOpenDashboardMenu.value = !isOpenDashboardMenu.value
  if (!isOpenDashboardMenu.value) return false

  const currentElement = ports.value.find((el) => el.id === portId)
  currentlyPort.value = currentElement

  portName.value = currentlyPort.value.cells[2].name
  currentlyPortComment.value = currentlyPort.value.cells[4].name
  currentlyPortStatus.value = currentlyPort.value.cells[3].name

  setTimeout(() => {
    positionContextMenu(event, menuPosition.value)
  }, 0)
}

const onTableLeftClick = () => {
  isOpenDashboardMenu.value = false
}

const changeResponsible = () => {
  isOpenChangeResponsible.value = true
}

onMounted(async () => {
  await store.dispatch('filter/getFilterModel', 'sea-ports')
  await store.dispatch('ports/getPortHeader')
  await store.dispatch('filter/getPortsFilters')
  await getPorts()
})
</script>

<style lang="scss" scoped>
.sea-ports-page {
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 10px;
  overflow: hidden;
}
</style>
