<template>
  <div class="about-client-modal">
    <BigModal
      :Title="Title"
      :ModalButton="ModalButton"
      :is-open="isOpen"
      @close="closeModal"
      @submit-form="onSubmit"
      class="about-client"
    >
      <form @submit.prevent="onSubmit">
        <BaseModalInput
          class="input-group-item"
          label="Наименование клиента"
          placeholder="Введите наименование клиента"
          v-model="name"
          :errors="v.name.$errors"
        />
        <div class="modal-input-name mb-2">Статус клиента</div>
        <div class="flex-block mb-2">
          <div class="form_radio">
            <input
              id="radio-status"
              type="radio"
              v-model="statusClient"
              :value="'Действующий'"
            />
            <label for="radio-status">
              <span class="radio-name">Действующий</span>
            </label>
          </div>
          <div class="form_radio ml-6">
            <input
              id="radio-status-2"
              type="radio"
              v-model="statusClient"
              :value="'Недействующий'"
            />
            <label for="radio-status-2">
              <span class="radio-name">Недействующий</span>
            </label>
          </div>
        </div>

        <div class="mb-2">
          <label class="modal-input-name">Ответственный</label>
          <BaseDropdown placeholder="Ответственный" :list="responseList" v-model="responsible"></BaseDropdown>
        </div>
        <div class="companies">
          <ValidateEach
            v-for="(item, index) in collection"
            :key="index"
            :state="item"
            :rules="rules"
          >
            <template #default="{ v }">
              <div class="form-group">
                <button class="companies__delete" v-if="index !== 0" @click.stop="() => deleteThisCompany(index)">
                  <SvgIcon name="close" width="10" height="10" />
                </button>
                <BaseModalInput
                  class="input-group-item"
                  label="ИНН"
                  @input="(value) => checkInn(value, v.inn, index, item)"
                  placeholder="Введите ИНН"
                  v-model="v.inn.$model"
                  :errors="v.inn.$errors"
                  :input-mask="{
                    mask: 'HHHHHHHHHHHH',
                    tokens: { H: { pattern: /[0-9]/ } },
                  }"
                />
                <!-- <div class="modal-input-name mb-2">Интеграция с КонтурФокус</div> -->
                <div class="flex-block mb-2 kontur">
                  <div class="form_radio">
                    <input
                      :id="`radio-kontur-${index}`"
                      type="radio"
                      v-model="v.kontur.$model"
                      :value="'Интегрирован с КонтурФокус'"
                    />
                    <label :for="`radio-kontur-${index}`">
                      <span class="radio-name">Интегрирован с КонтурФокус</span>
                    </label>
                  </div>
                  <div class="form_radio ml-6">
                    <input
                      :id="`radio-kontur-2-${index}`"
                      type="radio"
                      v-model="v.kontur.$model"
                      :value="'Не интегрирован с КонтурФокус'"
                    />
                    <label :for="`radio-kontur-2-${index}`">
                      <span class="radio-name">Не интегрирован с КонтурФокус</span>
                    </label>
                  </div>
                </div>

                <div class="form-field mb-2">
                  <div class="checkboxes-item display-flex">
                    <RadioComponent
                      :id="index"
                      name="main-company"
                      v-model="mainCompanyIndex"
                      @update:modelValue="setMainCompany(collection, index)"
                      RadioName="Основная компания"
                      :isDisabled="collection.length <= 1"
                    />
                  </div>
                </div>

                <div class="modal__info-block">
                  <p class="modal__info-row" v-if="v.name.$model"><span>{{ v.name.$model }},</span></p>
                  <p class="modal__info-row" v-if="v.registration_date.$model"><span>Дата регистрации: {{ v.registration_date.$model ? v?.registration_date.$model : '' }},</span></p>
                  <p class="modal__info-row" v-if="v.head.$model"><span>Генеральный директор: {{ v?.head.$model ? v?.head.$model : '' }}</span></p>
                  <p class="modal__info-row" v-if="v.legal_address_symbol.$model !== 'plug' && v.legal_address_symbol.$model">Юридический адрес: <span>{{ v.legal_address_symbol.$model }}</span></p>
                </div>

                <div class="modal-input-name">Тип компании</div>
                <div class="flex-block mb-2">
                  <div class="form_radio">
                    <input
                      type="radio"
                      :value="true"
                      :disabled="true"
                      v-model="v.type.$model"
                    />
                    <label :for="`radio-client-${index}`">
                      <span class="radio-name">Юридическое лицо</span>
                    </label>
                  </div>
                  <div class="form_radio ml-6">
                    <input
                      :id="`radio-client-2-${index}`"
                      type="radio"
                      :disabled="true"
                      :value="false"
                      v-model="v.type.$model"
                    />
                    <label :for="`radio-client-2-${index}`">
                      <span class="radio-name">Физическое лицо</span>
                    </label>
                  </div>
                </div>
                <div class="modal-input-name mb-2">Статус компании</div>
                <div class="flex-block mb-2">
                  <div class="form_radio">
                    <input
                      :id="`radio-status-company-${index}`"
                      type="radio"
                      v-model="v.status.$model"
                      :disabled="
                        v.statusDisabled.$model === 'legal' ||
                          v.statusDisabled.$model === 'ip'
                      "
                      :value="'Действующая'"
                      @update:modelValue="companyCheck"
                    />
                    <label :for="`radio-status-company-${index}`">
                      <span class="radio-name">Действующая</span>
                    </label>
                  </div>
                  <div class="form_radio ml-6">
                    <input
                      :id="`radio-status-company-2-${index}`"
                      type="radio"
                      v-model="v.status.$model"
                      :disabled="
                        v.statusDisabled.$model === 'legal' ||
                          v.statusDisabled.$model === 'ip'
                      "
                      :value="'Недействующая'"
                      @update:modelValue="companyCheck"
                    />
                    <label :for="`radio-status-company-2-${index}`">
                      <span class="radio-name">Недействующая</span>
                    </label>
                  </div>
                </div>
                <BaseModalTextarea
                  class="input-group-item"
                  label="Комментарий"
                  placeholder="Введите комментарий"
                  v-model="v.comment.$model"
                />
              </div>
            </template>
          </ValidateEach>
        </div>
        <div v-if="collection.length < 5" class="display-f__jc-c">
          <button
            type="button"
            class="button-more display-flex mb-2"
            @click="addCountForm"
          >
            <span class="button-more__icon display-flex mr-2">
              <SvgIcon name="plus-more" />
            </span>
            Добавить компанию
          </button>
        </div>

        <ClientAddAdress
          :address-list="fact_addresses"
          @update-address="updateAdress($event)"
          :not-validate="!companyType"
        />
      </form>
    </BigModal>
    <AlertModal ref="alert" :nameButton="'Понятно'"></AlertModal>
    <AlertModal ref="alertLinkInn" :close-button="true" close-text="Закрыть">
    </AlertModal>
  </div>
</template>

<script>
import Vuex, { mapGetters } from 'vuex';
import { computed, reactive, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core';
import { ValidateEach } from '@vuelidate/components';
import { helpers, maxLength, required } from '@vuelidate/validators';
import BigModal from '@/components/BigModal.vue';
import RadioComponent from '@/components/RadioComponent.vue';
import { validateInn } from '@/utils/helper.js';
import clientsService from '@/services/clientsService.js';
import AlertModal from '@/components/AlertModal.vue';
import BaseModalInput from '@/components/base/BaseModalInput.vue';
import BaseModalTextarea from '@/components/base/BaseModalTextarea.vue';
import ClientAddAdress from '@/components/Clients/ClientAddAdress.vue';
import SvgIcon from '@/components/base/SvgIcon.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';

const clientId = ref('');

export default {
  name: 'AboutClientModal',
  data() {
    return {
      isOpenAboutClient: false,
      Title: 'О клиенте',
      ModalButton: 'Сохранить',
      name: '',
      responsible: ''
    };
  },
  computed: {
    ...mapGetters({
      clientData: 'clients/getSelectedClient',
      company: 'company/company',
      filterParams: 'clients/getClientsFilterParams',
      responseList: 'users/responseList',
    }),
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isModalEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseDropdown,
    SvgIcon,
    BigModal,
    RadioComponent,
    ValidateEach,
    AlertModal,
    BaseModalInput,
    BaseModalTextarea,
    ClientAddAdress,
  },
  setup(props) {
    const store = Vuex.useStore();
    const statusClient = ref('Действующий');
    const companyType = ref(true)
    const fact_addresses = ref([])

    const rulesForInn = computed(() => {
      if (companyType.value) {
        return {
          required: helpers.withMessage(
            'Поле обязательно для заполнения',
            required
          ),
          validateInn: helpers.withMessage(
            'Введите корректное значение',
            validateInn
          )
        }
      }
      return {}
    })

    const rules = {
      inn: rulesForInn,
      name: {
        required: helpers.withMessage(
          'Поле обязательно для заполнения',
          required
        ),
        maxLength: maxLength(255),
      },
      kpp: {},
      kppIp: {},
      ogrn: {},
      legal_address_symbol: {
        required: helpers.withMessage(
          'Поле обязательно для заполнения',
          required
        ),
      },
      legal_address_symbol_ip: {},
      general: {
        required: helpers.withMessage(
          'Поле обязательно для заполнения',
          required
        ),
      },
      type: {
        required: helpers.withMessage(
          'Поле обязательно для заполнения',
          required
        ),
      },
      comment: {},
      statusDisabled: {},
      kontur: {},
      status: {},
      registration_date: {},
      head: {},
    };

    const collection = props.isModalEdit
      ? reactive([])
      : reactive([
        {
          status: 'Действующая',
          type: true,
          inn: '',
          ogrn: '',
          kpp: '',
          kppIp: '',
          legal_address_symbol: '',
          legal_address_symbol_ip: '',
          fact_addresses: [],
          comment: '',
          name: '',
          id: '',
          kontur: 'Не интегрирован с КонтурФокус',
          general: false,
          statusDisabled: 'legal',
          registration_date: '',
          head: '',
        },
      ]);


    const v = useVuelidate();

    let selectedClient;

    if (props.isModalEdit) {
      selectedClient = store.getters['clients/getSelectedClient'];
      clientId.value = selectedClient.id;

      selectedClient.companies.forEach((company) => {
        companyType.value = company.type;

        collection.push({
          ...company,
          statusDisabled: company.type ? 'legal' : 'ip',
          status: company.status === true ? 'Действующая' : 'Недействующая',
          kpp:
            company.kpp == '' || company.kpp == null
              ? '999999999'
              : company.kpp,
          kppIp: company.kpp ? company.kpp : (company.kppIp ? company.kppIp : ''),
          kontur: company.kontur == true ? 'Интегрирован с КонтурФокус' : 'Не интегрирован с КонтурФокус',
          legal_address_symbol:
            company.legal_address_symbol == '' ||
            company.legal_address_symbol == null
              ? 'plug'
              : company.legal_address_symbol,
          legal_address_symbol_ip: company.legal_address_symbol
            ? company.legal_address_symbol
            : company.legal_address_symbol,
          registration_date: company.registration_date,
          head: company?.head
        })
      })
      fact_addresses.value = selectedClient.fact_addresses;

      if (selectedClient.status == false) {
        statusClient.value = 'Недействующий';
      }
    } else {
      collection[0].general = true;

      watch(collection, (currentValue) => {
        localStorage.setItem('clientFormState', JSON.stringify(currentValue))
      });

      watch(statusClient, (currentValue) => {
        const statusValue = currentValue == 'Действующий' ? true : false
        localStorage.setItem('clientFormStatus', JSON.stringify(statusValue))
      })

      const localFormState = localStorage.getItem('clientFormState')
      const localFormStatus = localStorage.getItem('clientFormStatus')

      if (localFormState) {
        const parsedState = JSON.parse(localFormState);

        for (let i = 0; i <= collection.length; i++) {
          collection.shift();
        }

        parsedState.forEach((item) => {
          collection.push(item)
        })
      }

      if (localFormStatus) {
        const parsedState = JSON.parse(localFormStatus);
        const parsedStateStatus = parsedState == true ? 'Действующий' : 'Недействующий'

        statusClient.value = parsedStateStatus
      }
    }

    const mainCompanyIndex = ref(0);

    const setMainCompany = (collection, activeIndex = null) => {
      mainCompanyIndex.value = activeIndex ? activeIndex : collection.length - 1;
      collection.forEach((company, arrIndex) => {
        if (activeIndex !== null) company.general = arrIndex === activeIndex;
        company.general = arrIndex === collection.length - 1;
      })
    }

    const deleteThisCompany = (index) => {
      collection.splice(index,1);
      setMainCompany(collection)
    }

    return {
      companyType,
      rules,
      collection,
      deleteThisCompany,
      setMainCompany,
      mainCompanyIndex,
      v,
      modalEditData: props.isModalEdit,
      statusClient,
      fact_addresses
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage(
          'Поле обязательно для заполнения',
          required
        ),
        maxLength: maxLength(255),
      },
    };
  },
  mounted() {
    if (this.modalEditData) {
      this.name = this.clientData?.name;
      this.responsible = this.clientData?.responsible?.id
    } else {
      const localFormName = localStorage.getItem('clientFormName')
      localStorage.setItem('clientFormIsOpen', true)

      if (localFormName) {
        const parsedState = JSON.parse(localFormName);

        this.name = parsedState
      }
    }
  },
  watch: {
    '$route.params': 'functionToRunWhenParamsChange',
    name(currentValue) {
      if (!this.isModalEdit) {
        localStorage.setItem('clientFormName', JSON.stringify(currentValue))
      }
    }
  },
  methods: {
    functionToRunWhenParamsChange() {
      location.reload()
    },

    companyCheck() {
      const companyCheckStatus = this.collection.filter((value) => {
        if (value.status === 'Действующая') {
          return true;
        } else {
          return false;
        }
      });

      if (companyCheckStatus.length > 0) {
        this.statusClient = 'Действующий';
      } else {
        this.statusClient = 'Недействующий';
      }
    },
    basicCompany(event, items, index) {
      if (event === true) {
        items.forEach((item, itemIndex) => {

          if (itemIndex !== index) {
            item.general = false;
          }
        });
      }
    },

    async checkInn(v, validate, index, itemCollection) {
      const isInnValid = await validate.$validate();

      if (isInnValid) {
        try {
          await this.$store.dispatch('company/getCompany', {
            inn: v.target.value,
          });

          itemCollection.type = this.company.type === '1';
          itemCollection.inn = this.company.inn;
          itemCollection.ogrn = this.company.ogrn;
          itemCollection.kpp = this.company.kpp;
          itemCollection.legal_address_symbol =
            this.company.legal_address_symbol;
          itemCollection.name = this.company.name;
          itemCollection.id = this.company.id;
          itemCollection.statusDisabled =
            this.company.type === '1' ? 'legal' : 'ip';
          itemCollection.status = 'Действующая';
          itemCollection.kontur = this.company.kontur == true ? 'Интегрирован с КонтурФокус' : 'Не интегрирован с КонтурФокус';
          itemCollection.registration_date = this.company?.registration_date;
          itemCollection.head = this.company?.head;

          if (this.name.length < 1) {
            this.name = this.company.short_name;
          }

          if (itemCollection.type == false && itemCollection.kpp == null) {
            itemCollection.kpp = '999999999'
          }

          if (
            itemCollection.type == false &&
            itemCollection.legal_address_symbol == null
          ) {
            itemCollection.legal_address_symbol = 'plug'
          }

          // статус клиента
          this.statusClient = 'Действующий';
        } catch (e) {
          // если в контур фокусе не найден ИНН
          if (e.status === 404) {
            itemCollection.statusDisabled = 'error';
            itemCollection.kontur = 'Не интегрирован с КонтурФокус'

            if (itemCollection.inn.length > 10) {
              itemCollection.type = false;
            } else {
              itemCollection.type = true;
            }
          }

          this.$refs.alert.show('Ошибка', e.data.data.message, {
            nameButton: 'Понятно',
            color: '#FFA500',
          });

          itemCollection.inn = '';

        } finally {
          const companyCheckStatus = this.collection.filter(
            (value) => value.status === 'Действующая'
          );

          if (companyCheckStatus.length > 0) {
            this.statusClient = 'Действующий';
          } else {
            this.statusClient = 'Недействующий';
          }
        }
      }
    },

    updateAdress(value) {
      this.fact_addresses = value;
    },

    closeModal() {
      this.$emit('close');

      if (this.modalEditData === true) {
        // очистили коллекцию
        for (let i = 0; i <= this.collection.length; i++) {
          this.collection.shift();
        }

        this.addCountForm();
      }

      localStorage.removeItem('clientFormState')
      localStorage.removeItem('clientFormStatus')
      localStorage.removeItem('clientFormName')
      localStorage.removeItem('clientFormIsOpen')
    },

    addCountForm() {
      this.collection.push({
        status: 'Действующая',
        type: true,
        inn: '',
        ogrn: '',
        kpp: '',
        kppIp: '',
        legal_address_symbol: '',
        legal_address_symbol_ip: '',
        fact_addresses: [],
        comment: '',
        name: '',
        id: '',
        kontur: 'Не интегрирован с КонтурФокус',
        general: false,
        statusDisabled: 'legal',
      });
    },

    async editClient(params, id) {
      console.log(this.filterParams)

      try {
        await clientsService.editClient(params, id);
        this.$emit('submitValue', {});
        this.closeModal();

        let filterParams = {
          ...this.filterParams,
          page: this.$route.query?.page ? this.$route.query?.page : undefined
        }

        await this.$store.dispatch('clients/getClients', filterParams)
      } catch (error) {
        const { data } = error.response
        let msgError
        let errorSplit
        let textError

        if (data?.errors) {
          msgError = Object.values(data.errors)
          const msgErrorParse = msgError[0].join()
          errorSplit = msgErrorParse.split('|')
          textError = errorSplit[0]
        } else {
          textError = data.data.message
        }

        // ссылка inn
        if (error.response.status == 422) {
          const id = errorSplit[1]

          if (id) {
            this.$refs.alertLinkInn.show(
              'Внимание!',
              textError,
              {
                nameButton: 'Перейти на страницу',
                color: '#FFA500',
              },
              () => {
                this.$router.push({
                  path: `/table-clients-page/${id}`,
                })
              }
            );
          } else {
            this.$refs.alert.show('Ошибка', textError, {
              nameButton: 'Окей',
              color: '#FFA500',
            });
          }
        } else {
          this.$refs.alert.show('Ошибка', textError, {
            nameButton: 'Окей',
            color: '#FFA500',
          });
        }
      }
    },

    async createClient(params) {
      try {
        const { data } = await clientsService.createClient(params);

        this.closeModal();

        let filterParams = {
          ...this.filterParams,
          page: this.$route.query?.page ? this.$route.query?.page : undefined
        }

        await this.$store.dispatch('clients/getClients', filterParams)

        localStorage.removeItem('clientFormState')
        localStorage.removeItem('clientFormStatus')
        localStorage.removeItem('clientFormName')
        localStorage.removeItem('clientFormIsOpen')
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data?.errors)
        const msgErrorParse = msgError[0].join()
        const errorSplit = msgErrorParse.split('|')
        const textError = errorSplit[0]

        // ссылка inn
        if (error.response.status == 422) {
          const id = errorSplit[1]

          if (id) {
            this.$refs.alertLinkInn.show(
              'Внимание!',
              textError,
              {
                nameButton: 'Перейти на страницу',
                color: '#FFA500',
              },
              () => {
                this.$router.push({
                  path: `/table-clients-page/${id}`,
                })
              }
            )
          } else {
            this.$refs.alert.show('Ошибка', textError, {
              nameButton: 'Окей',
              color: '#FFA500',
            })
          }
        }
      }
    },

    async onSubmit() {
      const isFormCorrect = await this.v.$validate();

      if (isFormCorrect) {
        // submit модалки редактирования
        if (this.modalEditData) {
          await this.editClient(
            {
              id: clientId.value,
              name: this.name,
              responsible: this.responsible,
              status: this.statusClient === 'Действующий',
              // type: true,
              fact_addresses: this.fact_addresses,
              companies: this.collection.map((item) => {
                return {
                  ...item,
                  head: item.head || undefined,
                  registration_date: item.registration_date || undefined,
                  id: item.id || null,
                  status: item.status === 'Действующая',
                  kpp: item.kpp === '999999999' ? '' : item.kpp,
                  kontur: item.kontur === 'Интегрирован с КонтурФокус',
                  legal_address_symbol: item.legal_address_symbol === 'plug' ? '' : item.legal_address_symbol,
                  type: item.type ? 1 : 0,
                }
              }),
            },
            clientId.value
          );
          // submit модалки создания
        } else {
          await this.createClient({
            name: this.name,
            responsible: this.responsible,
            status: this.statusClient == 'Действующий' ? true : false,
            type: true,
            companies: this.collection.map((item) => ({
              ...item,
              general: item.general ? true : false,
              status: item.status === 'Действующая',
              kpp: item.kpp === '999999999' ? '' : item.kpp,
              kontur: item.kontur === 'Интегрирован с КонтурФокус',
              head: item.head || undefined,
              registration_date: item.registration_date || undefined,
              legal_address_symbol: item.legal_address_symbol === 'plug' ? '' : item.legal_address_symbol,
            })),
          });
        }
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.checkbox-component.is-checked {
  pointer-events: none;
}

.input-group-item {
  margin-bottom: 16px;
}
.add-button {
  margin-left: 0;
}

.modal-form {
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.about-client {
  .clients-modal__title {
    margin-bottom: 5px;
  }
}

.modal-flex.align_start {
  align-items: flex-start;
}

/* .modal-input-name {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #a4a4a4;
  margin-bottom: 2px;
} */

.form-group {
  &:not(:last-child) {
    border-bottom: 1px solid #efefef;
    margin-bottom: 10px;
  }
}

// .button-delete {
//   display: inline-block;

//   &__wrap {
//     text-align: right;
//   }
// }

.modal {
  &__info-block {
    margin-bottom: 20px;
  }

  &__info-row {
    display: block;
    margin: 5px 0;
  }
}

.kontur {
  display: none;
}

.button-more {
  font-size: 14px;
  line-height: 22px;
}

.companies {
  &__delete {
    position: absolute;
    background: #f4f4f4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 10;
    right: 0;
    top: -15px;
  }

  :deep(.form-field) {
    margin-bottom: 10px;
  }

  .form-group {
    position: relative;
  }

  .form-group:not(:last-of-type) {
    border-bottom: 1px solid #f4f4f4;
  }

  .form-group:not(:first-of-type) {
    .form-field.modal-input {
      margin-top: 25px;
    }
  }
}
</style>
