import ApiUtil from '@/utils/api'

export default {
  getContacts: (params = {}) =>
    ApiUtil({
      url: '/api/v1/contacts',
      method: 'get',
      requiresAuth: true,
      params,
    }),
  getContactsQuarry: (id) =>
    ApiUtil({
      url: '/api/v1/contacts',
      method: 'get',
      requiresAuth: true,
      params: {
        quarry_id: id,
      },
    }),
  createContact: (data) => {
    return ApiUtil({
      url: '/api/v1/contacts',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  existenceCheckContact: (data) => {
    return ApiUtil({
      url: '/api/v1/contact',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  deleteContact: (id) => {
    return ApiUtil({
      url: `/api/v1/contacts/${id}`,
      method: 'delete',
      requiresAuth: true,
    })
  },
  editContact: (data, id) => {
    return ApiUtil({
      url: `/api/v1/contacts/${id}`,
      method: 'patch',
      requiresAuth: true,
      data,
    })
  },
  addToFavorites: (id) => {
    return ApiUtil({
      url: `/api/v1/contacts/${id}/favorite`,
      method: 'post',
      requiresAuth: true
    })
  },
}
