<template>
  <div class="input-group">
    <p v-if="description" class="input-group__description">
      {{ description }}
    </p>
    <div class="input-wrapper">
      <input
        :name="name"
        class="input authorization-input"
        @input="handleInput"
        :type="type"
        :value="modelValue"
        :maxlength="maxLength"
        :placeholder="placeholder"
      >
      <SvgIcon
        :width="iconPostfixSize"
        :height="iconPostfixSize"
        class="input-group__postfix"
        v-if="iconPostfix"
        :name="iconPostfix"
      ></SvgIcon>
      <span v-if="clear" class="input-clear">
        <SvgIcon name="cross" />
      </span>
    </div>
    <div v-if="isError" class="input-error">
      {{ errorString }}
    </div>
    <div v-if="subDescription && !isError" class="input-group__subdescription">
      {{ subDescription }}
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon';
export default {
  name: 'BaseInput',
  components: { SvgIcon },
  props: {
    name: {
      type: String,
      default() {
        return '';
      }
    },
    isError: {
      type: Boolean,
      default: true
    },
    /**
     * Иконка сначала
     */
    iconPostfix: {
      type: String,
      default: null
    },
    maxLength: {
      default: 256,
      type: Number,
    },
    iconPostfixSize: {
      type: [Number, String],
      default: 24
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    error: {
      type: Array,
      default() {
        return [{
          status: false,
          text: '',
        }]
      },
    },
    clear: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    subDescription: {
      type: String
    }
  },
  computed: {
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join('. ')
    }
  },
  data() {
    return {
      value: '',
      rangeValue: 0
    };
  },
  watch: {
    modelValue(v) {
      this.value = v;
    },
    value() {
      this.$emit('update:modelValue', this.value);
    },
    rangeValue(v) {
      this.$emit('update:modelValue', v);
    }
  },
  methods: {
    validPhone(v) {
      let valid = false;
      if (v.valid != null)
        valid = v.valid;
      this.$emit('validPhone', valid);
    },
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value);
    }
  }
}
</script>
