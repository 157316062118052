<template class="table-specifications-page">
  <div>
    <v-app class="specifications-page">
      <v-card>
        <v-layout>
          <v-main class="sidebar-content">
            <BackgroundComponent class="specifications-cards-page">
              <SpecificationsHeader
                @toggle-filter="filterState = !filterState"
                :isPreloaderActive="isPreloaderActive"
                :sortSchemas="specificationsHeader.schemas"
              />
              <AlertModal ref="modalDelete">
                <template v-slot:button>
                  <BaseButton @click="() => $refs.modalDelete.closeModal()">
                    Нет
                  </BaseButton>
                </template>
              </AlertModal>
              <div class="display-flex width-100 global-table" :class="{ 'open-filter': filterState }">
                <FilterWidget
                  v-if="filterState"
                  @filter-submit="filter"
                  @filter-reset="filterReset"
                  :filters-array="filterModel"
                  filter-page="specifications"
                  @update-state="updateFilterState"
                />
                <BaseTableMenu
                  :is-open="menuState"
                  :style="{
                    left: menuPosition.left,
                    top: menuPosition.top,
                  }"
                >
                  <DashboardDialogMenu
                    current-entity="specs"
                    :permissions="specificationData.dashboard_permissions"
                    @edit-entity="showEdit"
                    @duplicate-info="duplicateInfo"
                    @change-responsible="showChangeResponsible"
                    @delete-entity="modalDelete"
                  />
                </BaseTableMenu>
                <BaseTable
                  :key="`table-spec-${tempSpecificationTableHeader.length}`"
                  :table-head="specificationsHeader.schemas"
                  :table-body="specificationsTableList"
                  :table-header-params="tempSpecificationTableHeader"
                  :is-loading="isPreloaderActive"
                  @left-click="onLeftClick"
                  @right-click="onRightClick"
                  @sortBy="sortBy"
                  :size-big="true"
                  @update-width="updateWidth"
                >
                  <template #item="{ value, name, specificationId, spec_permissions }">
                    <div v-if="name === 'user'">
                      {{ `${value?.name || ''} ${value?.family_name || ''}` }}
                    </div>
                    <div v-else-if="name === 'logist'">
                      {{ `${value?.name ? value?.name : ''} ${value?.family_name ? value?.family_name : ''}` }}
                    </div>
                    <div v-else-if="name === 'company'">
                      <router-link
                        :to="{ name: 'ClientCardTablePage', params: { id: value?.client_id } }"
                        class="table-list__user"
                      >
                        {{ value?.client_name }}
                      </router-link>
                      <span class="table-list__user-inn">
                        {{ value?.inn }}
                      </span>
                    </div>
                    <router-link
                      class="table-list__id"
                      v-else-if="name === 'id'"
                      :to="{ name: 'SpecificationCardPage', params: { id: value } }"
                    >
                      {{ value }}
                    </router-link>
                    <div v-else-if="name === 'material_type'">
                      {{ value === true ? "Нерудная продукция" : "Сельскохозяйственная продукция" }}
                    </div>
                    <div v-else-if="name === 'load' || name === 'unload'">
                      <div class="address" @click="copyAddress($event, value)">
                        <div class="address-preview copy-success">Скопировано!</div>
                        <div class="address-preview">{{ value }}</div>
                      </div>
                    </div>
                    <div v-else-if="name === 'admin' && value?.name">
                      {{ value.name }} {{ value?.family_name || '' }}
                    </div>
                    <div v-else-if="name === 'lpr_status'" :class="`lpr-status-${value.id}`">
                      <TableItemComboBox
                        :value="value.title"
                        :options="lprStatuses"
                        :isDisabled="!permissions.status_lpr"
                        @updated="(statusId) => updateLprStatus(statusId, specificationId)"
                      />
                      <BaseTooltip text="Изменить статус" />
                    </div>
                    <div v-else-if="name && editableFields.find(item => item === name)">
                      <EditTableTextItem
                        :name="name"
                        :value="value"
                        :id="specificationId"
                        :permissions="spec_permissions"
                        @update:value="editField"
                      />
                    </div>
                    <div v-else-if="!value"></div>
                    <div v-else>
                      {{ formattedValue(name, value) }}
                    </div>
                  </template>
                </BaseTable>
                <BaseNotification
                  :type="notificationType"
                  :position="['bottom', 'right']"
                  :text="notificationText"
                  v-model="isNotificationShow"
                />
              </div>
            </BackgroundComponent>
          </v-main>
        </v-layout>
      </v-card>
    </v-app>
  </div>
  <div>
    <ChangeResponseModal
      :is-open="isOpenChangeResponsible"
      :list="responseList"
      :current-id="specificationData?.id"
      :current-responsible-id="specificationData?.user?.id"
      url-request="specifications/changeResponse"
      @responsibleModal:close="closeResponseModal"
    />
  </div>
  <AlertModal ref="alert" :nameButton="'Понятно'"/>
  <ChangePassword />
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import SpecificationsHeader from '@/components/Specifications/SpecificationsHeader';
import BackgroundComponent from '@/components/BackgroundComponent';
import BaseTableMenu from '@/components/base/BaseTableMenu';
import specificationService from '@/services/specificationService';
import BaseTable from '@/components/base/BaseTable';
import AlertModal from '@/components/AlertModal';
import { LOGISTIAN, COORDINATOR, ADMINISTRATOR, ROP, MANAGER } from '@/main';
import BaseButton from '@/components/base/BaseButton';
import { positionContextMenu } from '@/utils/helper';
import ChangePassword from '@/components/Authorization/ChangePassword';
import { computed } from 'vue';
import { tableFormatter } from '@/utils/helper'
import FilterWidget from '@/components/filter-templates/FilterWidget';
import TableItemComboBox from '@/components/Specifications/TableItemComboBox';
import ChangeResponseModal from '@/components/Specifications/ChangeResponseModal';
import DashboardDialogMenu from '@/components/Dashboard/DashboardDialogMenu.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import EditTableTextItem from '@/components/Specifications/EditTableTextItem.vue';
import BaseNotification from '@/components/base/BaseNotification.vue';

export default {
  name: 'SpecificationsCardsPage',
  components: {
    BaseNotification,
    EditTableTextItem,
    BaseTooltip,
    DashboardDialogMenu,
    ChangeResponseModal,
    TableItemComboBox,
    FilterWidget,
    ChangePassword,
    SpecificationsHeader,
    BaseTableMenu,
    BaseTable,
    BackgroundComponent,
    AlertModal,
    BaseButton,
  },
  data() {
    return {
      filterState: false,
      LOGISTIAN: LOGISTIAN,
      MANAGER: MANAGER,
      ROP: ROP,
      ADMINISTRATOR: ADMINISTRATOR,
      COORDINATOR: COORDINATOR,
      isOpenChangeResponsible: false,
      menuState: false,
      modalEditState: false,
      modalDeleteState: false,
      menuPosition: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      filters: '',
      filtersResponse: [],
      filterForm: {
        revenueFrom: '',
        revenueTo: '',
        statusActive: false,
        statusNotActive: false,
        typeLegal: false,
        typePhysical: false,
        career: false,
      },
      statePreloader: false,
      editableFields: [
        'flights',
        'cost_transportation_client',
        'cost_transportation_ADT',
        'recommended_price',
        'coefficient',
      ],
      isNotificationShow: false,
      notificationType: 'default',
      notificationText: 'Изменения успешно сохранены',
    };
  },
  setup() {
    const store = useStore()
    const filterModel = computed(() => store.getters['filter/filterModel']);
    store.dispatch('filter/getFilterModel', 'specs');

    return {
      filterModel
    }
  },
  computed: {
    ...mapGetters({
      specifications: 'specifications/specificationsList',
      specificationsTableList: 'specifications/specificationsTableList',
      specificationsHeader: 'specifications/specificationsHeaderList',
      specificationData: 'specifications/getSelectedSpecification',
      searchResultState: 'specifications/specificationsSearchResult',
      isPreloaderActive: 'specifications/getIsSpecificationsLoading',
      specificationsPagesCount: 'specifications/specificationsPagesCount',
      specificationsPerPage: 'specifications/specificationsPerPage',
      nameGood: 'dict/nameGood',
      account: 'account/account',
      users: 'users/users',
      tempSpecificationTableHeader: 'specifications/tempSpecificationTableHeader',
      adminList: 'users/adminList',
      logistianList: 'users/logistianList',
      responseList: 'users/responseList',
      lprStatuses: 'dict/lprStatuses',
      generalPermissions: 'account/getPermissions',
      specificationLocalState: 'filter/specificationLocalString',
    }),
    permissions() {
      if (!this.generalPermissions || !this.generalPermissions['specs']) return [];
      return this.generalPermissions['specs'];
    }
  },
  methods: {
    copyAddress($event, text) {
      const copyInput = document.querySelector('.js-copyInput');
      const elem = $event.currentTarget

      elem.classList.add('is_copied')

      setTimeout(() => {
        elem.classList.remove('is_copied')
      }, 1000);

      copyInput.value = text;
      copyInput.focus();
      copyInput.select();
      document.execCommand('copy');
    },
    async updateLprStatus(lprStatusId, specificationId) {
      await this.$store.dispatch('specifications/setLprStatus', {
        lprStatusId,
        specificationId,
        lprStatuses: this.lprStatuses,
      })
    },
    formattedValue(name, value) {
      return tableFormatter(name, value);
    },
    showChangeResponsible() {
      this.isOpenChangeResponsible = true;
    },
    async getLprStatuses() {
      try {
        await this.$store.dispatch('dict/getLprStatuses');
      } catch (e) {
        this.$refs.alert.show('Ошибка ', e, {
          nameButton: 'Понятно',
        });
      }
    },
    async modalDelete() {
      this.$refs.modalDelete.show(
        'Внимание!',
        'Вы уверены, что хотите удалить спецификацию?',
        {
          nameButton: 'Удалить',
        },
        () => {
          this.deleteSpec();
        }
      );
    },
    async deleteSpec() {
      try {
        const { specificationData } = this;
        await specificationService.deleteSpecification(specificationData.id);
        await this.$store.dispatch('specifications/getSpecifications', {
          ...this.$route?.query
        });
      } catch (error) {
        const { data } = error.response?.data;

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            type: 'error',
            nameButton: 'Понятно',
            color: '#FFA500',
          });
        }
      } finally {
        this.modalDeleteState = false;
      }
    },
    async closeResponseModal(isDataUpdate = false) {
      this.isOpenChangeResponsible = false
      if (!isDataUpdate) return;
      await this.getSpecificationsList(this.$route.query);
    },
    async getSpecificationsHeaderList(params) {
      try {
        await this.$store.dispatch(
          'specifications/getSpecificationsHeader',
          params
        );
      } catch (e) {
        this.$refs.alert.show(this.$t('alert.error'), e, {
          type: 'error',
          nameButton: 'Понятно',
        });
      }
    },
    async getSpecificationsList(params) {
      try {
        await this.$store.dispatch('specifications/getSpecifications', params);
      } catch (error) {
        const data = error.response;

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            type: 'error',
            nameButton: 'Понятно',
            color: '#FFA500',
          });
        }
      } finally {
        this.statePreloader = false;
      }
    },
    async filter(filterList) {
      const query = {
        ...filterList,
        sort: this.$route.query.sort,
        page: 1,
        per_page: this.specificationsPerPage
      };

      await this.$router.push({ query });
      await this.getSpecificationsList(query);
    },
    async getFilterSpecifications() {
      await this.$store.dispatch('filter/getFilterSpecList')
    },
    async sortBy(item) {
      let query = {};
      if (item.sort === 'default') {
        query = { ...this.$route.query, sort: item.id };
        await this.$router.push({ query });
      }
      if (item.sort === 'ASC') {
        query = { ...this.$route.query, sort: `-${item.id}` };
        await this.$router.push({ query });
      }
      if (item.sort === 'DESC') {
        this.$router.replace({ name: 'SpecificationsTablePage' });
      }

      query.page = 1;
      query.per_page = this.specificationsPerPage;

      await this.getSpecificationsList(query);
      await this.getSpecificationsHeaderList(query);
    },
    async filterReset() {
      const query = {
        per_page: this.specificationsPerPage,
        page: 1,
      }
      this.$router.replace({
        name: 'SpecificationsTablePage',
        query: query,
      })
      await this.getSpecificationsList(query)
    },
    hideMenu() {
      this.menuState = false;
    },
    async showEdit() {
      await this.$store.dispatch(
        'specifications/setSpecificationDetail',
        this.specificationData
      );
      const id = this.specificationData.id;
      await this.$router.push({
        name: 'EditSpecificationPage',
        params: { id },
      });
    },
    async duplicateInfo() {
      await this.$store.dispatch(
        'specifications/setSpecificationDetail',
        this.specificationData
      );
      await this.$router.push({ name: 'CreateSpecificationPage' });
    },
    onLeftClick() {
      // переход на детальную страницу + id страниц
      // this.$router.push({ name: "SpecificationCardPage", params: { id } });
    },
    onRightClick({ event, id }) {
      const current = this.specifications.filter((item) => item.id === id)[0];

      this.$store.dispatch('specifications/setSelectedSpecification', current);

      this.menuState = true;

      setTimeout(() => {
        positionContextMenu(event, this.menuPosition)
      });
    },
    updateFilterState(filterList) {
      const sorting = this.$route.query.sort;
      let query;
      if (sorting) {
        query = { ...filterList, sort: sorting };
      } else {
        query = { ...filterList };
      }
      query.page = 1
      this.$store.dispatch('filter/updateCurrentFilter', query)
    },
    async updateWidth(params) {
      await this.$store.dispatch('specifications/updateColumnWidth', params)
    },
    async editField(id, name, newValue) {
      try {
        await this.$store.dispatch('specifications/specFieldUpdate', { id: id, field: name, value: newValue })
        await this.$store.dispatch('specifications/getSpecifications',  {
          ...this.$route?.query
        })
        this.notificationType = 'success'
        this.notificationText = 'Изменения успешно сохранены'
        this.isNotificationShow = true
      } catch (e) {
        this.notificationType = 'error'
        this.notificationText = 'Произошла ошибка'
        this.isNotificationShow = true
      }
    },
  },
  async created() {
    await this.getSpecificationsHeaderList(this.$route.query);
    await this.getSpecificationsList(this.$route.query);
    await this.getFilterSpecifications();
    await this.getLprStatuses();
  },
  async mounted() {
    window.addEventListener('click', this.hideMenu);
    if (this.specificationLocalState) {
      const query = Object.fromEntries(
        new URLSearchParams(this.specificationLocalState || '')
      )
      if (Object.values(query).every(prop => prop === 'null')) return;
      Object.keys(query).forEach(key => {
        if (query[key] === 'null') delete query[key];
      });
      await this.$router.push({ query });
    }
  },
  unmounted() {
    window.removeEventListener('click', this.hideMenu);
  },
};
</script>

<style scoped lang="scss">
.specifications-cards-page {
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 10px;
  overflow: hidden;
}

:deep(.lpr-status-1 .item__text) {
  background-color: rgba(51, 206, 13, 0.16);
}

:deep(.lpr-status-2 .item__text) {
  background-color: rgba(255, 31, 0, 0.16);
}

:deep(.lpr-status-3 .item__text) {
  background-color: rgba(255, 184, 0, 0.15);
}

.table-list__id {
  text-decoration: underline;
  color: #0A2333;
  cursor: pointer;
}

.table-list__user {
  color: #0055e8;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;

  &-inn {
    display: block;
    margin-top: 4px;
    font-weight: 600;
  }
}

.table-specifications-page {
  .specifications-page {
    background-color: #f4f4f8;
  }

  .flex-container {
    display: flex;
  }

  .v-expansion-panel__shadow {
    box-shadow: none !important;
  }

  .table {
    //overflow: auto;
    position: relative;
  }

  .table .v-table {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    //overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .content-header {
    display: flex;
    margin-top: 35px;
    align-items: center;
    margin-bottom: 24px;
  }

  .specifications-page {
    background: #f2f4f6;
  }

  .content {
    background: #f4f4f8;
    border-radius: 12px;
    margin-left: 18px;
    padding: 20px;
    /*height: 100vh; */
  }

  .tr-title {
    background: #f4f4f8;
    font-family: "Manrope";
    font-weight: 500;
    font-size: 10px;
    line-height: 19px;
    color: #0a2333;
  }

  .td-item {
    font-family: "Manrope";
    font-size: 10px !important;
    line-height: 19px;
    text-transform: capitalize;
  }

  .client-name {
    //font-size: 10px !important;
    font-weight: 600;
    color: #0055e8;
  }

  .td-total-revenue {
    font-weight: 600;
    color: #0f537e;
  }

  .td-phone {
    font-weight: 400;
    color: #afafaf;
  }

  .td-items {
    font-weight: 400;
    color: #5a5a5a;
    font-size: 10px;
  }

  .td-contact-person {
    font-weight: 400;
    color: #0a2333;
  }

  .td-client-id {
    font-weight: 500;
    color: #11142d;
  }

  .flex-block {
    display: flex;
  }

  .sidebar-content {
    height: 100%;
  }
}

.address:not(.is_copied) {
  .copy-success {
    display: none;
  }
}

.is_copied {
  div:not(.copy-success) {
    color: transparent;
  }
}

.address-preview.copy-success {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.22);
  background-color: #fff;
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 25px;
}

.address {
  position: relative;

  &-preview {
    width: 100%;
    padding: 10px;
    cursor: pointer;
  }
}
</style>
