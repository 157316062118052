<template>
  <div>
    <span v-if="!isOpen" @click="toggleEdit(true)">
      {{ tableFormatter(name, value) }}
    </span>
    <SubmitInput
      v-else
      type="number"
      @trigger:submit="submitValue"
    />
    <BaseTooltip :text="editTooltipText" v-if="canEdit" />
  </div>
</template>

<script setup>

import BaseTooltip from '@/components/base/BaseTooltip.vue';
import { tableFormatter } from '../../utils/helper.js';
import { computed, ref } from 'vue';
import SubmitInput from '@/components/base/SubmitInput.vue';

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  value: {
    type: [Number, String],
    required: true
  },
  id: {
    type: [Number, String],
    required: true
  },
  permissions: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['update:value'])

const isOpen = ref(false)

const toggleEdit = (val) => {
  if (!canEdit.value) return

  isOpen.value = val
}
const editTooltipText = computed(() => {
  if (!canEdit.value) return ''

  switch (props.name) {
  case 'flights':
    return 'Редактировать количество'
  case 'cost_transportation_client':
  case 'cost_transportation_ADT':
    return 'Редактировать стоимость'
  case 'recommended_price':
    return 'Редактировать цену'
  case 'coefficient':
    return 'Редактировать коэффициент'
  default:
    return 'Редактировать поле'
  }
})

const canEdit = computed(() => {
  return props.name && props.permissions && props.permissions[props.name]?.edit
})

const submitValue = (val) => {
  isOpen.value = false

  if (val !== null)
    emit('update:value', props.id, props.name, val)

}
</script>