import ApiUtil from '@/utils/api'

export default {
  getSpecifications: (params = {}) =>
    ApiUtil({
      url: '/api/v1/specs',
      method: 'get',
      requiresAuth: true,
      params
    }),
  getSpecificationsSearch: (name, params = null) =>
    ApiUtil({
      url: `/api/v1/specs/search/${name}`,
      method: 'get',
      requiresAuth: true,
      params,
    }),
  getSpecificationsHeader: () =>
    ApiUtil({
      url: '/api/v1/schemas/specs',
      method: 'get',
      requiresAuth: true,
    }),
  getSpecificationDetail: (id) =>
    ApiUtil({
      url: `/api/v1/specs/${id}`,
      method: 'get',
      requiresAuth: true,
    }),
  getSpecificationsXlsx: (params) =>
    ApiUtil({
      url: '/api/v1/specs/xls',
      method: 'get',
      requiresAuth: true,
      responseType: 'blob',
      params
    }),
  createSpecification: (data) => {
    return ApiUtil({
      url: '/api/v1/specs',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  specificationAddColumns: (data) => {
    return ApiUtil({
      url: '/api/v1/specs/columns',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  getSpecificationsCalculate: (data) => {
    return ApiUtil({
      url: '/api/v1/specs/calculate',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  createManagerSpecification: ({ id, managerId }) => {
    return ApiUtil({
      url: `/api/v1/specs/${id}/person`,
      method: 'post',
      requiresAuth: true,
      data: managerId,
    })
  },
  logisticianHiredSpecification: (id) => {
    return ApiUtil({
      url: `/api/v1/specs/${id}/work`,
      method: 'post',
      requiresAuth: true,
    })
  },
  editSpecification: (data) => {
    return ApiUtil({
      url: `/api/v1/specs/${data.id}`,
      method: 'patch',
      requiresAuth: true,
      data,
    })
  },
  editLprStatus: (data) => {
    return ApiUtil({
      url: `/api/v1/specs/${data.specificationId}/lpr-statuses/${data.lprStatusId}`,
      method: 'patch',
      requiresAuth: true,
    })
  },
  deleteSpecification: (id) => {
    return ApiUtil({
      url: `/api/v1/specs/${id}`,
      method: 'delete',
      requiresAuth: true,
    })
  },
  getSpecificationXlsx: (id) =>
    ApiUtil({
      url: `/api/v1/specs/xls/${id}`,
      method: 'get',
      requiresAuth: true,
      responseType: 'blob',
    }),
  createOrder1c: (id) =>
    ApiUtil({
      url: `/api/v1/specs/${id}/1c`,
      method: 'get',
      requiresAuth: true,
    }),
  searchRoutes: (data) =>
    ApiUtil({
      url: '/api/v1/specs/search/routes',
      method: 'post',
      requiresAuth: true,
      data,
    }),
  getPortsList: (query) =>
    ApiUtil({
      url: '/api/v1/address-points',
      method: 'get',
      requiresAuth: true,
      query
    }),
  specFieldUpdate: (id, payload) =>
    ApiUtil({
      url: `/api/v1/specs/${id}/fields/update`,
      method: 'post',
      requiresAuth: true,
      data: payload
    })
}
