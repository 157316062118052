<template class="table-specifications-page">
  <div>
    <BackgroundComponent class="interactive-map">
      <InteractiveMapHeader/>

      <div class="interactive-map__spinner">
        <BaseSpinner
          :isLoading="isMapDataLoading"
          :spinnerDiameter="30"
        />
      </div>

      <div v-if="!isShareMode" :class="['interactive-map__wrapper', { 'filter-active': isFilterActive}]">
        <div class="interactive-map__filter-toggle" @click="() => toggleFilter()">
          <SvgIcon name="sortingWhite"/>
          <BaseTooltip text="Открыть/скрыть фильтры" />
        </div>

        <Sidebar
          v-show="isFilterActive"
          :isShareMode="isShareMode"
          @filter-reset="clearMapData"
        />
        <ExtendedInfo
          :extendedInfoId="extendedInfoId"
          v-show="!!extendedInfoId"
          @close-filter="toggleFilter"
        />
        <InteractiveMap
          class="ya-map"
          :isFilterActive="isFilterActive"
          :isExtendedInfoActive="isExtendedInfoActive"
          :isExactSpecification="!!exactSpecificationId"
          @placemark-clicked="openAdditioalInfo"
          ref="interactiveMap"
        />
        <ExtendedQuickMetaRoute
          v-show="!!quickMetaRouteMode"
        />
      </div>
      <div v-else :class="['interactive-map__wrapper', { 'filter-active': isFilterActive}]">
        <Sidebar
          v-show="isFilterActive"
          :isShareMode="isShareMode"
        />
        <div class="interactive-map__filter-toggle" @click="() => toggleFilter()">
          <SvgIcon name="sorting"/>
          <BaseTooltip text="Открыть/скрыть фильтры" />
        </div>
        <ExtendedInfo
          :extendedInfoId="extendedInfoId"
          v-show="!!extendedInfoId"
          @close-filter="toggleFilter"
        />
        <InteractiveMap
          class="ya-map"
          :isShareMode="isShareMode"
          :isFilterActive="isFilterActive"
          :isExtendedInfoActive="isExtendedInfoActive"
          @placemark-clicked="openAdditioalInfo"
          ref="interactiveMap"
        />
      </div>
    </BackgroundComponent>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { MANAGER_QUARRY } from '@/main.js';
import { useRouter } from 'vue-router';
import BackgroundComponent from '@/components/BackgroundComponent.vue';
import InteractiveMap from '@/components/InteractiveMap/Map.vue';
import SvgIcon from '@/components/base/SvgIcon.vue';
import ExtendedInfo from '@/components/InteractiveMap/ExtendedInfo.vue';
import Sidebar from '@/components/InteractiveMap/Sidebar.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import InteractiveMapHeader from '@/components/InteractiveMap/InteractiveMapHeader.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import ExtendedQuickMetaRoute from '@/components/InteractiveMap/ExtendedQuickMetaRoute.vue';

const isFilterActive = ref(false);
const isExtendedInfoActive = ref(false);
const interactiveMap = ref(null);
const toggleFilter = (filterState = undefined) => {
  if (filterState !== undefined) {
    isFilterActive.value = filterState
    return;
  }
  isFilterActive.value = !isFilterActive.value;
}

const store = useStore();
const router = useRouter();

const openAdditioalInfo = async (data) => {
  const [id, entityType, addressId] = data;
  if (!id) return;
  
  await store.dispatch('interactiveMap/handleExtendedData', [id, entityType, addressId]);
}

const quickMetaRouteMode = computed(() => store.getters['interactiveMap/getIsQuickMetaMode'])
const isShareMode = computed(() => {
  return !!sharedMetaRouteSlug.value;
})

const sharedMetaRouteSlug = computed(() => {
  const shareMetaroute = 'meta_route'
  const url = new URL(window.location.href);
  return url.searchParams.get(shareMetaroute)
})

const exactSpecificationId = computed({
  get: () => {
    const specificationId = 'specification_id'
    const url = new URL(window.location.href);
    return url.searchParams.get(specificationId)
  },
  set: (newValue) => {
    return newValue
  }
})

const extendedData = computed(() => {
  return store.getters['interactiveMap/getExtendedData'];
})

const extendedInfoId = computed(() => {
  return store.getters['interactiveMap/getExtendedDataId'];
})

const isMapDataLoading = computed(() => {
  return store.getters['interactiveMap/getIsMapDataLoading'];
})

const clearMapData = async () => {
  await store.dispatch('interactiveMap/resetMapData');
}

const accountRole = computed(() => {
  return store.getters['account/account'];
})

watch(() => accountRole.value.role, async (value) => {
  // ограничение доступа по прямой ссылке
  if (value === MANAGER_QUARRY) {
    await router.replace({ name: 'SpecificationsTablePage' });
  }
})

onMounted(async () => {
  await store.dispatch('interactiveMap/getMapData', exactSpecificationId.value);
  await store.dispatch('interactiveMap/getIntegratedFilters');

  if (isShareMode.value) {
    await store.dispatch('interactiveMap/getSharedMetaRoute', sharedMetaRouteSlug.value);
    return;
  }

  // затираем гет параметры, если не режим поделиться мета-маршрутом
  window.history.replaceState(null, null, window.location.pathname);
  exactSpecificationId.value = null;
})

onBeforeUnmount(() => {
  store.dispatch('interactiveMap/handleExtendedData',[null, null, null]);
})

</script>

<style scoped lang="scss">
.interactive-map {
  margin: 15px 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;

  &__filter-toggle {
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0055E8;
    border-radius: 6px;
    margin-right: 8px;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 45px;
  }

  &__filters {
    margin-bottom: 0;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  :deep(.filters-templates__item-wrap) {
    flex: 1;
    max-height: initial;
  }

    &__wrapper {
    position: relative;
    flex: 1;
    display: flex;

    &.filter-active {
      .ya-map {
        margin: 0 15px 0 370px;
        position: relative;
        width: calc(100% - 385px);
      }

      .interactive-map__filter-toggle {
        left: 410px;
      }
    }
  }

  &__spinner {
    width: 60px;
    position: absolute;
    top: 120px;
    right: 40px;
    z-index: 100;
  }
}
</style>
