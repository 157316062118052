import contactsService from "@/services/contactsService";
import { handleError } from "@/utils/api/handleError";

export default {
  namespaced: true,
  state: {
    contactsList: [],
    selectedContact: null,
  },
  actions: {
    async getContacts({ commit }, client_id) {
      try {
        const data = await contactsService.getContacts(client_id);
        commit("SET_CONTACTS_LIST", data);
      } catch (e) {
        handleError(e);
      }
    },
    async setSelectedContact({ commit }, data) {
      commit("SET_SELECTED_CONTACT", data);
    },
    async addToFavorites({ commit }, id) {
      try {
        await contactsService.addToFavorites(id);
      } catch (e) {
        handleError(e);
      }
    }
  },
  getters: {
    contactsList(state) {
      return state.contactsList;
    },
    getSelectedContact(state) {
      return state.selectedContact;
    },
  },
  mutations: {
    SET_CONTACTS_LIST(state, value) {
      state.contactsList = value.data.data;
    },
    SET_SELECTED_CONTACT(state, value) {
      state.selectedContact = value;
    },
  },
};
