import AuthService from '@/services/authService'
import LsUtil from '@/utils/ls'
import {LS_REFRESH_TOKEN, LS_USER_LANG} from '@/utils/ls/keys'
import {errorFormat} from "@/utils/helper";


export default {
    namespaced: true,
    state: {
        user: {},
        token: LsUtil.getItem(LS_REFRESH_TOKEN),
    },
    actions: {
        async getUserData({commit}) {
            try {
                const response = await AuthService.getUserData();
                commit('SET_USER', response.data.data)
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        },
        async signIn({commit}, data) {
            commit('SET_ERROR', null);

            try {
                const response = await AuthService.auth(data);
                LsUtil.setItem(LS_REFRESH_TOKEN, response.data.data.token)
                if(response.data.data.tempPass) {
                    LsUtil.setItem('LS_TEMP_PASS', true)
                }
                commit('SET_AUTH', response.data.data.token)
                return true
            } catch (e) {
                throw errorFormat(e.response.data.data);
            }
        },
        async signOut({commit}) {
            LsUtil.removeItem(LS_REFRESH_TOKEN)
            commit('SET_AUTH', null)
            commit('SET_USER', {})
        },
        async changePass({ commit }, payload) {
            try {
                const response = await AuthService.setPass(payload);

                await commit('SET_AUTH', response.data.data.token)
                await LsUtil.setItem(LS_REFRESH_TOKEN, response.data.data.token)

                LsUtil.removeItem('LS_TEMP_PASS')
            } catch (e) {
                throw errorFormat(e.response.data);
            }
        }
    },
    mutations: {
        SET_AUTH(state, value) {
            state.token = value
        },
        SET_USER(state, value) {
            state.user = value
        },
        SET_ERROR(state, value) {
            state.error = value
        },
        ADD_AUTO_INCREMENT(state) {
            state.autoIncrement++;
        },
    },
    getters: {
        isAuth(state) {
            return !!state.token
        },
        lang(state) {
            if (state.lang == null)
                return 'ru';
            return state.lang
        },
        user(state) {
            return state.user;
        },
        error(state) {
            return state.error
        },
        autoIncrement: (state) => {
            return state.autoIncrement
        },
    },
}
