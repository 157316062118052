<template>
  <div>
    <v-app>
      <v-card>
        <v-layout>
          <v-main class="sidebar-content sidebar-content--double">
            <div class="card-block">
              <div class="card-block__header">
                <router-link to="/table-clients-page">
                  <div class="card__arrow">
                    <SvgIcon name="arrow-back"/>
                  </div>
                </router-link>
                <h1 class="card__title">Создание клиента</h1>
              </div>
              <div class="card-block__row">
                <div class="card-block__left g-card-block">
                  <ClientCreateForm
                    is-contact-person
                    @createClient="onSubmit"
                  />
                </div>
                <div class="card-block__right" v-if="isAddContactAvailable">
                  <div class="contact-faces">
                    <div class="client-card__subheader">
                      <h1 class="client-card__subtitle">Контактные лица</h1>
                      <div
                        v-if="localContactList?.length < 5"
                        class="client-card__svg pointer"
                        @click="showModalContact"
                      >
                        <SvgIcon name="contact-faces"/>
                      </div>
                    </div>

                    <div class="contact-faces__list">
                      <ContactFacesCard
                        :item="item"
                        v-for="item of localContactList"
                        :key="item"
                        :account="account"
                        @copy="copyContactInfo"
                        @delete="deleteContact"
                        @edit="editContext"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-specifications__wrap">
                <TableSpecifications
                  type="client"
                  class="table-specifications_block"
                />
              </div>
            </div>

            <BaseNoResult type="clients" />
          </v-main>
        </v-layout>
      </v-card>
    </v-app>

    <ContactPersonModal
      v-if="modalContactState"
      :is-open="modalContactState"
      @close="closeModal"
      local-submit
      :local-list="localContactList"
      :auto-increment="autoIncrement"
      :local-current="currentContact"
      @create="createPerson"
      @edit="editContact"
      contact-type="client"
    />

    <AlertModal
      ref="warning"
      :close-button="true"
      name-button="Нет"
    />

    <input class="js-copyInput hidden_input" readonly/>
  </div>
</template>

<script>
import TableSpecifications from '@/components/Clients/TableSpecifications'
import ClientCreateForm from '@/components/Clients/ClientCreateForm';
import SvgIcon from '@/components/base/SvgIcon';
import { mapGetters } from 'vuex';
import ContactPersonModal from '@/components/ClientsModals/ContactPersonModal';
import contactsService from '@/services/contactsService';
import { ref } from 'vue';
import contactContextMenu from '@/hooks/contactContextMenu';
import ContactFacesCard from '@/components/Contacts/ContactFacesCard';
import BaseNoResult from '@/components/base/BaseNoResult';
import AlertModal from '@/components/AlertModal';
import { onBeforeRouteLeave } from 'vue-router';
import _get from 'lodash.get';

export default {
  name: 'ClientCreatePage',
  components: {
    AlertModal,
    BaseNoResult,
    ContactFacesCard,
    ContactPersonModal,
    SvgIcon,
    ClientCreateForm,
    TableSpecifications,
  },
  computed: {
    ...mapGetters({
      contactsList: 'contacts/contactsList',
      account: 'account/account',
      generalPermissions: 'account/getPermissions',
    }),
    isAddContactAvailable() {
      if (!this.generalPermissions) return true;
      return _get(this.generalPermissions, 'contacts.create', false);
    },
  },
  setup() {
    const autoIncrement = ref(1)
    const isSaveData = ref(false)
    const warning = ref(null);

    const {
      copyContactInfo, deleteContact, editContext, closeModal, editContact, currentContact,
      modalContactState, localContactList
    } = contactContextMenu()

    onBeforeRouteLeave((to, from, next) => {
      if (isSaveData.value) {
        return next();
      }
      if (!warning.value) return next(false);
      warning.value.show(
        'Внимание!',
        'Вы не до конца заполнили поля, ваши данные не сохранятся. Уверены, что хотите выйти?', {
          type: 'error',
          nameButton: 'Да',
          color: '#FFA500',
        }, () => {
          isSaveData.value = true;
          return next();
        })
    });

    return {
      isSaveData,
      localContactList,
      autoIncrement,
      modalContactState,
      currentContact,
      warning,
      copyContactInfo,
      deleteContact,
      editContext,
      closeModal,
      editContact
    }
  },
  methods: {
    showModalContact() {
      this.modalContactState = true;
    },
    createPerson(params) {
      this.localContactList.push(params);
      this.autoIncrement++;
    },
    onSubmit(id) {
      if (id) {
        const temp = [];
        this.localContactList.forEach(item => {
          temp.push({
            client_id: id,
            comment: item.comment,
            contact_numbers: item.contact_numbers,
            email: item.email,
            id: item.id,
            name: item.name,
            position: item.position,
            quarry_id: item.quarry_id
          })
        })
        this.isSaveData = true;
        // TODO: работает, но возможно будут проблемы в случае ошибки с сервера
        temp.forEach(item => {
          this.createContact(item)
        })
        this.$router.push('/table-clients-page')
      }
    },
    async createContact(params) {
      try {
        await contactsService.createContact(params)
        let paramsCurrent = {
          client_id: this.pageId,
        }

        if (this.isQuarryModal) {
          paramsCurrent = {
            quarry_id: this.pageId,
          }
        }
        await this.$store.dispatch('contacts/getContacts', paramsCurrent)
        this.closeModal()
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data?.errors)
        const msgErrorParse = msgError.join()
        const errorSplit = msgErrorParse.split('|')
        const textError = errorSplit[0]

        if (error.response.status == 422) {
          const pageLink = errorSplit[1]
          const pageLinkName = pageLink == 'client' ? 'table-clients-page' : 'table-quarry-page'
          const id = errorSplit[2]

          if (id) {
            this.$refs.alertLinkPhone.show(
              'Внимание!',
              textError,
              {
                nameButton: 'Перейти на страницу',
                color: '#FFA500',
              },
              () => {
                this.$router.push({
                  path: `/${pageLinkName}/${id}`,
                })
                this.routLinkPage(`${pageLinkName},${id}`)
                this.closeModal()
              }
            );
          } else {
            const errorText = []
            for (let item in data?.errors) {
              errorText.push(data?.errors[item])
            }
            this.$refs.alert.show('Ошибка', errorText.join(', '), {
              type: 'error',
              nameButton: 'Понятно',
              color: '#FFA500',
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
.g-card-block {
  &.card-block__left {
    display: flex;
    height: 100%;

    .inner-left {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      &__form {
        height: calc(100% - 80px);
        overflow-y: auto;
      }

      &__meta {
        display: flex;
        align-items: center;
        height: 80px;
      }
    }
  }
}
</style>
