export const loadYandexMaps = (settings = {}) => {
  const scriptIsLoaded = new CustomEvent('script:loaded');
  return new Promise((resolve, reject) => {
    if (window.ymaps && window.ymaps.hasOwnProperty('Map')) return resolve();

    if (document.getElementById('ymaps-script')) {
      document.addEventListener('script:loaded', resolve);
      return;
    }

    const yandexMapScript = document.createElement('SCRIPT');
    const {
      apiKey = '',
      lang = 'ru_RU',
      version = '2.1',
      coordorder = 'latlong',
      debug = false,
      enterprise = false,
      suggestApiKey = process.env.VUE_APP_YANDEX_MAP_SUGGEST_API_KEY,
    } = settings;
    const mode = debug ? 'debug' : 'release';
    const settingsPart = `lang=${lang}${apiKey && `&apikey=${apiKey}`}&mode=${mode}&coordorder=${coordorder}&suggest_apikey=${suggestApiKey}`;
    const link = `https://${enterprise ? 'enterprise.' : ''}api-maps.yandex.ru/${version}/?${settingsPart}`;
    yandexMapScript.setAttribute('src', link);
    yandexMapScript.setAttribute('async', '');
    yandexMapScript.setAttribute('defer', '');
    yandexMapScript.setAttribute('id', 'ymaps-script');
    document.head.appendChild(yandexMapScript);
    yandexMapScript.onload = () => {
      // eslint-disable-next-line
      ymaps.ready(() => {
        document.dispatchEvent(scriptIsLoaded);
        resolve();
      });
    };
    yandexMapScript.onerror = reject;
  });
}
