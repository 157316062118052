<template>
  <div class="ya-map">
    <AlertModal ref="alert" />
    <ArrowMapModal ref="arrowMap" />
    <div class="ya-map__btns">
      <button @click="mapZoomIn" class="ya-map__btn">
        <SvgIcon name="map_plus" class="ya-map__icon"/>
      </button>
      <button @click="mapZoomOut" class="ya-map__btn">
        <SvgIcon name="map_minus" class="ya-map__icon"/>
      </button>
    </div>
    <div ref="map" id="map"></div>
    <MapMode />
  </div>
</template>

<script setup>
import { onMounted, watch, nextTick, ref, computed, onUnmounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { loadYandexMaps } from '@/services/yandexMapLoader.js';
import SvgIcon from '@/components/base/SvgIcon.vue';
import AlertModal from '@/components/AlertModal.vue';
import ArrowMapModal from './ArrowMapModal.vue';
import MapMode from './MapMode.vue'
import {
  createClusterBaloonTamplate,
  createObjectIconTamplate,
  createPolylineArrow,
  createSpecificationTamplate,
  setPlacemark,
  multicolors,
} from '@/utils/map/map.js';
import { debounce } from '@/utils/helper.js';

const store = useStore();

const props = defineProps({
  isFilterActive: Boolean,
  isExactSpecification: Boolean,
  isExtendedInfoActive: Boolean,
  isShareMode: Boolean,
})

const emits = defineEmits(['placemark-clicked'])

let interactiveMap = null;
let mapCluster = null;
let placemarksCollection = null;
let polylineCollection = null;
let routesCollection = null;
let iconTemplateLayout = null;
let specificationTemplateLayout = null;
let clusterBalloonLayout = null;
const alert = ref(null);
const arrowMap = ref(null);
const isMapSet = ref(false);



const arrowColor = computed(() => store.getters['interactiveMap/getColorArrowsForSpecs'])
const arrowWidth = computed(() => store.getters['interactiveMap/getWidthArrowsForSpecs'])
const extendedDataId = computed(() => store.getters['interactiveMap/getExtendedDataId'])
const extendedDataType = computed(() => store.getters['interactiveMap/getExtendedDataType'])
const baseSpecification = computed(() => store.getters['interactiveMap/getBaseSpecification'])
const isQuickMetaMode = computed(() => store.getters['interactiveMap/getIsQuickMetaMode'])
const quickMetaRoute = computed(() => store.getters['interactiveMap/getQuickMetaRoute'])
let oldActiveSpecification = null
let oldBaseSpecification = null
let oldBaseMultiroute = null;
let oldSecondMultiroute = null;

const mapZoomIn = () => {
  if (!interactiveMap) return;
  interactiveMap.setZoom(interactiveMap.getZoom() + 1, {
    duration: 300
  });
};
const mapZoomOut = () => {
  if (!interactiveMap) return;
  interactiveMap.setZoom(interactiveMap.getZoom() - 1, {
    duration: 300
  });
}

onMounted(async () => {
  if (interactiveMap) return;
  try {
    isMapSet.value = false
    await loadYandexMaps({ apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY });
    interactiveMap = new ymaps.Map('map', {
      center: [55.76, 37.64],
      zoom: 10,
      controls: []
    }, { autoFitToViewport: 'always' });
    isMapSet.value = true

    // создаем коллекции для работы с уже готовыми маршрутами
    // спецификаций и для предложенных яндексом маршрутов
    polylineCollection = new ymaps.GeoObjectCollection(null);
    routesCollection = new ymaps.GeoObjectCollection(null);
    placemarksCollection = new ymaps.GeoObjectCollection(null);
    interactiveMap.geoObjects.add(placemarksCollection);
    interactiveMap.geoObjects.add(polylineCollection);
    interactiveMap.geoObjects.add(routesCollection);
    // создаем лейауты для кастомных плейсмарков
    iconTemplateLayout = createObjectIconTamplate(ymaps);
    specificationTemplateLayout = createSpecificationTamplate(ymaps);
    clusterBalloonLayout = createClusterBaloonTamplate(ymaps);

    mapCluster = new ymaps.Clusterer({
      groupByCoordinates: false,
      gridSize: 70,
      clusterIconLayout: 'default#pieChart',
      clusterIconPieChartRadius: 20,
      clusterIconPieChartCoreRadius: 12,
      clusterIconPieChartStrokeWidth: 2,
      clusterHideIconOnBalloonOpen: false,
      geoObjectHideIconOnBalloonOpen: false,
      clusterDisableClickZoom: true,
      clusterBalloonPanelMaxMapArea: 0,
      clusterBalloonMaxHeight: 200,
      clusterBalloonContentLayout: clusterBalloonLayout
    });

    document.addEventListener('click', (e) => {
      if (!e.target.classList.contains('baloon-entity')) return;
      const { id, type, addressId } = e.target.dataset;

      emits('placemark-clicked', [id, type, addressId]);
    })
    document.addEventListener('keydown', handleKeyDown)

    setPlacemarks([...clientsList.value, ...quarryList.value, ...specificationList.value])
    interactiveMap.setType(store.getters['interactiveMap/getActiveMapMode'].code)
  } catch (e) {
    alert.value.show(
      'Ошибка',
      'При инициализации карты возникла ошибка, пожалуйста, перезагрузите страницу',
      { nameButton: 'Понятно' },
    )
    throw new Error(e);
  }
})

const adjustMapViewport = () => {
  if (!interactiveMap || !polylineCollection || !mapCluster) return;
  const specificationsBound = polylineCollection.getBounds();
  const placemarksBound = mapCluster.getBounds();
  const options = {
    duration: 600,
    checkZoomRange: true,
  }
  if (!specificationsBound && !placemarksBound) return;
  interactiveMap.setBounds(specificationsBound ?
    specificationsBound :
    placemarksBound, options
  );
}

const refreshCluster = (cluster) => {
  if (!cluster || !placemarksCollection) return;
  cluster.removeAll();
  placemarksCollection.removeAll();
}

const setPlacemarks = (mapData) => {
  if (!interactiveMap) return;
  refreshCluster(mapCluster);
  mapData.forEach(entity => {
    const iconHref = entity.icon ? entity.icon : null;
    const isEntityActive = entity.active === 'Действующий';

    if (entity.type === 'spec') { // плейсмарки для спецификаций
      if (!entity.route || !entity.route.route_line || entity.route.route_line.length < 3) return;

      const route = entity.route.route_line;
      const middleIndex = Math.round(route.length / 2);
      const idPlacemark = setPlacemark(ymaps, entity.route.route_line[middleIndex], {
        id: entity.id,
        contentBody: `Спецификация ${entity.id}`,
        type: 'specs',
      }, {
        iconContentLayout: specificationTemplateLayout,
        iconImageSize: [50, 50],
      });
      const startPlacemark = new ymaps.Placemark(route[0], {
        id: entity.id,
        contentBody: `Начало спецификации ${entity.id}`,
        type: 'specs',
      }, {
        preset: 'islands#brownCircleDotIcon',
        iconColor: '#CE5050',
        iconImageSize: [10, 10],
        iconImageOffset: [-5, -5],
      })

      const endPlacemark = new ymaps.Placemark(route[route.length - 1], {
        id: entity.id,
        contentBody: `Конец спецификации ${entity.id}`,
        type: 'specs',
      }, {
        preset: 'islands#brownCircleDotIcon',
        iconColor: '#4D4D4D',
        iconImageSize: [10, 10],
        iconImageOffset: [-5, -5],
      })

      const specificationPlacemarks = [idPlacemark, startPlacemark, endPlacemark];
      specificationPlacemarks.forEach(placemark => {
        placemark.events.add('click', () => {
          emits('placemark-clicked', [entity.id, 'specs', 0]); // третий параметр - index маршрута
        })
        placemarksCollection.add(placemark)
      })

      return;
    }

    for (const indexedEntity of entity.coordinates) {
      if (!indexedEntity.hasOwnProperty('coordinates') || indexedEntity.coordinates === null) continue;
      const placemark = new ymaps.Placemark(indexedEntity.coordinates, {
        href: iconHref,
        contentBody: `${entity.type === 'quarry' ? 'Карьер' : entity.type === 'client' ? 'Клиент' : 'Порт'} ${entity.id}`,
        type: entity.type,
        id: entity.id,
        isEntityNotActive: !isEntityActive,
        addressId: indexedEntity.address_id,
        opacity: isEntityActive ? 1 : .4,
      }, iconHref ? {
        iconLayout: 'default#imageWithContent',
        iconColor: '#0055E8',
        iconImageHref: iconHref,
        iconImageSize: [20, 20],
        iconImageOffset: [-12, -12],
        iconContentLayout: iconTemplateLayout
      } : {
        preset: 'islands#brownCircleDotIcon',
        iconColor: '#0055E8',
        iconImageSize: [20, 20],
        iconImageOffset: [-10, -10],
      })

      placemark.events.add('click', () => {
        emits('placemark-clicked', [entity.id, entity.type, indexedEntity.address_id]);
      })
      placemarksCollection.add(placemark);
    }
  });

  mapCluster.add(placemarksCollection.toArray());
  interactiveMap.geoObjects.add(mapCluster);
}

// функция, которая рисует ломаные для спек
const setArrowsForSpecs = (specifications, isMultiColor = false, isMetaRouteMode = false) => {
  if (!interactiveMap || !polylineCollection) return;
  polylineCollection.removeAll();
  let counter = 0;
  for (const specification of specifications) {
    if (specification.hasOwnProperty('route') && specification.route.hasOwnProperty('route_line')) {
      const route = specification.route.route_line;
      const color = multicolors[counter] ? multicolors[counter] : '#B34BE0';
      const isSpecComplete = specification.specification_status === 'Выполнено';
      const isMainRoute = specification.request_type === 'Основной маршрут';
      const baseColor = isSpecComplete ? '#DEA1FFFF' : '#B34BE0'
      const customWidth = isMetaRouteMode && (metaRouteMainSpec.value === specification.id) ? '4' : '2';
      const baseWidth = isMainRoute ? '4' : '2';
      const arrow = createPolylineArrow(ymaps, route, {
        id: specification.id,
      }, {
        strokeColor: isMultiColor ? color : baseColor,
        strokeWidth: isMetaRouteMode ? customWidth : baseWidth,
      });
      polylineCollection.add(arrow);
      counter++
    }
  }
}

// функция, которая рисует ломаные маршрутов в мета-маршрутах
const setArrowsForMetaRoutes = (routeLines) => {
  for (const line of routeLines) {
    if (line.hasOwnProperty('coordinates')) {
      const route = line.coordinates;
      const arrow = createPolylineArrow(ymaps, route, {}, {
        strokeColor: 'rgba(176, 176, 176, 0.8)'
      });
      polylineCollection.add(arrow);
    }
  }
}

// функция убирает жирность с выбранной спеки/базовой спеки
const killOldSpecification = (oldSpecification) => {
  if (!oldSpecification) return

  const isSpecComplete = oldSpecification?.specification_status === 'Выполнено';
  const isMainRoute = oldSpecification?.request_type === 'Основной маршрут';

  oldSpecification?.options.set('strokeColor', isSpecComplete ? '#DEA1FFFF' : '#B34BE0');
  oldSpecification?.options.set('strokeWidth', isMainRoute ? '4' : '2');
}

const removePreviousRoutes = () => {
  if (!routesCollection) return;
  store.dispatch('interactiveMap/setMultiRoutes', [])
  routesCollection.removeAll();
}

// рассчитывает и перекрашивает спеки мультирута
const recolorBaseSpec = () => {
  if (oldBaseSpecification) {
    killOldSpecification(oldBaseSpecification)
  }
  if (!baseSpecification.value || !polylineCollection) return

  polylineCollection.each((polyline) => {
    if (polyline.properties.get('id') === baseSpecification.value.id) {
      polyline.options.set('strokeColor', '#e04b4b');
      polyline.options.set('strokeWidth', '5');
      polyline.options.set('zIndex', '9999');

      oldBaseSpecification = polyline
    }
  })
}

const calculateQuickMetaRoute = async (quickMetaRoute) => {
  if (props.isShareMode) return;
  if (!quickMetaRoute || quickMetaRoute.length < 2 || quickMetaRoute.filter(item => item === null).length || !isQuickMetaMode.value) {
    removePreviousRoutes()
    return
  }

  let tmpBase = null
  let tmpSecond = null
  if (!polylineCollection) return
  polylineCollection.each((polyline) => {
    if (oldBaseMultiroute && (polyline.properties.get('id') === oldBaseMultiroute.properties.get('id'))) {
      polyline.options.set('strokeColor', '#B34BE0');
      polyline.options.set('strokeWidth', '2');
      polyline.options.set('zIndex', '9999');
    }
    if (oldSecondMultiroute && (polyline.properties.get('id') === oldSecondMultiroute.properties.get('id'))) {
      polyline.options.set('strokeColor', '#B34BE0');
      polyline.options.set('strokeWidth', '2');
      polyline.options.set('zIndex', '9999');
    }

    if (polyline.properties.get('id') === quickMetaRoute[0].id) {
      polyline.options.set('strokeColor', multicolors[0]);
      polyline.options.set('strokeWidth', '5');
      polyline.options.set('zIndex', '9999');

      tmpBase = polyline
    }
    if (polyline.properties.get('id') === quickMetaRoute[1].id) {
      polyline.options.set('strokeColor', multicolors[1]);
      polyline.options.set('strokeWidth', '5');
      polyline.options.set('zIndex', '9999');

      tmpSecond = polyline
    }
  })

  oldBaseMultiroute = tmpBase
  oldSecondMultiroute = tmpSecond
  await nextTick();
  await getMultiRoute(quickMetaRoute, ['rgba(0,56,255,0.4)', 'rgba(0,56,255,0.6)']);
}

//смешивает быстрый мультирут и список спек
const createSpecList = () => {
  return quickMetaRoute.value ?
      [...quickMetaRoute.value.filter(item => item !== null), ...specificationList.value.filter(item => !quickMetaRoute.value.filter(el => el && el.id === item.id).length)]
      : specificationList.value
}

const getMultiRoute = async (metaRoute, colors = ['#7777B080', '#B34BE0']) => {
  removePreviousRoutes();
  if (metaRoute?.length < 2) return;
  const routesCoordinates = metaRoute.reduce((acc, specification) => {
    if (!specification?.route?.route_line?.length) return acc;
    const specStart = specification.route.route_line[0];
    const specEnd = specification.route.route_line[specification.route.route_line.length - 1];
    acc.push({
      start: specStart,
      finish: specEnd,
    })
    return acc;
  }, []);

  const coordinatesToFindRoutes = routesCoordinates.reduce((acc, coord, index) => {
    if (!routesCoordinates[index + 1]) { // last index
      acc.push([coord.finish, routesCoordinates[0].start])
      return acc;
    }
    acc.push([coord.finish, routesCoordinates[index + 1].start]);
    return acc;
  }, []);

  const multiRoutes = [];

  coordinatesToFindRoutes.forEach(coordinates => {
    const multiRoute = new ymaps.multiRouter.MultiRoute({
      referencePoints: coordinates,
    }, {
      editorDrawOver: false,
      wayPointDraggable: false,
      viaPointDraggable: true,
      editorMidPointsType: 'via',
      viaPointIconRadius: 8,
      viaPointIconFillColor: '#008000',
      viaPointActiveIconFillColor: '#008000',
      routeStrokeColor: colors[0],
      routeActiveStrokeColor: colors[1],
      routeStrokeWidth: 4,
      routeActiveStrokeWidth: 6,
      pinIconFillColor: '#ff0000',
      zoomMargin: 30
    });
    routesCollection.add(multiRoute)
    multiRoute.editor.start({
      dragWayPoints: false,
      addWayPoints: false,
    });
    multiRoute.events.add('update', debouncedCalculation);
    multiRoute.events.add('activeroutechange', debouncedCalculation);
    multiRoutes.push(multiRoute);
  })
  await store.dispatch('interactiveMap/setMultiRoutes', multiRoutes);
}

const debouncedCalculation = debounce(async () => {
  await store.dispatch('interactiveMap/calcMultirouteData');
}, 800)

const clientsList = computed(() => {
  if (isMetaRouteMode.value) return [];
  return store.getters['interactiveMap/getClientList'] || [];
})

const quarryList = computed(() => {
  if (isMetaRouteMode.value) return [];
  return store.getters['interactiveMap/getQuarryList'] || [];
});

const portsList = computed(() => {
  if (isMetaRouteMode.value) return [];
  return store.getters['interactiveMap/getPortsList'] || [];
});

const specificationList = computed(() => {
  if (isMetaRouteMode.value) return [];
  return store.getters['interactiveMap/getSpecificationList'];
});

const metaRouteData = computed(() => {
  return store.getters['interactiveMap/getMetaRouteData'];
})

const isMetaRouteMode = computed(() => store.getters['interactiveMap/getIsMetaRouteMode']);

const metaRouteMainSpec = computed(() => {
  return store.getters['interactiveMap/getMetaRouteMainSpecId'];
})

const sharedMetaRoute = computed(() => {
  return store.getters['interactiveMap/getSharedMetaRoute'];
})

watch(() => props.isFilterActive, async () => {
  if (!interactiveMap) return;
  await nextTick();
  interactiveMap.container.fitToViewport();
})

watch(() => store.getters['interactiveMap/getActiveMapMode'], () => {
  interactiveMap.setType(store.getters['interactiveMap/getActiveMapMode'].code)
})

watch(() => clientsList.value, async () => {
  if (props.isShareMode) return;
  const specsArray = createSpecList()
  setPlacemarks([...clientsList.value, ...quarryList.value, ...specsArray, ...portsList.value])
  await nextTick();
  adjustMapViewport();
}, {
  immediate: true,
})

watch(() => portsList.value, async () => {
  if (props.isShareMode) return;
  const specsArray = createSpecList()
  setPlacemarks([...clientsList.value, ...quarryList.value, ...specsArray, ...portsList.value])
  await nextTick();
  adjustMapViewport();
}, {
  immediate: true,
})

watch(() => [specificationList.value, isMapSet.value, quickMetaRoute.value], async (val) => {
  if (props.isShareMode) return;

  if (!specificationList.value || !isMapSet.value || metaRouteData.value.length) return
  const specsArray = createSpecList()
  setArrowsForSpecs(specsArray)
  setPlacemarks([...clientsList.value, ...quarryList.value, ...specsArray, ...portsList.value])
  await nextTick();
  adjustMapViewport();
  recolorBaseSpec()
  await calculateQuickMetaRoute(quickMetaRoute.value)
}, {
  immediate: true
})

watch(() => quarryList.value, async () => {
  if (props.isShareMode) return;
  const specsArray = createSpecList()
  setPlacemarks([...clientsList.value, ...quarryList.value, ...specsArray, ...portsList.value])
  await nextTick();
  adjustMapViewport();
}, {
  immediate: true,
})

//рисует основной мультирут
watch(() => metaRouteData.value, async () => {
  if (props.isShareMode) return;

  setArrowsForSpecs(metaRouteData.value, true, true);
  setPlacemarks(metaRouteData.value);
  await nextTick();
  await getMultiRoute(metaRouteData.value);
})

watch(() => sharedMetaRoute.value, () => {
  if (!props.isShareMode) return;
  setArrowsForSpecs(sharedMetaRoute.value.specs, true, true);
  setPlacemarks(sharedMetaRoute.value.specs);
  setArrowsForMetaRoutes(sharedMetaRoute.value.line);
  adjustMapViewport();
})

// отслеживаем изменение основной спеки и делаем её жирной
watch(() => metaRouteMainSpec.value, () => {
  polylineCollection.each((polyline) => {
    if (polyline.properties.get('id') === metaRouteMainSpec.value) {
      polyline.options.set('strokeWidth', 6);
    } else {
      polyline.options.set('strokeWidth', 3);
    }
  })
})


watch(() => extendedDataId.value, () => {
  if (!extendedDataId.value) {
    killOldSpecification(oldActiveSpecification)

    return
  }
  let item = null;

  const list = extendedDataType.value === 'specs' ? specificationList.value : extendedDataType.value === 'quarry' ? quarryList.value : extendedDataType.value === 'clients' ? clientsList.value : portsList.value

  const itemIndex = list.findIndex(el => el.id === Number(extendedDataId.value))
  item = list[itemIndex]

  if (item) {
    if (oldActiveSpecification) {
      killOldSpecification(oldActiveSpecification)
    }

    if (!quickMetaRoute.value.filter(spec => spec && spec.id === item.id)?.length)
      polylineCollection.each((polyline) => {
        if (polyline.properties.get('id') === item.id) {
          polyline.options.set('strokeColor', arrowColor.value);
          polyline.options.set('strokeWidth', arrowWidth.value);

          oldActiveSpecification = polyline
        }
      })
  }
})

onBeforeUnmount(async () => {
  await store.dispatch('interactiveMap/resetMapData');
  polylineCollection?.removeAll();
  routesCollection?.removeAll();
  placemarksCollection?.removeAll();
})

const handleKeyDown = (event) => {
  if (event.code === 'KeyB' && (event.ctrlKey || event.metaKey)) {
    arrowMap.value.show()
  }
}

</script>
<style lang="scss">
  .ya-map {
    margin: 0 15px;
    position: relative;
    width: calc(100% - 30px);

    #map {
      width: 100%;
      height: calc(100% - 15px);
    }

    &__btns {
      z-index: 10;
      position: absolute;
      transform: translate(0, -50%);
      right: 48px;
      top: 50%;
      display: flex;
      flex-direction: column;
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin: 10px;
    }

    &__btn {
      width: 43px;
      height: 43px;
      background: white;
      color: #0a2333;
    }
  }
</style>
