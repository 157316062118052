<template>
  <v-tooltip v-bind="tooltipOptions">
    <slot name="tooltip-content">
      {{ text }}
    </slot>
  </v-tooltip>
</template>

<script setup>
import { computed } from 'vue';
import { enumLocation } from '@/utils/common/enums.js';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  position: {
    type: String,
    default: enumLocation.Bottom,
  },
  delay: {
    type: [String, Number],
    default: 1000,
  },
})

const tooltipOptions = computed(() => {
  return {
    'location': props.position,
    'activator': 'parent',
    'open-delay': props.delay,
  }
})
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

</style>