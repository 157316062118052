<template>
  <div
    v-click-outside="handleFocusOut"
    class="base-dropdown__wrapper"
    @click.stop="checkOpen"
    :class="{ error: isError && !isDisabled, 'is-right': isRight }"
  >
    <p class="base-dropdown__caption" v-if="title">{{ title }}</p>
    <div class="base-dropdown">
      <div
        ref="selectInput"
        class="base-dropdown__block"
        :class="{ 'base-dropdown__block_disabled': isDisabled }"
      >
        <input
          type="text"
          :required="required"
          @input="filterList"
          v-model="searchText"
          :placeholder="placeholder"
          class="base-dropdown__field"
          :class="{ selected: isSelected }"
          :disabled="noSearch || isDisabled"
        />
        <div class="base-dropdown__btn" :class="{ rotate: isDropdown && !isDisabled && isRotate }">
          <SvgIcon :name="dropdownIcon" />
        </div>
      </div>
      <Teleport to="body">
        <div
          v-if="isDropdown && !isDisabled"
          class="base-dropdown__list"
          :class="{ 'base-dropdown__list-width': isWidth }"
          @click.stop
          :style="{top: (top + 5) + 'px', left: left + 'px', width: width + 'px'}"
        >
          <div
            class="base-dropdown__item"
            :class="{
              'base-dropdown__item-width': isWidth,
              'base-dropdown__item-size': isDropdown,
              'base-dropdown__item-icon': itemIcon
            }"
            v-for="item in filtered"
            :key="`dropdown_${item.id}`"
            @click="changeActive(item)"
          >
            <span class="base-dropdown__item-inner">{{ item.name }}</span>
            <div v-if="itemIcon" class="base-dropdown__icon" @click.stop="clickItem(item.id)">
              <SvgIcon :name="itemIcon"/>
            </div>
          </div>

          <div v-if="specialBtnTitle" class="base-dropdown__special-button" @click="specialClick">
            {{ specialBtnTitle }}
          </div>
        </div>
      </Teleport>
      <div v-if="errors.length > 0 && !isDisabled">
        <div class="input-errors" v-for="error of errors" :key="error.$uid">
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue';
import { debounce } from '@/utils/helper';

export default {
  name: 'BaseDropdown',
  components: { SvgIcon },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isWidth: {
      type: Boolean,
      default: false,
    },
    modelValue: [String, Number, Boolean],
    errors: {
      type: [Array],
      default: () => [],
    },
    error: {
      type: Array,
      default() {
        return [
          {
            status: false,
            text: '',
          },
        ];
      },
    },
    isError: {
      type: [Boolean, Number],
      default: false,
    },
    noSearch: {
      type: Boolean,
      default: false
    },
    isRight: {
      type: Boolean,
      default: false
    },
    specialBtnTitle: {
      type: String,
      default: ''
    },
    itemIcon: {
      type: String,
      default: null
    },
    dropdownIcon: {
      type: String,
      default: 'arrow_drop_down'
    },
    isRotate: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      filtered: this.list,
      searchText: null,
      isDropdown: false,
      isSelected: false,
      top: 0,
      left: 0,
      width: 0,
      selectPositionDebounce: null
    };
  },
  created() {
    if (this.modelValue != null) {
      let selectItem = this.list.filter(
        (value) => value.id === this.modelValue
      );
      if (selectItem.length > 0) {
        this.searchText = selectItem[0].name;
        this.isSelected = true;
      }
    }
    this.selectPositionDebounce = debounce(this.selectPosition, 5)
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll, true);
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  watch: {
    list: function (val) {
      this.filtered = val;
      this.setTextAndValue(this.modelValue);
    },
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.setTextAndValue(newValue);
      },
    },
  },
  methods: {
    handleScroll () {
      this.selectPositionDebounce();
    },
    handleFocusOut() {
      this.isDropdown = false;
      this.$emit('close');
    },
    selectPosition() {
      if(this.$refs.selectInput != null) {
        this.left = this.$refs.selectInput.getBoundingClientRect().left;
        this.top = this.$refs.selectInput.getBoundingClientRect().bottom;
        this.width = this.$refs.selectInput.getBoundingClientRect().width;
      }
    },
    setTextAndValue(value) {
      if (value != null) {
        let selectItem = this.list.filter((listItem) => listItem.id === value);
        if (selectItem.length > 0) {
          this.searchText = selectItem[0].name;
          this.isSelected = true;
        }
      } else {
        this.searchText = ''
      }
    },
    filterList() {
      this.isSelected = false;
      if (this.searchText.length) {
        let text = this.searchText.toLowerCase();
        this.filtered = this.list.filter((item) => {
          let resText = item.name.toLowerCase();
          return resText.indexOf(text) !== -1;
        });
      } else {
        this.filtered = this.list;
      }
      // this.filtred = this.filtred.filter((item, index) => index < 5)
    },
    changeActive(val) {
      this.$emit('update:modelValue', val.id);
      this.setTextAndValue(this.modelValue);
      this.isDropdown = false;
      this.searchText = val.name;
      this.isSelected = true;
    },
    checkOpen() {
      if (!this.isDisabled) {
        if (this.isDropdown)
          this.isDropdown = false;
        else {
          this.isDropdown = true;
          this.selectPosition();
        }
      }
    },
    clickItem(id) {
      this.$emit('clickItem', id)
    },
    specialClick() {
      this.$emit('specialClick')
    }
  },
  computed: {
    checksStatus() {
      return this.error.filter((error) => error.status).length > 0;
    },
    errorString() {
      return this.error
        .filter((error) => error.status)
        .map((error) => error.text)
        .join('. ');
    },
  },
};
</script>

<style lang="scss">
.base-dropdown {
  &__list {
    position: absolute;
    width: 100%;
    top: 48px;
    z-index: 111;
    border-radius: 12px;
    overflow: hidden;
    max-height: 245px;
    overflow-y: auto;
    box-shadow: 7px 8px 15px #a7a7a761;
    background: #ffffff;
  }
  &__special-button {
    font-size: 14px;
    padding: 13px 20px;
    color: #808191;
    cursor: pointer;
  }
  &__item {
    background-color: #ffffff;
    cursor: pointer;
    padding: 13px 20px;
    font-size: 10px;
    line-height: 20px;
    color: #808191;
  }

  &__item-size {
    font-size: 14px;
  }

  &__item-icon {
    display: flex;
    align-items: center;

    .base-dropdown__item-inner {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    margin-left: auto;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
</style>

<style lang="scss" scoped>
.base-dropdown__wrapper {
  &.error .base-dropdown__block {
    border: 1px solid red;
  }

  &.is-right {
    .base-dropdown__block {
      border-radius: 0 6px 6px 0;
    }
  }

  .input-errors {
    display: none;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #D72028;
    margin-top: 4px;
  }


  .base-dropdown {
    position: relative;

    &__input-error {
      color: #222222;
      margin-top: 8px;
    }

    &__sub-description {
      color: #222222;
      margin-top: 10px;
    }


    &__list-width {
      width: 100%;
    }

    &__btn {
      transform: rotate(0);
      transition: 0.3s;
      display: flex;
      align-items: center;

      &.rotate {
        transform: rotate(-180deg);
        transition: 0.3s;
      }
    }

    &__field {
      flex-grow: 1;
      padding: 0;
      width: 100%;
      font-size: 12px;
      cursor: pointer;
      height: 100%;

      &:active,
      &:focus,
      &:focus-visible,
      &:focus-within {
        border: none !important;
      }

      &.selected {
        font-weight: 700;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #0a2333;
      }
    }

    &__block {
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 14px;
      border-radius: 6px;
      border: 1px solid #efefef;
      background-color: #ffffff;

      &_disabled {
        background: #f0f0f0;

        &:hover {
          cursor: default;
        }

        .base-dropdown__field {
          cursor: default;
        }
      }
    }

    &__caption {
      margin-bottom: 8px;
      font-weight: 500;
    }
  }
}

.error .input-errors {
  display: block;
}

.bold {
  font-weight: 700;
}

.size-medium {
  .base-dropdown__block {
    height: 50px;
    font-size: 14px;
    border-color: #EFEFEF;
  }

  .base-dropdown__field,
  .base-dropdown__field::placeholder {
    font-size: 14px;
  }
}

.errors-static {
  .input-errors {
    position: static;
  }
}

.dropdown-filters {
  .base-dropdown {
    &__special-button,
    &__item {
      padding: 7px 20px;
      border-bottom: 1px solid rgba(224, 224, 224, 0.25);

      span {
        max-width: 100%;
        overflow: hidden;
        height: 20px;
      }

      &:hover {
        background-color: #F6F6F6;
      }
    }

    &__special-button {
      border-bottom: none;
    }

    &__btn {
      width: 10px;
      right: -6px;
      position: relative;
    }
  }
}


@media (min-width: 1025px) {
  .base-dropdown__wrapper {
    width: 100%;
  }
}
</style>
