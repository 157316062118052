/**
 * @param { string } coords - координаты в строковом формате
 * @param { string } fallbackValue - дефолтное значение
 * @param { object } options - опции
 * @returns { Promise } - адрес в строковом формате
 */
export const geocode = async  (coords, fallbackValue, options) => {
  const api = process.env.VUE_APP_YANDEX_MAP_API_KEY
  if (!api || !coords) return fallbackValue;

  const returnedResult = options?.returnedResult ? options.returnedResult : 'address'

  const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${api}&sco=latlong&format=json&geocode=${coords}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (returnedResult === 'coords') {
      return data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ')?.reverse()?.join() || []
    }

    if (returnedResult === 'address-item') {
      const city = data.response.GeoObjectCollection.featureMember[0].GeoObject
      return {
        region: city.metaDataProperty.GeocoderMetaData.Address.Components[2].name,
        country: city.metaDataProperty.GeocoderMetaData.Address.Components[0].name,
        coordinates: city.Point.pos.split(' ')?.reverse()?.map(el => Number(el)) || [],
      }
    }

    return data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.AddressDetails.Country.AddressLine
  } catch (error) {
    throw new Error('Yandex geocoder failed to calculate coordinates');
  }
}
