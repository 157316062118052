<template>
  <div>
    <div class="specifications-header">
      <div class="content-header">
        <h1 class="content-title">Спецификации</h1>
        <button
          v-if="permissions.create"
          @click="addSpecification"
          class="add-button"
        >
          <SvgIcon name="plus" class="button-plus" />
          Добавить Спецификацию
        </button>
      </div>
    </div>

    <div class="menu">
      <div class="sorting-search-favorites">
        <div :class="['favorites', { 'is-filled': hasLocalFilterSaved }]" @click="toggleFilter">
          <SvgIcon v-if="hasLocalFilterSaved" name="sortingWhite" />
          <SvgIcon v-else name="sorting" />
          <BaseTooltip text="Открыть/скрыть фильтры" />
        </div>
        <DashboardSearch
          @update-search="updatePage"
          ref="search"
          erase-tooltip="Очистить все"
        />
      </div>
      <PaginationComponent
        :total-pages="specificationsPagesCount"
        @update-page="updatePage"
      />
      <SpecificationHistory
        :specificationsPerPage="specificationsPerPage"
      />
      <div class="settings-select">
        <PerPageSelect
          :items="['20', '50', '100']"
          @update-select="changePerPage"
          :current-per-page="specificationsPerPage"
        />
      </div>
      <div class="download-settings">
        <SvgIcon
          v-if="this.account.role === 'administrator'"
          @click="downloadXlsx"
          name="download"
          class="download-settings__img"
        />
        <BaseTooltip text="Скачать файл с отображаемыми на экране спецификациями" />
      </div>
      <div class="download-settings">
        <SvgIcon
          name="settings"
          class="download-settings__img"
          @click="showModalSettings"
        />
        <BaseTooltip text="Настройки отображения дашборда" />
      </div>
    </div>
  </div>
  <AlertModal ref="alert" :nameButton="'Понятно'"/>
  <AlertModal ref="alertSelect" :close-button="true"/>
  <BaseTableSettingsModal
    :is-open="modalSettingsState"
    @close="modalSettingsState = false"
    :schemas="specificationsHeaderFull.schemas"
    :schemas-active="specificationsHeader.schemas"
    @on-submit="onSubmitSettings"
  />
</template>

<script>
import specificationService from '@/services/specificationService';
import { saveAs } from 'file-saver';
import AlertModal from '@/components/AlertModal';
import SvgIcon from '@/components/base/SvgIcon';
import { mapGetters } from 'vuex';
import { isEmptyObject } from '@/utils/helper';
import { LOGISTIAN, COORDINATOR, ADMINISTRATOR } from '@/main';
import PaginationComponent from '@/components/Dashboard/PaginationComponent.vue';
import BaseTableSettingsModal from '@/components/base/BaseTableSettingsModal'
import PerPageSelect from '@/components/Dashboard/PerPageSelect.vue';
import DashboardSearch from '@/components/Dashboard/DashboardSearch.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import SpecificationHistory from './SpecificationHistory.vue';

export default {
  name: 'SpecificationsHeader',
  data() {
    return {
      isActiveSort: false,
      sort: [],
      sortValue: {},
      LOGISTIAN: LOGISTIAN,
      COORDINATOR: COORDINATOR,
      ADMINISTRATOR: ADMINISTRATOR,
      modalSettingsState: false,
      statePreloader: false,
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      specificationsHeader: 'specifications/specificationsHeaderList',
      specificationsHeaderFull: 'specifications/specificationsHeaderListFull',
      specificationsPagesCount: 'specifications/specificationsPagesCount',
      specificationsPerPage: 'specifications/specificationsPerPage',
      tempSpecificationTableHeader: 'specifications/tempSpecificationTableHeader',
      isSearchMode: 'specifications/getIsSearchMode',
      generalPermissions: 'account/getPermissions',
      isHistory: 'specifications/getIsHistory',
      specificationLocalState: 'filter/specificationLocalString',
    }),
    searchQuery() {
      return this.$refs.search.searchValue
    },
    hasLocalFilterSaved() {
      return this.specificationLocalState !== '';
    },
    permissions() {
      if (!this.generalPermissions || !this.generalPermissions['specs']) return [];
      return this.generalPermissions['specs'];
    },
  },
  props: {
    sortSchemas: {
      type: Array,
    },
    isPreloaderActive: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['toggle-filter'],
  components: {
    BaseTooltip,
    DashboardSearch,
    AlertModal,
    SvgIcon,
    BaseTableSettingsModal,
    PaginationComponent,
    PerPageSelect,
    SpecificationHistory,
  },
  methods: {
    async updatePage(query) {
      try {
        this.$store.dispatch('specifications/getSpecifications', {
          ...this.$route.query,
          ...query,
          page: query.page,
          per_page: this.specificationsPerPage,
        });

        this.$router.push({ query: { ...this.$route.query, ...query } })
      } catch (error) {
        const data = error.response;

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            type: 'error',
            nameButton: 'Понятно',
            color: '#FFA500',
          });
        }
      }
    },

    showModalSettings() {
      this.modalSettingsState = true
    },

    async onSubmitSettings(items) {
      this.statePreloader = true

      const tempList = [];
      const tempHeader = [];

      this.tempSpecificationTableHeader.forEach(item => {
        tempHeader.push({
          name: item.split('?')[0],
          width: item.split('?')[1] ? item.split('?')[1] : undefined
        })
      })

      items.forEach(item => {
        let index = tempHeader.findIndex(el => el.name === item);

        if (index !== -1) {
          let el = tempHeader[index];
          tempList.push(`${el.name}${el.width ? '?' + el.width : ''}`)
        } else {
          tempList.push(item)
        }
      })

      try {
        const params = { columns: tempList }

        const { data } = await specificationService.specificationAddColumns(
          params
        )


        await this.getSpecificationsHeaderList(this.$route.query)
        await this.getSpecificationsList(this.$route.query)
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data.errors)
        const msgErrorParse = msgError[0].join()
        const textError = msgErrorParse

        this.$refs.alert.show('Ошибка', textError, {
          type: 'error',
          nameButton: 'Понятно',
          color: '#FFA500',
        })
      } finally {
        this.statePreloader = false
      }
    },

    async addSpecification() {
      this.$store.dispatch('specifications/clearSpecificationDetail', {})
      this.$router.push({ name: 'CreateSpecificationPage' })
    },

    async getSpecificationsXlsx(params) {
      try {
        const { data } = await specificationService.getSpecificationsXlsx(
          params
        )
        this.isDisabled = true

        saveAs(data, 'file_specifications.xlsx');
      } catch (e) {
        this.$refs.alert.show('Ошибка', e, {
          type: 'error',
          nameButton: 'Понятно',
          color: '#FFA500',
        })
      }
    },

    downloadXlsx() {
      this.$refs.alertSelect.show(
        'Экспорт',
        'Информация скачается в соответствии с настроенными фильтрами',
        {
          nameButton: 'Скачать',
          color: '#FFA500',
        },
        () => {
          this.getSpecificationsXlsx(this.$route.query)
        }
      )
    },

    toggleFilter() {
      this.$emit('toggle-filter')
    },

    async getSpecificationsHeaderList(params) {
      try {
        await this.$store.dispatch(
          'specifications/getSpecificationsHeader',
          params
        )
      } catch (e) {
        this.$refs.alert.show(this.$t('alert.error'), e, {
          type: 'error',
          nameButton: 'Ок',
        })
      }
    },

    async getSpecificationsList(params) {
      try {
        await this.$store.dispatch('specifications/getSpecifications', params)
      } catch (error) {
        const data = error.response

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            type: 'error',
            nameButton: 'Ок',
            color: '#FFA500',
          })
        }
      }
    },
    async changePerPage(value) {
      let query = { ...this.$route.query, per_page: value };
      delete query.page
      await this.$router.push({ query });

      await this.$store.dispatch('specifications/getSpecifications', query);
    }
  },
  watch: {
    isPreloaderActive(newValue) {
      this.statePreloader = newValue;
    }
  }
}
</script>

<style lang="scss">
.specifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.specifications-header__buttons {
  display: flex;
  margin-right: 8px;
}

.markup-btn {
  background: #0055e8;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.markup-btn__grid {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ffa500;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sorting-wrap {
  position: relative;
  cursor: pointer;
}

.sorting-content-block {
  display: none;
  position: absolute;
  background: #f7f7f7;
  padding: 12px;
  z-index: 1;
  width: 215px;
  height: 300px;
  overflow-x: auto;
  border-radius: 6px;
  margin-top: 5px;
}

.active {
  display: block;
}

.menu {
  margin-bottom: 14px;
}

.v-btn--icon.v-btn--density-default {
  display: block;
}

.is_hostory_checkbox {
  span {
    font-size: 14px;
  }
}

</style>
