<template>
  <div class="checkbox-day">
    <input
      :type="type"
      :id="'checkbox-day' + uid"
      :name="name"
      @input="(event) => $emit('update:modelValue', event.target.checked)"
      :checked="modelValue"
    />
    <label :for="'checkbox-day' + uid"
      ><span class="checkbox-day__label">{{ checkboxName }}</span></label
    >
  </div>
</template>

<script>
import { getCurrentInstance } from "vue"
export default {
  name: "BaseCheckboxDay",
  setup() {
    const uid = getCurrentInstance().uid

    return {
      uid: uid,
    }
  },
  props: {
    name: {
      type: String,
    },
    type: {
      type: String,
      default: "checkbox",
    },
    id: {
      type: String,
    },
    checkboxName: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [Boolean, String],
      default: false,
    },
  },
}
</script>

<style lang="scss">
.checkbox-day {
  position: relative;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 1px;
  }

  &:first-child {
    label {
      border-radius: 8px 0px 0px 8px;
    }
  }

  &:last-child {
    label {
      border-radius: 0 8px 8px 0;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked + label {
      background-color: #0055e8;
      color: #fff;
    }
  }

  label {
    width: 30px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
