import accountService from '@/services/accountService';
import { handleError } from '@/utils/api/handleError';

export default {
  namespaced: true,
  state: {
    account: {},
    permissions: null,
    cells: [],
  },
  actions: {
    async getAccount({ commit }) {
      try {
        const data = await accountService.getAccount();
        commit('SET_ACCOUNT', data);
      } catch (e) {
        handleError(e);
      }
    },
    async getPermissionList({ commit }) {
      try {
        const permisions = await accountService.getPermisionList()
        commit('SET_PERMISSIONS', permisions.data);
      } catch (e) {
        throw new Error('Ошибка получания списка прав');
      }
    },
    async getValidCells({ commit }) {
      try {
        const data = await accountService.getValidCells();
        commit('SET_VALID_CELLS', data);
      } catch (e) {
        handleError(e);
      }
    },
  },
  getters: {
    account(state) {
      return state.account;
    },
    cells(state) {
      return state.cells;
    },
    getPermissions(state) {
      return state.permissions
    }
  },
  mutations: {
    SET_ACCOUNT(state, value) {
      state.account = value.data.data;
    },
    SET_PERMISSIONS(state, value) {
      state.permissions = value;
    },
    SET_VALID_CELLS(state, value) {
      state.cells = value.data.data.cells;
    },
  },
};
