<template>
  <AlertModal ref="alert" />
  <div class="meta-route">
    <BaseDropDownExtended
      v-if="!isShareMode"
      :taggable="true"
      v-model="metaRouteSpecifications"
      :is-loading="isSearchHintLoading"
      :options="dynamicOptions"
      track-by="id"
      label="id"
      class="meta-route__search"
      @search-updated="(searchQuery) => getSearchHintData(searchQuery)"
    />
    <div v-if="isNotEnoughSpecifications && !isShareMode" class="meta-route__disclaimer">
      Добавьте от 2 до 4 спецификаций, чтобы создать мета-маршрут
    </div>
    <MetaRouteDataWrapper
      v-else
      :meta-route-specifications="metaRouteSpecifications"
      :is-share-mode="isShareMode"
      :main-spec="mainSpec"
      :multiroute-detailed-data="multirouteDetailedData"
      @update:main-spec="handleMainSpecChange"
      @update:specs-order="updateSpecsOrder"
    />
    <div class="meta-route__controls">
      <BaseButton class="meta-route__calculate" v-show="!isShareMode" @click="saveAndUpdateMetaRoute">
        Сохранить мета-маршрут
      </BaseButton>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import BaseDropDownExtended from '@/components/base/BaseDropDownExtended.vue';
import filterService from '@/services/filterService';
import AlertModal from '@/components/AlertModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import MetaRouteDataWrapper from './MetaRouteDataWrapper.vue';

const store = useStore();
const props = defineProps({
  isShareMode: Boolean,
})

const isQuickMetaRouteMode = computed(() => store.getters['interactiveMap/getIsQuickMetaMode'])

const multirouteDetailedData = computed(() => {
  return store.getters['interactiveMap/getMultirouteDetailedData'];
})

const mainSpec = computed({
  get: () => {
    return store.getters['interactiveMap/getMetaRouteMainSpecId'];
  },
  set: (newId) => {
    store.dispatch('interactiveMap/setMetaRouteMainSpecId', newId);
  },
});
const handleMainSpecChange = (id) => {
  mainSpec.value = id;
}
const metaRouteSpecifications = computed({
  get: () => {
    if (props.isShareMode) {
      return sharedMetaRouteSpecs.value;
    }
    return store.getters['interactiveMap/getMetaRouteData']
  },
  set: (newValue) => {
    if(newValue.length > 4) {
      fireException();
      return;
    }
    store.dispatch('interactiveMap/setMetaRouteData', newValue)
  }
})
const dynamicOptions = ref([]);
const isSearchHintLoading = ref(false)
const alert = ref(null);
const isNotEnoughSpecifications = computed(() => metaRouteSpecifications.value.length < 2);
const emits = defineEmits(['calculate-route'])

const updateSpecsOrder = (specs) => {
  store.dispatch('interactiveMap/setMetaRouteData', specs);
}

const saveAndUpdateMetaRoute = () => {
  store.dispatch('interactiveMap/calculatePolylines');
}

const sharedMetaRouteSpecs = computed(() => {
  if (!props.isShareMode) return [];
  const route = store.getters['interactiveMap/getSharedMetaRoute']
  if (!route || !route.hasOwnProperty('specs')) return [];
  return route.specs
})

const query = computed(() => {
  return metaRouteSpecifications.value.map(specification => specification.id).join(',')
})

const fireException = () => {
  alert.value.show(
    'Внимание!',
    'Нельзя создать мета-маршрут больше, чем с четырьмя спецификациями',
    { nameButton: 'Ок' },
  )
}

const getSearchHintData = async (searchString) => {
  if (!searchString) return;
  try {
    isSearchHintLoading.value = true
    const url = new URL('http://alfadon.dev.amiga.agency/api/v1/specs/search');
    const query = `${process.env.VUE_APP_API_URL}${url.pathname}/${searchString}${url.search}`;
    const res = await filterService.getFilterSearchHintData(query);
    dynamicOptions.value = res.data.data;
  } catch(e) {
    console.error('Ошибка загрузки хинтинга')
    dynamicOptions.value = [];
  } finally {
    isSearchHintLoading.value = false
  }
}

onMounted(async () => {
  await store.dispatch('interactiveMap/setIsMetaRouteMode');

  const specs = store.getters['interactiveMap/getQuickMetaRoute'];
  await store.dispatch('interactiveMap/resetMapData');

  if (!specs) return;
  metaRouteSpecifications.value = specs.filter(item => !!item);
})

onUnmounted(() => {
  store.dispatch('interactiveMap/setMetaRouteData', []);
  store.dispatch('interactiveMap/setIsMetaRouteMode', false);
})
</script>
<style scoped lang="scss">
.meta-route {
  padding: 20px 18px 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);

  &__search {
    margin-bottom: 12px;
  }

  &__wrapper {
    flex: 1;
    overflow-y: scroll;
  }

  &__spec-id {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-right: 8px;
  }

  &__spec-delete {
    width: 20px;
    height: 20px;
  }

  &__order {
    flex: 1;
    display: flex;
    justify-content: end;
  }

  &__order-up,
  &__order-down {
    width: 20px;
    height: 20px;
  }

  &__order-up {
    transform: rotate(180deg);
  }

  &__disclaimer {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #102F43;
    flex: 1;
    overflow-y: scroll;
  }

  &__calculate {
    background: #ffa500;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    box-shadow: inset -1px 0px 0px rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    border: none;
    height: 35px;
  }
}


</style>
