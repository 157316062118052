<template>
  <form :class="['search-wrap', { 'is-dashboard': isDashboardSearch }]" @submit.prevent="submitForm">
    <input ref="search" type="search" placeholder="Поиск" v-on:input="isCleared" class="search"/>
    <button class="search-icon">
      {{ isDashboardSearch ? 'Найти' : '' }}
      <BaseTooltip text="Поиск"/>
    </button>
    <template v-if="searchValue">
      <button class="search-clear" @click="clearForm">
        <BaseTooltip :text="eraseTooltip" v-if="eraseTooltip?.length"/>
      </button>
    </template>
  </form>
</template>

<script>
import BaseTooltip from '@/components/base/BaseTooltip.vue';

export default {
  name: 'BaseSearch',
  components: { BaseTooltip },
  props: {
    isDashboardSearch: {
      type: Boolean,
      default: false
    },
    eraseTooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchValue: null
    }
  },
  methods: {
    submitForm() {
      this.$emit('on-submit', this.$refs.search.value)
    },
    clearForm() {
      this.$refs.search.value = ''
      this.searchValue = this.$refs.search.value
      this.submitForm()
    },
    isCleared() {
      this.searchValue = this.$refs.search.value
      if (!this.$refs.search.value) this.submitForm()
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 14px 30px 14px 30px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0a2333;

  &::placeholder {
    font-size: 14px;
  }

  &-wrap {
    position: relative;
  }

  &-icon {
    background-image: url("~@/assets/img/search-grey.svg");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 40px;
  }

  &-clear {
    background-image: url("~@/assets/img/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    opacity: 0.5;
  }

  &:hover {
    border: 1px solid #a6a6a6;
  }

  &:active, &:focus {
    border-color: #0055e8;
    outline: none;
  }

  &-wrap.is-dashboard {

    .search {
      padding: 10px 100px 10px 15px;
    }

    .search-icon {
      left: unset;
      right: 4px;
      background: #0055e8;
      border-radius: 4px;
      color: #FFFFFF;
      font-size: 12px;
      width: unset;
      padding: 0 13px;
      height: calc(100% - 8px);
      top: 50%;
      transform: translateY(-50%);
    }

    .search-clear {
      right: 60px;
    }
  }
}
</style>
