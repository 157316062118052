<template>
  <div class="inner-left">
    <form class="inner-left__form" @submit.prevent="onSubmit">
      <ValidateEach
        v-for="(item, index) in collection"
        :key="index"
        :state="item"
        :rules="rules"
      >
        <template #default="{ v }">
          <BaseModalInput
            class="input-group-item"
            label="Наименование карьера"
            placeholder="Введите наименование карьера"
            v-model="v.name.$model"
            :errors="v.name.$errors"
          />

          <div class="modal-input-name">Статус карьера</div>
          <div class="flex-block mb-2">
            <div class="form_radio">
              <input
                id="radio-status"
                type="radio"
                v-model="statusQuarry"
                :value="'Действующий'"
              />
              <label for="radio-status">
                <span class="radio-name">Действующий</span>
              </label>
            </div>
            <div class="form_radio ml-6">
              <input
                id="radio-status-2"
                type="radio"
                v-model="statusQuarry"
                :value="'Недействующий'"
              />
              <label for="radio-status-2">
                <span class="radio-name">Недействующий</span>
              </label>
            </div>
          </div>

          <BaseModalInput
            class="input-group-item"
            label="ИНН"
            placeholder="Введите ИНН"
            @input="(value) => checkInn(value, v.inn, index, item)"
            v-model="v.inn.$model"
            :errors="v.inn.$errors"
            :input-mask="{
              mask: 'HHHHHHHHHHHH',
              tokens: { H: { pattern: /[0-9]/ } },
            }"
          />

          <div class="modal__info-block">
            <p class="modal__info-row" v-if="v.client_name.$model"><span>{{ v.client_name.$model }},</span></p>
            <p class="modal__info-row" v-if="v.registration_date.$model">
              <span>Дата регистрации: {{ v.registration_date.$model ? v?.registration_date.$model : '' }},</span></p>
            <p class="modal__info-row" v-if="v.head.$model">
              <span>Генеральный директор: {{ v?.head.$model ? v?.head.$model : '' }}</span></p>
            <p class="modal__info-row" v-if="v.legal_address_symbol.$model !== 'plug' && v.legal_address_symbol.$model">
              Юридический адрес: <span>{{ v.legal_address_symbol.$model }}</span></p>
          </div>

          <ClientAddAdress
            :address-list="item.fact_addresses"
            @update-address="updateAddress($event, item)"
          />

          <QuarryMultiGoods
            :goods="item.name_goods"
            :cargo-quarry="cargoQuarry"
            :strength-grade="strengthGrade"
            :fraction-dict="fractionDict"
            @updateGoods="updateGoods"
          />

          <div class="holding-inputs">
            <BaseModalInput
              class="input-group-item"
              label="Холдинг"
              placeholder="Введите холдинг"
              v-model="v.holding.$model"
              :errors="v.holding.$errors"
              :suggest-list="holding"
              @create-suggest="createHolding(v.holding.$model)"
              @select-suggest="createSelectHolding"
            />
            <BaseModalInput
              class="input-group-item"
              label="Холдинг"
              v-model="v.holding_id.$model"
              :errors="v.holding_id.$errors"
            />
          </div>

          <div class="mb-2">
            <label class="modal-input-name">Ответственный</label>
            <BaseDropdown
              placeholder="Ответственный"
              :list="responseList"
              v-model="v.responsible.$model"
              :is-disabled="checkIfIsDisabled"
            />
          </div>

          <QuarrySchedule
            :schedule-list="item.work_schedule"
            @update-days="updateDays($event, item)"
          />

          <BaseModalTextarea
            class="input-group-item"
            label="Комментарий"
            :id="`comment-${index}`"
            placeholder="Введите комментарий"
            v-model="v.comment.$model"
          />
        </template>
      </ValidateEach>
    </form>

    <div class="inner-left__meta" v-if="isSubmitBtn">
      <button class="clients-modal__button" @click="onSubmit">
        Сохранить
      </button>
    </div>

    <AlertModal ref="alert" :nameButton="'Понятно'"></AlertModal>
    <AlertModal ref="alertLinkInn" :close-button="true" close-text="Закрыть">
    </AlertModal>
  </div>
</template>

<script>
import { mapGetters, useStore } from 'vuex'
import { computed, reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { ValidateEach } from '@vuelidate/components'
import quarryService from '@/services/quarryService.js'
import AlertModal from '@/components/AlertModal.vue'
import BaseModalInput from '@/components/base/BaseModalInput.vue'
import BaseModalTextarea from '@/components/base/BaseModalTextarea.vue'
import ClientAddAdress from '@/components/Clients/ClientAddAdress.vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import QuarrySchedule from '@/components/Quarry/QuarrySchedule.vue'
import QuarryMultiGoods from '@/components/Quarry/QuarryMultiGoods.vue';
import _get from 'lodash.get';
import { quarryValidationRules } from '@/utils/quarry/quarry.js';

const quarryId = ref('')

const getIdByText = (text, list) => {
  const currentItem = list.find((item) => {
    if (item.name == text) {
      return item
    }
  })
  if (currentItem == null)
    return { id: null }
  return currentItem
}

const parseWorkScheduleToString = (value) => {
  const list = []

  value.forEach((day) => {
    if (day.show) {
      if (day.always) {
        const time = '24:00-24:00'

        list.push(time)
      } else {
        const loadStart = day.load_start
        const unloadEnd = day.unload_end
        const time = `${loadStart}-${unloadEnd}`

        list.push(time)
      }
    } else {
      const time = ''

      list.push(time)
    }
  })

  value = list.join()

  return value
}

export default {
  name: 'QuarryCreateForm',
  data() {
    return {
      isOpenAboutClient: false,
      Title: 'О карьере',
      ModalButton: 'Сохранить',
      disabledKontur: false,
      formState: {},
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      company: 'company/company',
      holding: 'dict/holding',
      strengthGrade: 'dict/strengthGrade',
      filterParams: 'quarry/getQuarryFilterParams',
      fractionDict: 'dict/fraction',
      cargoQuarry: 'dict/cargoQuarry',
      getSelectedQuarry: 'quarry/getSelectedQuarry',
      responseList: 'users/responseList',
      generalPermissions: 'account/getPermissions',
    }),
    checkIfIsDisabled() {
      return !_get(this.generalPermissions, 'quarries.person', false)
    },
  },
  props: {
    isQuarryPage: Boolean,
    isModalEdit: {
      type: Boolean,
      default: false,
    },
    isContactPerson: {
      type: Boolean,
      default: false
    },
    isSubmitBtn: {
      type: Boolean,
      default: true
    }
  },
  components: {
    QuarryMultiGoods,
    ValidateEach,
    AlertModal,
    BaseModalInput,
    BaseModalTextarea,
    ClientAddAdress,
    BaseDropdown,
    QuarrySchedule,
  },
  setup(props) {
    const statusQuarry = ref('Действующий')
    const store = useStore();
    const account = computed(() => {
      return store.getters['account/account'];
    })

    const rules = quarryValidationRules;

    let currentResponsibleId = '';
    // устанавливаем текущего пользователя ответственным
    if (account.value) {
      currentResponsibleId = account.value?.id ? account.value.id : '';
    }

    const collection = props.isModalEdit
      ? reactive([])
      : reactive([
        {
          comment: '',
          name: '',
          inn: '',
          ogrn: '',
          kpp: '',
          legal_address_symbol: '',
          fact_addresses: [],
          holding: '',
          holding_id: '',
          work_schedule: '',
          registration_date: '',
          head: '',
          responsible: currentResponsibleId,
          name_goods: []
        },
      ])

    const v = useVuelidate()

    return {
      rules,
      collection,
      v,
      modalEditData: props.isModalEdit,
      statusQuarry,
    }
  },
  methods: {
    async checkInn(v, validate, index, itemCollection) {
      const isInnValid = await validate.$validate()

      if (isInnValid) {
        try {
          await this.$store.dispatch('company/getCompany', {
            inn: v.target.value,
          })

          itemCollection.inn = this.company.inn
          itemCollection.ogrn = this.company.ogrn
          itemCollection.kpp = this.company.kpp
          itemCollection.legal_address_symbol =
            this.company.legal_address_symbol
          itemCollection.client_name = this.company.name
          itemCollection.name = this.company.short_name
          itemCollection.registration_date = this.company?.registration_date;
          itemCollection.head = this.company?.head;

          if (itemCollection.client_name.length < 1) {
            itemCollection.name = this.company.short_name
            itemCollection.client_name = this.company.name
          }

          await this.$store.dispatch('clients/setSelectedClient', {
            name: this.company?.short_name || '',
            companies: [{ inn: this.company?.inn || '' }]
          });

          // статус карьера
          this.statusQuarry = 'Действующий'

          this.disabledKontur = true
        } catch (e) {
          this.$refs.alert.show('Ошибка', e.data.data.message, {
            nameButton: 'Окей',
            color: '#FFA500',
          })
        }
      } else {
        await this.$store.dispatch('clients/setSelectedClient', null);
      }
    },
    async createHolding(value) {
      try {
        const params = { name: value }

        const { data } = await quarryService.createHoldingQuarry(params)

        this.collection[0].holding_id = data.data.id
        this.$store.dispatch('dict/getHolding');
      } catch (error) {
        console.log(error)
      }
    },
    createSelectHolding(value) {
      this.collection[0].holding = value.name
      this.collection[0].holding_id = value.id
    },
    updateAddress(value, item,) {
      item.fact_addresses = value
    },
    updateDays(value, item) {
      item.work_schedule = parseWorkScheduleToString(value)
    },
    closeModal() {
      this.$emit('close')
    },
    addCountForm() {
      this.collection.push({
        comment: '',
        name: '',
        client_name: '',
        inn: '',
        ogrn: '',
        kpp: '',
        legal_address_symbol: '',
        fact_addresses: [],
        holding: '',
        holding_id: '',
        work_schedule: '',
        registration_date: '',
        head: '',
        responsible: '',
        name_goods: []
      })
    },
    async editQuarry(params, id) {
      try {
        await quarryService.editQuarry(params, id);
        if (!this.isQuarryPage) {
          const filterParams = {
            ...this.filterParams,
            page: this.$route.query?.page ? this.$route.query?.page : undefined
          }

          await this.$store.dispatch('quarry/getQuarry', filterParams);
        } else {
          await this.$store.dispatch('quarry/getQuarryDetail', id);
        }

        this.$emit('extra')
        this.closeModal()
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data?.errors)
        const msgErrorParse = msgError[0].join()
        const errorSplit = msgErrorParse.split('|')
        const textError = errorSplit[0]

        // ссылка inn
        if (error.response.status === 422) {
          const id = errorSplit[1]

          if (id) {
            this.$refs.alertLinkInn.show(
              'Внимание!',
              textError,
              {
                nameButton: 'Перейти на страницу',
                color: '#FFA500',
              },() => {
                this.$router.push({
                  path: `/table-quarry-page/${id}`,
                })
              }
            )
          } else {
            this.$refs.alert.show('Ошибка', textError, {
              nameButton: 'Окей',
              color: '#FFA500',
            })
          }
        }
      }
    },
    async createQuarry(params) {
      try {
        const { data } = await quarryService.createQuarry(params)

        if (this.isContactPerson) {
          this.$emit('createQuarry', data.data.id);
        }

        this.closeModal()

        let filterParams = {
          ...this.filterParams,
          page: this.$route.query?.page ? this.$route.query?.page : undefined
        }

        await this.$store.dispatch('quarry/getQuarry', filterParams)
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data?.errors)
        const msgErrorParse = msgError[0].join()
        const errorSplit = msgErrorParse.split('|')
        const textError = errorSplit[0]

        // ссылка inn
        if (error.response.status == 422) {
          const id = errorSplit[1]

          if (id) {
            this.$refs.alertLinkInn.show(
              'Внимание!',
              textError,
              {
                nameButton: 'Перейти на страницу',
                color: '#FFA500',
              },() => {
                this.$router.push({
                  path: `/table-quarry-page/${id}`,
                })
              }
            )
          } else {
            this.$refs.alert.show('Ошибка', textError, {
              nameButton: 'Окей',
              color: '#FFA500',
            })
          }
        }
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v.$validate()

      if (isFormCorrect) {
        // submit модалки редактирования
        if (this.modalEditData) {
          await this.editQuarry(
            {
              id: quarryId.value,
              name: this.collection[0].name,
              client_name: this.collection[0].client_name,
              comment: this.collection[0].comment,
              inn: this.collection[0].inn,
              ogrn: this.collection[0].ogrn,
              kpp: this.collection[0].kpp,
              legal_address_symbol: this.collection[0].legal_address_symbol,
              head: this.collection[0].head || undefined,
              registration_date: this.collection[0].registration_date || undefined,
              fact_addresses: this.collection[0].fact_addresses,
              holding_id: this.collection[0].holding ? this.collection[0].holding_id : undefined,
              work_schedule: this.collection[0].work_schedule,
              status: this.statusQuarry == 'Действующий' ? true : false,
              responsible: this.collection[0].responsible,
              name_goods: this.collection[0].name_goods
            },
            quarryId.value
          )
          // submit модалки создания
        } else {
          await this.createQuarry({
            name: this.collection[0].name,
            client_name: this.collection[0].client_name,
            comment: this.collection[0].comment,
            inn: this.collection[0].inn,
            ogrn: this.collection[0].ogrn,
            kpp: this.collection[0].kpp,
            legal_address_symbol: this.collection[0].legal_address_symbol,
            head: this.collection[0].head || undefined,
            registration_date: this.collection[0].registration_date || undefined,
            fact_addresses: this.collection[0].fact_addresses,
            holding_id: this.collection[0].holding_id,
            work_schedule: this.collection[0].work_schedule,
            status: this.statusQuarry == 'Действующий' ? true : false,
            responsible: this.collection[0].responsible,
            name_goods: this.collection[0].name_goods
          })
        }
      }
    },
    updateGoods(val) {
      this.collection[0].name_goods = val;
    }
  },
  watch: {
    account() {
      if (this.collection[0]?.responsible) return
      this.collection[0].responsible = this.account.id;
    }
  },
  async created() {
    let selectedQuarry = this.getSelectedQuarry

    if (this.isModalEdit) {
      quarryId.value = selectedQuarry?.id

      this.statusQuarry = selectedQuarry?.status;
      this.collection.push({
        comment: selectedQuarry.comment,
        name: selectedQuarry.name,
        client_name: selectedQuarry.client_name,
        inn: selectedQuarry.inn,
        ogrn: selectedQuarry.ogrn,
        kpp: selectedQuarry.kpp,
        legal_address_symbol: selectedQuarry.legal_address_symbol,
        fact_addresses: selectedQuarry.fact_addresses,
        holding: selectedQuarry.holding,
        holding_id: selectedQuarry.holding ? getIdByText(selectedQuarry.holding, this.holding).id : undefined,
        work_schedule: selectedQuarry.work_schedule,
        registration_date: selectedQuarry?.company?.registration_date,
        head: selectedQuarry?.company?.head,
        responsible: selectedQuarry?.responsible?.id,
        name_goods: selectedQuarry?.name_goods
      })
    } else {
      const localFormState = localStorage.getItem('quarryFormState')
      const localFormStatus = localStorage.getItem('quarryFormStatus')

      if (localFormState) {
        const parsedState = JSON.parse(localFormState);

        this.collection.shift()
        this.collection.push(parsedState[0])
      }

      if (localFormStatus) {
        const parsedState = JSON.parse(localFormStatus);
        const parsedStateStatus = parsedState == true ? 'Действующий' : 'Недействующий'

        this.statusQuarry = parsedStateStatus
      }
    }
  },
}
</script>

<style scoped lang="scss">
.clients-modal__button {
  font-family: "Manrope";
  background: #ffa500;
  border-radius: 8px;
  height: 44px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  margin-right: 6px;
  font-size: 13px;
}

.input-group-item {
  margin-bottom: 16px;
}

.add-button {
  margin-left: 0;
}

.modal-form {
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.about-client {
  .clients-modal__title {
    margin-bottom: 5px;
  }
}

.modal-flex.align_start {
  align-items: flex-start;
}

.holding-inputs {
  position: relative;

  .form-field + .form-field {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
}

.modal {
  &__info-block {
    margin-bottom: 20px;
  }

  &__info-row {
    display: block;
    margin: 5px 0;
  }
}
</style>
