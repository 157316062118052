<template>
  <div class="select w-100 page-select">
    <div v-if="!isShortData" class="page-select__text">
      {{ text }}
    </div>
    <v-select
      :model-value="currentPerPage"
      :items="items"
      label=""
      hide-details
      single-line
      variant="outlined"
      @update:model-value="updateSelect"
      class="page-select__input-select"
    />
  </div>
</template>

<script>
export default {
  name: 'PerPageSelect',
  props: {
    items: Array,
    currentPerPage: [Number, String],
    isShortData: { type: Boolean, default: false },
    text: {
      type: String,
      default: 'Количество элементов'
    }
  },
  methods: {
    updateSelect(value) {
      this.$emit('updateSelect', value)
    }
  },
}
</script>

<style scoped lang="scss">
  .page-select {
    display: flex;
    align-items: center;
    gap: 15px;
    &__text {
      width: 100%;
      text-align: right;
    }

    &__input-select {
      min-width: 85px;
      max-width: 85px;

      ::v-deep .v-field__input {
        height: 40px;
        min-height: 40px;
        padding: 0 0 0 14px;
        display: flex;
        align-items: center;
      }
      ::v-deep .v-field__append-inner {
        padding-top: 0;
        display: flex;
        align-items: center;
      }
    }
  }
</style>
