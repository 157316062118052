export default function filterTemplates() {
  /**
   * Функция заполнения фильтра из URL
   * @param filterList - массив фильтров (тот который отрисовывается)
   * @param query - параметры которыми нужно заполнить фильтр (берутся из url)
   * @returns {*}
   */
  const updateFilterFromUrl = (filterList, query) => {
    if (!filterList || !query) return;
    filterList.map(item => {
      if ('capital_material_total' in query) {
        query.revenue = query['capital_material_total']
      }

      let isKey = (
        (item.name in query) &&
        (query[item.name] !== 'null' && query[item.name] !== undefined && query[item.name] !== null)
      );

      if (isKey) {
        let valueFromQueryString = query[item.name].split(',')
        if (item.type === 'checkbox') {
          const isQueryDataOnlyInt = valueFromQueryString.every(queryArgument => /^[0-9]+$/.test(queryArgument))

          item['filter'] = item['filter'].map(option => {
            if (isQueryDataOnlyInt) { // работа с айдишниками
              option.value = valueFromQueryString.includes(`${option.dataValue ? option.dataValue : option.id}`)
            } else { // проверяем строковые значени true/false
              option.value = option.returnValue === (/true/).test(valueFromQueryString);
            }
            return option
          })
        }
        if (item.type === 'combobox') {
          item.select = item.filter.reduce((accum, el) => {
            if (valueFromQueryString.includes(el.dataValue.toString())) {
              accum.push(el?.text ? el.text : el);
            }
            return accum;
          }, []);
        }
        if (item.type === 'price') {
          if (valueFromQueryString.length > 1) {
            item['filter'][0].value = valueFromQueryString[0];
            item['filter'][1].value = valueFromQueryString[1];
          } else {
            item['filter'][0].value = '';
            item['filter'][1].value = '';
          }
        }
        if (item.type === 'date_range') {
          const values = query[item.name]?.split(',')
          if (query[item.name] && Array.isArray(values)) {
            item.filter[0].value = values[0] || '';
            item.filter[1].value = values[1] || '';
          }
        }
        if (item.type === 'search') {
          item.select = query[item.name].split(',')?.map(el => { return { id: el, title: el }}) || [];
        }
      } else {
        if (item.type === 'checkbox') {
          item['filter'].map(el => {
            el.value = false
            return el
          })
        }
        if (item.type === 'date_range') {
          item.filter[0].value = '';
          item.filter[1].value = '';
        }
        if (item.type === 'combobox') {
          item.select = []
        }
        if (item.type === 'search') {
          item.select = [];
        }
        if (item.type === 'price') {
          item['filter'][0].value = '';
          item['filter'][1].value = '';
        }
      }
      return item
    })

    return filterList;
  }

  return {
    updateFilterFromUrl
  }
}
