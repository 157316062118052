import auth from './auth';
import registration from './registration';
import clients from './clients';
import specifications from './specifications';
import contacts from './contacts';
import dict from './dict';
import company from './company';
import users from './users';
import account from './account';
import quarry from './quarry';
import filter from './filter';
import interactiveMap from './interactiveMap';
import ports from './ports';

export default {
  auth,
  registration,
  clients,
  contacts,
  specifications,
  dict,
  company,
  users,
  account,
  quarry,
  filter,
  interactiveMap,
  ports
}
