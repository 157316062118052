<template>
  <div class="menu">
    <div class="sorting-search-favorites">
      <div
          class="ports-filters__button favorites"
          @click="toggleFilter"
      >
        <SvgIcon name="sorting" />
        <BaseTooltip text="Открыть/скрыть фильтры" />
      </div>
      <div class="ports-filters__search">
        <DashboardSearch
            @update-search="updateSearch"
            ref="search"
            erase-tooltip="Очистить все"
        />
      </div>
    </div>
    <PaginationComponent
        :total-pages="totalPages"
        @update-page="updatePage"
    />
  </div>

</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import BaseTooltip from '@/components/base/BaseTooltip.vue'
import DashboardSearch from '@/components/Dashboard/DashboardSearch.vue'
import PaginationComponent from '@/components/Dashboard/PaginationComponent.vue'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['update-search', 'toggle-filter', 'search'])

const meta = computed(() => store.getters['ports/meta'])
const totalPages = computed(() => meta.value?.last_page)

const toggleFilter = () => {
  emit('toggle-filter')
}

const updateSearch = (value) => {
  emit('search', value)
}

const updatePage = (params) => {
  router.replace({
    query: {
      ...route.query,
      ...params
    }
  })

  setTimeout(() => emit('update-search'), 100)
}
</script>

<style lang="scss" scoped>
.ports-filters {
  display: flex;
  align-items: center;
}
</style>
