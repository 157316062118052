<template>
  <div class="multi-goods">
    <div class="multi-goods__wrapper" v-for="(item, index) in state.collection" :key="`quarry-goods-${index}`">
      <div class="mb-2">
        <button class="multi-goods__delete" v-if="index !== 0" @click="() => deleteThisGoods(index)">
          <SvgIcon name="close" width="10" height="10" />
        </button>
        <label class="modal-input-name">Тип породы</label>
        <base-dropdown
          class="size-medium errors-static"
          placeholder="Выберите"
          :errors="v$.collection.$each?.$response.$errors[index]?.id"
          v-model="state.collection[index].id"
          :list="cargoQuarry"
          @update:modelValue="updateGoods"
          :isError="v$.collection.$each?.$response.$errors[index]?.id.length && v$.$dirty"
        />
      </div>

      <div class="mb-2">
        <label class="modal-input-name">Марка прочности</label>

        <BaseDropDownExtended
          :options="strengthGrade"
          label="name"
          track-by="name"
          v-model="state.collection[index].strength_grades"
          :errors="v$.collection.$each?.$response.$errors[index]?.strength_grades"
          :is-error="v$.collection.$each?.$response.$errors[index]?.strength_grades.length && v$.$dirty"
          @update:modelValue="updateStrength($event, index)"
          placeholder="Марка прочности"
        />
      </div>

      <div class="mb-2">
        <label class="modal-input-name">Выберите фракции</label>
        <BaseDropDownExtended
          :options="fractionDict"
          label="name"
          track-by="name"
          v-model="state.collection[index].fractions"
          @update:modelValue="updateFractions($event, index)"
          :errors="v$.collection.$each?.$response.$errors[index]?.fractions"
          :is-error="v$.collection.$each?.$response.$errors[index]?.fractions.length && v$.$dirty"
          placeholder="Фракции"
        />
      </div>
    </div>

    <button
      v-if="state.collection.length < 5"
      class="add-button mb-2"
      @click.prevent="addGoods"
    >
      <SvgIcon name="plus" class="button-plus"/>
      Добавить груз
    </button>
  </div>
</template>

<script setup>
import BaseDropdown from "@/components/base/BaseDropdown";
import SvgIcon from "@/components/base/SvgIcon.vue";
import {reactive} from "vue";
import useVuelidate from "@vuelidate/core"
import {helpers, required} from "@vuelidate/validators";
import BaseDropDownExtended from "@/components/base/BaseDropDownExtended";

const props = defineProps({
  fractionDict: {
    type: Array,
    default: () => []
  },
  cargoQuarry: {
    type: Array,
    default: () => []
  },
  strengthGrade: {
    type: Array,
    default: () => []
  },
  goods: {
    type: Array,
    default: () => []
  }
})

const deleteThisGoods = (goodsIndex) => {
  state.collection.splice(goodsIndex,1);
  updateGoods();
}

const emit = defineEmits(['updateGoods'])

const state = props.goods?.length
    ? reactive({collection: []})
    : reactive({
      collection: [
        {
          id: '',
          fractions: [],
          strength_grades: []
        }
      ]
    })

if (props.goods) {
  props.goods.map((item) => {
    state.collection.push({
      id: item?.id,
      fractions: item?.fractions,
      strength_grades: item?.strength_grades,
    })
  })
}

let v$ = useVuelidate({
  collection: {
    $each: helpers.forEach({
      id: {
        required: helpers.withMessage(
            "Поле обязательно для заполнения",
            required
        ),
      },
      fractions: {
        required: helpers.withMessage(
            "Поле обязательно для заполнения",
            required
        ),
      },
      strength_grades: {
        required: helpers.withMessage(
            "Поле обязательно для заполнения",
            required
        ),
      }
    })
  }
}, state, {$autoDirty: true})

const updateFraction = (value, item) => {
  item.fractions = value
  updateGoods()
}

const addGoods = () => {
  state.collection.push({
    id: '',
    fractions: [],
    strength_grades: []
  })
}

const updateGoods = () => {
  emit('updateGoods', state.collection)
}

const updateFractions = (val, index) => {
  const temp = []

  val.forEach(item => {
    temp.push({
      id: item.id,
      name: item.name
    })
  })

  state.collection[index].fractions = temp
  updateGoods()
}

const updateStrength = (val, index) => {
  const temp = []

  val.forEach(item => {
    temp.push({
      id: item.id,
      name: item.name
    })
  })

  state.collection[index].strength_grades = temp
  updateGoods()
}
</script>

<style scoped lang="scss">
.multi-goods {
  &__delete {
    position: absolute;
    background: #f4f4f4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 10;
    right: 0;
    top: 10px;
    margin: 0 0 -15px auto;
  }

  &__wrapper {
    position: relative;
    border-top: 1px solid #dedede;
    padding-top: 25px;
    margin-top: 20px;

    &:first-child {
      border-top: none;
      padding-top: 10px;
    }
  }
}

.add-button {
  margin: 0 0 16px;
}
</style>
