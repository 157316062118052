<template>
  <div class="contact-faces__card-functions" v-click-outside="contextOutside">
    <SvgIcon
      name="functions"
      @click.stop="toggleMenu"
    />
    <div v-if="openMenuId" class="client-card__mini-modal">
      <div
        v-if="permissions.edit"
        class="client-card__mini-modal__item"
        @click="editContact"
      >
        <div class="client-card__mini-modal__icon">
          <SvgIcon name="edit-mini"/>
        </div>
        <div class="client-card__mini-modal__text">
          Редактировать
        </div>
      </div>
      <div class="client-card__mini-modal__item" @click="copyContactInfo">
        <div class="client-card__mini-modal__icon">
          <SvgIcon name="copy-mini"/>
        </div>
        <div class="client-card__mini-modal__text">
          Копировать
        </div>
      </div>
      <div
        class="client-card__mini-modal__item"
        v-if="permissions.edit"
        @click="deleteContact"
      >
        <div class="client-card__mini-modal__icon">
          <SvgIcon name="delete-mini"/>
        </div>
        <div class="client-card__mini-modal__text">Удалить</div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue';

export default {
  name: 'ContactContextMenu',
  components: { SvgIcon },
  props: {
    permissions: {
      type: Object,
    },
  },
  data() {
    return {
      openMenuId: false
    }
  },
  methods: {
    toggleMenu() {
      this.openMenuId = !this.openMenuId;
    },
    async copyContactInfo() {
      this.$emit('copy')
      this.openMenuId = false;
    },
    async deleteContact() {
      this.$emit('delete')
      this.openMenuId = false;
    },
    async editContact() {
      this.$emit('edit')
      this.openMenuId = false;
    },
    contextOutside() {
      this.openMenuId = false;
    }
  }
}
</script>
