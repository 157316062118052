import ApiUtil from '@/utils/api'

export default {
  // получить список шаблонов для клиента
  getClientsFilters: () =>
    ApiUtil({
      url: '/api/v1/clients/filters',
      method: 'get',
      requiresAuth: true,
    }),
  // создать шаблон для клиента
  createClientsFilters: (data) =>
    ApiUtil({
      url: '/api/v1/clients/filters',
      method: 'post',
      data,
      requiresAuth: true,
    }),
  // удалить шаблон клиента
  deleteClientsFilter: (id) =>
    ApiUtil({
      url: `/api/v1/clients/filters/${id}`,
      method: 'delete',
      requiresAuth: true,
    }),
  // обновить шаблон клиента
  updateClientsFilter: (data, id) =>
    ApiUtil({
      url: `/api/v1/clients/filters/${id}`,
      method: 'patch',
      data,
      requiresAuth: true,
    }),
  // получить список шаблонов спецификаций
  getSpecFilter: () =>
    ApiUtil({
      url: '/api/v1/specs/filters',
      method: 'get',
      requiresAuth: true,
    }),
  // создать шаблон для спецификации
  createSpecFilter: (data) =>
    ApiUtil({
      url: '/api/v1/specs/filters',
      method: 'post',
      data,
      requiresAuth: true,
    }),
  // удалить шаблон спецификации
  deleteSpecFilter: (id) =>
    ApiUtil({
      url: `/api/v1/specs/filters/${id}`,
      method: 'delete',
      requiresAuth: true,
    }),
  // обновить шаблон спецификации
  updateSpecFilter: (data, id) =>
    ApiUtil({
      url: `/api/v1/specs/filters/${id}`,
      method: 'patch',
      data,
      requiresAuth: true,
    }),
  // получить список шаблонов для карьера
  getQuarryFilters: () =>
    ApiUtil({
      url: '/api/v1/quarries/filters',
      method: 'get',
      requiresAuth: true,
    }),
  // создать шаблон для карьера
  createQuarryFilter: (data) =>
    ApiUtil({
      url: '/api/v1/quarries/filters',
      method: 'post',
      data,
      requiresAuth: true,
    }),
  // удалить шаблон карьера
  deleteQuarryFilter: (id) =>
    ApiUtil({
      url: `/api/v1/quarries/filters/${id}`,
      method: 'delete',
      requiresAuth: true,
    }),
  // обновить шаблон карьера
  updateQuarryFilter: (data, id) =>
    ApiUtil({
      url: `/api/v1/quarries/filters/${id}`,
      method: 'patch',
      data,
      requiresAuth: true,
    }),
  // обновить шаблон портов
  updatePortsFilter: (data, id) =>
    ApiUtil({
      url: `/api/v1/sea-ports/filters/${id}`,
      method: 'patch',
      data,
      requiresAuth: true,
    }),
  // Список фильтров по сущностям
  getFilterModal: (filter) =>
    ApiUtil({
      url: `/api/v1/filters/${filter}`,
      method: 'get',
      requiresAuth: true,
    }),
  // поиск в фильтрах
  getFilterSearchHintData: (url) => {
    return ApiUtil({
      url: `${url}`,
      method: 'get',
      requiresAuth: true,
    })
  }
}
