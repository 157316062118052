<template>
  <span :style="style" class="loader"></span>
</template>

<script>
export default {
  name: "BaseLoader",
  props: {
    size: {
      type: String,
      default: '16'
    }
  },
  computed: {
    style() {
      return `width: ${this.size}px;` + `height: ${this.size}px;`;
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  border: 2px solid #fff;
  border-bottom-color: #0055e8;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  flex-shrink: 0;
}

@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
</style>
