import ApiUtil from '@/utils/api'

export default {
  getPorts: (params = {}) => ApiUtil(
    {
      url: '/api/v1/sea-ports',
      method: 'get',
      requiresAuth: true,
      params,
    },
  ),
  getPortDetail: (id) => ApiUtil(
    {
      url: `/api/v1/sea-ports/${id}`,
      method: 'get',
      requiresAuth: true,
    },
  ),
  getPortSchema: () => ApiUtil(
    {
      url: '/api/v1/schemas/sea-ports',
      method: 'get',
      requiresAuth: true,
    }
  ),
  createPort: (data) => ApiUtil(
    {
      url: '/api/v1/sea-ports',
      method: 'post',
      requiresAuth: true,
      data,
    },
  ),
  editPort: (data, id) => {
    return ApiUtil({
      url: `/api/v1/sea-ports/${id}`,
      method: 'patch',
      requiresAuth: true,
      data,
    });
  },
  searchPort: (params = {}) => {
    return ApiUtil({
      url: `/api/v1/sea-ports/search/${params.search}`,
      method: 'get',
      requiresAuth: true,
      params,
    })
  },
  deletePort: (id) => ApiUtil(
    {
      url: `/api/v1/sea-ports/${id}`,
      method: 'delete',
      requiresAuth: true,
    },
  ),
  getPortsFilters: () => {
    return ApiUtil({
      url: '/api/v1/sea-ports/filters',
      method: 'get',
      requiresAuth: true,
    })
  },
  createPortsFilters: (data) => {
    return ApiUtil({
      url: '/api/v1/sea-ports/filters',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  deletePortsFilters: (id) => {
    return ApiUtil({
      url: `/api/v1/sea-ports/filters/${id}`,
      method: 'delete',
      requiresAuth: true,
    })
  },
  portsAddColumns: (data) => {
    return ApiUtil({
      url: '/api/v1/sea-ports/columns',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
}
