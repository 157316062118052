<template>
  <BaseModal :is-open="isOpen" @close="closeModal">
    <div class="change-password-modal">
      <h1 class="header-styles">
        Сменить пароль
      </h1>
      <form id="" action="" class="authorization-form" @submit.prevent="submit">
        <div class="authorization-password">
          <Input
            v-model="form.password"
            placeholder="Введите пароль"
            label="Пароль"
            :max-length="256"
            :type="showPass ? 'text' : 'password'"
            :errors="v$.form.password.$errors"
            :inputClass="{ error: v$.form.password.$error }"
          >
            <template #input-icon>
              <SvgIcon name="hide-password" class="authorization-password-hide" @click="showPass=!showPass"/>
            </template>
          </Input>
        </div>

        <div class="authorization-password">
          <Input
            v-model="form.password_confirmation"
            placeholder="Повторите пароль"
            label="Повторите пароль"
            :max-length="256"
            :type="showRepeatPass ? 'text' : 'password'"
            :errors="v$.form.password_confirmation.$errors"
            :inputClass="{ error: v$.form.password_confirmation.$error }"
          >
            <template #input-icon>
              <SvgIcon
                class="authorization-password-hide"
                @click="showRepeatPass=!showRepeatPass"
                name="hide-password"
              />
            </template>
          </Input>
        </div>

        <BaseButton type="submit" class="authorization-button" success-btn>Сменить пароль</BaseButton>
      </form>
    </div>
  </BaseModal>
  <AlertModal ref="alert"/>
</template>

<script>
import BaseButton from '@/components/base/BaseButton';
import useVuelidate from '@vuelidate/core';
import { required, minLength, sameAs, helpers } from '@vuelidate/validators';
import BaseModal from '@/components/base/BaseModal';
import LsUtil from '@/utils/ls';
import SvgIcon from '@/components/base/SvgIcon';
import Input from '@/components/Specifications/Input';
import AuthService from "@/services/authService";
import AlertModal from "@/components/AlertModal.vue";
import { stringifyErrors } from "@/utils/helper";

export default {
  name: 'ChangePassword',
  data() {
    return {
      inputPassword: 'Введите пароль',
      signInError: null,
      showPass: false,
      showRepeatPass: false,
      form: {
        password: '',
        password_confirmation: ''
      },
      isOpen: false
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  components: {
    SvgIcon,
    BaseButton,
    BaseModal,
    AlertModal,
    Input
  },
  created() {
    if (this.isAuth) {
      this.$router.replace({ name: 'SpecificationsTablePage' });
    }
  },
  mounted() {
    this.checkTempPassword()
  },
  emits: ['close'],
  methods: {
    checkTempPassword() {
      if (this.$route.path === '/') {
        this.isOpen = false;
      } else {
        this.isOpen = LsUtil.getItem('LS_TEMP_PASS') ?? false
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      try {
        const passwordChange = await AuthService.setPass({
          password: this.form.password,
          password_confirmation: this.form.password_confirmation
        });
        this.isOpen = false;
        LsUtil.removeItem('LS_TEMP_PASS')
        window.location.reload();
      } catch (e) {
        const errors = stringifyErrors(e);
        this.$refs.alert.show('Ошибка', errors, {
          type: 'error',
          nameButton: 'Окей',
          color: '#FFA500'
        })
      }
    },
    show(option) {
      if (option != null) {
        if (option.type != null)
          this.type = option.type;
      }
    },
    closeModal() {
      this.$router.replace({ name: 'AuthorizationPage' })

      this.$store.reset();
      this.$store.dispatch('auth/signOut');

      LsUtil.removeItem('LS_TEMP_PASS')

      this.$emit('close')
    },
    passNumber(value) {
      return value && /[1-9]/.test(value)
    },
    latin(value) {
      return value && /[a-zA-Z]/.test(value)
    },
    specialChar(value) {
      return value && /[!¡@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
    },
    bothCases(value) {
      return value && /[A-ZА-Я]/.test(value) && /[a-zа-я]/.test(value)
    }
  },
  validations() {
    return {
      form: {
        password: {
          required: helpers.withMessage(
            'Поле обязательно для заполнения',
            required
          ),
          minLength: helpers.withMessage(
            'Пароль должен содержать минимум 8 символов',
            minLength(8)
          ),
          bothCases: helpers.withMessage(
            'Пароль должен содержать символы разного регистра',
            this.bothCases
          ),
          passNumber: helpers.withMessage(
            'Пароль должен содержать минимум 1 цифру',
            this.passNumber
          ),
          latin: helpers.withMessage(
            'Пароль должен содержать минимум 1 латинскую букву',
            this.latin
          ),
          specialChar: helpers.withMessage(
            'Пароль должен содержать минимум 1 спецсимвол',
            this.specialChar
          )
        },
        password_confirmation: {
          required: helpers.withMessage(
            'Поле обязательно для заполнения',
            required
          ),
          sameAs: helpers.withMessage(
            'Пароли не совпадают',
            sameAs(this.form.password)
          )
        }
      }
    }
  },
  watch: {
    '$route.path'() {
      this.checkTempPassword()
    },
  }
}
</script>

<style lang="scss">
.header-styles {
  font-size: 40px;
  margin-bottom: 15px;
}

.button--slot {
  color: #ffffff;
}

.authorization-password {
  position: relative;
}

.authorization-password-hide {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.button__forgot-password {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #102F43;
}

.form-field {
  margin-bottom: 20px;
}

.change-password-modal {
  width: 360px;

  .modal-input-style {
    padding: 0 42px 0 14px;
    font-size: 14px;
  }
}
</style>
