<template>
  <div v-if="permissions.view" class="form-field modal-input" :class="classes">
    <label v-if="label" :for="'input-modal' + uid" class="modal-input-name">
      {{ label }}
    </label>
    <div class="input-wrap">
      <input
        v-if="!timeList.length"
        :placeholder="placeholder"
        :id="'input-modal' + uid"
        class="modal-input-style"
        :type="type"
        @input="handleInput"
        :value="modelValue"
        :class="inputClass"
        v-maska="inputMask"
        :disabled="isDisabled"
        :maxlength="maxlength"
        @focus="$emit('focus')"
        @paste.stop="checkPaste"
        @blur="blurUp"
        autocomplete="off"
      />
      <v-select
        v-else
        class="modal-input-style time-select"
        v-model="time"
        dense
        ref="vSelect"
        :items="timeList"
        :menu-props="{
          closeOnClick: true,
          closeOnContentClick: true,
        }"
        :disabled="isDisabled"
      >
        <template #item="{item}">
          <div class="time-select__item" @click="handleTimeInput(item)">
            {{ item.title }}
          </div>
        </template>
      </v-select>
      <slot name="input-icon"></slot>
      <div v-if="suggestArray && stateSuggest" class="suggest-list">
        <div
          v-for="item of suggestArray"
          :key="item"
          class="suggest-elem"
          @click="selectSuggest(item)"
        >
          {{ item.name }}
        </div>
        <div v-if="suggestArray.length < 1">
          <p>Ничего не найдено</p>
          <a href="javascript:;" class="suggest-link" @click="createSuggest">Хотите создать новый?</a>
        </div>
      </div>
    </div>
    <template v-if="errors.length > 0">
      <div class="input-errors">
        <div class="error-msg">{{ errors[0].$message }}</div>
      </div>
    </template>
  </div>
</template>
<script>
import { getCurrentInstance } from 'vue';

export default {
  name: 'BaseModalInput',
  data() {
    return {
      stateSuggest: false,
      time: null,
    };
  },
  props: {
    errors: {
      type: [Array],
      default: () => [],
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    inputClass: {
      type: [Object, String],
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    inputMask: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: String,
      default: '255',
    },
    suggestList: {
      type: [String, Array],
      default: '',
    },
    timeList: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Object,
      default: () => ({
        edit: true,
        view: true
      })
    }
  },
  emits: [
    'input',
    'update:modelValue',
    'create-suggest',
    'blur',
    'focus',
    'change'
  ],
  computed: {
    classes() {
      return {
        'modal-input__default': this.hasBg,
        'modal-input__disable': this.isDisabled,
        'modal-input__error': this.errors.length > 0
      }
    },
    isDisabled() {
      return this.disabled || !this.permissions.edit;
    }
  },
  created() {
    if (this.timeList && this.modelValue) {
      this.time = this.modelValue
    }
  },
  methods: {
    blurUp(e) {
      this.$emit('blur', e);
    },
    hasBg() {
      return !this.isDisabled
    },
    handleInput(e) {
      this.$emit('input', e)
      this.$emit('update:modelValue', e.target.value)

      if (this.suggestList.length > 0) {
        this.suggest(e.target.value)
      }
    },
    handleTimeInput(item) {
      this.time = item
      this.$emit('update:modelValue', item.value)
    },
    suggest(value) {
      if (value.length > 0) {
        this.stateSuggest = true

        const arraySuggest = this.suggestList

        let suggestInputValue = value
        const suggestFiltered = arraySuggest.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(suggestInputValue.toLowerCase())
        })

        this.suggestArray = suggestFiltered
      } else {
        this.stateSuggest = false
      }
    },
    createSuggest() {
      this.$emit('create-suggest')

      this.stateSuggest = false
    },
    selectSuggest(value) {
      this.$emit('select-suggest', value)

      this.stateSuggest = false
    },
    checkPaste(e) {
      let text = e.clipboardData.getData('text');

      if (text?.length > 10 && this.inputMask?.mask === '+7 (HHH) HHH-HH-HH') {
        this.$emit('update:modelValue', text.slice(text.length - 10))
      }
    }
  },
  setup() {
    const uid = getCurrentInstance().uid
    const suggestArray = []

    return {
      uid: uid,
      suggestArray,
    }
  },
  watch: {
    modelValue() {
      this.time = this.modelValue
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-input {

  &::v-deep .v-input__control .v-field {
    padding-right: 0;
  }
  &::v-deep .v-input__details {
    display: none;
  }
  &__disable {
    .modal-input-style {
      background: #f0f0f0;
      border-radius: 8px;
    }
  }

  &__error {
    .modal-input-style {
      border-color: red;
    }
  }

  &.new-specification-input {
    margin-bottom: 0;

    .modal-input-style {
      padding: 0;
      height: 40px;
    }

    .input-wrap {
      transform: translateY(-3px);

      .v-input {
        .v-input__control {
          display: flex;
          flex-direction: column;
          height: auto;
          flex-grow: 1;
          flex-wrap: wrap;
          width: 100%;

          :deep(.v-field__outline) {
            display: none;
          }

          .v-field__outline {
            display: none;
            opacity: 0;

            &::before {
              display: none;
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

.modal-input-style {
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 14px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0a2333;
}

.modal-input-style:not(.modal-input-disabled):hover,
.modal-input-style:focus {
  border: 1px solid #a6a6a6;
}

.modal-input-style::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #808191;
}

.modal-input-disabled {
  background: #f0f0f0;
  border-radius: 8px;
}

.input-errors {
  margin-top: 5px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #d72028;
}
.input-wrap {
  position: relative;
}

.time {
  &:first-child {
    .modal-input-style {
      border-radius: 8px 0 0 8px;
    }
  }

  &:nth-child(2) {
    .modal-input-style {
      border-radius: 0 8px 8px 0;
    }
  }
}

.suggest-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 10px 16px;
  background-color: #fff;
  z-index: 1;
  border: 1px solid #efefef;
  border-radius: 8px;
  max-height: 250px;
  overflow: auto;
}

.suggest-elem {
  cursor: pointer;
}

.suggest-link {
  color: #0055e8;
  border-bottom: 1px solid;
  transition: border-color .2s ease;
  text-decoration: none;

  &:hover {
    border-color: transparent;
  }
}
</style>
