<template>
  <div v-if="isOpen" class='modal-wrapper' :style="cssProps">
    <div class='modal-overlay' @click='closeModal'></div>
    <div class='dialog-content' :class="classes">
      <span @click='closeModal'
            class='dialog-content__close-btn'>
        <svg-icon name="close"/>
      </span>
      <slot/>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/base/SvgIcon.vue";

export default {
  name: 'BaseModal',
  components: {
    SvgIcon
  },
  props: {
    /** Сделать модальное окно большого размера */
    big: Boolean,
    size: {
      type: [String, Number],
      default: 476
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    AuthorizationPopup: {
      type: Boolean,
      default: false
    },
    ClientsMiniModal: {
      type: Boolean,
      default: false
    },
    ClientsModal: {
      type: Boolean,
      default: false
    },
    bigModal: {
      type: Boolean,
      default: false
    },
    contactFaceModal: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    isOpen(currentVal) {
      const body = document.querySelector('html')
      if (currentVal) {
        body.classList.add('no-scroll')
      } else {
        body.classList.remove('no-scroll')
      }
    }
  },
  computed: {
    cssProps() {
      return {
        '--base-modal-size': this.size + "px",
      }
    },
    classes () {
      return {
        // 'dialog--default': this.hasBg,
        'dialog-content--big': this.big,
        'dialog-content--big-modal': this.bigModal,
        'dialog-content--contact-face-modal': this.contactFaceModal,
        'authorization--popup': this.AuthorizationPopup,
        'clients--mini--modal':this.ClientsMiniModal,
        'clients--modal':this.ClientsModal,
      }
    },
    hasBg () {
      return !this.text && !this.outlined
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  max-height: 100vh;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000000, 0.4);
}

.dialog-content {
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  max-height: 90vh;
  padding: 20px;
  margin: 12px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--base-modal-size);
  overflow-y: hidden;

  &--big {
    max-width: 1280px;
    min-width: 840px;
  }

  &--big-modal {
    padding: 20px 5px 20px 20px;
    border-radius: 12px;
  }

  &--contact-face-modal {
    width: 651px;
  }

  &__close-btn {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    top: 10px;
    right: 10px;
    z-index: 10;
    cursor: pointer;
  }
}

.authorization--popup {
  width: 745px;
  height: 571px;
}

.clients--mini--modal {
  width: 340px;
  border-radius: 12px;
  padding: 12px 0 24px 0;
}

.clients--modal {
  width: 476px;
  border-radius: 8px;
  padding: 12px 24px 24px 24px;
}

.button {
  margin-right: 0;
}
</style>
