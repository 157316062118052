<template>
  <div>
    <div class="content-header">
      <h1 class="content-title">Клиенты</h1>
      <button
        v-if="permissions.create"
        class="add-button"
        @click.stop="showModalClient"
      >
        <SvgIcon name="plus" class="button-plus" />
        Добавить Клиента
      </button>
      <div v-if="modalClientState">
        <AboutClientModal
          :is-open="modalClientState"
          @close="modalClientState = false"
        />
      </div>
    </div>
    <div class="menu">
      <div class="sorting-search-favorites">
        <div class="favorites" @click="toggleFilter">
          <SvgIcon name="sorting" />
          <BaseTooltip text="Открыть/скрыть фильтры" />
        </div>
        <DashboardSearch
          @update-search="updatePage"
          ref="search"
          erase-tooltip="Очистить все"
        />
      </div>
      <PaginationComponent
        :total-pages="clientsPagesCount"
        @update-page="updatePage"
      />
      <div class="settings-select">
        <PerPageSelect
          :current-per-page="clientsPerPage"
          @update-select="changePerPage"
          :items="['20', '50', '100']"
        />
      </div>
      <div
        class="download-settings"
        @click="modalDownloadXlsx"
        :class="{ disabled: (isTemporaryBlocked || !searchResultState) || !permissions.xls }"
      >
        <SvgIcon name="download" class="download-settings__img" />
        <BaseTooltip text="Скачать файл с отображаемыми на экране клиентами" />
      </div>
      <div class="download-settings">
        <SvgIcon
          name="settings"
          class="download-settings__img"
          @click="showModalSettings"
        />
        <BaseTooltip text="Настройки отображения дашборда" />
      </div>
    </div>
  </div>
  <AlertModal ref="alertButtons" :close-button="true"/>
  <AlertModal ref="alert"/>

  <BaseTableSettingsModal
    :is-open="modalSettingsState"
    @close="modalSettingsState = false"
    :schemas="clientsHeaderFull.schemas"
    :schemas-active="clientsHeaderList.schemas"
    @on-submit="onSubmitSettings"
  />
</template>

<script>
import { mapGetters } from "vuex";
import AboutClientModal from "@/components/ClientsModals/AboutClientModal";
import DashboardSearch from "@/components/Dashboard/DashboardSearch";
import clientsService from "@/services/clientsService";
import { saveAs } from "file-saver";
import AlertModal from "@/components/AlertModal";
import SvgIcon from "@/components/base/SvgIcon";
import PaginationComponent from "@/components/Dashboard/PaginationComponent.vue";
import BaseTableSettingsModal from "@/components/base/BaseTableSettingsModal"
import PerPageSelect from "@/components/Dashboard/PerPageSelect.vue";
import BaseTooltip from "@/components/base/BaseTooltip.vue";

export default {
  name: "ClientsHeader",
  data() {
    return {
      isTemporaryBlocked: false,
      modalClientState: false,
      modalSettingsState: false,
      statePreloader: false,
    };
  },
  components: {
    BaseTooltip,
    SvgIcon,
    AboutClientModal,
    DashboardSearch,
    AlertModal,
    PaginationComponent,
    BaseTableSettingsModal,
    PerPageSelect
  },
  emits: ['toggle-filter'],
  computed: {
    ...mapGetters({
      account: "account/account",
      filterParams: "clients/getClientsFilterParams",
      searchResultState: "clients/clientsSearchResult",
      clientsPagesCount: "clients/clientsPagesCount",
      clientsHeaderList: "clients/clientsHeaderList",
      clientsHeaderFull: "clients/clientsHeaderListFull",
      clientsPerPage: "clients/clientsPerPage",
      isSearchMode: "clients/getIsSearchMode",
      generalPermissions: "account/getPermissions",
    }),
    searchQuery() {
      return this.$refs.search.searchValue
    },
    permissions() {
      if (!this.generalPermissions || !this.generalPermissions['clients']) return [];
      return this.generalPermissions['clients'];
    }
  },
  mounted() {
    const localFormIsOpen = localStorage.getItem('clientFormIsOpen')

    if (localFormIsOpen) {
      this.modalClientState = true
    }
  },
  methods: {
    async updatePage(query) {
      try {
        const curFilterParams = {}

        for (const key in this.filterParams) {
          if (this.filterParams[key]) {
            curFilterParams[key] = this.filterParams[key]
          }
        }

        this.$store.dispatch("clients/getClients", { ...this.$route.query, ...query, ...curFilterParams });
        this.$router.push({ query: { ...this.$route.query, ...query, ...curFilterParams } })
      } catch (error) {
        const data = error.response;

        if (data?.message) {
          this.$refs.alert.show("Ошибка", data?.message, {
            type: "error",
            nameButton: "Понятно",
            color: "#FFA500",
          });
        }
      }
    },
    async getClientsXlsx(params) {
      try {
        this.isTemporaryBlocked = true;
        const { data } = await clientsService.getClientsXlsx(params);
        saveAs(data, 'file_clients.xlsx');

        setTimeout(() => {
          this.isTemporaryBlocked = false;
        }, 2000);
      } catch (e) {
        this.$refs.alert.show("Ошибка", e, {
          type: "error",
          nameButton: "Понятно",
          color: "#FFA500",
        });
      }
    },
    showModalClientsXlsx() {
      this.$refs.alertButtons.show(
        "Экспорт",
        "Информация скачается в соответствии с настроенными фильтрами",
        {
          nameButton: "Скачать",
          color: "#FFA500",
        },
        () => {
          this.getClientsXlsx(this.filterParams);
        }
      );
    },
    modalDownloadXlsx() {
      this.showModalClientsXlsx();
    },
    toggleFilter() {
      this.$emit('toggle-filter');
    },
    async showModalClient() {
      await this.$store.dispatch("clients/setSelectedClient", null);
      this.$router.push({ path: `/table-clients-page/create` })
    },
    showModalSettings() {
      this.modalSettingsState = true
    },
    async getClientsHeaderList(params) {
      try {
        await this.$store.dispatch("clients/getClientsHeader", params)
      } catch (e) {
        this.$refs.alert.show(this.$t("alert.error"), e, {
          nameButton: "Понятно",
        })
      }
    },
    async getClientsList(params) {
      try {
        await this.$store.dispatch("clients/getClients", params)
      } catch (error) {
        const { data } = error.response?.data

        if (data?.message) {
          this.$refs.alert.show("Ошибка", data?.message, {
            nameButton: "Понятно",
            color: "#FFA500",
          })
        }
      }
    },
    async onSubmitSettings(items) {
      this.statePreloader = true

      try {
        const params = { columns: items }
        const { data } = await clientsService.clientsAddColumns(
          params
        )

        await this.getClientsHeaderList(this.$route.query)
        await this.getClientsList(this.$route.query)
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data.errors)
        const msgErrorParse = msgError[0].join()
        const textError = msgErrorParse

        this.$refs.alert.show("Ошибка", textError, {
          type: "error",
          nameButton: "Понятно",
          color: "#FFA500",
        })
      } finally {
        this.statePreloader = false
      }
    },
    async changePerPage(value) {
      let query = { ...this.$route.query, per_page: value};
      delete query.page
      await this.$router.push({ query });

      if (!this.searchQuery) {
        this.$store.dispatch("clients/getClients", query);
        return;
      }

      this.$store.dispatch("clients/getClientsSearch", {
        search: this.searchQuery,
        perPage: value,
      })
    }
  },
};
</script>

<style lang="scss">
.square-border {
  border: 1px solid #d0d5da;
  display: flex;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.square {
  background: #0055e8;
  border-radius: 2px;
  width: 11px;
  height: 10px;
}

.folder-arrow-border {
  width: 16px;
  height: 24px;
  border: 1px solid #a3a9ae;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 4px;
}

.separation-mini {
  width: 1px;
  height: 10px;
  background: #eceef1;
  margin-right: 8px;
  margin-left: 8px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
