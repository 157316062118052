import ApiUtil from '@/utils/api'

export default {
  getDocumentType: () =>
    ApiUtil({
      url: '/api/v1/dict/document_type',
      method: 'get',
      requiresAuth: true,
    }),
  getFraction: () =>
    ApiUtil({
      url: '/api/v1/dict/fraction',
      method: 'get',
      requiresAuth: true,
    }),
  getHolding: () =>
    ApiUtil({
      url: '/api/v1/dict/holding',
      method: 'get',
      requiresAuth: true,
    }),
  getLoadingRate: () =>
    ApiUtil({
      url: '/api/v1/dict/loading_rate',
      method: 'get',
      requiresAuth: true,
    }),
  getNameGood: () =>
    ApiUtil({
      url: '/api/v1/dict/name_good',
      method: 'get',
      requiresAuth: true,
    }),
  getRequestStatus: () =>
    ApiUtil({
      url: '/api/v1/dict/request_status',
      method: 'get',
      requiresAuth: true,
    }),
  getRequestType: () =>
    ApiUtil({
      url: '/api/v1/dict/request_type',
      method: 'get',
      requiresAuth: true,
    }),
  getSpecificationStatus: () =>
    ApiUtil({
      url: '/api/v1/dict/specification_status',
      method: 'get',
      requiresAuth: true,
    }),
  getSpecificationStatusSpecial: (str) =>
    ApiUtil({
      url: `/api/v1/specs/statuses${str}`,
      method: 'get',
      requiresAuth: true,
    }),
  getStrengthGrade: () =>
    ApiUtil({
      url: '/api/v1/dict/strength_grade',
      method: 'get',
      requiresAuth: true,
    }),
  getCargoQuarry: () =>
    ApiUtil({
      url: '/api/v1/dict/name_good?material_type=1',
      method: 'get',
      requiresAuth: true,
    }),
  getLprStatuses: () =>
    ApiUtil({
      url: '/api/v1/dict/lpr-statuses',
      method: 'get',
      requiresAuth: true,
    }),
}
