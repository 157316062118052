import seaPortsService from '@/services/portsService.js'
import {filterOrders} from "@/utils/helper";
import quarryService from "@/services/quarryService";
import {handleError} from "@/utils/api/handleError";

export default {
  namespaced: true,
  state: {
    ports: [],
    meta: null,
    portDetail: null,
    perPage: '20',
    isPortsLoading: false,
    filters: [],
    tempPortTableHeader: [],
    portSchema: {},
    portSchemaFull: {},
    portHeaderList: [],
    portHeaderListFull: [],
  },
  getters: {
    ports (state) {
      return state.ports
    },
    meta (state) {
      return state.meta
    },
    portDetail (state) {
      return state.portDetail
    },
    portSchema (state) {
      return state.portSchema
    },
    getIsPortsLoading (state) {
      return state.isPortsLoading
    },
    filters (state) {
      return state.filters
    },
    getPerPage (state) {
      return state.perPage
    },
    tempPortTableHeader (state) {
      return state.tempPortTableHeader
    },
    portHeaderList (state) {
      return state.portHeaderList
    }
  },
  actions: {
    async getPorts ({ commit }, params) {
      commit('SET_IS_PORTS_LOADING', true)
      const { data } = await seaPortsService.getPorts(params)

      commit('SET_PORTS', data?.data || [])
      commit('SET_PORTS_META', data.meta)
      commit('SET_IS_PORTS_LOADING', false)
    },
    async getPortDetails ({ commit }, id) {
      const { data } = await seaPortsService.getPortDetail(id)

      commit('SET_PORT_DETAIL', data?.data)
    },
    async getPortHeader ({ commit }, params) {
      const { data } = await seaPortsService.getPortSchema()

      commit('SET_PORT_HEADER_LIST', {
        data: data,
        params: params,
      })
    },
    async searchPorts ({ commit }, params) {
      const { data } = await seaPortsService.searchPort(params)

      commit('SET_PORTS', data?.data || [])
      commit('SET_PORTS_META', data.meta)
    },
    async getPortsFilters ({ commit }) {
      const { data } = await seaPortsService.getPortsFilters()

      commit('SET_PORTS_FILTERS', data.data)
    },
    async createPortsFilters ({ commit }, body) {
      const { data } = await seaPortsService.createPort(body)

      commit('SET_PORTS_FILTERS', data.data)
    },
    async deletePortTemplateFilter (_, id) {
      await seaPortsService.deletePortsFilters(id)
    },
    async updateColumnWidth({ commit }, params) {
      try {
        await seaPortsService.portsAddColumns(params)
        commit('SET_TEMP_PORT_TABLE_HEADER', params.columns)
      } catch (e) {
        handleError(e)
      }
    },
  },
  mutations: {
    SET_PORTS (state, ports) {
      state.ports = ports.map(el => {
        return {
          id: el.id,
          dashboard_permissions: el.dashboard_permissions,
          cells: [
            {
              name: 'id',
              value: el.id
            },
            {
              name: el.inn,
              value: 'inn'
            },
            {
              name: el.name,
              value: 'name'
            },
            {
              name: el.status,
              value: 'status'
            },
            {
              name: el.comment,
              value: 'comment'
            },
            {
              name: el.legal_address_symbol,
              value: 'address'
            },
          ],
          company: el.company,
          work_schedule: el.work_schedule,
        }
      })
    },
    SET_PORTS_META(state, meta) {
      state.meta = meta
    },
    SET_PORT_DETAIL(state, port) {
      state.portDetail = port
    },
    SET_IS_PORTS_LOADING(state, value) {
      state.isPortsLoading = value
    },
    SET_PORTS_FILTERS(state, value) {
      state.filters = value
    },
    SET_PORTS_SORT(state, params) {
      const { id, sort } = params
      state.portSchema = state.portSchema.map(item => {
        return item.id === id ? {
          ...item,
          sort: sort === 'default' ? 'ASC' : sort === 'ASC' ? 'DESC' : 'default'
        } : {
          ...item,
          sort: 'default'
        }
      })
    },
    SET_PORTS_PER_PAGE(state, value) {
      state.perPage = value
    },
    SET_PORT_HEADER_LIST(state, value) {
      let { schemas } = value.data.data
      let temp = []

      if (schemas._order?.length) {
        schemas._order = schemas._order.map(item => {
          // проверяем первая загрузка или обновление колонок, если повторная загрузка, то проверяем наличие такой колоноки ранее и пушим значение в стейт, если она была ранее
          if (state.tempPortTableHeader) {
            let index = state.tempPortTableHeader.findIndex(el => el.includes(item))

            if (index === -1) {
              temp.push(item)
            } else {
              temp.push(state.tempPortTableHeader[index])
            }
          } else {
            temp.push(item)
          }

          return item.split('?')[0]
        })
      }

      const schemasFull = schemas

      if (schemas._order?.length > 0) {
        const schemasOrders = schemas._order

        schemas = filterOrders(schemasOrders, schemas)
      }

      state.portSchema = schemas
      state.portSchemaFull = schemasFull

      const headerTable = []
      const headerTableFull = []

      function headerConstruct(vSchema, vTable) {
        for (let i = 0; i < Object.keys(vSchema).length; i++) {
          const key = Object.keys(vSchema)[i];

          if (key !== '_order' && key !== '_filters') {
            vTable.push(
              {
                id: key,
                value: vSchema[key],
                sort: key === value?.params?.sort
                  ? 'ASC'
                  : `-${key}` === value?.params?.sort
                    ? 'DESC'
                    : 'default'
              });
          }
        }
      }

      headerConstruct(schemas, headerTable)
      headerConstruct(schemasFull, headerTableFull)

      const quarryHeaderList = {
        schemas: headerTable,
      }

      const quarryHeaderListFull = {
        schemas: headerTableFull
      }

      state.tempPortTableHeader = temp;
      state.portHeaderList = quarryHeaderList
      state.portHeaderListFull = quarryHeaderListFull;
    },
    SET_TEMP_PORT_TABLE_HEADER(state, value) {
      state.tempPortTableHeader = value
    },
  },
}
