<template>
  <div
    class="address-widget"
    :class="['address-widget-input-address_wrap', {
      unfocused: !isAddressFocused,
      invalid: isValid
    }]"
  >
    <div class="address-widget__remove">
      <div
        class="address-widget__remove__button"
        @click.stop="removeAddress"
        role="button"
      >
        <SvgIcon name="close" width="10" height="10" />
      </div>
    </div>
    <label for="suggestMapLoadValue" class="modal-input-name">Фактический адрес</label>
    <input
      type="text"
      :id="`suggestMapLoadValue-${uniquerIndex}`"
      class="input-group-item address-widget__input"
      placeholder="Введите фактический адрес"
      v-model="address"
      @focus="() => applyFocus(true)"
      @blur="() => applyFocus(false)"
      @input="setAddressDebounce"
    >
    <div class="address-widget__activision">
      <div
        class="specification__copy-route address-widget__sync"
        role="button"
        :class="{
          disabled: !isSyncMode
        }"
        @click="toggleSyncMode"
      >
        <SvgIcon
          name="sync"
        />
      </div>
      <BaseModalInput
        class="input-group-item"
        placeholder="Координаты"
        @input="setCoodsDebounced"
        v-model="coords"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import SvgIcon from '@/components/base/SvgIcon.vue';
import { loadYandexMaps } from '@/services/yandexMapLoader.js';
import BaseModalInput from '@/components/base/BaseModalInput.vue';
import { geocode } from '@/utils/specification/geocoder.js';
import { debounce } from '@/utils/helper.js'

const emits = defineEmits([
  'update-address',
  'remove-address'
])

const props = defineProps({
  defaultAddress: Object,
  isValid: {
    type: Boolean,
    default: false
  },
})

const addressItem = ref({})
const address = ref('')
const coords = ref('')
const uniquerIndex = Math.random()
const loadSuggest = ref(null)
const isAddressFocused = ref(null)
const isSyncMode = ref(true)
const lastChange = ref(null)

const setLastChange = (field) => {
  lastChange.value = field
}

const toggleSyncMode = () => {
  isSyncMode.value = !isSyncMode.value
  if (isSyncMode.value) {
    syncCoordsAndAddress()
  }
}

const applyFocus = (focus) => {
  setTimeout(() => isAddressFocused.value = focus, 300)
}

const getCoords = async () => {
  if (!address.value) return ''
  coords.value = await geocode(address.value, '', { returnedResult: 'coords' })

  return coords.value
}

const getAddress = async () => {
  if (!coords.value) return ''
  address.value = await geocode(coords.value)

  return address.value
}

const syncCoordsAndAddress = async () => {
  if (lastChange.value === 'address' || !addressItem.value.coordinates) {
    coords.value = addressItem.value.coordinates ? addressItem.value.coordinates.join(',') : await getCoords()

    if (!addressItem.value.coordinates) {
      addressItem.value = await geocode(coords.value, '', { returnedResult: 'address-item' })
    }
  }

  const address = {
    ...addressItem.value,
    address: await getAddress(),
    city: addressItem.value.region,
  }

  emits('update-address', address)
}

const setAddressDebounce = debounce(async () => {
  setLastChange('address')
  addressItem.value.address = address.value
  emits('update-address', addressItem.value)
}, 700)

const setCoodsDebounced = debounce(async () => {
  setLastChange('coords')
  const item = await geocode(coords.value, '', { returnedResult: 'address-item' })

  addressItem.value = {
    ...addressItem.value,
    ...item,
    city: addressItem.value.region,
    address: addressItem.value.address ? addressItem.value.address : address.value,
  }

  if (isSyncMode.value) {
    const name = await geocode(coords.value)
    address.value = name
    addressItem.value.address = name
  }

  emits('update-address', addressItem.value)
}, 700)

const removeAddress = () => {
  emits('remove-address')
}


onMounted(async () => {
  if (props.defaultAddress) {
    address.value = props.defaultAddress.address
    coords.value = props.defaultAddress.coordinates.join(',')
  }

  await loadYandexMaps({ apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY });
  loadSuggest.value = new ymaps.SuggestView(`suggestMapLoadValue-${uniquerIndex}`);

  loadSuggest.value.events.add('select', async (event) => {
    address.value = event.get('item').value
    addressItem.value.address = event.get('item').value

    if (isSyncMode.value) {
      addressItem.value = await geocode(address.value, '', { returnedResult: 'address-item' })
      setLastChange('address')
      syncCoordsAndAddress()
    }

    if (!isSyncMode.value) {
      emits('update-address', {
        ...addressItem.value,
        address: address.value
      })
      return
    }
  })
})
</script>

<style lang="scss">
.address-widget {
  position: relative;

  &.invalid {
    input {
      border: 1px solid #d72028;

      &::placeholder {
        color: #d72028;
      }
    }

    label {
      color: #d72028;
    }
  }

  &__remove {
    display: flex;
    justify-content: flex-end;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 30px;
      height: 30px;

      padding: 10px;
      background: #e7e7e7;
      border-radius: 4px;
    }
  }

  &__activision {
    display: flex;
  }

  &__input {
    border: 1px solid #efefef;
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 10px;
  }

  &__sync {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;

    background: #0055e8;
    border-radius: 6px;
    margin-right: 12px;
    flex-shrink: 0;
    cursor: pointer;

    svg {
      width: 21px;
      height: 18px;
    }

    &.disabled {
      background-color: #f0f0f0;
      pointer-events: all;
      opacity: 1;
    }
  }

  .input-group-item {
    width: 100%;
  }
}
.address-widget-input-address_wrap.unfocused ymaps[class*=suggest] {
  display: none !important;
  visibility: hidden !important;
  pointer-events: none !important;
  width: 0 !important;
  height: 0 !important;
}
</style>
