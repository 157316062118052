import ApiUtil from '@/utils/api';

export default {
  getQuarry: (params = {}) =>
    ApiUtil({
      url: '/api/v1/quarries',
      method: 'get',
      requiresAuth: true,
      params,
    }),
  getQuarrySearch: (name, params = null) =>
    ApiUtil({
      url: `/api/v1/quarries/search/${name}`,
      method: 'get',
      requiresAuth: true,
      params,
    }),
  getQuarryHeader: () =>
    ApiUtil({
      url: '/api/v1/schemas/quarries',
      method: 'get',
      requiresAuth: true,
    }),
  getQuarryXlsx: (params = {}) =>
    ApiUtil({
      url: '/api/v1/quarries/xls',
      method: 'get',
      requiresAuth: true,
      responseType: 'blob',
      params,
    }),
  createQuarry: (data) => {
    return ApiUtil({
      url: '/api/v1/quarries',
      method: 'post',
      requiresAuth: true,
      data,
    });
  },
  deleteQuarry: (id) => {
    return ApiUtil({
      url: `/api/v1/quarries/${id}`,
      method: 'delete',
      requiresAuth: true,
    });
  },
  editQuarry: (data, id) => {
    return ApiUtil({
      url: `/api/v1/quarries/${id}`,
      method: 'patch',
      requiresAuth: true,
      data,
    });
  },
  createHoldingQuarry: (data) => {
    return ApiUtil({
      url: '/api/v1/dict/holding',
      method: 'post',
      requiresAuth: true,
      data,
    });
  },
  disabledQuarry: (id) => {
    return ApiUtil({
      url: `/api/v1/quarries/${id}/disable`,
      method: 'post',
      requiresAuth: true,
    })
  },
  getQuarryDetail: (id) =>
    ApiUtil({
      url: `/api/v1/quarries/${id}`,
      method: 'get',
      requiresAuth: true,
    }),
  quarryAddColumns: (data) => {
    return ApiUtil({
      url: '/api/v1/quarries/columns',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  updateResponseQuarry: ({ id, managerId }) => {
    return ApiUtil({
      url: `/api/v1/quarries/responsible-users/update/${id}`,
      method: 'put',
      requiresAuth: true,
      data: { id: managerId.user_id },
    })
  },
};
