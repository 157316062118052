<template>
  <BaseModal
    :is-open="isOpen"
    @close="closeModal"
    :bigModal="true"
    :clientsMiniModal="true"
    :contactFaceModal="contactFaceModal"
  >
    <div class="modal-container w-100">
      <div class="clients-modal">
        <h2 class="clients-modal__title">{{ Title }}</h2>
        <h4 class="clients-modal__subtitle" v-if="SubTitle">{{ SubTitle }}</h4>
        <div class="modal-content">
          <div>
            <input
              type="search"
              placeholder="Поиск"
              v-if="Search"
              class="search"
            />
            <slot></slot>
          </div>
        </div>
        <div class="clients-modal__buttons">
          <button v-if="ModalButtonShow" class="clients-modal__button" @click="submitForm">
            {{ ModalButton }}
          </button>
          <button
            class="clients-modal__button-repeal"
            v-if="ModalButtonRepealShow"
          >
            {{ ModalButtonRepeal }}
          </button>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue'

export default {
  name: 'BigModal',
  data() {
    return {
      ClientsModalSize: true,
    }
  },
  components: {
    BaseModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    Title: {
      type: String,
      default: '',
    },
    SubTitle: {
      type: String,
      default: '',
    },
    ModalButton: {
      type: String,
      default: '',
    },
    ModalButtonShow: {
      type: Boolean,
      default: true
    },
    ModalButtonRepeal: {
      type: String,
      default: '',
    },
    ModalButtonRepealShow: {
      type: Boolean,
      default: false,
    },
    Search: {
      type: Boolean,
      default: false,
    },
    contactFaceModal: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    show(option) {
      if (option != null) {
        if (option.type != null) this.type = option.type
      }
    },
    closeModal() {
      this.$emit('close')
    },
    submitForm() {
      this.$emit('submit-form')
    },
  },
}
</script>

<style scoped lang="scss">
.ps {
  max-height: 90vh;
  overflow-x: hidden;
}

.clients-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 90vh;
  padding-bottom: 20px;
  padding-top: 20px;
}

.modal-content {
  margin-right: 5px;
  padding-right: 10px;
  overflow: auto;
  scrollbar-color: #999 #f1f1f1;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #999;
  }
}

.clients-modal__title {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0a2333;
  margin-bottom: 15px;
  padding-right: 50px;
}

.clients-modal__subtitle {
  font-family: "Manrope";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #102f43;
  margin-bottom: 24px;
}

.clients-modal__buttons {
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
  //margin-bottom: 20px;
}

.clients-modal__button {
  background: #ffa500;
  border-radius: 8px;
  height: 52px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  margin-right: 6px;
  font-size: 14px;
  line-height: 150%;
  //margin-bottom: 20px;
}

.clients-modal__button-repeal {
  background: #ffffff;
  border-radius: 8px;
  height: 52px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #0a2333;
  border: 1px solid #efefef;
  margin-left: 6px;
}

.search {
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 16px 12px;
}

.search::placeholder {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #102f43;
}
</style>
