<template>
  <base-modal
    :is-open="isOpen"
    @close="closeModal"
    :ClientsMiniModal="ClientsMiniModalSize"
    class="clients-modal"
  >
    <div class="modal-container">
      <div
        class="clients-mini-modal"
        :class="{ 'active-height': activeHeight }"
      >
        <h3
          class="clients-modal__title"
          :class="{ 'active-padding': activePadding }"
        >
          {{ Title }}
        </h3>
        <h4
          class="clients-modal__subtitle"
          :class="{ 'active-padding': activePadding }"
        >
          {{ SubTitle }}
        </h4>
        <div>
          <input
            type="search"
            placeholder="Поиск"
            v-if="Search"
            class="client-modal__search"
            :class="{ 'active-margin': activeMargin }"
          />
          <slot></slot>
        </div>

        <div
          class="clients-modal__buttons"
          :class="{ 'active-padding': activePadding }"
        >
          <button class="clients-modal__button" @click="approved">
            {{ ModalButton }}
          </button>
          <button
            class="clients-modal__button-repeal"
            v-if="ModalButtonRepealShow"
            @click="closeModal"
          >
            {{ ModalButtonRepeal }}
          </button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/base/BaseModal";
import ScrollbarComponent from "@/components/ScrollbarComponent";

export default {
  name: "ClientsModal",
  data() {
    return {
      ClientsMiniModalSize: true,
    };
  },
  components: {
    ScrollbarComponent,
    BaseModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    Title: {
      type: String,
      default: "",
    },
    SubTitle: {
      type: String,
      default: "",
    },
    ModalButton: {
      type: String,
      default: "",
    },
    ModalButtonRepeal: {
      type: String,
      default: "",
    },
    ModalButtonRepealShow: {
      type: Boolean,
      default: false,
    },
    Search: {
      type: Boolean,
      default: false,
    },
    activePadding: {
      type: Boolean,
      default: false,
    },
    activeHeight: {
      type: Boolean,
      default: false,
    },
    activeMargin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    show(option) {
      if (option != null) {
        if (option.type != null) this.type = option.type;
      }
    },
    closeModal() {
      this.$emit("close");
    },
    approved() {
      this.$emit("approved");
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.clients-mini-modal {
  width: 100%;
  // height: 90vh;
  padding: 16px 32px 8px;
}

.active-height {
  height: 100%;
  padding: 0;
}

.active-padding {
  padding: 0 32px;
}

.active-margin {
  margin: 0 32px;
}

.modal-container {
  width: 100%;
}

//.clients-mini-modal {
//  display: flex;
//  flex-direction: column;
//  width: 100%;
//  overflow-x: hidden;
//}

.clients-modal__title {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #102f43;
  margin-bottom: 20px;
}

.clients-modal__subtitle {
  font-family: "Manrope";
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #102f43;
  margin-bottom: 22px;
}

.clients-modal__buttons {
  display: flex;
  margin-top: 10px;
}

.clients-modal__button {
  font-family: "Manrope";
  background: #ffa500;
  border-radius: 8px;
  height: 44px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  margin-right: 6px;
  font-size: 13px;
}

.clients-modal__button-repeal {
  background: #ffffff;
  border-radius: 8px;
  height: 52px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #0a2333;
  border: 1px solid #efefef;
  margin-left: 6px;
}

.client-modal__search {
  border: 1px solid #efefef;
  border-radius: 6px;
  padding: 16px 12px;
  width: 278px;
}

.search::placeholder {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #102f43;
}
</style>
