<template>
  <svg :class="className" :style="style" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    },
    custom: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    iconPath() {
      try {
        let icon = require(`@/assets/svg/${this.name}.svg`);
        if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
          icon = icon.default;
        }

        return icon.url;
      } catch (ex) {
        console.warn(ex);
      }
    },

    className() {
      return 'svg-icon svg-icon--' + this.name;
    },

    style() {
      let width = this.width ? `width: ${this.width}px;` : '';
      let height = this.height ? `height: ${this.height}px;` : '';
      return width + height;
    }
  }
};
</script>

<style>
.svg-icon {
  fill: currentColor;
  height: 1em;
  width: 1em;
}
</style>
