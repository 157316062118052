<template>
  <div class="fake-input__label">{{ title }}</div>
  <div
    class="fake-input__text"
    :class="{
      error: errorMessage,
      empty: !modelValue
    }"
  >
    <span>{{ modelValue }}</span>
    <span class="rouble">"</span>
  </div>
  <div
    v-if="errorMessage"
    class="fake-input__error"
  >
    {{ errorMessage }}
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: String,
  modelValue: [String, Number],
  errors: [Array, undefined],
})

const errorMessage = computed(() => {
  if (!props.errors || !props.errors.length) return null
  return props.errors[0].$message ? props.errors[0].$message : 'Поле заполнено неверно'
})

</script>

<style scoped lang="scss">

.fake-input__label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #A4A4A4;
  display: block;
  margin-bottom: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fake-input__text {
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border: 1px solid #efefef;
  border-radius: 6px;
  font-size: 12px;
  background: #f0f0f0;
  font-weight: 700;

  &.error {
    border-color: red;
  }

  &.empty {
    color: #a9a9a9;
  }

  .fake-input__error {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #d72028 !important;
  }

}

</style>
