<template>
  <div class="table-clients-page">
    <BackgroundComponent class="table-clients-container">
      <QuarryHeader
        @toggle-filter="filterState = !filterState"
      />
      <div v-if="modalEditState">
        <QuarryModal
          :is-open="modalEditState"
          @close="modalEditState = false"
          :is-modal-edit="true"
          :is-quarry-page="false"
        />
      </div>
      <AlertModal ref="modalDelete">
        <template v-slot:button>
          <BaseButton @click="() => $refs.modalDelete.closeModal()">
            Нет
          </BaseButton>
        </template>
      </AlertModal>
      <div class="display-flex width-100 global-table" :class="{ 'open-filter': filterState }">
        <FilterWidget
          v-if="filterState"
          @filter-submit="filter"
          @filter-reset="filterReset"
          :filters-array="filterModel"
          @update-state="updateFilterState"
          filter-page="quarry"
        />
        <BaseTableMenu
          :is-open="menuState"
          :style="{
            left: menuPosition.left,
            top: menuPosition.top,
          }"
        >
          <DashboardDialogMenu
            current-entity="quarry"
            :permissions="quarryData.dashboard_permissions"
            @edit-entity="showEdit"
            @copy-entity-info="copyQuarryInfo"
            @change-responsible="changeResponsible"
            @link-specification="linkSpecification"
            @delete-entity="modalDelete"
          />
        </BaseTableMenu>
        <BaseTable
          :key="`table-quarry-${tempQuarryTableHeader.length}`"
          :table-head="quarryHeader.schemas"
          :table-body="quarryTableList"
          :table-header-params="tempQuarryTableHeader"
          :is-loading="isQuarryLoading"
          @right-click="onRightClick"
          @sortBy="sortBy"
          @update-width="updateWidth"
        >
          <template #item="{ value, name, specificationId }">
            <router-link
              :to="{ name: 'QuarryCardPage', params: { id: specificationId } }"
              v-if="name === 'name'"
              class="client-name"
            >
              {{ value }}
            </router-link>
            <router-link
              v-else-if="name === 'id'"
              :to="{ name: 'QuarryCardPage', params: { id: value } }"
              class="td-client-id"
            >
              {{ value }}
            </router-link>
            <div
              v-else-if="name === 'responsible'"
            >
              {{ value?.name }}
              {{ value?.family_name }}
            </div>
            <div v-else-if="name === 'fact_addresses'">
              {{ getFatAddress(value) }}
            </div>
            <div v-else>
              {{ value }}
            </div>
          </template>
        </BaseTable>
      </div>
      <div
        v-if="!searchResultState"
        class="no-result display-f__jc-sb-ai-c display-f__jc-c"
      >
        Ничего не найдено
      </div>
      <AlertModal ref="alert"/>
      <AlertModal
        ref="alertQuarryDisabled"
        :close-button="true"
        close-text="Нет"
      />
    </BackgroundComponent>
    <div>
      <ChangeResponseModal
        :is-open="isOpenChangeResponsible"
        :list="responseList"
        :current-id="quarryData?.id"
        :current-responsible-id="quarryData?.responsible?.id"
        url-request="quarry/updateResponseQuarry"
        @responsibleModal:close="closeResponseModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, useStore } from 'vuex'
import BackgroundComponent from '@/components/BackgroundComponent.vue'
import QuarryHeader from '@/components/Quarry/QuarryHeader.vue'
import DashboardDialogMenu from '@/components/Dashboard/DashboardDialogMenu.vue'
import BaseTable from '@/components/base/BaseTable.vue'
import BaseTableMenu from '@/components/base/BaseTableMenu.vue'
import QuarryModal from '@/components/QuarryModals/QuarryModal.vue'
import quarryService from '@/services/quarryService.js'
import AlertModal from '@/components/AlertModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import { positionContextMenu } from '@/utils/helper.js'
import FilterWidget from '@/components/filter-templates/FilterWidget.vue';
import { computed } from 'vue';
import ChangeResponseModal from '@/components/Specifications/ChangeResponseModal.vue';

export default {
  name: 'TableQuarryPage',
  data() {
    return {
      filterState: false,
      menuState: false,
      modalEditState: false,
      modalDeleteState: false,
      menuPosition: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      filters: '',
      statePreloader: true,
      currentQuarryOnClick: null,
      isOpenChangeResponsible: false,
    }
  },
  setup() {
    const store = useStore()
    const filterModel = computed(() => store.getters['filter/filterModel']);
    store.dispatch('filter/getFilterModel', 'quarries');

    return {
      filterModel
    }
  },
  components: {
    ChangeResponseModal,
    FilterWidget,
    QuarryHeader,
    QuarryModal,
    AlertModal,
    BaseButton,
    BaseTable,
    BaseTableMenu,
    DashboardDialogMenu,
    BackgroundComponent,
    SvgIcon,
  },
  computed: {
    ...mapGetters({
      quarry: 'quarry/quarryList',
      quarryTableList: 'quarry/quarryTableList',
      quarryHeader: 'quarry/quarryHeaderList',
      holding: 'dict/holding',
      nameGood: 'dict/nameGood',
      quarryData: 'quarry/getSelectedQuarry',
      searchResultState: 'quarry/quarrySearchResult',
      account: 'account/account',
      quarryPerPage: 'quarry/quarryPerPage',
      tempQuarryTableHeader: 'quarry/tempQuarryTableHeader',
      responseList: 'users/responseList',
      isQuarryLoading: 'quarry/getIsQuarryLoading',
    }),
  },
  methods: {
    async modalDelete() {
      this.$refs.modalDelete.show(
        'Внимание!',
        'Вы уверены, что хотите удалить карьер?',
        {
          nameButton: 'Да',
        },
        () => {
          this.deleteQuarry()
        }
      )
    },
    async getQuarryHeaderList(params) {
      try {
        await this.$store.dispatch('quarry/getQuarryHeader', params)
      } catch (e) {
        this.$refs.alert.show(this.$t('alert.error'), e, {
          nameButton: 'Понятно',
        })
      }
    },
    async getQuarryList(params) {
      try {
        await this.$store.dispatch('quarry/getQuarry', params)
      } catch (error) {
        throw new Error(error)
      } finally {
        this.statePreloader = false
      }
    },
    async linkSpecification() {
      const currentQuarry = this.currentQuarryOnClick;

      await this.$store.dispatch('specifications/setSpecificationDetail', {
        company_unload: {
          name: currentQuarry.name,
          inn: currentQuarry.inn,
        },
      });

      await this.$router.push({ name: 'CreateSpecificationPage' });
    },
    async sortBy(item) {
      let sort = {}
      let query = {}
      if (item.sort === 'default') {
        query = { ...this.$route.query, sort: item.id }
        this.$router.push({ query })
        sort['sort'] = item.id
      }
      if (item.sort === 'ASC') {
        query = { ...this.$route.query, sort: `-${item.id}` }
        this.$router.push({ query })
        sort['sort'] = `-${item.id}`
      }
      if (item.sort === 'DESC') {
        this.$router.replace({ name: 'TableQuarryPage' })
      }

      await this.getQuarryHeaderList(sort)
      await this.getQuarryList({
        ...query,
        page: 1,
        per_page: this.quarryPerPage,
      })
    },
    async disabledQuarry() {
      try {
        const { quarryData } = this;

        await quarryService.disabledQuarry(quarryData.id);

        await this.getQuarryList(this.$route.query);
      } catch (error) {
        if (error.response?.message) {
          this.$refs.alert.show('Ошибка', error.response?.message, {
            nameButton: 'Понятно',
            color: '#FFA500',
          });
        }
      }
    },
    async deleteQuarry() {
      try {
        const { quarryData } = this

        await quarryService.deleteQuarry(quarryData.id)
        await this.$store.dispatch('quarry/getQuarry', {
          ...this.$route.query
        })
      } catch (error) {
        const { data } = error.response

        if (data.data?.message) {
          this.$refs.alertQuarryDisabled.show(
            'Внимание',
            data.data?.message,
            {
              nameButton: 'Да',
              color: '#FFA500',
            },
            () => {
              this.disabledQuarry()
            }
          )
        }
      } finally {
        this.modalDeleteState = false
      }
    },
    hideMenu() {
      this.menuState = false
    },
    onLeftClick(event) {
      const id = event.currentTarget.parentNode.dataset.id;
      // переход на детальную страницу + id страниц
      this.$router.push({ name: 'QuarryCardPage', params: { id } });
    },
    onRightClick({ event, id }) {
      const current = this.quarry.filter((item) => item.id === id)[0]
      this.currentQuarryOnClick = current

      this.$store.dispatch('quarry/setSelectedQuarry', current)

      this.menuState = true

      setTimeout(() => {
        positionContextMenu(event, this.menuPosition)
      })
    },
    showEdit() {
      this.modalEditState = true
      this.menuState = false
    },
    async copyQuarryInfo(text) {
      const innTitle = 'ИНН компании: '

      const currentFactAddress = `, Фактический адрес: ${this.currentQuarryOnClick.fact_addresses[0].address}`

      text = innTitle + this.currentQuarryOnClick.inn + currentFactAddress

      const copyInput = document.querySelector('.js-copyInput')

      copyInput.value = text
      copyInput.focus()
      copyInput.select()
      document.execCommand('copy')
    },
    filter(filterList) {
      this.statePreloader = true
      this.$router.replace({ query: { ...this.$route.query, page: 1, per_page: this.quarryPerPage } })
      this.getQuarryList({ ...filterList, page: 1, per_page: this.quarryPerPage })

      this.$store.dispatch('quarry/setQuarryFilterParams', filterList)
    },
    async filterReset() {
      this.$router.replace({
        name: 'TableQuarryPage',
        query: {
          page: 1,
          per_page: this.quarryPerPage
        }
      })
      this.$store.dispatch('quarry/setQuarryFilterParams', {})
      await this.getQuarryList({ page: 1, per_page: this.quarryPerPage })
    },
    updateFilterState(filterList) {
      this.$store.dispatch('filter/updateCurrentFilter', filterList);
    },
    async getCargoQuarry() {
      try {
        await this.$store.dispatch('dict/getCargoQuarry');
      } catch (error) {
        console.log(error)
      }
    },
    async updateWidth(params) {
      await this.$store.dispatch('quarry/updateColumnWidth', params)
    },
    async getFilterQuarry() {
      await this.$store.dispatch('filter/getFilterQuarryList')
    },
    changeResponsible() {
      this.isOpenChangeResponsible = true;
    },
    async closeResponseModal(isDataUpdate = false) {
      this.isOpenChangeResponsible = false
      if (!isDataUpdate) return;
      await this.getQuarryList(this.$route.query)
    },
    getFatAddress (addresses) {
      if (!addresses?.length) {
        return ''
      }

      return addresses[0]?.address || ''
    }
  },
  async created() {
    await this.getQuarryHeaderList(this.$route.query)
    await this.getQuarryList(this.$route.query)
    await this.getCargoQuarry()
    await this.getFilterQuarry();
  },
  mounted() {
    window.addEventListener('click', this.hideMenu)
  },
  unmounted() {
    window.removeEventListener('click', this.hideMenu)
  },
}
</script>

<style scoped lang="scss">
.table-clients-container {
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 10px;
  overflow: hidden;
}

.table-clients-page {
  .ps {
    width: 100%;
  }

  .clients-page {
    background-color: #f4f4f8;
  }

  .flex-container {
    display: flex;
  }

  .v-expansion-panel__shadow {
    box-shadow: none !important;
  }

  .table {
    //overflow: auto;
    position: relative;
  }

  .table .v-table {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    //overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .content-header {
    display: flex;
    margin-top: 35px;
    align-items: center;
    margin-bottom: 24px;
  }

  .clients-page {
    background: #f2f4f6;
  }

  .content {
    background: #f4f4f8;
    border-radius: 12px;
    margin-left: 18px;
    padding: 20px;
    /*height: 100vh; */
  }

  .tr-title {
    background: #f4f4f8;
    font-family: "Manrope";
    font-weight: 500;
    font-size: 10px;
    line-height: 19px;
    color: #0a2333;
  }

  .td-item {
    font-family: "Manrope";
    font-size: 10px !important;
    line-height: 19px;
    // text-transform: capitalize;
  }

  .client-name {
    text-decoration: none;
    font-weight: 600;
    color: #0055e8;
  }

  .td-client-id {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .td-total-revenue {
    font-weight: 600;
    color: #0f537e;
  }

  .td-phone {
    font-weight: 400;
    color: #afafaf;
  }

  .td-items {
    font-weight: 400;
    color: #5a5a5a;
    font-size: 10px;
  }

  .td-contact-person {
    font-weight: 400;
    color: #0a2333;
  }

  .td-client-id {
    font-weight: 500;
    color: #11142d;
  }

  .flex-block {
    display: flex;
  }

  .sidebar-content {
    height: 100%;
  }
}

.no-result {
  min-height: 400px;
}
</style>
