<template>
  <div class="base-notification" :class="[computePosition, computeState, {'active': modelValue}]">
    <slot>
      <template v-if="hasIcon">
        <SvgIcon :name="computeIcon" width="24" height="24" />
      </template>
      <p class="base-notification__text">
        {{ text }}
      </p>
    </slot>
  </div>
</template>

<script setup>

import { computed, watch } from 'vue';
import SvgIcon from '@/components/base/SvgIcon.vue';

/**
 * @typedef {'error' | 'success' | 'default'} NotificationType
 * @typedef {'top' | 'right' | 'bottom' | 'left'} PositionType
 */
const props = defineProps({
  /**
   * @type {Array<PositionType>}
   * @default () => ['bottom', 'right']
   */
  position: {
    type: Array,
    default: () => ['bottom', 'right']
  },
  text: String,
  /**
   * @type {NotificationType}
   * @default 'success'
   */
  type: {
    type: String,
    default: 'success'
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  timeout: {
    type: Number,
    default: 3000
  },
  hasIcon: {
    type: Boolean,
    default: true
  },
  iconName: String,
})
const emit = defineEmits(['update:modelValue'])

const computePosition = computed(() => {
  if (!props.position?.length) return

  return {
    top: props.position.findIndex(item => item === 'top') !== -1,
    right: props.position.findIndex(item => item === 'right') !== -1,
    bottom: props.position.findIndex(item => item === 'bottom') !== -1,
    left: props.position.findIndex(item => item === 'left') !== -1,
  }
})

const computeState = computed(() => {
  if (!props.type) return 'default'

  return {
    success: props.type === 'success',
    error: props.type === 'error',
    default: props.type === 'default',
  }
})

const computeIcon = computed(() => {
  if (!props.hasIcon) return null

  if (props.iconName) return props.iconName

  if (props.type === 'success' || props.type === 'default') return 'done-icon'

  return 'error-icon'
})

const hideNotificationTimeout = () => {
  if (props.timeout !== null)
    setTimeout(() => {
      emit('update:modelValue', false)
    }, props.timeout)
}

watch(() => props.modelValue, (newValue) => {
  if (newValue) hideNotificationTimeout()
}, {
  immediate: true,
})
</script>

<style lang="scss" scoped>

.base-notification {
  width: fit-content;
  max-width: 400px;
  min-height: 48px;
  padding: 8px 16px;
  background-color: #FFFFFF;
  position: fixed;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  z-index: 100;
  pointer-events: none;

  transition: .3s opacity ease;
  opacity: 0;
  border: 2px solid #E6E6E6;

  &__text {
    font-size: 14px;
    line-height: 20px;
  }

  //notification show
  &.active {
    opacity: 1;
  }

  //position
  &.bottom {
    bottom: 24px;
  }

  &.right {
    right: 24px;
  }

  &.left {
    left: 24px;
  }

  &.top {
    top: 24px;
  }

  //state
  &.default {
    border-color: #E6E6E6;
  }

  &.error {
    border-color: #d72028;
  }

  &.success {
    border-color: #53b374;
  }
}
</style>