<template>
  <div class="filters-templates">
    <div v-if="!isInteractiveMapsPage" class="filters__title">
      <h2>Фильтры</h2>
    </div>
    <div v-if="isInteractiveMapsPage" class="filters-templates__item-wrap">
      <v-expansion-panels class="expansion-panels__custom multiple" v-for="filter in filters" :key="filter" multiple>
        <v-expansion-panel class="expansion-panel__custom">
          <v-expansion-panel-title class="expansion-panel__title">
            <BaseCheckbox
              v-model="filter.isFilterActive"
              @click="(e) => { e.stopPropagation() }"
              class="filter-checkbox"
            />
            <span class="filter-title">
              {{ filter.filterNameRussian ? filter.filterNameRussian : '' }}
            </span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="expansion-panels__custom" v-for="item of filter.filterData">
              <p class="expansion-panel__title">
                {{ item.title }}
              </p>
              <div v-if="item.type === 'price'" class="display-flex ui-range">
                <div v-for="(elem) of item.filter" :key="elem.id">
                  <input
                    :id="elem.id"
                    type="number"
                    :placeholder="elem.label"
                    class="filter-input-start input-number-hidden"
                    v-model="elem.value"
                  />
                </div>
              </div>
              <div v-if="item.type === 'text'" class="display-flex">
                <div v-for="elem of item.filter" :key="elem">
                  <label :for="elem.id" class="filter-label">
                    {{ elem.label }}
                  </label>
                  <input
                    :id="elem.id"
                    type="text"
                    class="filter-input"
                    :placeholder="elem.text"
                    v-model="elem.value"
                  />
                </div>
              </div>
              <div v-if="item.type === 'checkbox'">
                <CheckboxComponent
                  v-for="elem of item.filter"
                  :key="elem.id"
                  :id="elem.id"
                  :checkboxName="elem.text"
                  v-model="elem.value"
                />
              </div>
              <div v-if="item.type === 'combobox'">
                <BaseDropDownExtended
                  v-model="item.select"
                  :options="item.items"
                />
              </div>
              <div v-if="item.type === 'search'">
                <BaseDropDownExtended
                  :taggable="true"
                  v-model="item.select"
                  :is-loading="isFilterHintLoading"
                  :is-internal-search="false"
                  :options="dynamicOptions[item.title] || []"
                  track-by="id"
                  label="title"
                  @search-updated="(searchQuery) => getSearchHintData(searchQuery, item.filter.url, item.title)"
                />
              </div>
              <div class="ui-date" v-if="item.type === 'date_range'">
                <div class="ui-date__item" v-for="(elem) of item.filter" :key="elem.id">
                  <div class="ui-date__label">{{ elem.label }}</div>
                  <BaseDatepicker
                    placeholder="гггг-мм-дд"
                    customFormatting="yyyy-LL-dd"
                    :dateValue="elem.value"
                    @changeValue="(newDate) => { elem.value = newDate }"
                  />
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else class="filters-templates__item-wrap">
      <div class="expansion-panels__custom">
        <FilterTemplates
          ref="refFilterTemplates"
          @update="updateState"
          @select="selectUpdateFilterValue"
          :filterPage="filterPage"
        />

        <div
          v-for="item of filters"
          :key="item"
          class="expansion-panel__custom"
        >
          <p class="expansion-panel__title">
            {{ item.title }}
          </p>

          <div v-if="item.type === 'price'" class="display-flex ui-range">
            <div v-for="(elem, index) of item.filter" :key="elem.id">
              <label :for="elem.id" class="filter-label">
                {{ elem.label }}
              </label>
              <input
                :id="elem.id"
                type="number"
                class="filter-input-start input-number-hidden"
                :class="{ 'filter-input-finish': index > 0 }"
                v-model="elem.value"
              />
            </div>
          </div>
          <div class="ui-date" v-if="item.type === 'date_range'">
            <div class="ui-date__item" v-for="(elem) of item.filter" :key="elem.id">
              <div class="ui-date__label">{{ elem.label }}</div>
              <BaseDatepicker
                placeholder="гггг-мм-дд"
                customFormatting="yyyy-LL-dd"
                :dateValue="elem.value"
                @changeValue="(newDate) => { elem.value = newDate }"
              />
            </div>
          </div>
          <div v-if="item.type === 'text'" class="display-flex">
            <div v-for="elem of item.filter" :key="elem">
              <label :for="elem.id" class="filter-label">
                {{ elem.label }}
              </label>
              <input
                :id="elem.id"
                type="text"
                class="filter-input"
                :placeholder="elem.text"
                v-model="elem.value"
              />
            </div>
          </div>
          <div v-if="item.type === 'checkbox'">
            <CheckboxComponent
              v-for="elem of item.filter"
              :key="elem.id"
              :id="elem.id"
              :checkboxName="elem.text"
              v-model="elem.value"
            />
          </div>
          <div v-if="item.type === 'combobox'">
            <BaseDropDownExtended
              v-model="item.select"
              :options="item.items"
            />
          </div>
          <div v-if="item.type === 'search'">
            <BaseDropDownExtended
              :taggable="true"
              v-model="item.select"
              :is-loading="isFilterHintLoading"
              :options="dynamicOptions[item.title] || []"
              :is-internal-search="false"
              track-by="id"
              label="title"
              @search-updated="(searchQuery) => getSearchHintData(searchQuery, item.filter.url, item.title)"
            />
          </div>
        </div>
      </div>
    </div>
    <div @click="filterUpdate" v-if="currentFilter" class="template-update">
      Обновить шаблон
    </div>
    <div class="filters__buttons">
      <div class="display-flex">
        <button class="filters__btn-apply" @click="filterSubmit">
          Применить
        </button>
      </div>
      <button class="filters__btn-erase" @click.prevent="filterReset">
        <SvgIcon name="erase"/>
        <BaseTooltip text="Очистить все" />
      </button>
    </div>
  </div>
</template>

<script>
import CheckboxComponent from '@/components/CheckboxComponent.vue'
import SvgIcon from '@/components/base/SvgIcon.vue';
import FilterTemplates from '@/components/filter-templates/FilterTemplates.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/dist/vue-router';
import BaseDropDownExtended from '@/components/base/BaseDropDownExtended.vue';
import filterTemplates from '@/hooks/filterTemplates';
import filterService from '@/services/filterService';
import BaseDatepicker from '@/components/base/BaseDatepicker.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import { dumbCopy } from '@/utils/helper';
import BaseTooltip from '@/components/base/BaseTooltip.vue';

export default {
  name: 'FilterWidget',
  components: { BaseTooltip, BaseCheckbox, BaseDatepicker, BaseDropDownExtended, FilterTemplates, SvgIcon, CheckboxComponent },
  emits: ['filter-submit', 'filter-reset'],
  props: {
    filtersArray: Array,
    filterPage: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const filterTemplatesBlock = ref(null)
    const route = useRoute()
    const currentFilter = ref(null)
    const filterModel = ref(null)
    const { updateFilterFromUrl } = filterTemplates()
    const getComboboxItems = (elem) => {
      const items = [];
      elem.forEach((item) => {
        items.push(item.text)
      })
      return {
        select: [],
        items: items,
      }
    }

    const dynamicOptions = ref({});
    const isFilterHintLoading = ref(false)

    const isInteractiveMapsPage = computed(() => {
      return props.filterPage === 'interactiveMaps'
    })

    const initMultipleFilters = () => {
      filterModel.value = props.filtersArray.map(filter => {
        const filterData = dumbCopy(filter.filterData).map(internalFilter => {
          let filterItem = dumbCopy(internalFilter);
          if (filterItem.type === 'combobox') {
            filterItem = { ...filterItem, ...getComboboxItems(filterItem.filter) }
          }
          return filterItem;
        })
        return {
          filterData: filterData,
          filterName: filter.filterName,
          filterNameRussian: filter.filterNameRussian,
          isFilterActive: filter.isFilterActive,
        }
      })
      return filterModel.value;
    }

    const filters = computed(() => {
      if (isInteractiveMapsPage.value) {
        return initMultipleFilters();
      }
      filterModel.value = JSON.parse(JSON.stringify(props.filtersArray)).map(v => {
        if (v.type === 'combobox') {
          v = { ...v, ...getComboboxItems(v.filter) }
        }
        return v;
      })
      return filterModel.value;
    })
    const filterUpdate = () => {
      filterTemplatesBlock.value.openEditModal(currentFilter.value);
    }

    const updateFilterValue = (val) => {
      currentFilter.value = val;
      setTimeout(() => {
        updateFilterFromUrl(filters.value, route.query);
      }, 200)
    }

    return {
      filterTemplatesBlock,
      currentFilter,
      isInteractiveMapsPage,
      updateFilterFromUrl,
      filterUpdate,
      updateFilterValue,
      initMultipleFilters,
      isFilterHintLoading,
      dynamicOptions,
      filters
    }
  },
  computed: {
    isSpecificationPage() {
      return this.filterPage === 'specifications';
    }
  },
  watch: {
    filters: {
      handler: async function(value) {
        if (!this.isSpecificationPage) return;
        await this.$store.dispatch('filter/updateCurrentSpecificationFilters', this.filterPrepareData(value));
      },
      deep: true,
    }
  },
  methods: {
    selectUpdateFilterValue() {
      this.updateFilterValue();
      setTimeout(() => {
        this.filterSubmit();
      }, 400)
    },
    getPrice(filterForm) {
      if (filterForm.length > 0) {
        const [revenueFrom, revenueTo] = filterForm
        if (!revenueFrom.value && !revenueTo.value && revenueFrom.value !== 0 && revenueTo.value !== 0) {
          return null
        }
        return `${revenueFrom.value},${revenueTo.value}`
      }
      console.error('Ошибка фильтра', filterForm)
      return null
    },
    getCheckboxTrueFalse(filterForm) {
      const selected = filterForm.filter(option => option.value);
      if (!selected || selected.length === 0 || selected.length > 1) return null;
      return selected[0].returnValue;
    },
    findComboboxId(list, listHasId) {
      const idList = []

      list.forEach((elem) => {
        const elemValid = listHasId.filter((itemListHasId) => {
          if (elem == itemListHasId.text) {
            return itemListHasId.dataValue
          }
        })

        if (elemValid && elemValid.length > 0) {
          idList.push(elemValid[0].dataValue)
        }
      })

      return idList
    },
    getCombobox(filterForm) {
      if (filterForm.filter.length > 0) {
        const { select, items } = filterForm;
        const currentSelected = []
        select.forEach((elem) => {
          const elemValid = items.filter((itemsElem) => {

            if (elem === itemsElem) {
              return itemsElem
            }
          })

          if (elemValid && elemValid.length > 0) {
            currentSelected.push(elemValid)
          }
        })
        return this.findComboboxId(currentSelected, filterForm.filter);
      }
      console.error('Ошибка фильтра', filterForm)
      return null
    },
    filterPrepareData(filterList) {
      const filter = {};
      filterList.forEach(element => {
        if (element.type === 'price') {
          filter[element.name] = this.getPrice(element.filter);
        }
        if (element.type === 'checkbox') {
          filter[element.name] = this.getCheckboxData(element.filter);
        }
        if (element.type === 'combobox') {
          filter[element.name] = this.getCombobox(element).join(',');
          if (filter[element.name] === '') {
            filter[element.name] = null;
          }
        }
        if (element.type === 'search') {
          filter[element.name] = this.getSearchTypeData(element);
        }
        if (element.type === 'date_range') {
          filter[element.name] = this.getDateRangeData(element);
        }
      });
      return filter;
    },
    getDateRangeData(element) {
      const [from, to] = element.filter;
      const fromQuery = from.value || null;
      const toQuery = to.value || null;
      if (toQuery === null && fromQuery === null) return null;
      return `${from.value ? from.value : null},${to.value ? to.value : null}`
    },
    getSearchTypeData(element) {
      if (!element.hasOwnProperty('select')) return null;
      const query = element.select.map(item => item.id).join(',');
      return query !== '' ? query : null;
    },
    getCheckboxData(element) {
      if (element[0].hasOwnProperty('returnValue')) {
        return this.getCheckboxTrueFalse(element);
      }
      // TODO унифицировать ответ с бека, чтобы значение всех чекбоксов было в ключе в поле value, а не в dataValue
      const res = element.filter(v => v.value).map(v => v.dataValue ? v.dataValue : v.id).join(',');
      return res === '' ? null : res;
    },
    async getSearchHintData(searchString, queryUrl, title) {
      if (!queryUrl || (!searchString && searchString !== '')) return;
      try {
        if (searchString?.trim() === '') {
          this.dynamicOptions[title] = [];
          return;
        }
        this.isFilterHintLoading = true
        const url = new URL(queryUrl);
        const query = `${process.env.VUE_APP_API_URL}${url.pathname}/${searchString}${url.search}`;
        const res = await filterService.getFilterSearchHintData(query);
        this.dynamicOptions[title] = res.data.data;
      } catch(e) {
        this.dynamicOptions[title] = [];
        console.error('Ошибка загрузки хинтинга')
      } finally {
        this.isFilterHintLoading = false
      }
    },
    filterSubmit() {
      if (!this.isInteractiveMapsPage) {
        this.$emit('filter-submit', this.filterPrepareData(this.filters))
        return
      }
      const multipleFilters = this.filters.map(filter => {
        return {
          filterName: filter.filterName,
          query: this.filterPrepareData(filter.filterData),
          isFilterActive: filter.isFilterActive
        }
      })
      this.$emit('filter-submit', multipleFilters)
    },
    filterReset() {
      if (this.isInteractiveMapsPage) {
        this.initMultipleFilters();
        this.$emit('filter-reset');
        return;
      }
      this.$emit('filter-reset');
      this.updateFilterValue()
      this.$refs.refFilterTemplates.clearValue();
    },
    updateState() {
      this.$emit('updateState', this.filterPrepareData(this.filters))
    },
  },
  mounted() {
    this.updateFilterFromUrl(this.filters, this.$route?.query);
  }
};
</script>

<style lang="scss">

:global(.v-input__details) {
  display: none;
}

:global(.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line) {
  padding: 0;
}

:global(.v-label.v-field-label) {
  font-size: 12px;
}

:global(.v-label.v-field-label.v-field-label--floating) {
  font-size: 8px;
}

:global(.v-input__control) {
  background-color: #fff;
}

:global(.v-field--variant-filled .v-field__overlay) {
  border-radius: 0;
  background-color: #fff;
}

:global(.v-input__control .v-field) {
  padding-right: 0;
}

.filters-templates {
  margin-bottom: auto;
  max-height: calc(100vh - 146px);
  overflow: auto;

  &__item-wrap {
    .filter-checkbox {
      margin: unset;
      width: 30px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: unset;
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
      left: 0;

      .checkbox-name {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        cursor: pointer;
      }
    }

    .filter-title {
      padding-left: 30px;
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
    }

    overflow-y: auto;
    max-height: calc(100% - 130px);
  }
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}

.v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 1.1em);
}

.checkbox-name {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #0a2333;
}

.filters-templates {
  background: #f4f4f8;
  padding: 14px 13px 11px 15px;
  font-family: "Manrope";
  width: 307px;
  min-width: 240px;

  .filter-label {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6a6a6a;
  }

  .filter-input {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #d7d7d7;
    background: #ffffff;
    border-radius: 6px;
    font-size: 12px;
    width: calc(50% - 5px);
  }

  .ui-date {
    display: flex;
    justify-content: space-between;

    &__label {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    &__item {
      width: calc(50% - 5px);
    }
  }

  .ui-range {
    justify-content: space-between;

    div {
      width: calc(50% - 5px);
    }
  }

  .filter-input-start,
  .filter-input-finish {
    padding: 0 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #d7d7d7;
    background: #ffffff;
    font-size: 12px;
    width: 100%;

    &::placeholder {
      font-size: 14px;
    }
  }

  .v-expansion-panel-title__overlay {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0a2333;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    color: #0a2333;
  }

  .filters__title {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .template-name {
    background: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    height: 37px;
    padding: 0 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
  }

  .template-name__text {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #808191;
  }

  .template-name__arrows {
    display: flex;
    flex-direction: column;
  }

  .arrow-top {
    margin-bottom: 2px;
  }

  .arrow-bottom {
    margin-top: 1px;
  }

  .filters__btn-apply {
    background: #ffa500;
    color: #ffffff;
    border-radius: 6px 0px 0px 6px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    height: 35px;
    padding: 0 27px;
    display: flex;
    align-items: center;
    box-shadow: inset -1px 0px 0px rgba(255, 255, 255, 0.25);
  }

  .expansion-panel__custom {
    background-color: #f4f4f800;
    padding-left: 3px;
  }

  .expansion-panel__title {
    font-weight: 700;
    font-size: 16px;
    height: max-content;
    line-height: 20px;
    color: #0a2333;
    padding: 16px 0;
  }

  .v-expansion-panel-title--active > .v-expansion-panel-title__overlay {
    opacity: 0 !important;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
    margin-bottom: 10px;
    margin: 0 10px 10px 10px;
  }
}
</style>

<style lang="scss" scoped>
.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}

.v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 1.1em);
}

.checkbox-name {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #0a2333;
}

.filters-templates {
  .filter-label {
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6a6a6a;
  }

  .filter-input {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #d7d7d7;
    background: #ffffff;
    border-radius: 6px;
    width: calc(100% - 10px);
    font-size: 12px;
  }

  .filter-input-finish::placeholder,
  .filter-input-start::placeholder {
    font-size: 12px;
  }

  .v-expansion-panel-title__overlay {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0a2333;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    color: #0a2333;
  }

  .filters__title {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .template-name {
    background: #ffffff;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    height: 37px;
    padding: 0 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
  }

  .template-name__text {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #808191;
  }

  .template-name__arrows {
    display: flex;
    flex-direction: column;
  }

  .arrow-top {
    margin-bottom: 2px;
  }

  .arrow-bottom {
    margin-top: 1px;
  }

  .filters__btn-apply-arrow {
    background: #ffa500;
    border-radius: 0px 6px 6px 0px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expansion-panel__custom {
    background-color: #f4f4f800;
    padding-left: 3px;
  }

  .expansion-panel__title {
    font-weight: 700;
    font-size: 16px;
    height: max-content;
    line-height: 20px;
    color: #0a2333;
    padding: 16px 0;
  }

  .v-expansion-panel-title--active > .v-expansion-panel-title__overlay {
    opacity: 0 !important;
  }

  .v-expansion-panel-text__wrapper {
    padding: 0;
    margin-bottom: 10px;
  }

  .expansion-panels__custom {
    border: none;
    box-shadow: none;

    &.multiple {
      z-index: unset;

      .expansion-panels__custom {
        z-index: unset;
      }

      .expansion-panel__title[aria-expanded="false"] {
        border-radius: 0;
      }

      .expansion-panel__title:hover {
        opacity: unset;
        background: #f4f4f8;
      }
    }
  }

  .v-expansion-panel__shadow {
    box-shadow: none;
  }
}
</style>
