<template>
  <div>
    <v-snackbar
      v-model="isSnackbarActive"
      :timeout="1500"
      location="top-right"
      :color="snackbarColor"
      absolute
    >
      {{ snackbarText }}
    </v-snackbar>
    <div class="specification-card">
      <div class="specification-header-block">
        <div class="card__header mb-7 ml-1">
          <SvgIcon
            name="arrow-back"
            @click="goBack"
            class="ml-n4 mr-2 arrow-back"
          />
          <h1 class="card__title">Карточка спецификации</h1>
          <div v-if="getPermissions('edit')" class="button-edit" @click="showEdit">
            <SvgIcon name="edit-white"/>
            <BaseTooltip text="Редактировать" />
          </div>

          <div class="specification-header-block__date">
            <span class="date-time-style mr-2">{{ date.date }}</span>
            <span class="date-time-style">{{ date.time }}</span>
          </div>
          <router-link
            :to="`/interactive-map?specification_id=${specification.id}`"
            :class="['specification-header__open-map', { disabled: !specification.id }]"
            target="_blank"
          >
            <SvgIcon
              :name="!specification.id ? 'map-link-dark' : 'map-link'"
            />
            <BaseTooltip text="Открыть в разделе “Интерактивные карты”" />
          </router-link>
        </div>

        <div class="actions-block">
          <div class="display-flex">
            <div class="specification-card-id mr-2">
              ID {{ specification.id }}
            </div>

            <div v-if="specification.request_type" class="request-type mr-2">
              <span>
                {{ specification.request_type }}
              </span>
            </div>

            <div v-if="specification.specification_status" class="request-type mr-2">
              <span>
                {{ specification.specification_status }}
              </span>
            </div>

            <div v-if="specification.request_status" class="request-type mr-2">
              <span>
                {{ specification.request_status }}
              </span>
            </div>

          </div>
        </div>
      </div>
      <div class="card-item">
        <div class="display-flex">
          <div class="block-right">
            <div>
              <div class="organization-data">
                <div class="specification-card__about-company">
                  <div>
                    <h3 class="specification-title">
                      {{ specification.company_unload?.client_name }}
                    </h3>
                    <span class="specification-card-label">
                      {{ specification.company_unload?.inn }}
                    </span>
                  </div>
                  <div @click="openClient" class="specification-person">
                    <SvgIcon name="person-white"/>
                    <BaseTooltip text="Открыть карточку контрагента спецификации" />
                  </div>
                </div>
              </div>
              <div class="separation"></div>
            </div>

            <div>
              <div class="cargo-block">
                <div class="flex-block mb-5">
                  <div class="dates-works-image mr-3">
                    <SvgIcon name="calendar_today"/>
                  </div>
                  <div>
                    <div class="specification-card-label">Даты работ</div>
                    <div class="dates-works-text">
                      {{ start }} - {{ end }}
                    </div>
                  </div>
                </div>

                <h3 class="specification-title mb-3">Груз</h3>

                <div class="specification-cargo">
                  <div class="specification-cargo__list">
                    <CargoItem title="Тип материала" :info="specification.material_type === true ? 'Нерудная продукция' : 'Сельскохозяйственная продукция'" />
                    <CargoItem title="Марка прочности" :info="specification.strength_grade" />
                    <CargoItem title="Наименование груза" :info="specification.name_good" />
                    <CargoItem title="Фракция" :info="specification.fraction" />
                  </div>
                </div>
              </div>
              <div class="separation"></div>

              <div class="delivery-cost-type">
                <h3 class="specification-title mb-4">Условия оплаты</h3>
                <div class="display-f__jc-sb">
                  <div class="specification-card-item">
                    <div class="mb-6">
                      <div class="specification-card-label">Тип оплаты</div>
                      <div class="specification-subtitle">
                        {{ specification.payment_type === 1 ? 'Предоплата' : 'Постоплата' }}
                      </div>
                    </div>
                    <div v-if="specification.payment_type !== 1" class="mb-1">
                      <div class="specification-card-label">
                        Тип документа
                      </div>
                      <div class="specification-subtitle">
                        {{ specification.document_type }}
                      </div>
                    </div>
                  </div>
                  <div v-if="specification.payment_type !== 1" class="specification-card-item">
                    <div class="mb-6">
                      <div class="specification-card-label">Кол. дней</div>
                      <div class="specification-subtitle">
                        {{ specification.postponement_days }}
                      </div>
                    </div>
                    <div class="mb-1">
                      <div class="specification-card-label">Аванс,
                        {{ specification.prepayment_type === 'amount' ? '₽' : '%' }}
                      </div>
                      <div class="specification-subtitle">
                        {{ specification.prepaid_expense }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="separation"></div>

              <div class="responsible">
                <div class="specification-card-label mb-2">
                  Ответственный
                </div>
                <div class="specification-responsible-name">
                  {{ `${specification?.user?.name || ''} ${specification?.user?.family_name || ''}` }}
                </div>
                <div class="specification-responsible-text">
                  {{ specification?.user_comment || '' }}
                </div>
              </div>
              <div class="separation"></div>

              <div class="agreement">
                <h3 class="specification-title mb-3">Согласование</h3>
                <div class="specification-responsible__block" v-if="specification.admin">
                  <div class="display-flex">
                    <div class="circle agreed-circle-1 position-relative">
                      <SvgIcon name="line-2" class="dashed-2"/>
                    </div>

                    <div class="display-f__jc-sb width-100">
                      <div class="agreed">
                        {{ specification.admin_approval ? 'Согласовано' : 'Не согласовано' }}
                      </div>
                      <div>
                        <span class="agreed__date-time-style mr-2">
                          {{ formatDate(specification.admin_approval).date }}
                        </span>
                        <span class="agreed__date-time-style">
                          {{ formatDate(specification.admin_approval).time }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="dates-works-text ml-6">
                    <span>
                      {{ specification.admin?.name }}
                    </span>
                    <span v-if="specification.admin.middle_name">
                      {{ specification.admin.middle_name }}
                    </span>
                  </div>
                  <div class="specification-card-label ml-6 mb-6">
                    {{ specification.admin_comment }}
                  </div>
                </div>
                <div class="specification-responsible__block" v-if="specification.logist">
                  <div class="flex-block">
                    <div class="circle agreed-circle-2"></div>
                    <div class="at-work mb-2">
                      {{
                        specification.logist_approval
                          ? "В работе"
                          : "Не согласовано"
                      }}
                    </div>
                  </div>
                  <div class="specification-responsible-name mb-6 ml-6">
                    {{ `${specification?.logist?.name || ''} ${specification?.logist?.middle_name || ''}` }}
                  </div>
                  <div class="specification-card-label ml-6 mb-6">
                    {{ specification?.logist_comment }}
                  </div>
                </div>
                <div class="specification-responsible__block flex-block">
                  <div class="circle agreed-circle-2"></div>
                  <div class="at-start mb-2" :style="getSpecificationStatusColor(specification.specification_status)">
                    {{ specification.specification_status }}
                  </div>
                </div>
              </div>
              <div class="separation"></div>
              <div class="lpr-status">
                <h3 class="specification-title mb-3">Согласование ЛПР</h3>
                <div class="lpr-status__status" v-if="specification.lpr_status">
                  {{ specification.lpr_status.title }}
                </div>
              </div>
            </div>
          </div>

          <div class="block-left">
            <div
              @click="toogleAccordion('route')"
              class="route"
            >
              <div class="display-f__jc-sb">
                <div class="flex-block">
                  <h3 class="specification-title">Маршрут</h3>
                  <button
                    v-if="specification.route"
                    class="specification__copy-route"
                    @click.stop="copyRoute"
                    type="button"
                  >
                    <SvgIcon
                      name="share"
                      width="18"
                      class="copy_input-icon"
                    />
                  </button>
                </div>
                <SvgIcon
                  name="arrow_down"
                  class="arrow-down"
                  :class="{ 'arrow-down-active': isActiveBlock.route }"
                />
              </div>
              <RouteSchema
                v-if="isActiveBlock.route"
                :route="specification.route"
                :transit-points="transitPoints"
              />
            </div>
            <div class="separation"></div>

            <div
              @click="toogleAccordion('logistics')"
              class="logistics"
            >
              <div class="display-f__jc-sb">
                <div class="flex-block">
                  <h3 class="specification-title">Логистика</h3>
                </div>
                <SvgIcon
                  name="arrow_down"
                  class="arrow-down"
                  :class="{ 'arrow-down-active': isActiveBlock.logistics }"
                />
              </div>

              <div v-if="isActiveBlock.logistics" class="display-f__jc-sb mt-2" @click.stop>
                <div class="mr-3">
                  <div class="specification-card-label">Плечо, км</div>
                  <div class="logistics-text">
                    {{ formatter('distance', specification.distance) }}
                  </div>
                </div>

                <div class="mr-3">
                  <div class="specification-card-label">
                    Кол.план. рейсов
                  </div>
                  <div class="logistics-text">
                    {{ specification.flights }}
                  </div>
                </div>

                <div>
                  <div class="specification-card-label">Кол. машин</div>
                  <div class="logistics-text">
                    {{ specification.cars }}
                  </div>
                </div>
              </div>
            </div>
            <div class="separation"></div>

            <div
              @click="toogleAccordion('delivery_terms')"
              class="delivery-terms"
            >
              <div class="display-f__jc-sb">
                <div class="flex-block">
                  <h3 class="specification-title">Условия поставки</h3>
                </div>
                <SvgIcon
                  name="arrow_down"
                  class="arrow-down"
                  :class="{
                    'arrow-down-active': isActiveBlock.delivery_terms,
                  }"
                />
              </div>

              <div
                v-if="isActiveBlock.delivery_terms"
                class="display-f__jc-sb mt-2"
                @click.stop
              >
                <div class="mr-4">
                  <div class="shipment-label">Норма загрузки, тонн</div>
                  <div class="shipment-text">
                    {{ formatter('loading_rate', parseInt(specification.loading_rate)) }}
                  </div>
                </div>

                <div class="mr-3">
                  <div class="shipment-label">
                    Суточная норма клиента, тонн
                  </div>
                  <div class="shipment-text">
                    {{ formatter('daily_rate', specification.daily_rate) }}
                  </div>
                </div>

                <div>
                  <div class="shipment-label">Кол. груза, тонн</div>
                  <div class="shipment-text">
                    {{ formatter('total_weight', specification.total_weight) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="separation"></div>

            <div
              @click="toogleAccordion('shipment_cost')"
              class="shipment-cost"
            >
              <div class="display-f__jc-sb">
                <div class="flex-block mb-3">
                  <h3 class="specification-title">Стоимость поставки</h3>
                </div>
                <SvgIcon
                  name="arrow_down"
                  class="arrow-down"
                  :class="{
                    'arrow-down-active': isActiveBlock.shipment_cost,
                  }"
                />
              </div>
              <div class="shipment-label__line"></div>
              <div v-if="isActiveBlock.shipment_cost" class="shipment-label__content" @click.stop>
                <div class="display-flex mb-4">
                  <div class="mr-3">
                    <div class="shipment-label--bold">Стоимость материала</div>
                    <div class="shipment-text--bold">
                      {{ formatNumber(specification.cost_material) ? formatNumber(specification.cost_material) : '0' }}₽
                    </div>
                  </div>

                  <div>
                    <div class="shipment-label--bold">Коэффициент</div>
                    <div class="shipment-text--bold">
                      {{ specification?.coefficient }}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h3 class="specification-title mb-3">
                  Стоимость перевозки 1 тонны
                </h3>

                <div class="display-flex">
                  <div class="transportation-cost__item mr-3">
                    <div class="specification-item-text-style mr-3">
                      АДТ
                    </div>
                    <div class="transportation-cost__item-price">
                      {{ specification?.cost_transportation_ADT?.toLocaleString() }}₽
                    </div>
                  </div>

                  <div class="transportation-cost__item mr-3">
                    <div class="specification-item-text-style mr-2">
                      Клиент
                    </div>
                    <div class="transportation-cost__item-price">
                      {{ specification?.cost_transportation_client?.toLocaleString() }}₽
                    </div>
                  </div>

                  <div class="transportation-cost__item">
                    <div class="specification-item-text-style mr-2">
                      ЛПР
                    </div>
                    <div class="transportation-cost__item-price">
                      {{ specification?.recommended_price?.toLocaleString() }}₽
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="shipment-cost__table">
              <table class="width-100">
                <tr>
                  <td></td>
                  <td class="specification-item-text-style">Материал</td>
                  <td class="specification-item-text-style">Услуги</td>
                </tr>
                <tr>
                  <td class="transportation-cost__shaft">Общий вал</td>
                  <td class="transportation-cost__price">
                    {{
                      specification.capital_material_total ? `${specification.capital_material_total.toLocaleString()}₽` : '0₽'
                    }}
                  </td>
                  <td class="transportation-cost__price">
                    {{
                      specification.capital_service_total ? `${specification.capital_service_total.toLocaleString()}₽` : '0₽'
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="transportation-cost__shaft">Дневной вал</td>
                  <td class="transportation-cost__price">
                    {{
                      specification.capital_material_daily ? `${specification.capital_material_daily.toLocaleString()}₽` : '0₽'
                    }}
                  </td>
                  <td class="transportation-cost__price">
                    {{
                      specification.capital_service_daily ? `${specification.capital_service_daily.toLocaleString()}₽` : '0₽'
                    }}
                  </td>
                </tr>
              </table>
            </div>

            <div class="separation"></div>
          </div>
        </div>
      </div>
      <div class="specification-card-buttons">
        <button
          v-if="getPermissions('crm')"
          class="specification-card-button"
          :disabled="loader1c"
          @click="createOrder1c">
          <BaseLoader v-if="loader1c" />
          {{ loader1c ? 'Выполняется заказ' : 'Создать заказ в 1С' }}
        </button>
        <button
          :class="['specification-card-button-delete', { disabled: !getPermissions('delete') }]"
          @click="openDeleteSpecification"
        >
          Удалить
        </button>
      </div>
    </div>
    <AlertModal ref="alert" :nameButton="'Понятно'"></AlertModal>
    <AlertModal
      ref="warning"
      :close-button="true"
      name-button="Нет"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AlertModal from '@/components/AlertModal';
import SvgIcon from '@/components/base/SvgIcon';
import { format } from 'date-fns';
import { copyToClipboard, getShareYandexLink, tableFormatter } from '@/utils/helper';
import CargoItem from '@/components/Specifications/CargoItem';
import BaseLoader from '@/components/base/BaseLoader';
import RouteSchema from '@/components/Specifications/RouteSchema.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';

export default {
  name: 'SpecificationCard',
  data() {
    return {
      pageId: this.$route.params.id,
      isSnackbarActive: false,
      snackbarColor: 'success',
      snackbarText: '',
      isActiveBlock: {
        route: false,
        logistics: false,
        shipment_cost: false,
        delivery_terms: false,
      },
      loader1c: false
    };
  },
  components: {
    BaseTooltip,
    RouteSchema,
    BaseLoader,
    CargoItem,
    SvgIcon,
    AlertModal,
  },
  computed: {
    ...mapGetters({
      specification: 'specifications/specificationDetail',
      company: 'company/company',
    }),

    date() {
      return this.formatDate(this.specification.created_at);
    },

    start() {
      return this.specification.start
    },

    end() {
      return this.specification.end
    },

    transitPoints() {
      return this.specification.route?.transit?.length ? this.specification.route?.transit : []
    }
  },
  methods: {
    //TODO Нужно поменять после корректировки бека
    getSpecificationStatusColor(status) {
      let color = '#ffa500';
      if (status === 'В работе') {
        color = '#53b374';
      }
      return { 'background-color': color }
    },
    async getSpecificationDetail() {
      try {
        await this.$store.dispatch(
          'specifications/getSpecificationDetail',
          this.pageId
        );
        await this.debagger();
      } catch (e) {
        this.$refs.alert.show('Ошибка', e, {
          type: 'error',
          nameButton: 'Понятно',
        });
      }
    },
    formatter(type, value) {
      return tableFormatter(type, value);
    },
    getPermissions(permission) {
      if (!this.specification?.dashboard_permissions) return false;
      return this.specification.dashboard_permissions[permission];
    },
    debagger() {
      console.log(this.specification);
    },

    goBack() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.replace({ name: 'SpecificationsTablePage' })
      }
    },

    openClient() {
      const id = this.specification?.company_unload?.client_id;
      this.$router.push({ name: 'ClientCardTablePage', params: { id } });
    },

    toogleAccordion(block) {
      this.isActiveBlock[block] = !this.isActiveBlock[block];
    },

    async showEdit() {
      await this.$store.dispatch(
        'specifications/setSpecificationDetail',
        this.specification
      );
      const id = this.specification.id;
      await this.$router.push({
        name: 'EditSpecificationPage',
        params: { id },
      });
    },

    async copyRoute() {
      // const shareLink = getShareYandexLink(this.specification.route);
      try {
        await copyToClipboard(this?.specification?.route?.url || 'no url provided');
        this.snackbarColor = 'success';
        this.snackbarText = 'Успешно скопировано';
        this.isSnackbarActive = true;
      } catch(error) {
        this.snackbarColor = 'red';
        this.snackbarText = 'Не удалось скопировать';
        this.isSnackbarActive = true;
      }
    },

    formatDate(date) {
      if (date !== undefined) {
        const editDate = new Date(date);
        return {
          date: format(editDate, 'dd.MM.yyyy'),
          time: format(editDate, 'HH:mm'),
        };
      } else {
        return {
          date: 'Нет Даты',
          time: 'Нет Времени',
        };
      }
    },
    formatNumber(number) {
      if (number) {
        let newNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return newNumber
      }
    },
    async createOrder1c() {
      try {
        this.loader1c = true;
        const response = await this.$store.dispatch('specifications/createOrder1c', this.specification.id)
        this.$refs.alert.show('Успешно', response.data.data.message, {
          nameButton: 'Окей',
          color: '#FFA500',
        });
        await this.getSpecificationDetail();

        this.loader1c = false;
      } catch (error) {
        console.error(error);
        this.$refs.alert.show('Ошибка', error, {
          nameButton: 'Понятно',
          color: '#FFA500',
        });
        this.loader1c = false;
      }
    },
    openDeleteSpecification() {
      if (!this.getPermissions('delete')) return;

      this.$refs.warning.show(
        'Внимание!',
        'Вы уверены, что хотите удалить спецификацию?',
        {
          type: 'error',
          nameButton: 'Да',
          color: '#FFA500',
        },
        () => {
          this.deleteSpec(this.specification.id)
          this.$refs.warning.closeModal()
          this.$router.push({ path: '/specifications-table-page' })
        }
      )
    },
    async deleteSpec(id) {
      try {
        await this.$store.dispatch('specifications/deleteSpecification', id);
      } catch (error) {
        console.log(error)
      }
    }
  },
  async created() {
    await this.getSpecificationDetail();
  },
  mounted() {
    console.log(this.specification);
  },
  unmounted() {
    this.$store.dispatch('specifications/clearSpecificationDetail', {})
  },
};
</script>

<style lang="scss">
.specification-card {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 28px);
  font-family: "Manrope";
  margin: 14px 0 14px 10px;
  min-width: 524px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 8px;

  .specification-header__open-map {
    background: #0055e8;
    border-radius: 6px;
    width: 39px;
    height: 34px;
    display: flex;
    margin-left: 25px;
    justify-content: center;
    align-items: center;
    transition: .2s;

    &.disabled {
      pointer-events: none;
      background-color: #f7f7f7;
      cursor: not-allowed;
      z-index: 10;
    }
  }

  .card-item {
    position: relative;
    overflow-y: auto;
  }

  .dashed {
    position: absolute;
    top: 49px;
    right: 50%;
    z-index: 1;
  }

  .dashed-2 {
    position: absolute;
    top: 10px;
    right: 50%;
    z-index: 1;
  }

  .arrow-down {
    margin-right: -14px;
    margin-top: -12px;

    &-active {
      rotate: 180deg;
    }
  }

  .arrow-back {
    cursor: pointer;
  }

  .specification-card-id {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #7d63ff;
    padding: 10px 14px;
    height: 40px;
    background: #f0edff;
    border-radius: 6px;
    white-space: nowrap;
  }

  .request-type {
    height: 40px;
    background: #f7f7f7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #808191;
  }

  .actions-block {
    .display-flex {
      max-width: 100%;
    }

    .request-type {
      overflow: hidden;
      max-width: 50%;

      span {
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &:hover {
        overflow: visible;
        flex-grow: 1;
        max-width: 90%;
      }
    }
  }

  .button-edit {
    background: #0055e8;
    border-radius: 6px;
    width: 39px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
  }

  .date-time-style {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #0a2333;
  }

  .agreed__item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a8a8a8;
  }

  .agreed__date-time-style {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a4a4a4;
  }

  .logistics {
    padding: 16px;
  }

  .logistics-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;
  }

  .actions-block {
    display: flex;
    justify-content: space-between;
  }

  .separation {
    width: 100%;
    background: #e9e9e9;
    height: 1px;
    margin-right: 0;
    margin-left: 0;
  }

  .block-right {
    border-right: 1px solid #e9e9e9;
    flex: 2;
  }

  .block-left {
    flex: 3;
  }

  .specification-header-block {
    padding: 17px 21px 16px 26px;
    border-bottom: 1px solid #e9e9e9;

    &__date {
      margin-left: auto;
    }
  }

  .copy_input {
    opacity: 0;
    position: absolute;
  }

  .route,
  .logistics,
  .delivery-terms {
    padding: 16px;

    .position-relative {
      margin-top: 12px;
    }
  }

  .organization-data {
    padding: 16px 16px 12px 16px;
  }

  .cargo-block {
    padding: 16px 16px 3px 16px;
  }

  .delivery-cost-type {
    padding: 16px;
  }

  .agreement {
    padding: 16px;
  }

  .lpr-status {
    padding: 16px;

    &__status {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0a2333;
    }
  }

  .responsible {
    padding: 16px;
  }

  .specification-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;
  }

  .specifications__cargo-select select {
    width: 100px;
  }

  .specification-card-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #828282;
  }

  .specification-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;
  }

  .specification-text-subtitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;
  }

  .specification-person {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffa500;
    border-radius: 8px;
    cursor: pointer;
    flex-shrink: 0;
  }

  .specification-card__about-company {
    display: flex;
    justify-content: space-between;
  }

  .specification-card-item {
    min-width: 83px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .specification-responsible-name {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;
    margin-bottom: 12px;
  }

  .specification-responsible {
    &__block {
      background-color: #fff;
      position: relative;

      &:first-of-type {
        z-index: 3;
      }

      &:nth-of-type(2) {
        z-index: 2;
      }

      &:nth-of-type(3) {
        z-index: 1;
      }

      &:last-of-type {
        &:before {
          content: none;
        }
      }

      &:before {
        position: absolute;
        content: "";
        left: 6px;
        top: 10px;
        border-left: 1px dashed #0055E8;
        height: calc(100% + 25px);
      }
    }
  }

  .specification-responsible-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #838383;
  }

  .agreed {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background: #0055e8;
    border-radius: 4px;
    padding: 4px 8px;
    margin-bottom: 16px;
  }

  .at-work {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background: #53b374;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .at-start {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    background: #ffa500;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .circle {
    width: 12px;
    height: 12px;
    border: 3px solid #0055e8;
    border-radius: 100%;
    margin-right: 12px;
    background: #ffffff;
    z-index: 11;
  }

  .agreed-circle-1 {
    margin-top: 7px;
  }

  .agreed-circle-2 {
    margin-bottom: 5px;
  }

  .border-location {
    border: 1px solid #0055e8;
    border-radius: 12px;
    width: 49px;
    height: 49px;
    display: flex;
    flex: none;
    align-items: center;
    margin-right: 12px;
    justify-content: center;
    background: #ffffff;
    z-index: 11;
  }

  .shipment-label {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #6a6a6a;
    margin-bottom: 4px;

    &--bold {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0a2333;
    }
    &__line {
      color: #e9e9e9;
      border-bottom: solid 1px;
      margin-bottom: 12px;
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  .shipment-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0a2333;

    &--bold {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0055e8;
    }
  }

  .shipment-cost {
    background: #f3f7fe;
    padding: 16px 16px 13px 16px;
  }

  .shipment-cost__table {
    background: #f3f7fe;
    padding: 0 9px 9px 9px;
  }

  .transportation-cost__item {
    display: flex;
    align-items: center;

    .specification-item-text-style {
      padding-top: 3px;
    }
  }

  .specification-item-text-style {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #0a2333;
  }

  .transportation-cost__item-price {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0055e8;
  }

  .transportation-cost__shaft {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #b0b0b0;
  }

  .transportation-cost__price {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    background: #ffa500;
    border-radius: 6px;
    padding: 4px 10px;
    text-align: center;
  }

  table {
    border-spacing: 6px 7px;
    border-collapse: separate;
  }

  .related-specifications {
    margin: 47px 16px;
  }

  .another-specification {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0055e8;
  }

  .separation-vertical {
    background: #e7e7e7;
    height: 20px;
    width: 1px;
    margin-right: 9px;
    margin-left: 9px;
  }

  .specification-card-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 16px;
    border-top: 1px solid #e9e9e9;
  }

  .specification-card-button {
    display: flex;
    align-items: center;

    &:disabled {
      background-color: rgba(#ffa500, 0.4);
    }

    span {
      margin-right: 8px;
    }
  }

  .specification-card-button {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    padding: 14px 16px;
    background: #ffa500;
    border-radius: 8px;
    margin-right: 8px;
  }

  .specification-card-button-delete {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #d72028;
    padding: 14px 16px;

    &.disabled {
      pointer-events: all;
      cursor: not-allowed;
    }
  }
}

.item-route {
  &.flex-block {
    align-items: flex-start;
  }

  &:first-child {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25px;
      width: 1px;
      border-right: 1px dashed #0055e8;
    }
  }

  &:last-child {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
    }

    .border-location + div {
      position: relative;
    }
  }
}

.specification-cargo {
  margin-bottom: 10px;
  margin-right: 10px;
  width: calc(100% + 10px);

  &__list {
    display: grid;
    grid-template-columns: 56% 1fr;
    gap: 20px 5px;
  }
}

.v-snackbar__wrapper {
  transform: translate(0%, 0%);
  right: 0;
  top: 0;
  bottom: unset;
  left: unset;
}

.specification__copy-route {
  margin-left: 4px;
  padding: 0 4px;
  display: flex;
}

</style>
