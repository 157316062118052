<template>
  <BaseCheckbox
    checkbox-name="Показывать историю"
    style="min-width: 170px; margin: 0;"
    class="is_hostory_checkbox"
    :value="isHistory" @update="updateIsHistory"
  />
</template>

<script setup>
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'

const $store = useStore()
const $route = useRoute()
const emit = defineEmits(['update:history'])
const isHistory = computed(() => {
  if (props.isInteractiveMap) return $store.getters['interactiveMap/getIsHistory']
  else return $store.getters['specifications/getIsHistory']
})

const props = defineProps({
  specificationsPerPage: Number,
  getSpecification: {
    type: Boolean,
    default: true
  },
  isInteractiveMap: {
    type: Boolean,
    default: false
  },
})

const updateIsHistory = (value) => {
  if (props.isInteractiveMap) $store.commit('interactiveMap/SET_IS_HISTORY', value)
  else $store.commit('specifications/SET_IS_HISTORY', value)
  emit('update:history', value)

  if (!props.getSpecification) return

  $store.dispatch('specifications/getSpecifications', {
    ...$route.query,
    page: $route.query.page || 1,
    per_page: props.specificationsPerPage || 20,
  })
}
</script>
