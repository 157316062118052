<template>
  <div class="contact-person-modal">
    <BigModal
      :activePadding="true"
      :activeHeight="true"
      :Title="Title"
      :ModalButton="ModalButton"
      :is-open="isOpen"
      @close="closeModal"
      @submit-form="onSubmit"
    >
      <form @submit.prevent="onSubmit">
        <ValidateEach
          v-for="(item, index) in collection"
          :key="index"
          :state="item"
          :rules="rules"
        >
          <template #default="{ v }">
            <ContactAddPhone
              @update-phones="updatePhones"
              ref="addPhone"
              :contact-numbers-list="item.contact_numbers"
              :is-modal-edit="modalEditState || isEditLocal"
              :existing-list="existingNumbers"
              :state-error="contactError"
              :is-validation="isCheckNumber"
              :is-edit-local="isEditLocal"
            />
            <BaseModalInput
              class="input-group-item"
              label="Email"
              :type="'email'"
              placeholder="Введите почту"
              v-model="v.email.$model"
              :errors="v.email.$errors"
            />
            <BaseModalInput
              class="input-group-item"
              label="ФИО"
              placeholder="Введите ФИО"
              v-model="v.name.$model"
              :errors="v.name.$errors"
            />
            <BaseModalInput
              class="input-group-item"
              label="Должность"
              placeholder="Введите должность"
              v-model="v.position.$model"
              :errors="v.position.$errors"
            />
            <BaseModalTextarea
              class="input-group-item"
              label="Комментарий"
              placeholder="Введите комментарий"
              v-model="v.comment.$model"
              :errors="v.comment.$errors"
            />
          </template>
        </ValidateEach>
      </form>
    </BigModal>
    <AlertModal ref="alert" nameButton="Понятно"/>
    <AlertModal ref="alertLinkPhone" :close-button="true" close-text="Закрыть"/>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import BigModal from '@/components/BigModal.vue'
import BaseModalInput from '@/components/base/BaseModalInput.vue'
import BaseModalTextarea from '@/components/base/BaseModalTextarea.vue'
import { ValidateEach } from '@vuelidate/components'
import ContactAddPhone from '@/components/Contacts/ContactAddPhone.vue'
import contactsService from '@/services/contactsService.js'
import AlertModal from '@/components/AlertModal.vue'
import validateForm from '@/hooks/validateForm.js';
import { contactSaveAdapter, contactValidationRules } from '@/utils/contact/contact.js';

export default {
  name: 'ContactPersonModal',
  data() {
    return {
      contactNumbers: [],
      isOpenContactPerson: false,
      Title: 'Контактное лицо',
      ModalButton: 'Сохранить',
      numberPhoneLength: 10,
      contactError: false,
      existingNumbers: [],
      isNumberUnavailable: false
    }
  },
  components: {
    BigModal,
    BaseModalInput,
    BaseModalTextarea,
    ValidateEach,
    ContactAddPhone,
    AlertModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    pageId: {
      type: [String, Number],
      required: false,
    },
    isModalEdit: {
      type: Boolean,
      default: false,
    },
    /**
     * @params 'quarry' | 'client' | 'seaport'
     */
    contactType: {
      type: String,
      default: 'client',
    },
    localSubmit: {
      type: Boolean,
      default: false
    },
    autoIncrement: {
      type: Number,
      default: 1
    },
    localList: {
      type: Array,
      default: () => [],
    },
    localCurrent: {
      type: Object,
      default: () => {
      }
    }
  },
  setup(props) {
    const store = useStore();
    const isEditLocal = props.localCurrent ? Object.entries(props.localCurrent).length !== 0 : false
    let cloneEdit = null

    const {
      isCheckNumber,
      oldEmail,
      customCheckEmail,
    } = validateForm(props.localSubmit, props.localList)

    const v = useVuelidate()

    const collection = props.isModalEdit || isEditLocal
      ? reactive([])
      : reactive([
        {
          name: '',
          position: '',
          email: '',
          comment: '',
        },
      ])

    let selectedContact = store.getters['contacts/getSelectedContact']

    let modalEditState = false

    // если нужно редактировать локально
    if (isEditLocal) {
      // клонируем объект чтобы не модифицировать пропс
      cloneEdit = structuredClone(props.localCurrent)

      collection.push(cloneEdit)
      oldEmail.value = cloneEdit.email;

      modalEditState = true
    }

    if (props.isModalEdit) {
      cloneEdit = structuredClone(selectedContact)
      collection.push(cloneEdit)

      oldEmail.value = cloneEdit.email;

      modalEditState = true
    }

    const rules = contactValidationRules(customCheckEmail)

    return {
      rules,
      collection,
      v,
      selectedContact,
      modalEditState,
      isCheckNumber,
      isEditLocal,
      cloneEdit
    }
  },
  methods: {
    updatePhones(item) {
      this.contactNumbers = item
    },
    async createContact(params) {
      try {
        const { data } = await contactsService.createContact(params)
        let paramsCurrent = {
          client_id: this.pageId,
        }

        if (this.contactType === 'quarry') {
          paramsCurrent = {
            quarry_id: this.pageId,
          }
        }

        if (this.contactType === 'seaport') {
          paramsCurrent = {
            sea_port_id: this.pageId
          }
        }

        await this.$store.dispatch('contacts/getContacts', paramsCurrent)

        this.closeModal()
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data?.errors)
        const msgErrorParse = msgError.join()
        const errorSplit = msgErrorParse.split('|')
        const textError = errorSplit[0]

        if (error.response.status === 422) {
          const pageLink = errorSplit[1]
          const pageLinkName = pageLink === 'client' ? 'table-clients-page' : pageLink === 'quarry' ? 'table-quarry-page' : pageLink === 'seaport' ? 'ports-page' : ''
          const id = errorSplit[2]

          if (id) {
            this.$refs.alertLinkPhone.show(
              'Внимание!',
              textError,
              {
                nameButton: 'Перейти на страницу',
                color: '#FFA500',
              },
              () => {
                this.$router.push({
                  path: `/${pageLinkName}/${id}`,
                })

                this.routLinkPage(`${pageLinkName},${id}`)
                this.closeModal()
              }
            );
          } else {
            const errorText = []
            for (let item in data?.errors) {
              errorText.push(data?.errors[item])
            }
            this.$refs.alert.show('Ошибка', errorText.join(', '), {
              type: 'error',
              nameButton: 'Понятно',
              color: '#FFA500',
            })
          }
        }
      }
    },
    async editContact(params, id) {
      try {
        const { data } = await contactsService.editContact(params, id)

        const paramsCurrent = {}

        switch (this.contactType) {
          case 'client':
            paramsCurrent.client_id = this.pageId
            break;
          case 'quarry':
            paramsCurrent.quarry_id = this.pageId
            break;
          case 'seaport':
            paramsCurrent.sea_port_id = this.pageId
        }

        await this.$store.dispatch('contacts/getContacts', paramsCurrent)

        this.closeModal()
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data?.errors)
        const msgErrorParse = msgError.join()
        const errorSplit = msgErrorParse.split('|')
        const textError = errorSplit[0]

        if (error.response.status === 422) {
          const pageLink = errorSplit[1]
          const pageLinkName = pageLink === 'client' ? 'table-clients-page' : 'table-quarry-page'
          const id = errorSplit[2]

          if (id) {
            this.$refs.alertLinkPhone.show(
              'Внимание!',
              textError,
              {
                nameButton: 'Перейти на страницу',
                color: '#FFA500',
              },
              () => {
                this.$router.push({
                  path: `/${pageLinkName}/${id}`,
                })

                this.routLinkPage(`${pageLinkName},${id}`)
                this.closeModal()
              }
            );
          } else {
            const errorText = []
            for (let item in data?.errors) {
              errorText.push(data?.errors[item])
            }
            this.$refs.alert.show('Ошибка', errorText.join(', '), {
              type: 'error',
              nameButton: 'Понятно',
              color: '#FFA500',
            })
          }
        }
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v.$validate();
      if (!isFormCorrect || this.$refs.addPhone[0].isLocalError) return;
      const params = contactSaveAdapter(this.collection[0], this.contactType, this.pageId, this.contactNumbers);

      if (!this.localSubmit) {
        if (this.isModalEdit) {
          params.id = this.collection[0].id;

          await this.editContact(params, this.collection[0].id);
        } else {
          await this.createContact(params)
        }
      } else {
        if (this.isEditLocal) {
          params.autoIncrement = this.cloneEdit.autoIncrement;
          this.$emit('edit', params)
          this.closeModal();
        } else {
          params.autoIncrement = this.autoIncrement;
          this.$emit('create', params);
          this.closeModal();
        }
      }
    },
    routLinkPage(pageInfo) {
      this.$emit('rout-link-page', pageInfo)
    },
    closeModal() {
      this.$emit('close')

      // очистили коллекцию
      for (let i = 0; i <= this.collection.length; i++) {
        this.collection.shift()
      }

      this.contactNumbers = []
      this.existingNumbers = []

      this.addCountForm()
    },
    addCountForm() {
      this.collection.push({
        name: '',
        position: '',
        email: '',
        comment: '',
        contact_numbers: [],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-item {
  margin-bottom: 16px;
}

.contact-person-modal {
  .modal-button__add {
    font-family: "Manrope";
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #0a2333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .margin-icon {
    margin-right: 7px;
  }

  .choose-option {
    border: 1px solid #efefef;
    border-radius: 8px;
    padding: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-left: 10px;
  }

  .modal-input-style__additional {
    width: 80px;
  }

  .select-modal {
    position: relative;
    display: inline-block;
  }

  .select-modal:after {
    content: url("@/assets/svg/blue-arrow-bottom.svg");
    padding: 0 8px;
    font-size: 12px;
    position: absolute;
    right: 14px;
    top: 24px;
    margin-left: 20px;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
    box-sizing: border-box;
  }

  select {
    padding-right: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-top: 8px;
    width: 144px;
  }
}
</style>
