<template>
  <div>
    <div class="content-header">
      <h1 class="content-title">Карьеры</h1>
      <button
        v-if="permissions.create"
        class="add-button"
        @click.stop="showModalQuarry"
      >
        <SvgIcon name="plus" class="button-plus" />
        Добавить Карьер
      </button>
      <div v-if="modalQuarryState">
        <QuarryModal
          :is-open="modalQuarryState"
          @close="modalQuarryState = false"
        />
      </div>
    </div>
    <div class="menu">
      <div class="sorting-search-favorites">
        <div class="favorites" @click="toggleFilter">
          <SvgIcon name="sorting" />
          <BaseTooltip text="Открыть/скрыть фильтры" />
        </div>
        <DashboardSearch
          @update-search="updatePage"
          ref="search"
          erase-tooltip="Очистить все"
        />
      </div>
      <PaginationComponent
        :total-pages="quarryPagesCount"
        @update-page="updatePage"
      />
      <div class="settings-select">
        <PerPageSelect
          :items="['20', '50', '100']"
          @update-select="changePerPage"
          :current-per-page="quarryPerPage"
        />
      </div>
      <div
        class="download-settings"
        @click="modalDownloadXlsx"
        :class="{ disabled: isTemporaryBlocked || !permissions.xls }"
      >
        <SvgIcon name="download" class="download-settings__img" />
        <BaseTooltip text="Скачать файл с отображаемыми на экране карьерами" />
      </div>
      <div class="download-settings">
        <SvgIcon
          name="settings"
          class="download-settings__img"
          @click="showModalSettings"
        />
        <BaseTooltip text="Настройки отображения дашборда" />
      </div>
    </div>
  </div>
  <AlertModal
    ref="alertButtons"
    :close-button="true"
  />
  <AlertModal
    ref="alert"
  />
  <BaseTableSettingsModal
    :is-open="modalSettingsState"
    @close="modalSettingsState = false"
    :schemas="quarryHeaderFull.schemas"
    :schemas-active="quarryHeaderList.schemas"
    @on-submit="onSubmitSettings"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import QuarryModal from '@/components/QuarryModals/QuarryModal'
import quarryService from '@/services/quarryService'
import { saveAs } from 'file-saver'
import AlertModal from '@/components/AlertModal'
import SvgIcon from '@/components/base/SvgIcon'
import PaginationComponent from '@/components/Dashboard/PaginationComponent.vue'
import BaseTableSettingsModal from '@/components/base/BaseTableSettingsModal'
import PerPageSelect from '@/components/Dashboard/PerPageSelect.vue';
import DashboardSearch from '@/components/Dashboard/DashboardSearch.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';

export default {
  name: 'QuarryHeader',
  emits: ['toggle-filter'],
  data() {
    return {
      isTemporaryBlocked: false,
      modalQuarryState: false,
      modalSettingsState: false,
      statePreloader: false,
    }
  },
  components: {
    BaseTooltip,
    DashboardSearch,
    SvgIcon,
    QuarryModal,
    AlertModal,
    PaginationComponent,
    BaseTableSettingsModal,
    PerPageSelect
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      filterParams: 'quarry/getQuarryFilterParams',
      quarryPagesCount: 'quarry/quarryPagesCount',
      quarryHeaderList: 'quarry/quarryHeaderList',
      quarryHeaderFull: 'quarry/quarryHeaderListFull',
      quarryPerPage: 'quarry/quarryPerPage',
      isSearchMode: 'quarry/getIsSearchMode',
      generalPermissions: 'account/getPermissions',
    }),
    searchQuery() {
      return this.$refs.search.searchValue
    },
    permissions() {
      if (!this.generalPermissions || !this.generalPermissions['quarries']) return [];
      return this.generalPermissions['quarries'];
    }
  },
  mounted() {
    const localFormState = localStorage.getItem('quarryFormState')
    const localFormStatus = localStorage.getItem('quarryFormStatus')

    if (localFormState || localFormStatus) {
      this.modalQuarryState = true
    }
  },
  methods: {
    async updatePage(query) {
      try {
        const curFilterParams = {}

        for (const key in this.filterParams) {
          if (this.filterParams[key]) {
            curFilterParams[key] = this.filterParams[key]
          }
        }

        await this.$store.dispatch('quarry/getQuarry', { ...this.$route.query, ...query, ...curFilterParams });
        await this.$router.push({query: { ...this.$route.query, ...query, ...curFilterParams }})
      } catch (error) {
        const data = error.response;

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            type: 'error',
            nameButton: 'Понятно',
            color: '#FFA500',
          });
        }
      }
    },
    async getQuarryXlsx(params) {
      try {
        this.isTemporaryBlocked = true
        const { data } = await quarryService.getQuarryXlsx(params)
        saveAs(data, 'file_quarry.xlsx')

        setTimeout(()=> {
          this.isTemporaryBlocked = false
        }, 2000)
      } catch (e) {
        this.$refs.alert.show('Ошибка', e, {
          type: 'error',
          nameButton: 'Окей',
          color: '#FFA500',
        })
      }
    },
    showModalQuarryXlsx() {
      this.$refs.alertButtons.show(
        'Экспорт',
        'Информация скачается в соответствии с настроенными фильтрами',
        {
          nameButton: 'Скачать',
          color: '#FFA500',
        },
        () => {
          this.getQuarryXlsx(this.filterParams)
        }
      )
    },
    modalDownloadXlsx() {
      this.showModalQuarryXlsx()
    },
    toggleFilter() {
      this.$emit('toggle-filter')
    },
    async showModalQuarry() {
      await this.$store.dispatch('clients/setSelectedClient', null);
      // this.modalQuarryState = true
      this.$router.push({
        path: '/table-quarry-page/create'
      })
    },
    showModalSettings() {
      this.modalSettingsState = true
    },
    async getQuarryHeaderList(params) {
      try {
        await this.$store.dispatch('quarry/getQuarryHeader', params)
      } catch (e) {
        this.$refs.alert.show(this.$t('alert.error'), e, {
          nameButton: 'Понятно',
        })
      }
    },
    async getQuarryList(params) {
      try {
        await this.$store.dispatch('quarry/getQuarry', params)
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmitSettings(items) {
      this.statePreloader = true

      try {
        const params = { columns: items }
        const { data } = await quarryService.quarryAddColumns(
          params
        )

        await this.getQuarryHeaderList(this.$route.query)
        await this.getQuarryList(this.$route.query)
      } catch (error) {
        const { data } = error.response
        const msgError = Object.values(data.errors)
        const msgErrorParse = msgError[0].join()
        const textError = msgErrorParse

        this.$refs.alert.show('Ошибка', textError, {
          type: 'error',
          nameButton: 'Понятно',
          color: '#FFA500',
        })
      } finally {
        this.statePreloader = false
      }
    },
    async changePerPage(value) {
      let query = { ...this.$route.query, per_page: value };
      delete query.page
      await this.$router.push({ query });

      if (!this.searchQuery) {
        this.$store.dispatch('quarry/getQuarry', query);
        return;
      }

      this.$store.dispatch('quarry/getQuarrySearch', {
        search: this.searchQuery,
        perPage: value,
      })
    }
  },
}
</script>

<style lang="scss">
.square-border {
  border: 1px solid #d0d5da;
  display: flex;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.square {
  background: #0055e8;
  border-radius: 2px;
  width: 11px;
  height: 10px;
}

.folder-arrow-border {
  width: 16px;
  height: 24px;
  border: 1px solid #a3a9ae;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 4px;
}

.separation-mini {
  width: 1px;
  height: 10px;
  background: #eceef1;
  margin-right: 8px;
  margin-left: 8px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
