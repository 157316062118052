<template>
  <div class="port-card-from">
    <form class="inner-left__form" @submit.prevent="onSubmit">
      <BaseModalInput
        class="input-group-item"
        label="Наименование порта"
        placeholder="Введите наименование порта"
        v-model="v.name.$model"
        ref="nameInput"
        :errors="v.name.$errors"
      />

      <div class="modal-input-name">Статус порта</div>
      <div class="flex-block mb-2">
        <div class="form_radio">
          <input
            id="radio-status"
            type="radio"
            v-model="statusQuarry"
            :value="'Действующий'"
          />
          <label for="radio-status">
            <span class="radio-name">Действующий</span>
          </label>
        </div>
        <div class="form_radio ml-6">
          <input
            id="radio-status-2"
            type="radio"
            v-model="statusQuarry"
            :value="'Недействующий'"
          />
          <label for="radio-status-2">
            <span class="radio-name">Недействующий</span>
          </label>
        </div>
      </div>

      <BaseModalInput
        class="input-group-item"
        label="ИНН"
        placeholder="Введите ИНН"
        v-model="v.inn.$model"
        id="innInput"
        :errors="v.inn.$errors"
        :input-mask="{
          mask: 'HHHHHHHHHHHH',
          tokens: { H: { pattern: /[0-9]/ } },
        }"
        @input="(value) => checkInn(value, v.inn, 0, state)"
      />

      <ClientAddAdress
        :address-list="fact_addresses"
        @update-address="updateAddress"
      />

      <QuarrySchedule
        :schedule-list="work_schedule"
        @update-days="updateDays"
      />

      <BaseModalTextarea
        class="input-group-item"
        label="Комментарий"
        placeholder="Введите комментарий"
        ref="commentTextarea"
        v-model="v.comment.$model"
      />

      <button
        v-if="isButtonShow"
        class="clients-modal__button"
        :disabled="isSendButtonDisabled"
        @click="onSubmit"
      >
        Сохранить
      </button>
    </form>
    <AlertModal ref="alert" nameButton="Понятно" />
  </div>
</template>

<script setup>
import BaseModalInput from '@/components/base/BaseModalInput.vue'
import useVuelidate from '@vuelidate/core'
import ClientAddAdress from '@/components/Clients/ClientAddAdress.vue'
import BaseModalTextarea from '@/components/base/BaseModalTextarea.vue'
import QuarrySchedule from '@/components/Quarry/QuarrySchedule.vue'
import AlertModal from '@/components/AlertModal.vue'
import { initialState, portsValidationRules } from '@/utils/ports/ports.js'
import portsService from '@/services/portsService.js'
import { ref, computed, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import companyService from '@/services/companyService.js'

const store = useStore()
const router = useRouter()
const route = useRoute()

const props = defineProps({
  isModalEditData: {
    type: Boolean,
    default: false
  },
  fact_addresses: {
    type: Array,
    default: () => []
  },
  work_schedule: {
    type: String,
    default: ''
  },
  inn: {
    type: [Number, String],
    default: ''
  },
  company: {
    type: Object,
  },
  isButtonShow: {
    type: Boolean,
    default: true,
  },
  portInn: {
    type: [Number, String]
  },
  comment: {
    type: String,
    default: ''
  },
  portName: {
    type: String,
    default: ''
  },
  portId: {
    type: [Number, String],
  },
  portStatus: {
    type: String,
    default: 'Действующий'
  }
})

const emit = defineEmits(['update-address', 'update-days', 'update-company'])

const portId = computed(() => route.params.id || props.portId)

const statusQuarry = ref('Действующий')
const innInput = ref(null)
const nameInput = ref(null)
const alert = ref(null)
const isSendButtonDisabled = ref(false)

const rules = portsValidationRules
const state = reactive({
  name: '',
  client_name: '',
  comment: props.comment,
  inn: '',
})

const v = useVuelidate(rules, state)

const onSubmit = async () => {
  isSendButtonDisabled.value = true
  const isFormCorrect = await v.value.$validate()

  if (isFormCorrect) {
    // submit модалки редактирования
    try {
      if (props.isModalEditData) {
        await portsService.editPort(
          {
            id: portId.value,
            name: state.name,
            client_name: state.client_name,
            comment: state.comment,
            inn: state.inn,
            ogrn: state.ogrn,
            kpp: state.kpp,
            legal_address_symbol: state.legal_address_symbol,
            head: state.head || undefined,
            registration_date: state.registration_date || undefined,
            fact_addresses: props.fact_addresses,
            holding_id: state.holding_id,
            work_schedule: props.work_schedule,
            status: statusQuarry.value == 'Действующий' ? true : false,
            responsible: state.responsible,
            name_goods: state.name_goods,
          },
          portId.value|| Number(props.portInn)
        )

        emit('update-company')
        // submit модалки создания
      } else {
        await portsService.createPort({
          name: state.name,
          client_name: state.client_name,
          comment: state.comment,
          inn: state.inn,
          ogrn: state.ogrn,
          kpp: state.kpp,
          legal_address_symbol: state.legal_address_symbol,
          head: state.head || undefined,
          registration_date: state.registration_date || undefined,
          fact_addresses: props.fact_addresses,
          holding_id: state.holding_id,
          work_schedule: props.work_schedule,
          status: statusQuarry.value == 'Действующий' ? true : false,
          responsible: state.responsible,
          name_goods: state.name_goods,
        })

        await router.push('/ports-page')
      }
    } catch(e) {
      const errors = e.response.data.errors

      const isAddressError = errors['fact_addresses.0.coordinates'] || errors['fact_addresses.0.country']

      if (isAddressError) {
        alert.value.show('Ошибка', isAddressError, {
          nameButton: 'Окей',
          color: '#FFA500',
        })
      }

      if (errors.inn) {
        alert.value.show('Ошибка', errors.inn, {
          nameButton: 'Окей',
          color: '#FFA500',
        })
      }
    }
  }
  isSendButtonDisabled.value = false
}

const checkInn = async (event, validate, index, itemCollection) => {
  const isInnValid = await validate.$validate()

  if (isInnValid) {
    try {
      const { data } = await companyService.getCompany({ inn: event.target.value })
      const company = data.data

      itemCollection.ogrn = company.ogrn
      itemCollection.kpp = company.kpp
      itemCollection.legal_address_symbol = company.legal_address_symbol
      itemCollection.client_name = company.name
      itemCollection.name = company.short_name
      itemCollection.registration_date = company?.registration_date
      itemCollection.head = company?.head

      if (itemCollection.client_name?.length < 1) {
        itemCollection.name = company.short_name
        itemCollection.client_name = company.name
      }

      await store.dispatch('clients/setSelectedClient', {
        name: company?.short_name || '',
        companies: [{ inn: company?.inn || '' }]
      })

      statusQuarry.value = 'Действующий'
    } catch (e) {
      alert.value.show('Ошибка', '', {
        nameButton: 'Окей',
        color: '#FFA500',
      })
    }
  } else {
    await store.dispatch('clients/setSelectedClient', null);
  }  
}

const updateAddress = (addresses) => {
  emit('update-address', addresses)
}

const parseWorkScheduleToString = (value) => {
  const list = []

  value.forEach((day) => {
    if (day.show) {
      if (day.always) {
        const time = '24:00-24:00'

        list.push(time)
      } else {
        const loadStart = day.load_start
        const unloadEnd = day.unload_end
        const time = `${loadStart}-${unloadEnd}`

        list.push(time)
      }
    } else {
      const time = ''

      list.push(time)
    }
  })

  value = list.join()

  return value
}

const updateDays = (value) => {
  const workSchedule = parseWorkScheduleToString(value)
  emit('update-days', workSchedule)
}

watch(() => props.portStatus, (newValue) => {
  statusQuarry.value = newValue
}, {
  immediate: true
})

onMounted(async () => {
  if (!props.isModalEditData) return
  innInput.value = props.company.inn
  nameInput.value = props.portName

  state.value = initialState(state, { value: { ...props.company, short_name: props.portName } })
})
</script>

<style lang="scss" scoped>
.port-card-from {
  width: 95%;

  margin-top: 20px;
}

.clients-modal__button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 44px;
  width: 100%;

  background: #ffa500;
  border-radius: 8px;

  color: #ffffff;
  font-size: 13px;
  font-family: "Manrope";
}
</style>
