<template>
  <div class="specification-cargo__item">
    <div class="specification-card-label" v-if="info">
      {{ title }}
    </div>
    <div class="specification-subtitle" v-if="info">
      {{ info }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CargoItem",
  props: {
    title: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
