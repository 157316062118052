<template>
  <div class="form-box">
    <div class="form-grid display-flex form-group align_bottom">
      <div class="form-col">
        <span class="modal-input-name">График работы</span>
        <div class="display-flex">
          <BaseCheckboxDay
            v-for="(day, index) of days"
            :key="day"
            v-model="day.value"
            :checkbox-name="day.name"
            @update:modelValue="
              editDaysParse($event, index);
              updateDays;
            "
          />
        </div>
      </div>
      <div class="form-col">
        <label class="base-checkbox">
          <input
            type="checkbox"
            name="everyday"
            v-model="formEveryday"
            @change="checkFormEveryday"
          >
          <span class="base-checkbox__name">Каждый день</span>
        </label>
      </div>
    </div>

    <ValidateEach
      v-for="(item, index) in collection"
      :key="`schedule_d_${index}`"
      :state="item"
      :rules="rules"
    >
      <template #default="{ v }">
        <hr v-show="item.show && index !== 0" />
        <div v-show="item.show" class="form-grid display-flex form-group">
          <div class="form-col">
            <div class="week-day">
              {{ v.day.$model }}
            </div>
          </div>
          <div class="form-col">
            <div class="display-flex mb-2 time-flex">
              <div class="time">
                <BaseModalInput
                  label="Часы работы от"
                  v-model="v.load_start.$model"
                  placeholder="Введите время"
                  type="time"
                  class="new-specification-input display-flex flex-column"
                  :time-list="timeWithStep30"
                  :errors="v.load_start.$errors"
                  :disabled="v.always.$model"
                  @update:modelValue="emitDays"
                />
              </div>
              <div class="time">
                <BaseModalInput
                  label="Часы работы до"
                  v-model="v.unload_end.$model"
                  placeholder="Введите время"
                  type="time"
                  class="new-specification-input display-flex flex-column"
                  :time-list="timeWithStep30"
                  :errors="v.unload_end.$errors"
                  :disabled="v.always.$model"
                  @update:modelValue="emitDays"
                />
              </div>
            </div>
            <label class="base-checkbox">
              <input
                type="checkbox"
                name="always"
                v-model="v.always.$model"
                @change="emitDays"
              >
              <span class="base-checkbox__name">Круглосуточно</span>
            </label>
          </div>
        </div>
      </template>
    </ValidateEach>
  </div>
</template>

<script>
import { reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { ValidateEach } from '@vuelidate/components';
import BaseModalInput from '@/components/base/BaseModalInput.vue';
import BaseCheckboxDay from '@/components/base/BaseCheckboxDay.vue';
import { helpers, required } from '@vuelidate/validators';

export default {
  name: 'QuarrySchedule',
  components: {
    BaseModalInput,
    ValidateEach,
    BaseCheckboxDay,
  },
  setup(props) {
    let formEveryday = false;

    let days = reactive([
      {
        name: 'пн',
        nameFull: 'Понедельник',
        value: true,
      },
      {
        name: 'вт',
        nameFull: 'Вторник',
        value: true,
      },
      {
        name: 'ср',
        nameFull: 'Среда',
        value: true,
      },
      {
        name: 'чт',
        nameFull: 'Четверг',
        value: true,
      },
      {
        name: 'пт',
        nameFull: 'Пятница',
        value: true,
      },
      {
        name: 'сб',
        nameFull: 'Суббота',
        value: false,
      },
      {
        name: 'вс',
        nameFull: 'Воскресенье',
        value: false,
      },
    ]);

    const rules = {
      load_start: {
        required: helpers.withMessage(
          'Поле обязательно для заполнения',
          required
        ),
      },
      unload_end: {
        required: helpers.withMessage(
          'Поле обязательно для заполнения',
          required
        ),
      },
      everyday: {},
      always: {},
      day: {},
    };

    const collection = reactive([]);

    if (props.scheduleList) {
      const daysList = props.scheduleList.split(',');
      let countDays = 0;

      daysList.forEach((item, index) => {
        if (item.length > 0) {
          countDays++;

          if (item !== '24:00-24:00' && item !== '24-24') {
            const times = item.split('-');
            const from = times[0];
            const to = times[1];

            days[index].load_start = from;
            days[index].unload_end = to;
            days[index].value = true;
          } else {
            days[index].always = true;
            days[index].load_start = '10:00';
            days[index].unload_end = '19:00';
            days[index].value = true;
          }
        } else {
          days[index].value = false
        }
      });

      if (countDays > 5) {
        formEveryday = true;
      }
    }

    const v = useVuelidate();

    return {
      rules,
      collection,
      v,
      days,
      formEveryday,
    };
  },

  props: {
    scheduleList: {
      type: [String, Array, Object],
      required: false,
    },
  },

  computed: {
    timeWithStep30() {
      const times = []
      let startHourInMinute = 0
      let endHourInMinute = (23 * 60) + 30
      let interval = 30

      for (let i = 0; startHourInMinute < 24 * 60; i++) {
        if (startHourInMinute > endHourInMinute) break

        var hh = Math.floor(startHourInMinute / 60)
        var mm = startHourInMinute % 60

        times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2)

        startHourInMinute = startHourInMinute + interval
      }

      return times
    },
  },

  methods: {
    checkFormEveryday(event) {
      if (event) {
        this.days.forEach((item, index) => {
          item.value = true;

          this.collection[index].show = true;

          this.emitDays();
        });
      }
    },
    createDaysParse(days) {
      days.forEach((item) => {
        if (item.load_start && item.load_start.length == 2) {
          item.load_start = item.load_start + ':00'
        }

        if (item.unload_end && item.unload_end.length == 2) {
          item.unload_end = item.unload_end + ':00'
        }

        const dayInfo = {
          day: item.nameFull,
          load_start: item.load_start ? item.load_start : '10:00',
          unload_end: item.unload_end ? item.unload_end : '19:00',
          always: item.always ? item.always : false,
          show: item.value,
        }

        this.collection.push(dayInfo);
      })

      this.emitDays();
    },
    editDaysParse(event, index) {
      if (event == false) {
        this.collection[index].show = false;
        this.formEveryday = false;
      } else {
        this.collection[index].show = true;
      }

      this.emitDays();
    },

    emitDays() {
      this.$emit('update-days', this.collection);
    },
  },
  created() {
    this.createDaysParse(this.days);
  },
};
</script>

<style lang="scss" scoped>
//.modal-input-name {
//  font-weight: 400;
//  font-size: 12px;
//  line-height: 16px;
//  color: #a4a4a4;
//  margin-bottom: 2px;
//}
.base-checkbox {
  display: flex;
  justify-content: flex-end;

  &__name {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0a2333;
    margin-left: 9px;
  }
}

.form {
  &-group {
    margin-bottom: 8px;
  }

  &-grid {
    justify-content: space-between;

    &.align_bottom {
      align-items: flex-end;
    }
  }

  &-col {
    width: calc(50% - 10px);
  }
}

.week-day {
  margin-top: 38px;
}

:global(.form-grid .checkbox-item) {
  justify-content: flex-end;
  margin-bottom: 0;
}

hr {
  border-top: none;
  border-bottom: 1px solid #efefef;
  outline: none;
  margin-bottom: 8px;
}

.time {
  width: 95px;
}

.time-flex {
  justify-content: flex-end;
}
</style>
