<template>
  <div :style="`background-color: ${backgroundColor}`" :class="['spinner', {'active': isLoading}]">
    <div class="spinner__element" :style="spinnerStyles"></div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  isLoading: Boolean,
  backgroundColor: {
    type: String,
    default: '#FFFFFF'
  },
  spinnerDiameter: {
    type: Number,
    default: 40,
  }
})

const spinnerStyles = computed(() => {
  return {
    width: props.spinnerDiameter ? `${props.spinnerDiameter}px`: '40px',
    height: props.spinnerDiameter ? `${props.spinnerDiameter}px`: '40px',
  }
})
</script>

<style scoped lang="scss">
.spinner {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;

  &__element {
    display: none;
    font-size: 10px;
    position: relative;
    width: 40px;
    height: 40px;
    text-indent: -9999em;
    border-radius: 50%;
    border-top: 5px solid #0055E8;
    border-right: 5px solid #0055E8;
    border-bottom: 5px solid #0055E8;
    border-left: 5px solid #FFFFFF;
    transform: translate(-50%, -50%);
    -webkit-animation: spinner 1.1s infinite linear;
    animation: spinner 1.1s infinite linear;
    top: 50%;
    left: 50%;
  }

  &.active {
    display: block;

    .spinner__element {
      display: block;
    }
  }

  @keyframes spinner {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
</style>
