<template>
  <div class="info">
    <BaseSpinner
      :isLoading="isExtendedDataLoading"
    />
    <div class="info__wrapper">
      <div class="info-header">
        <SvgIcon
          class="info-header__close"
          name="arrow-back"
          @click="closeExtendedInfo"
        />
        <h3 class="info-header__title">
          {{ extendedInfoName }}
        </h3>
        <div class="info-header__id">
          <div class="info-header__id-inner">
            {{ extendedInfoId }}
          </div>
        </div>
      </div>
      <div class="info-body">
        <div class="info-body__inner" v-for="info in preparedExtendedInfo">
          <div class="info-body__title">{{ info.title }}:</div>
          <div class="info-body__data" v-html="info.data"></div>
        </div>
      </div>
    </div>
    <div class="info-buttons">
      <router-link :to="showMoreLink">
        <div class="info-more">Подробнее</div>
      </router-link>
    <button class="info-more" v-if="isQuickModeShow" @click="setQuickMetaSpecification">Связать маршрут</button>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/base/SvgIcon.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';

const store = useStore();
const emits = defineEmits(['close-filter']);
const isQuickMetaMode = computed(() => store.getters['interactiveMap/getIsQuickMetaMode'])
const baseQuickMetaId = computed(() => {
  return store.getters['interactiveMap/getBaseSpecification'] ?? null
})

const extendedInfoType = computed(() => {
  return store.getters['interactiveMap/getExtendedDataType'];
})

const isQuickModeShow = computed(() => {
  return extendedInfoType.value === 'specs' && isQuickMetaMode.value && (!baseQuickMetaId.value || baseQuickMetaId.value?.id?.toString() !== extendedInfoId.value?.toString())
})

const extendedInfoId = computed(() => {
  return store.getters['interactiveMap/getExtendedDataId'];
})

const extendedData = computed(() => {
  return store.getters['interactiveMap/getExtendedData']
})

const showMoreLink = computed(() => {
  switch(extendedInfoType.value) {
  case 'quarry':
    return `/table-quarry-page/${extendedInfoId.value}`
  case 'specs':
    return `/specification-card-page/${extendedInfoId.value}`
  case 'client':
    return `/table-clients-page/${extendedInfoId.value}`
  case 'ports':
    return `/ports-page/${extendedInfoId.value}`
  default:
    return ''
  }
})

const extendedInfoName = computed(() => {
  switch(extendedInfoType.value) {
  case 'quarry':
    return 'Карьер'
  case 'specs':
    return 'Спецификация'
  case 'client':
    return 'Клиент'
  case 'ports':
    return 'Порт'
  default: 
    return ''
  }
})

const isExtendedDataLoading = computed(() => {
  return store.getters['interactiveMap/getIsExtendedDataLoading'];
})

const preparedExtendedInfo = computed(() => {
  return store.getters['interactiveMap/extendedDataDetailed'];
})

const closeExtendedInfo = () => {
  store.dispatch('interactiveMap/handleExtendedData',[null, null, null]);
}

const setQuickMetaSpecification = () => {
  store.dispatch('interactiveMap/setQuickSecondSpecification', extendedData.value)
  closeExtendedInfo()
}

watch(() => extendedInfoId.value, () => {
  if (!extendedInfoId.value) return;
  emits('close-filter', false);
})

</script>
<style scoped lang="scss">
.info {
  position: absolute;
  max-width: 380px;
  min-height: 220px;
  width: 100%;
  z-index: 10;
  padding: 18px 24px;
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE;

  &-more {
    padding: 14px 45px;
    background-color: #0055E8;
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    display: inline-block;
    text-decoration: none;
  }

  &-header {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 24px;

    &__close,
    &__share {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    &__close {
      margin-right: 8px;
    }

    &__share {
      margin-left: 10px;
    }

    &__title {
      font-size: 25px;
      line-height: 35px;
      display: block;
    }

    &__id {
      color: #FFFFFF;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      flex: 1;

      &-inner {
        background-color: #0055E8;
        padding: 10px;
        border-radius: 4px;
        display: inline-block;
        margin-left: 12px;
      }
    }
  }

  &-body {
    margin-bottom: 20px;
    &__inner {
      display: flex;
      align-items: baseline;
      margin-bottom: 8px;
    }

    &__title {
      font-size: 12px;
      line-height: 16px;
      color: #A4A4A4;
      margin-right: 4px;
      white-space: nowrap;
    }

    &__data {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #102F43;
    }
  }

  &-buttons {
    display: flex;
    gap: 9px;

    :deep(.info-more) {
      padding: 14px 30px;
    }
  }
}
</style>
