<template>
  <BaseModal size="340" :is-open="isOpen" @close="closeModal">
    <BaseSpinner :isLoading="isLoading"/>
    <div class="create-filter">
      <h2>{{ title }}</h2>
      <BaseModalInput
        v-model="templateTitle"
        :placeholder="isEditMode ? 'Введите новое название шаблона' : 'Введите название шаблона'"
      />
      <div class="create-filter__btn-row">
        <BaseButton
          :disabled="!templateTitle.length"
          @click="createTemplate"
          success-btn
        >
          Сохранить
        </BaseButton>
        <BaseButton @click="closeModal">Отмена</BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import BaseModal from "@/components/base/BaseModal.vue";
import BaseModalInput from "@/components/base/BaseModalInput.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseSpinner from "@/components/base/BaseSpinner.vue";

export default {
  name: "FilterModalCreate",
  components: {BaseSpinner, BaseButton, BaseModalInput, BaseModal },
  emits: ['close', 'create', 'edit'],
  setup(props, { emit }) {
    const store = useStore();
    const isOpen = ref(false)
    const templateTitle = ref('')
    const isEditMode = ref(false)
    const callback = ref(null)
    const tempEdit = ref(null)

    const isLoading = computed(() => {
      return store.getters['filter/getIsLoading']
    });

    const closeModal = () => {
      emit('close')
    }

    const title = computed(() => {
      return isEditMode.value ? 'Редактирование шаблона' : 'Создание нового шаблона';
    })

    const createTemplate = () => {
      if (isEditMode.value) {
        let temp = structuredClone(tempEdit.value)
        temp.name = templateTitle.value
        emit('edit', temp)
        return;
      }
      emit('create', templateTitle.value)
    }

    const toggleModal = (val, isEdit = false, currentTemplateFilter = null) => {
      isOpen.value = val
      templateTitle.value = ''
      isEditMode.value = isEdit;

      if (isEdit && currentTemplateFilter) {
        title.value = 'Редактирование шаблона'
        templateTitle.value = currentTemplateFilter.name
        callback.value = isEdit
        tempEdit.value = currentTemplateFilter
      }
    }

    return {
      isOpen,
      templateTitle,
      title,
      isEditMode,
      isLoading,
      closeModal,
      createTemplate,
      toggleModal
    }
  }
}
</script>

<style scoped lang="scss">
.create-filter {
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    margin-bottom: 24px;
  }

  &__btn-row {
    display: flex;
    gap: 6px;
  }
}
</style>
