<template>
  <div class="background">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BackgroundComponent'
}
</script>

<style lang="scss">
.background {
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  height: calc(100vh - 25px);
  //padding: 17px 26px;
  //overflow: hidden;
}
</style>