<template>
  <div
    class="table js-baseTable"
    :class="{
      'table-big': sizeBig,
    }"
  >
    <v-table
      fixed-header
      height="calc(100vh - 162px)"
      ref="customTable"
    >
      <thead v-if="tableHead.length" v-resizes="{cb: updateWidthDebounce, params: tableHeaderParse}">
        <tr class="tr-title">
          <th
            v-for="item of tableHead"
            :key="item"
            @click="sortBy(item)"
            class="cell"
            :class="{
              cell_id: item.id === 'id',
              cell_start: item.id === 'start',
              cell_end: item.id === 'end',
              'cell_total-service': item.id === 'total_service_capital_fact',
              cell_comment: item.id === 'user_comment',
              cell_load: item.id === 'load',
              cell_unload: item.id === 'unload',
              [`cell-th-${item.id}`]: true
            }"
          >
            <div class="flex-block width-100">
              <span class="title" :title="item.value"><p class="title__text">{{ item.value }}</p></span>
              <span class="flex-block sort-column">
                <SvgIcon
                  v-if="item.sort === 'default'"
                  name="table-arrows"
                  class="table-arrows"
                />
                <SvgIcon
                  v-if="item.sort === 'ASC'"
                  name="table-arrows-bottom"
                  class="table-arrows"
                />
                <SvgIcon
                  v-if="item.sort === 'DESC'"
                  name="table-arrows-top"
                  class="table-arrows"
                />
                <BaseTooltip text="Сортировать по возрастанию/убыванию" />
              </span>
            </div>
          </th>
          <th class="cell"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="tableBody.length > 0">
          <tr
            v-for="(item, index) in tableBody"
            :key="item.id"
            :style="{
              transition: '.3s',
              backgroundColor: item?.meta?.color ? item?.meta?.color : '#FFFFFF',
              borderBottom: getBorderBottom(item, index),
            }"
            @click.right.prevent="onRightClick($event, item.id)"
            @click.prevent="onLeftClick($event, item.id)"
          >
            <td
              :data-id="item.id"
              v-for="td of item.cells"
              :key="td"
              class="cell"
              :class="`cell-${td.name}`"
            >
              <template v-if="$slots.item">
                <slot name="item" v-bind="{...td, specificationId: item.id, spec_permissions: item.spec_permissions }"></slot>
              </template>
              <template v-else>
                <span>{{ td.value }}</span>
              </template>
            </td>
            <td></td>
          </tr>
        </template>
        <div
          v-else
          class="no-result display-f__jc-sb-ai-c display-f__jc-c"
        >
          Ничего не найдено
        </div>
      </tbody>
    </v-table>
    <BaseFullLoader v-if="isLoading" />
  </div>

</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'
import { debounce } from '@/utils/helper.js';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import BaseFullLoader from '@/components/base/BaseFullLoader.vue';

export default {
  name: 'BaseTable',
  data() {
    return {
      tableHeaderTemp: { columns: this.tableHeaderParams },
      updateWidthDebounce: null
    }
  },
  created() {
    this.updateWidthDebounce = debounce(this.saveColumSize, 500);
  },
  components: {
    BaseFullLoader,
    BaseTooltip,
    SvgIcon,
  },
  props: {
    tableHead: {
      type: Array,
      default: () => []
    },
    tableBody: {
      type: Array,
    },
    sizeBig: {
      type: Boolean,
      default: false,
    },
    tableHeaderParams: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onRightClick(event, id) {
      this.$emit('right-click', { event, id })
    },
    onLeftClick (event, id) {
      this.$emit('left-click', { event, id })
    },
    sortBy(item) {
      this.$emit('sortBy', item)
    },
    saveColumSize(params) {
      this.tableHeaderTemp.columns = JSON.parse(JSON.stringify(this.tableHeaderParams))
      this.tableHeaderTemp.columns[params.index] = this.tableHeaderTemp.columns[params.index]?.split('?')[0] + `?width=${params.width}`

      this.$emit('update-width', this.tableHeaderTemp)
    },
    getBorderColor (statusId) {
      if (!statusId) return 'rgba(255, 255, 255, 0.2)'
      if (+statusId === 1) return 'rgba(94, 224, 62, 0.2)'
      if (+statusId === 2) return 'rgba(255, 31, 0, 0.2)'
      if (+statusId === 3) return 'rgba(255, 184, 0, 0.3)'
    },
    getBorderBottom (item, index) {
      try {
        if (!item || !item.cells || typeof index !== 'number' || !this.tableBody) {
          return null;
        }

        const lprStatus = item.cells.find(cell => cell.name === 'lpr_status');
        const currentLprStatusId = lprStatus?.value?.id;

        const nextItemIndex = index + 1;
        if (nextItemIndex >= this.tableBody.length) {
          return null;
        }

        const nextItem = this.tableBody[nextItemIndex];
        const nextLprStatus = nextItem.cells.find(cell => cell.name === 'lpr_status');
        const nextLprStatusId = nextLprStatus?.value?.id
        if (!nextLprStatusId || !currentLprStatusId) return null;
        if (+nextLprStatusId === +currentLprStatusId) return `2px solid ${this.getBorderColor(currentLprStatusId)}`
      } catch(e) {
        return null;
      }
    },
  },
  computed: {
    tableHeaderParse() {
      const temp = []
      this.tableHeaderParams.forEach(item => {
        let el = item.split('?')
        const id = el[0]
        let width = null
        if (el.length > 1) {
          width = el[1].split('=')[1]
        }

        temp.push({
          id: id,
          width: width ? width : undefined
        })
      })
      return temp
    }
  },
}
</script>

<style lang="scss" scoped>

th {
  font-weight: 500;
}

:global(table) {
  position: relative;
  border-collapse: collapse;
}

.no-result {
  position: absolute;
  margin-left: 16px;
  margin-top: 10px;

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
}

.table {
  max-width: 100%;
  position: relative;

  thead {
    position: sticky;
    top: 0;
    box-shadow: inset 0 2px 0 #E0E0E0;
  }

  .cell {
    text-overflow: ellipsis;
    text-align: center;
    &_id {
      width: 16px;
    }

    &_start,
    &_end,
    &_total-service {
      min-width: 128px;
    }

    &_comment {
      min-width: 210px;
    }

    &_load,
    &_unload {
      min-width: 170px;
    }
  }

  th {
    white-space: pre-wrap;

    .title {
      width: 100%;
      font-size: 14px;
      height: 3em;
      padding: unset;
      margin: 14px 0;
      white-space: normal;
      overflow: hidden;
      &__text {
        height: 3em;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        width: 100vw;
      }
    }

    .sort-column {
      padding: 12px 8px;
      border-radius: 8px;
      cursor: pointer;

      .table-arrows {
        margin-left: 0;
      }

      &:hover {
        transition: .2s all ease;
        background-color: rgba(var(--v-theme-on-background), 0.1);
      }
    }
  }

  td {
    white-space: pre-wrap;

    &.cell {
      font-size: 14px !important;
      padding: 10px 15px 10px 16px;
    }
  }

  th,
  td {
    max-width: 220px;
  }
}

.table-big {
  th,
  td {
    max-width: 450px;
    min-width: auto;
  }
}

.cell-th-legal_address_symbol,
.cell-legal_address_symbol {
  max-width: 170px!important;
}

.tr-title {
  th.cell {
    position: relative;
  }
}

.th-separate {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 26px;
  margin: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 5;
  right: -15px;
  cursor: ew-resize;
}
</style>
