<template>
  <div class="meta-route-wrapper">
    <v-expansion-panels class="panels" multiple>
      <v-expansion-panel class="panel">
        <v-expansion-panel-title class="panel-title"> Мета-маршрут </v-expansion-panel-title>
        <v-expansion-panel-text class="panel-text">
          <v-expansion-panels>
            <v-expansion-panel class="panel" v-for="(specification, index) in metaRouteSpecifications">
              <v-expansion-panel-title class="panel-title" v-if="specification">
                <span class="meta-route__spec-id" :style="`color: ${multicolors[index]}`">
                  ID {{ specification.id }}
                </span>
                <SvgIcon
                  v-show="!isShareMode"
                  class="meta-route-wrapper__spec-delete"
                  name="trash"
                  @click.stop="deleteSpecFromMetaRoute(specification.id)"
                />
                <div class="meta-route-wrapper__order" v-show="!isShareMode && !isEditDisabled">
                  <SvgIcon
                    class="meta-route-wrapper__order-down"
                    v-if="index !== metaRouteSpecifications.length - 1"
                    @click.stop="updateSpecificaionPosition(index, index + 1)"
                    name="arrow_bottom_alt"
                  />
                  <SvgIcon
                    class="meta-route-wrapper__order-up"
                    v-if="index !== 0"
                    @click.stop="updateSpecificaionPosition(index, index - 1)"
                    name="arrow_bottom_alt"
                  />
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="panel-text">
                <MetaRouteDetails
                  :specification="specification"
                  :mainSpec="mainSpec"
                  :isShareMode="isShareMode"
                  :is-edit-disabled="isEditDisabled"
                  @radio-selected="handleMainSpecChange"
                />
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="panel">
        <v-expansion-panel-title class="panel-title"> Расчет показателей </v-expansion-panel-title>
        <v-expansion-panel-text class="panel-text">
          <MetaRouteRouteData :isShareMode="isShareMode" :multiroute-detailed-data="multirouteDetailedData" :is-quick-meta-mode="isQuickMetaMode" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup>
import { multicolors } from '@/utils/map/map';
import MetaRouteRouteData from '@/components/InteractiveMap/MetaRouteRouteData.vue';
import MetaRouteDetails from '@/components/InteractiveMap/MetaRouteDetails.vue';
import SvgIcon from '@/components/base/SvgIcon.vue';

const props = defineProps({
  metaRouteSpecifications: {
    type: Array,
    default: () => [],
  },
  isShareMode: {
    type: Boolean,
    default: false,
  },
  mainSpec: {
    type: Number,
    default: 0,
  },
  multirouteDetailedData: {
    type: Object,
    default: () => {},
  },
  isEditDisabled: {
    type: Boolean,
    default: false,
  },
  isQuickMetaMode: {
    type: Boolean,
    default: false,
  }
});
const emits = defineEmits(['update:mainSpec', 'update:specsOrder'])

const updateSpecificaionPosition = (targetElemPosition, newPosition) => {
  const specsCopy = [...props.metaRouteSpecifications];
  [specsCopy[targetElemPosition], specsCopy[newPosition]] = [specsCopy[newPosition], specsCopy[targetElemPosition]];
  emits('update:specsOrder', specsCopy)
};

const deleteSpecFromMetaRoute = (specificationId) => {
  const specsCopy = [...props.metaRouteSpecifications];
  const index = specsCopy.findIndex((specification) => specification.id === specificationId);
  specsCopy.splice(index, 1);
  emits('update:specsOrder', specsCopy)
};


const handleMainSpecChange = (id) => {
  emits('update:mainSpec', id);
}
</script>

<style lang="scss" scoped>
.meta-route-wrapper {
  flex: 1;
  overflow-y: scroll;

  &__spec-id {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-right: 8px;
  }

  &__spec-delete {
    width: 20px;
    height: 20px;
  }

  &__order {
    flex: 1;
    display: flex;
    justify-content: end;
  }

  &__order-up,
  &__order-down {
    width: 20px;
    height: 20px;
  }

  &__order-up {
    transform: rotate(180deg);
  }

  .panel {
    background-color: #f4f4f8;
    border-radius: 0;
    border: none;
  }

  .panel-title {
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0a2333;
    opacity: unset;
    background: transparent;
    display: flex;
  }

  .panel-text {
    padding: 0;
  }

  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
    position: relative;
  }

  :deep(.v-expansion-panel__shadow) {
    box-shadow: none;
  }

  :deep(.v-expansion-panel-title__overlay) {
    display: none;
  }

  .v-expansion-panel--active.panel .panel-title {
    min-height: 48px;
  }
}
</style>
