<template>
  <div>
    <v-app>
      <v-card>
        <v-layout>
          <v-main class="sidebar-content">
            <div class="new-specifications-page">
              <NewSpecification
                class="new-specifications_wrap"
              />
            </div>
          </v-main>
        </v-layout>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import NewSpecification from '@/components/Specifications/NewSpecification';

export default {
  name: 'NewSpecificationPage',
  components: {
    NewSpecification,
  },
};
</script>

<style lang="scss">
.new-specifications-page {
  display: flex;

  .new-specifications_wrap {
    width: 100%;
    display: flex;
  }

  .map {
    position: relative;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: 10px;
    height: 887px;
    width: 39.8%;
    z-index: 9;

    &-transit__btn {
      color: #0a2333;
      font-weight: 600;
      font-size: 13px;
      margin-top: 7px;
      margin-right: 12px;

      &:disabled {
        color: #b4b4b4;
      }
    }

    .specification-input-address::placeholder {
      background: none;
    }

    .no_active {
      display: none;
    }

    .specification-input-address {
      padding: 15px 25px 15px 15px;
      background: #f6f6f6;
      border-radius: 8px;
      font-size: 14px;
      margin-bottom: 5px;
      outline: none;
    }

    &-inputs__wrap {
      z-index: 10;
      position: absolute;
      background: #ffffff;
      border-radius: 12px;
      padding: 15px;
      left: 30px;
      top: 10%;
    }

    &-active-full {
      position: absolute;
      height: calc(100% - 28px);
      z-index: 9;
      width: calc(100% - 20px);

      .map-big {
        width: 100%;
        height: 100%;
      }

      .map-zoom {
        &__btns,
        &__btn-full {
          right: 58px;
        }
      }
    }

    &-big {
      width: 100%;
    }

    &-zoom__btns {
      z-index: 10;
      position: absolute;
      right: 38px;
      top: 180px;
      display: flex;
      flex-direction: column;
    }

    &-zoom__icon {
      width: 24px;
      height: 24px;
      margin: 10px;
    }

    &-zoom__btn {
      background: white;
      color: #0a2333;

      &-full {
        z-index: 10;
        border-radius: 6px;
        position: absolute;
        background: white;
        top: 31px;
        right: 38px;
        width: 44px;
        height: 44px;
      }

      &-full_back {
        z-index: 10;
        border-radius: 6px;
        position: absolute;
        background: white;
        top: 10px;
        left: 30px;
        width: 44px;
        height: 44px;
      }

      &:first-child {
        border-radius: 6px 6px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 6px 6px;
      }
    }
  }
}
</style>
