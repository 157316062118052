<template>
  <div class="contact-faces__card-block">
    <div class="contact-faces__card">
      <div class="contact-faces__card-header">
        <div class="contact-faces__card-photo">
          <img width="18" :src="image" alt="" />
        </div>
        <div class="contact-faces__card-name">{{ item.name }}</div>
        <ContactContextMenu
          :permissions="entityPermissions"
          @delete="deleteContact"
          @edit="editContact"
          @copy="copyContact"
        />
        <div v-if="favorite" @click="addToFavorites" class="contact-faces__favorite">
          <SvgIcon width="20" height="20" :name="favoriteIcon" />
          <BaseTooltip text="Контактное лицо будет отображаться первым в списке" />
        </div>
      </div>

      <div class="job-title">{{ item.position }}</div>

      <div class="separator"></div>

      <template v-if="item.contact_numbers">
        <div
          v-for="phone of item.contact_numbers"
          :key="phone"
          class="contact-faces__info"
        >
          <div v-if="!hideDescription" class="contact-faces__info-name">
            {{ phone.telephone_type ? 'Рабочий' : 'Личный' }}
          </div>
          <div class="contact-faces__info-content">
            +7 {{ phone.number }}
            <span v-if="phone.extension_number">({{ phone.extension_number }})</span>
          </div>
        </div>
      </template>

      <div class="contact-faces__info" v-if="item.email">
        <div v-if="!hideDescription" class="contact-faces__info-name">E-mail</div>
        <div class="contact-faces__info-content">
          {{ item.email }}
        </div>
      </div>

      <div class="contact-faces__info" v-if="item.comment">
        <div v-if="!hideDescription" class="contact-faces__info-name">Комментарий</div>
        <div class="contact-faces__info-content">
          {{ item.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image from '@/assets/img/user.svg'
import SvgIcon from '@/components/base/SvgIcon.vue';
import ContactContextMenu from '@/components/Contacts/ContactContextMenu.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';

export default {
  name: 'ContactFacesCard',
  components: { BaseTooltip, ContactContextMenu, SvgIcon },
  data() {
    return {
      image
    }
  },
  computed: {
    favoriteIcon() {
      return this.item.general ? 'favorite-fill' : 'favorite'
    },
    entityPermissions() {
      if (!this.entity) return { dashboard_permissions: [] }
      return this.entity.dashboard_permissions
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    global: {
      type: Boolean,
      default: false
    },
    hideDescription: {
      type: Boolean,
      default: false
    },
    favorite: {
      type: Boolean,
      default: false
    },
    entity: Object
  },
  methods: {
    deleteContact() {
      this.$emit('delete', this.global ? this.item.id : this.item.autoIncrement)
    },
    editContact() {
      this.$emit('edit', this.global ? this.item.id : this.item.autoIncrement)
    },
    copyContact() {
      this.$emit('copy', this.global ? this.item.id : this.item.autoIncrement);
    },
    addToFavorites() {
      this.$emit('add-favorites', this.global ? this.item.id : this.item.autoIncrement)
    }
  },
}
</script>

<style lang="scss">
.contact-faces {
  &__favorite {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }
}

@for $i from 1 through 10 {
  .contact-faces__card-block:nth-of-type(#{$i}) {
    position: relative;
    z-index: (20 - $i);
  }
}
</style>
