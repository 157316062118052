import ApiUtil from '@/utils/api'

export default {
  getClients: (params = {}) =>
    ApiUtil({
      url: '/api/v1/clients',
      method: 'get',
      requiresAuth: true,
      params,
    }),
  getClientsSearch: (name, params = null) =>
    ApiUtil({
      url: `/api/v1/clients/search/${name}`,
      method: 'get',
      requiresAuth: true,
      params,
    }),
  getClientsHeader: () =>
    ApiUtil({
      url: '/api/v1/schemas/clients',
      method: 'get',
      requiresAuth: true,
    }),
  getClientDetail: (id) =>
    ApiUtil({
      url: `/api/v1/clients/${id}`,
      method: 'get',
      requiresAuth: true,
    }),
  getClientsXlsx: (params = {}) =>
    ApiUtil({
      url: '/api/v1/clients/xls',
      method: 'get',
      requiresAuth: true,
      responseType: 'blob',
      params,
    }),
  createClient: (data) => {
    return ApiUtil({
      url: '/api/v1/clients',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  editClient: (data, id) => {
    return ApiUtil({
      url: `/api/v1/clients/${id}`,
      method: 'patch',
      requiresAuth: true,
      data,
    })
  },
  deleteClient: (id) => {
    return ApiUtil({
      url: `/api/v1/clients/${id}`,
      method: 'delete',
      requiresAuth: true,
    })
  },
  disabledClient: (id) => {
    return ApiUtil({
      url: `/api/v1/clients/${id}/disable`,
      method: 'post',
      requiresAuth: true,
    })
  },
  getClientXlsx: (id) =>
    ApiUtil({
      url: `/api/v1/clients/xls/${id}`,
      method: 'get',
      requiresAuth: true,
      responseType: 'blob',
    }),
  clientsAddColumns: (data) => {
    return ApiUtil({
      url: '/api/v1/clients/columns',
      method: 'post',
      requiresAuth: true,
      data,
    })
  },
  updateResponseClient: ({ id, managerId }) => {
    return ApiUtil({
      url: `/api/v1/clients/responsible-users/update/${id}`,
      method: 'put',
      requiresAuth: true,
      data: { id: managerId.user_id },
    })
  },
}
