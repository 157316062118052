import { helpers, minLength, minValue, maxValue, required } from '@vuelidate/validators';
import { differenceInDays, differenceInMinutes } from 'date-fns';
import _get from 'lodash.get';

export const validationRules = (componentData) => {
  if (!componentData) return {};
  const emptyErrorText = 'Поле обязательно для заполнения';
  return {
    formState: {
      capital_material_daily: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      capital_material_total: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      capital_service_daily: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      capital_service_total: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      cars: {
        required:
          checkIfIsDisabled(componentData.generalPermissions, 'cars', componentData.isEditSpec ? 'edit' : 'create')
            ? false
            : helpers.withMessage(emptyErrorText, required)
      },
      coefficient: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      cost_material: {
        required: helpers.withMessage(emptyErrorText, required),
        minValue: helpers.withMessage('Минимальное значение - 0', minValue(0))
      },
      cost_transportation_ADT: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      daily_rate: {
        required: helpers.withMessage(emptyErrorText, required),
        checkWeight: helpers.withMessage(
          'Суточная норма клиента не может превышать общ кол-во груза',
          componentData.checkWeight
        ),
      },
      company_unload: {
        name: {
          required: helpers.withMessage(
            emptyErrorText,
            required
          ),
          minLength: helpers.withMessage(
            'Имя должно содержать не менее 5 символов',
            minLength(5)
          ),
        },
        inn: {
          required: !componentData.searchCompany.type
            ? helpers.withMessage('ИНН состоит из 10 или 12 цифр', required)
            : false,
        },
      },
      distance: {
        required: helpers.withMessage('Для сохранения необходимо построить маршрут', required),
      },
      document_type_id: {
        required:
          componentData.paymentType === '0'
            ? helpers.withMessage(emptyErrorText, required)
            : false,
      },
      start: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      end: {
        required: helpers.withMessage(emptyErrorText, required),
        lessThanStart:
          checkIfIsDisabled(componentData.generalPermissions, 'end', componentData.isEditSpec ? 'edit' : 'create') ?
            false :
            helpers.withMessage(
              'Дата окончания не может быть раньше даты начала',
              () => {
                if (!componentData.dateStart || !componentData.dateEnd) return true;

                return differenceInDays(
                  parseDateFirefoxHack(componentData.dateStart),
                  parseDateFirefoxHack(componentData.dateEnd),
                ) <= 0
              }
            ),
      },
      flights: {
        required:
          checkIfIsDisabled(componentData.generalPermissions, 'flights', componentData.isEditSpec ? 'edit' : 'create')
            ? false
            : helpers.withMessage(emptyErrorText, required),
        twoDigitsAfterComma: helpers.withMessage('Не может быть больше двух знаков после запятой', () => {
          if (!componentData.formState.flights) return true;
          return /^\d+(\.\d{0,2})?$/.test(componentData.formState.flights);
        }),
        adtRequired: helpers.withMessage('Поле Коэффициент обязательно для заполнения', () => {
          return !!componentData.formState?.coefficient
        }),
        coefRequired: helpers.withMessage('Поле Стоимость перевозки 1 тонны (АДТ) обязательно для заполнения', () => {
          return !!componentData.formState?.cost_transportation_ADT
        }),
        distanceRequired: helpers.withMessage('Поле Плечо обязательно для заполнения', () => {
          return !!componentData.formState?.distance
        }),
      },
      fraction_id: {
        required:
          componentData.formState.material_type === 'true'
            ? helpers.withMessage(emptyErrorText, required)
            : false,
      },
      loading_rate_id: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      material_type: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      name_good_id: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      payment_type: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      postponement_days: {
        required:
          componentData.paymentType === '0'
            ? helpers.withMessage(emptyErrorText, required)
            : false,
      },
      prepaid_expense: {
        required:
          componentData.paymentType === '0'
            ? helpers.withMessage(emptyErrorText, required)
            : false,
        checkPrepaid: componentData.checkPrepaid
      },
      recommended_price: {
        required:
          checkIfIsDisabled(componentData.generalPermissions, 'recommended_price', componentData.isEditSpec ? 'edit' : 'create')
            ? false
            : helpers.withMessage(emptyErrorText, required),
      },
      request_status_id: {},
      request_type_id: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      specification_status_id: {
        required:
          checkIfIsDisabled(componentData.generalPermissions, 'specification_status', componentData.isEditSpec ? 'edit' : 'create')
            ? false
            : helpers.withMessage(emptyErrorText, required),
      },
      strength_grade_id: {
        required:
          componentData.formState.material_type === 'true'
            ? helpers.withMessage(emptyErrorText, required)
            : false,
      },
      total_weight: {
        required: helpers.withMessage(emptyErrorText, required),
      },
      payment_percentage: {},
      user_comment: {},
      user_id: {},
      route: {
        load_start: {
          required: helpers.withMessage(emptyErrorText, required),
          lessThan: helpers.withMessage(
            'Время окончания не может быть раньше начала',
            () => {
              return differenceInMinutes(
                componentData.parseTime(componentData.formState.route.load_start),
                componentData.parseTime(componentData.formState.route.load_end)
              ) <= 0
            }
          ),
        },
        load_end: {
          required: helpers.withMessage(emptyErrorText, required),
          lessThan: helpers.withMessage(
            'Время окончания не может быть раньше начала',
            () => {
              return differenceInMinutes(
                componentData.parseTime(componentData.formState.route.load_start),
                componentData.parseTime(componentData.formState.route.load_end)
              ) <= 0
            }
          ),
        },
        unload_start: {
          required: helpers.withMessage(emptyErrorText, required),
          lessThan: helpers.withMessage(
            'Время окончания не может быть раньше начала',
            () => {
              return differenceInMinutes(
                componentData.parseTime(componentData.formState.route.unload_start),
                componentData.parseTime(componentData.formState.route.unload_end)
              ) <= 0
            }
          ),
        },
        unload_end: {
          required: helpers.withMessage(emptyErrorText, required),
          lessThan: helpers.withMessage(
            'Время окончания не может быть раньше начала',
            () => {
              return differenceInMinutes(
                componentData.parseTime(componentData.formState.route.unload_start),
                componentData.parseTime(componentData.formState.route.unload_end)
              ) <= 0
            }
          ),
        },
        load_address: {
          required: helpers.withMessage(emptyErrorText, required),
        },
        unload_address: {
          required: helpers.withMessage(emptyErrorText, required),
        },
        load_coordinates: {
          required: helpers.withMessage(emptyErrorText, required),
        },
        unload_coordinates: {
          required: helpers.withMessage(emptyErrorText, required),
        }
      },
      prepayment_type: {
        required:
          componentData.paymentType === '0'
            ? helpers.withMessage(emptyErrorText, required)
            : false,
      }
    }
  }
}

export const checkIfIsDisabled = (permissions, fieldName, permissionType) => {
  return !_get(permissions, `specs.fields.${fieldName}.${permissionType}`, false);
}

const parseDateFirefoxHack = (dateString) => {
  const [day, month, year] = dateString.split('.');
  const formattedDateString = `${year}-${month}-${day}`;
  return new Date(formattedDateString);
}

export const getLprColor = (lprStatusObject) => {
  let color = '#FFFFFF'
  switch (lprStatusObject.id.toString()) {
    case '1':
      color = 'rgba(51, 206, 13, 0.08)'
      break;
    case '2':
      color = 'rgba(255, 31, 0, 0.09)'
      break;
    case '3':
      color = 'rgba(255, 184, 0, 0.13)'
      break;
    default:
      break;
  }
  return color;
}

export const specificationTemplate = {
  lpr_status: {
    title: null,
    id: null
  },
  capital_material_daily: null,
  capital_material_total: null,
  capital_service_daily: null,
  capital_service_total: null,
  cars: null,
  coefficient: null,
  cost_material: null,
  cost_transportation_ADT: null,
  cost_transportation_client: null,
  daily_rate: null,
  company_unload: {
    name: null,
    inn: null,
    id: null,
  },
  distance: null,
  document_type_id: null,
  end: null,
  flights: null,
  fraction_id: null,
  loading_rate_id: null,
  material_type: null,
  name_good_id: null,
  payment_type: null,
  postponement_days: null,
  prepaid_expense: null,
  recommended_price: null,
  request_status_id: null,
  request_type_id: null,
  specification_status_id: null,
  start: null,
  strength_grade_id: null,
  total_weight: null,
  payment_percentage: null,
  user_comment: null,
  user_id: null,
  prepayment_type: 'amount'
}

export const prepaidTypeList = [{
  name: '%',
  id: 'percent',
  value: false
}, {
  name: '₽',
  id: 'amount',
  value: false
}]
