import { ref } from 'vue';

export default function contactContextMenu() {
  const modalContactState = ref(false)
  const currentContact = ref({})
  const localContactList = ref([])

  /**
   * Для использования нужно добавить на страницу input
   * <input class="js-copyInput hidden_input" readonly />
   * @param autoIncrement - id копируемого элемента
   */
  const copyContactInfo = (autoIncrement) => {
    const contactCurrent = localContactList.value.find((item) => {
      if (item.autoIncrement === autoIncrement) {
        return item;
      }
    });

    const number = contactCurrent?.contact_numbers[0]?.number;
    const extensionNumber = contactCurrent?.contact_numbers[0]?.extension_number;
    const numberType = contactCurrent?.contact_numbers[0]?.telephone_type;

    let text = `${numberType ? 'Рабочий' : 'Личный'} телефон: +7${number}`;

    if (extensionNumber) {
      text = text + `(${extensionNumber})`;
    }

    const copyInput = document.querySelector('.js-copyInput');

    copyInput.value = text;
    copyInput.focus();
    copyInput.select();
    document.execCommand('copy');
  }

  /**
   *
   * @param autoIncrement - id удаляемого элемента
   * @returns {*[]} - возвращает массив
   */
  const deleteContact = (autoIncrement) => {
    let index = localContactList.value.findIndex(item => item.autoIncrement === autoIncrement)

    if (index !== -1) {
      localContactList.value.splice(index, 1);
    }

    return localContactList.value;
  }

  /**
   * Получение информации о выбранном элементе при редактировании и вывод этой информации в модальном окне
   * @param id
   */
  const editContext = (id) => {
    const contactCurrent = localContactList.value.find((item) => {
      if (item.autoIncrement === id) {
        return item;
      }
    });

    currentContact.value = contactCurrent;
    setTimeout(() => {
      modalContactState.value = true;
    }, 100)
  }

  /**
   * Закрытие модального окна и сброс текущего элемента
   */
  const closeModal = () => {
    modalContactState.value = false
    currentContact.value = {}
  }

  /**
   * Перезапись общего массива данных с учетом изменений
   * @param params
   */
  const editContact = (params) => {
    let temp = []
    localContactList.value.forEach(item => {
      if (item.autoIncrement === params.autoIncrement) {
        item = params;
      }

      temp.push(item);
    })

    localContactList.value = temp
  }


  return {
    copyContactInfo,
    deleteContact,
    editContext,
    closeModal,
    editContact,
    currentContact,
    modalContactState,
    localContactList
  }
}
