<template>
  <app-layout></app-layout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
  name: "App",

  components: {AppLayout},

  data: () => ({
    stateMenu: false,
  }),
};
</script>

<style lang="scss">
.v-list-item-title {
  font-size: 14px !important;
}
.vmain_padding {
  padding: 0 0 0 210px !important;
}

.vmain_padding_mini {
  padding: 0 0 0 72px !important;
}

.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > th {
  font-size: 10px !important;
}

.v-table > .v-table__wrapper > table > tbody > tr > td,
.v-table > .v-table__wrapper > table > thead > tr > td,
.v-table > .v-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}

.v-table--density-default > .v-table__wrapper > table > tbody > tr > th,
.v-table--density-default > .v-table__wrapper > table > thead > tr > th,
.v-table--density-default > .v-table__wrapper > table > tfoot > tr > th {
  height: 29px !important;
}

.v-table--density-default > .v-table__wrapper > table > tbody > tr > td,
.v-table--density-default > .v-table__wrapper > table > thead > tr > td,
.v-table--density-default > .v-table__wrapper > table > tfoot > tr > td {
  height: 50px !important;
}

.v-list {
  //overflow: hidden !important;
}

.v-list-item--rounded {
  border-radius: 0 !important;
}

.v-list--nav {
  padding-inline-end: 0 !important;
}

.page-link {
  position: fixed;
  right: 30px;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  padding: 10px;

  a {
    color: black;
  }

  span {
    display: block;
  }

  &__list {
    display: flex;
    flex-direction: column;
  }
}
</style>
