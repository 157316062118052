<template>
  <BaseModal size="440" :is-open="isOpen" @close="closeModal">
    <div class="alert">
      <!--   TODO: вернуть иконку когда будет добавлена в дизайн   -->
      <div v-if="type && type !== 'error'" class="icon-center">
        <SvgIcon
          :name='type'
          :height='60'
          :width='60'
        />
      </div>
      <h2 class="alert__header">{{ header }}</h2>
      <div v-if="subtitle !== ''" class="subtitle">
        <span v-html="subtitle"></span>
      </div>
      <div class="action" :class="{
        'action-grid':grid
      }">
        <BaseButton success-btn :style="{backgroundColor: color}" @click="successModal">
          <template v-if="!nameButton">
            <slot/>
          </template>
          <template v-else>
            {{ nameButton }}
          </template>
        </BaseButton>

        <BaseButton v-if="closeButton" @click="closeModal">
          {{ nameButtonClose }}
        </BaseButton>
        <slot name="button"/>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import SvgIcon from '@/components/base/SvgIcon.vue';

export default {
  name: 'AlertModal',
  components: {
    BaseButton,
    BaseModal,
    SvgIcon
  },
  props: {
    grid: Boolean,
    closeButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      header: '',
      subtitle: '',
      verificationCode: '',
      callback: null,
      type: null,
      nameButton: '',
      nameButtonClose: 'Отмена',
      color: null,
      callbackClose: null
    }
  },
  methods: {
    /**
     * Вызвать уведомления
     * @param header - Текст заголовка
     * @param subtitle - Дополнительный контент, отображаемый под заголовком
     * @param option Доп порамитры
     * @param option.type - Тип уведомления по простому влияет только на иконку (success, error)
     * @param option.nameButton - Текст кнопки
     * @param option.nameButtonClose - Текст кнопки отмена
     * @param option.color - Цвет кнопки
     * @param callback - Функция обратного вызова
     */
    show(header, subtitle, option, callback = null, callbackClose = null) {
      if (header != null)
        this.header = header;
      if (subtitle != null)
        this.subtitle = subtitle;
      if (option != null) {
        if (option.type != null)
          this.type = option.type;
        if (option.nameButton != null)
          this.nameButton = option.nameButton;
        if (option.nameButtonClose != null)
          this.nameButtonClose = option.nameButtonClose;
        if (option.color != null)
          this.color = option.color;
      }
      if (callback != null) {
        this.callback = callback;
      }
      if (callbackClose != null) {
        this.callbackClose = callbackClose;
      }
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;

      if (this.callbackClose != null) {
        this.callbackClose()
      }
    },
    successModal() {
      this.closeModal();

      if (this.callback != null) {
        this.callback()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vuetify_variables';

.alert {
  width: 100%;
}

.action {
  display: flex;
}

.action-grid {
  display: grid;
}

.icon-center {
  text-align: center;
}

h2 {
  margin-bottom: 12px;
  text-align: center;
}

span {
  text-align: center;
  font-size: 18px;
}

.subtitle {
  text-align: center;
  margin-bottom: 24px;
}
</style>
