<template>
  <div class="table-clients-page">
    <BackgroundComponent class="table-clients-container">
      <ClientsHeader
        @toggle-filter="filterState = !filterState"
      />
      <div v-if="modalEditState">
        <AboutClientModal
          :is-open="modalEditState"
          @close="modalEditState = false"
          :is-modal-edit="true"
        />
      </div>
      <AlertModal ref="modelDelete">
        <template v-slot:button>
          <BaseButton @click="() => $refs.modelDelete.closeModal()">
            Нет
          </BaseButton>
        </template>
      </AlertModal>
      <div class="display-flex width-100 global-table" :class="{ 'open-filter': filterState }">
        <FilterWidget
          v-if="filterState"
          @filter-submit="filter"
          @filter-reset="filterReset"
          :filters-array="filterModel"
          filter-page="clients"
          @update-state="updateFilterState"
        />
        <BaseTableMenu
          :is-open="menuState"
          :style="{
            left: menuPosition.left,
            top: menuPosition.top,
          }"
        >
          <DashboardDialogMenu
            current-entity="clients"
            :permissions="clientData.dashboard_permissions"
            @edit-entity="showEdit"
            @copy-entity-info="copyClientInfo"
            @change-responsible="changeResponsible"
            @link-specification="linkSpecification"
            @delete-entity="modalDelete"
          />
        </BaseTableMenu>
        <BaseTable
          :key="`table-client-${tempClientTableHeader.length}`"
          :table-head="clientsHeader.schemas"
          :table-body="clientsTableList"
          :table-header-params="tempClientTableHeader"
          :is-loading="isClientsLoading"
          @right-click="onRightClick"
          @sortBy="sortBy"
          @update-width="updateWidth"
        >
          <template #item="{ value, name, specificationId }">
            <router-link
              v-if="name === 'name'"
              :to="{ name: 'ClientCardTablePage', params: { id: specificationId } }"
              class="client-name"
            >
              {{ value }}
            </router-link>
            <router-link
              v-else-if="name === 'id'"
              :to="{ name: 'ClientCardTablePage', params: { id: value } }"
              class="td-client-id"
            >
              {{ value }}
            </router-link>
            <div v-else-if="name === 'status'">
              {{ value ? 'Действителен' : 'Недействителен' }}
            </div>
            <div v-else-if="name === 'responsible'">
              {{ value?.name }}
              {{ value?.family_name }}
            </div>
            <div v-else-if="name === 'fact_addresses'">
              {{ value.length > 0 ? value[0].address : '' }}
            </div>
            <div v-else>
              {{ value }}
            </div>
          </template>
        </BaseTable>
      </div>
    </BackgroundComponent>
    <AlertModal ref="alert" nameButton="Понятно"/>
    <AlertModal
      ref="alertClientDisabled"
      :close-button="true"
      close-text="Нет"
    />
    <div>
      <ChangeResponseModal
        :is-open="isOpenChangeResponsible"
        :list="responseList"
        :current-id="clientData?.id"
        :current-responsible-id="clientData?.responsible?.id"
        url-request="clients/updateResponseClient"
        @responsibleModal:close="closeResponseModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, useStore } from 'vuex'
import BackgroundComponent from '@/components/BackgroundComponent'
import ClientsHeader from '@/components/Clients/ClientsHeader'
import BaseTable from '@/components/base/BaseTable'
import BaseTableMenu from '@/components/base/BaseTableMenu'
import AboutClientModal from '@/components/ClientsModals/AboutClientModal'
import clientsService from '@/services/clientsService'
import AlertModal from '@/components/AlertModal'
import SvgIcon from '@/components/base/SvgIcon'
import BaseButton from '@/components/base/BaseButton'
import { positionContextMenu } from '@/utils/helper';
import { computed } from 'vue';
import FilterWidget from '@/components/filter-templates/FilterWidget';
import ChangeResponseModal from '@/components/Specifications/ChangeResponseModal';
import DashboardDialogMenu from '@/components/Dashboard/DashboardDialogMenu.vue';

export default {
  name: 'TableClientsPage',
  data() {
    return {
      filterState: false,
      menuState: false,
      modalEditState: false,
      modalDeleteState: false,
      menuPosition: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      filters: '',
      filtersResponse: [],
      filterForm: {
        revenueFrom: '',
        revenueTo: '',
        statusActive: false,
        statusNotActive: false,
        typeLegal: false,
        typePhysical: false,
        quarryActive: false,
        quarryNotActive: false,
      },
      statePreloader: true,
      sortParams: {},
      isOpenChangeResponsible: false,
    }
  },
  setup() {
    const store = useStore()
    const filterModel = computed(() => store.getters['filter/filterModel']);
    store.dispatch('filter/getFilterModel', 'clients');

    return {
      filterModel
    }
  },
  components: {
    DashboardDialogMenu,
    ChangeResponseModal,
    FilterWidget,
    BaseButton,
    SvgIcon,
    BackgroundComponent,
    ClientsHeader,
    BaseTable,
    BaseTableMenu,
    AboutClientModal,
    AlertModal,
  },
  computed: {
    ...mapGetters({
      clients: 'clients/clientsList',
      clientsTableList: 'clients/clientsTableList',
      clientsHeader: 'clients/clientsHeaderList',
      clientData: 'clients/getSelectedClient',
      searchResultState: 'clients/clientsSearchResult',
      account: 'account/account',
      clientsPerPage: 'clients/clientsPerPage',
      filterParams: 'clients/getClientsFilterParams',
      tempClientTableHeader: 'clients/tempClientTableHeader',
      responseList: 'users/responseList',
      isClientsLoading: 'clients/getIsClientsLoading',
    }),
  },
  methods: {
    async modalDelete() {
      this.$refs.modelDelete.show(
        'Внимание!',
        'Вы уверены, что хотите удалить карточку клиента?',
        {
          nameButton: 'Да',
        },
        () => {
          this.deleteClient()
        }
      )
    },

    async getClientsHeaderList(params) {
      try {
        await this.$store.dispatch('clients/getClientsHeader', params)
      } catch (e) {
        this.$refs.alert.show(this.$t('alert.error'), e, {
          nameButton: 'Понятно',
        })
      }
    },

    async linkSpecification() {
      const { clientData } = this

      let currentClient;

      if (clientData.companies.length < 1) {
        currentClient = {
          name: '',
          inn: '',
        }
      } else {
        currentClient = clientData.companies.find((company) => {
          return company?.general == 1 ? company : clientData.companies[0];
        });
      }

      await this.$store.dispatch('specifications/setSpecificationDetail', {
        company_unload: {
          name: currentClient.name,
          inn: currentClient.inn,
          id: currentClient.id,
        },
      })

      await this.$router.push({ name: 'CreateSpecificationPage' })
    },

    async getClientsList(params) {
      try {
        await this.$store.dispatch('clients/getClients', params)
      } catch (error) {
        const { data } = error.response?.data

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            nameButton: 'Понятно',
            color: '#FFA500',
          })
        }
      } finally {
        this.statePreloader = false
      }
    },

    async sortBy(item) {
      const currentFilterParams = this.filterParams
      let sort = {}
      let query = {}

      if (item.sort === 'default') {
        query = { ...this.$route.query, sort: item.id, ...currentFilterParams }
        this.$router.push({ query })
        sort['sort'] = item.id
      }
      if (item.sort === 'ASC') {
        query = { ...this.$route.query, sort: `-${item.id}`, ...currentFilterParams }
        this.$router.push({ query })
        sort['sort'] = `-${item.id}`
      }
      if (item.sort === 'DESC') {
        this.$router.push({ query: { ...currentFilterParams } })
      }

      this.sortParams = sort

      const currentSortParams = this.sortParams;
      query = {
        ...query,
        ...currentSortParams,
        ...currentFilterParams,
        page: 1,
        per_page: this.clientsPerPage
      }

      await this.getClientsHeaderList(sort)
      await this.getClientsList(query)
    },
    async disabledClient() {
      try {
        const { clientData } = this
        const currentFilterParams = this.filterParams
        const currentSortParams = this.sortParams
        const query = { ...currentSortParams, ...currentFilterParams }

        await clientsService.disabledClient(clientData.id)
        await this.$store.dispatch('clients/getClients', query)
      } catch (error) {
        const { data } = error.response?.data
        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            nameButton: 'Понятно',
            color: '#FFA500',
          })
        }
      }
    },

    async deleteClient() {
      try {
        const { clientData } = this
        const currentFilterParams = this.filterParams
        const currentSortParams = this.sortParams
        const query = {
          ...currentSortParams,
          ...currentFilterParams,
          ...this.$route.query
        }

        await clientsService.deleteClient(clientData.id)
        await this.$store.dispatch('clients/getClients', query)
      } catch (error) {
        const { data } = error.response?.data

        if (data?.message) {
          this.$refs.alertClientDisabled.show(
            'Внимание',
            data?.message,
            {
              nameButton: 'Да',
              nameButtonClose: 'Нет',
              color: '#FFA500',
            },
            () => {
              this.disabledClient()
            }
          )
        }
      } finally {
        this.modalDeleteState = false
      }
    },

    // async getClientXlsx() {
    //   try {
    //     const { clientData } = this
    //     const { data } = await clientsService.getClientXlsx(clientData.id)

    //     saveAs(data, "file_client.xlsx")
    //   } catch (error) {
    //     const data = error.response?.data

    //     if (data?.message) {
    //       this.$refs.alert.show("Ошибка", data?.message, {
    //         type: "error",
    //         nameButton: "Ок",
    //         color: "#FFA500",
    //       })
    //     }
    //   }
    // },

    // downloadClientXlsx() {
    //   this.getClientXlsx()
    // },

    hideMenu() {
      this.menuState = false
    },

    onLeftClick(event) {
      const id = event.currentTarget.parentNode.dataset.id
      // переход на детальную страницу + id страниц
      this.$router.push({ name: 'ClientCardTablePage', params: { id } })
    },

    onRightClick({ event, id }) {
      const current = this.clients.filter((item) => item.id === id)[0]

      this.$store.dispatch('clients/setSelectedClient', current)

      this.menuState = true

      setTimeout(() => {
        positionContextMenu(event, this.menuPosition);
      });
    },

    showEdit() {
      this.modalEditState = true
      this.menuState = false
    },

    async copyClientInfo(text) {
      const { clientData } = this;
      const innTitle = 'ИНН компании: '

      const currentClient = clientData.companies.find((company) => {
        return company?.general == 1 ? company : clientData.companies[0]
      })

      const currentFactAddress = `, Фактический адрес: ${clientData.fact_addresses[0].address}`

      text = innTitle + currentClient.inn + currentFactAddress

      const copyInput = document.querySelector('.js-copyInput')

      copyInput.value = text
      copyInput.focus()
      copyInput.select()
      document.execCommand('copy')
    },
    filter(filterList) {
      this.statePreloader = true;
      this.$router.replace({ query: { ...this.$route.query, page: 1, per_page: this.clientsPerPage } })
      this.getClientsList({ ...filterList, page: 1, per_page: this.clientsPerPage });

      this.$store.dispatch('clients/setClientsFilterParams', filterList);
    },
    async filterReset() {
      this.statePreloader = true
      this.$router.replace({
        name: 'TableClientsPage',
        query: {
          page: 1,
          per_page: this.clientsPerPage
        }
      })
      this.$store.dispatch('clients/setClientsFilterParams', {});
      await this.getClientsList({ page: 1, per_page: this.clientsPerPage });
    },

    updateFilterState(filterList) {
      this.$store.dispatch('filter/updateCurrentFilter', filterList);
    },
    async updateWidth(params) {
      await this.$store.dispatch('clients/updateColumnWidth', params)
    },

    async getFilterClients() {
      await this.$store.dispatch('filter/getFilterClientsList')
    },
    changeResponsible() {
      this.isOpenChangeResponsible = true;
    },
    async closeResponseModal(isDataUpdate = false) {
      this.isOpenChangeResponsible = false
      if (!isDataUpdate) return;
      await this.getClientsList(this.$route.query);
    },
  },
  async created() {
    await this.getClientsHeaderList(this.$route.query);
    await this.getClientsList(this.$route.query);
    await this.getFilterClients();
  },
  mounted() {
    window.addEventListener('click', this.hideMenu)
  },
  unmounted() {
    window.removeEventListener('click', this.hideMenu)
  },
}
</script>

<style scoped lang="scss">
.table-clients-container {
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 10px;
  overflow: hidden;
}

.td-client-id {
  text-align: center;
  width: 100%;
}

.td-client-id,
.client-name {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.table-clients-page {
  .ps {
    width: 100%;
  }

  .clients-page {
    background-color: #f4f4f8;
  }

  .flex-container {
    display: flex;
  }

  .v-expansion-panel__shadow {
    box-shadow: none !important;
  }

  .table {
    //overflow: auto;
    position: relative;
  }

  .table .v-table {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    //overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .content-header {
    display: flex;
    margin-top: 35px;
    align-items: center;
    margin-bottom: 24px;
  }

  .clients-page {
    background: #f2f4f6;
  }

  .content {
    background: #f4f4f8;
    border-radius: 12px;
    margin-left: 18px;
    padding: 20px;
    /*height: 100vh; */
  }

  .tr-title {
    background: #f4f4f8;
    font-family: "Manrope";
    font-weight: 500;
    font-size: 10px;
    line-height: 19px;
    color: #0a2333;
  }

  .td-item {
    font-family: "Manrope";
    font-size: 10px !important;
    line-height: 19px;
    // text-transform: capitalize;
  }

  .client-name {
    //font-size: 10px !important;
    font-weight: 600;
    color: #0055e8;
  }

  .td-total-revenue {
    font-weight: 600;
    color: #0f537e;
  }

  .td-phone {
    font-weight: 400;
    color: #afafaf;
  }

  .td-items {
    font-weight: 400;
    color: #5a5a5a;
    font-size: 10px;
  }

  .td-contact-person {
    font-weight: 400;
    color: #0a2333;
  }

  .td-client-id {
    font-weight: 500;
    color: #11142d;
    text-decoration: underline;
  }

  .flex-block {
    display: flex;
  }

  .sidebar-content {
    height: 100%;
  }
}

.no-result {
  min-height: 400px;
}

.cell-th-legal_address_symbol > div,
.cell-legal_address_symbol > div {
  max-width: 100%;
  overflow: hidden;
  max-height: 38px;
}
</style>
