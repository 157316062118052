export const handleError = (e) => {
  if (e.response.status === 401) {
    window.location.href = '/';
  }
}

export const errorPrettier = (error) => {
  if (error?.response?.data?.errors) {
    const prepError = Object.values(error.response.data.errors).flat()[0]
    throw new Error(prepError);
  }
  throw new Error(error);
}
