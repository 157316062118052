<template>
  <AuthorizationBackground class="try-again">
    <h1 class="header-styles">
      Повторите попытку позже
    </h1>
    <h1 class="subheader">
      Вы превысили количество попыток входа,<br> обратитесь к администратору IT-отдела.
    </h1>
  </AuthorizationBackground>
</template>

<script>
import AuthorizationBackground from '@/components/Authorization/AuthorizationBackground';

export default {
  name: 'TryAgainPage',
  components: {
    AuthorizationBackground
  }
}
</script>

<style lang="scss">
.try-again {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  width: 100%;
  height: 100vh;
  margin-top: 10px;
}

.subheader {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FF3D3D;
  margin-top: 10px;
}


</style>
