<template>
  <div class="registration-page">
    <AuthorizationBackground>
      <h1 class="header-styles">
        Зарегистрироваться
      </h1>
      <form id="" action="" class="authorization-form">
        <label for="fio" class="authorization-label">Ваши данные<span>*</span></label>
        <input type="text" id="fio" placeholder="ФИО" class="authorization-input">

        <label for="job-title" class="authorization-label">Должность<span>*</span></label>
        <input type="text" id="job-title" placeholder="Введите свою должность" class="authorization-input">

        <label for="tel" class="authorization-label">Телефон<span>*</span></label>
        <input type="tel" id="tel" placeholder="Введите телефон" class="authorization-input">

        <label for="password" class="authorization-label">Пароль<span>*</span></label>
        <input type="password" id="password" placeholder="Придумайте пароль" class="authorization-input">

      </form>
      <button type="submit" class="authorization-button">Зарегистрироваться</button>
    </AuthorizationBackground>
  </div>
</template>

<script>
import AuthorizationBackground from '@/components/Authorization/AuthorizationBackground';

export default {
  name: 'RegistrationPage',
  components: {
    AuthorizationBackground
  }
}
</script>

<style lang="scss">
.registration-page {
  display: flex;
  justify-content: center;
  .header-styles {
    margin-bottom: 29px;
  }
}
</style>