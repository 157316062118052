<template>
  <div>
    <v-app>
      <v-card>
        <v-layout>
          <v-main style="" class="sidebar-content">
            <div class="sidebar-content__card" :class="{ 'full-map': isFull }">
              <div class="sidebar-content__card-data">
                <SpecificationCard />
              </div>
              <div class="sidebar-content__card-map">
                <MapSpecification
                  :address="addressInstance?.routeList"
                  :routeLine="routePolyline"
                  :distance="specification.distance"
                  :isLeft="false"
                  show-route-on-start
                  is-editor-disabled
                  :coord-props="specification?.route?.route_line"
                  @toggleFull="toggleFull"
                />
              </div>
            </div>
          </v-main>
        </v-layout>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpecificationCard from '@/components/Specifications/SpecificationCard';
import MapSpecification from '@/components/Specifications/MapSpecification'
import Address from '@/utils/specification/Address';

export default {
  name: 'SpecificationCardPage',
  components: {
    SpecificationCard,
    MapSpecification
  },
  computed: {
    ...mapGetters({
      specification: 'specifications/specificationDetail',
    }),
  },
  data() {
    return {
      isFull: false,
      addressInstance: null,
      addressTypes: null,
      routePolyline: null,
    }
  },
  methods: {
    toggleFull(val) {
      this.isFull = val
    }
  },
  watch: {
    specification() {
      this.addressInstance = new Address(this.specification.route);
      this.addressTypes =  this.addressInstance.getAddressTypes();
      this.routePolyline = this.addressInstance.getRoutePolyline();
    }
  },
}
</script>

<style lang="scss">
.sidebar-content {
  &__card {
    display: grid;
    grid-template-columns: 690px calc(100% - 706px);
    max-height: 100vh;
    gap: 16px;

    &.full-map {
      grid-template-columns: 0 1fr;
      gap: 0;

      .sidebar-content__card-data {
        width: 0;
        overflow: hidden;
      }
    }
  }
  &__card-data {
    max-height: 100vh;
  }
}
.map-wrapper {
  display: flex;
  padding-left: 0px;

  .map {
    position: relative;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: 0px;
    height: 887px;
    width: 100%;
    z-index: 9;

    &-transit__btn {
      color: #0a2333;
      font-weight: 600;
      font-size: 13px;
      margin-top: 7px;
      margin-right: 12px;

      &:disabled {
        color: #b4b4b4;
      }
    }

    .specification-input-address::placeholder {
      background: url("@/assets/img/address.svg") no-repeat;
    }

    .no_active {
      display: none;
    }

    .specification-input-address {
      padding: 15px;
      background: #f6f6f6;
      border-radius: 8px;
      font-size: 14px;
      margin-bottom: 5px;
      outline: none;
    }

    &-inputs__wrap {
      z-index: 10;
      position: absolute;
      background: #ffffff;
      border-radius: 12px;
      padding: 15px;
      left: 30px;
      top: 10%;
    }

    &-active-full {
      position: absolute;
      height: 98vh;
      z-index: 9;
      width: 100%;
      left: 0px;

      .map-big {
        width: 100%;
        height: 100%;
      }
    }

    &-big {
      height: 887px;
      width: 100%;
    }

    &-zoom__btns {
      z-index: 10;
      position: absolute;
      right: 38px;
      top: 175px;
      display: flex;
      flex-direction: column;
    }

    &-zoom__icon {
      width: 24px;
      height: 24px;
      margin: 10px;
    }

    &-zoom__btn {
      background: white;
      color: #0a2333;

      &-full {
        z-index: 10;
        border-radius: 6px;
        position: absolute;
        background: white;
        top: 31px;
        right: 38px;
        width: 44px;
        height: 44px;
      }

      &-full_back {
        z-index: 10;
        border-radius: 6px;
        position: absolute;
        background: white;
        top: 10px;
        left: 30px;
        width: 44px;
        height: 44px;
      }

      &:first-child {
        border-radius: 6px 6px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 6px 6px;
      }
    }
  }
}
</style>

