<template>
  <div class="flex-block">
    <h2 class="specification-subtitle">Маршрут</h2>
    <input type="text" class="copy_input"/>
    <SvgIcon
      name="route"
      @click="copyRoute"
      class="specification-subtitle-img"
    />
  </div>
  <div class="flex-block">
    <div class="new-specification__route-addresses new-specification__route-addresses_single">
      <label class="specification__label">
        Ссылка на построенный маршрут из Яндекс.Карт
      </label>
      <div :class="['specification-input-address_wrap', { unfocused: !isLoadFocused }]">
        <input 
          autocomplete="off"
          placeholder="Добавьте ссылку"
          v-model="linkRouteMap" 
          type="text" 
          class="specification-input specification-input-address specification__route-parse-input"
          :disabled="!checkIfIsEnabled('route.load_address')"
        />
        <div class="fake-placeholder">Добавьте ссылку</div>
      </div>
    </div>
    <button @click="parseRoute" :disabled="!checkIfIsEnabled('route.load_address')" class="specification__route-parse-btn add-button">Применить</button>
  </div>
  <form autocomplete="off" @submit.prevent>
    <div class="new-specification__route">
      <div class="new-specification__route-addresses" :class="{ 'new-specification__route-addresses_single': isAddressFromDropdown }">
        <label class="specification__label">
          Адрес погрузки
        </label>
        <div :class="['specification-input-address_wrap', { unfocused: !isLoadFocused }]">
          <div v-show="!isAddressFromDropdown">
            <input
                autocomplete="off"
                type="text"
                placeholder="Введите адрес"
                id="load-suggest"
                v-model="addressLoad"
                @focus="() => applyFocus('load')"
                @blur="() => applyBlur('load')"
                :disabled="!checkIfIsEnabled('route.load_address')"
                :class="[
                  'specification-input',
                  'specification-input-address',
                  { error: validationErrors.load_address.$error }
                ]"
            />
            <div class="fake-placeholder">Введите адрес</div>
          </div>
          <BaseDropDownExtended
              v-show="isAddressFromDropdown"
              :is-multiple="false"
              placeholder="Выберите карьер или порт"
              v-model="addressLoadDropdownValue"
              :options="addressLoadList"
              label="title"
              :searchable="true"
              is-default
              has-custom-slot
              :search-by-fields="['title', 'address']"
              :is-disabled="!checkIfIsEnabled('route.load_address')"
              :class="[
                { error: validationErrors.load_address.$error }
              ]"
              is-single
          >
            <template #customItem="item">
              <div class="new-specification__dropdown-custom-item">
                <p class="new-specification__dropdown-title" v-if="item?.title">
                  {{ item.title }}
                </p>
                <p class="new-specification__dropdown-additional" v-if="item?.type">
                  {{ item.type === 'quarry' ? 'Карьер' : item.type === 'seaport' ? 'Порт' : '' }}
                </p>
              </div>
            </template>
          </BaseDropDownExtended>
          <BaseCheckbox
              class="specification-address-toggle"
              checkbox-name="Выбрать карьер или порт из списка"
              :value="isAddressFromDropdown"
              :disabled="!checkIfIsEnabled('route.load_address') || !addressLoadList"
              @update:modelValue="toggleAddressFromDropdown"
          />
        </div>
        <div class="specification__coordinates" v-if="!isAddressFromDropdown">
          <div :class="[
                 'specification__coordinates-sync', {
                   'active': _get(isSyncMode, addressTypes?.LOAD, true)
                 }]"
               @click="toggleIsSyncOn(addressTypes.LOAD)"
               @mouseleave="isTooltipActive = true"
          >
            <SvgIcon
              @click="isTooltipActive = false"
              name="sync"
            />
            <BaseTooltip v-if="isTooltipActive" text="Изменить стандартное название адреса" />
          </div>
          <div class="specification__coordinates-wrap">
            <Input
              autocomplete="off"
              label="Координаты"
              type="text"
              v-model="addressLoadCoordinates"
              placeholder="Введите координаты"
              :errors="validationErrors.load_coordinates?.$errors"
              :inputClass="{
                error: validationErrors.load_coordinates?.$error
              }"
              :disabled="!checkIfIsEnabled('route.load_address')"
              @input="(value) => triggerUpdateLocalAddress({ type: addressTypes.LOAD, coords: value })"
              class="specification-input coordinates"
            />
          </div>
        </div>
      </div>
      <div class="flex-block align-self-start route-field-offset_hours">
        <div class="form-col">
          <div class="display-flex mb-4 time-flex">
            <div class="time">
              <BaseModalInput
                label="Часы работы от"
                v-model="loadSchedule.start"
                placeholder="Введите время"
                type="time"
                class="new-specification-input display-flex flex-column"
                :class="{
                  error: validationErrors.load_start?.$error
                }"
                :errors="validationErrors.load_start?.$errors"
                :time-list="timeWithStep30"
                :disabled="
                  loadSchedule.end === '24:00' && loadSchedule.start === '24:00' ||
                    !checkIfIsEnabled('route.load_start')"
                @update:modelValue="updateSchedule($event, `${addressTypes.LOAD}|start`)"
              />
            </div>
            <div class="time">
              <BaseModalInput
                label="Часы работы до"
                v-model="loadSchedule.end"
                placeholder="Введите время"
                type="time"
                class="new-specification-input display-flex flex-column"
                :class="{
                  error: validationErrors.load_end?.$error
                }"
                :errors="validationErrors.load_end?.$errors"
                :time-list="timeWithStep30"
                :disabled="
                  loadSchedule.end === '24:00' && loadSchedule.start === '24:00' ||
                    !checkIfIsEnabled('route.load_start')"
                @update:modelValue="updateSchedule($event, `${addressTypes.LOAD}|end`)"
              />
            </div>
          </div>
        </div>
        <BaseCheckbox
          checkbox-name="Круглосуточно"
          :value="loadSchedule.end === '24:00' && loadSchedule.start === '24:00'"
          :disabled="!checkIfIsEnabled('route.load_start')"
          @input="updateSchedule($event, `${addressTypes.LOAD}|all-day`)"
        />
      </div>
      <span class="route-arrow route-arrow--top"></span>
    </div>
    <div class="new-specification__route">
      <div class="new-specification__route-addresses"  :class="{ 'new-specification__route-addresses_single': isAddressToDropdown }">
        <label class="specification__label">
          Адрес выгрузки
        </label>
        <div :class="['specification-input-address_wrap', { unfocused: !isUnloadFocused }]">
          <div v-show="!isAddressToDropdown">
            <input
                autocomplete="off"
                type="text"
                placeholder="Введите адрес"
                id="unload-suggest"
                v-model="addressUnload"
                @focus="() => applyFocus('unload')"
                @blur="() => applyBlur('unload')"
                :disabled="!checkIfIsEnabled('route.unload_address')"
                :class="[
                  'specification-input',
                  'specification-input-address',
                  { error: validationErrors.unload_address.$error }
                ]"
            />
            <div class="fake-placeholder">Введите адрес</div>
          </div>
          <BaseDropDownExtended
            v-show="isAddressToDropdown"
            :is-multiple="false"
            placeholder="Выберите карьер или порт"
            v-model="addressUnloadDropdownValue"
            :options="addressLoadList"
            :searchable="true"
            label="title"
            :search-by-fields="['title', 'address']"
            is-default
            has-custom-slot
            is-single
            :is-disabled="!checkIfIsEnabled('route.unload_address')"
            :class="[
              { error: validationErrors.unload_address.$error }
            ]"
          >
            <template #customItem="item">
              <div class="new-specification__dropdown-custom-item">
                <p class="new-specification__dropdown-title" v-if="item?.title">
                  {{ item.title }}
                </p>
                <p class="new-specification__dropdown-additional" v-if="item?.type">
                  {{ item.type === 'quarry' ? 'Карьер' : item.type === 'seaport' ? 'Порт' : '' }}
                </p>
              </div>
            </template>
          </BaseDropDownExtended>
          <BaseCheckbox
              class="specification-address-toggle"
              checkbox-name="Выбрать карьер или порт из списка"
              :value="isAddressToDropdown"
              :disabled="!checkIfIsEnabled('route.unload_address') || !addressLoadList"
              @update:modelValue="toggleAddressToDropdown"
          />
        </div>
        <div class="specification__coordinates" v-if="!isAddressToDropdown">
          <div :class="[
                 'specification__coordinates-sync', {
                   'active': _get(isSyncMode, addressTypes?.UNLOAD, true)
                 }]"
               @click="toggleIsSyncOn(addressTypes.UNLOAD)"
               @mouseleave="isTooltipActive = true"
          >
            <SvgIcon
              @click="isTooltipActive = false"
              name="sync"
            />
            <BaseTooltip v-if="isTooltipActive" text="Изменить стандартное название адреса" />
          </div>

          <div class="specification__coordinates-wrap">
            <Input
              autocomplete="off"
              label="Координаты"
              type="text"
              v-model="addressUnloadCoordinates"
              placeholder="Введите координаты"
              :errors="validationErrors.unload_coordinates?.$errors"
              :inputClass="{
                error: validationErrors.unload_coordinates?.$error
              }"
              @input="(value) => triggerUpdateLocalAddress({ type: addressTypes.UNLOAD, coords: value })"
              :disabled="!checkIfIsEnabled('route.unload_address')"
              class="specification-input coordinates"
            />
          </div>
        </div>
      </div>
      <div class="flex-block align-self-start route-field-offset_hours">
        <div class="form-col">
          <div class="display-flex mb-4 time-flex">
            <div class="time">
              <BaseModalInput
                label="Часы работы от"
                v-model="unloadSchedule.start"
                placeholder="Введите время"
                type="time"
                class="new-specification-input display-flex flex-column"
                :class="{
                  error: validationErrors.unload_start?.$error
                }"
                :errors="validationErrors.unload_start?.$errors"
                :time-list="timeWithStep30"
                :disabled="
                  unloadSchedule.end === '24:00' && unloadSchedule.start === '24:00' ||
                    !checkIfIsEnabled('route.unload_start')"
                @update:modelValue="updateSchedule($event, `${addressTypes.UNLOAD}|start`)"
              />
            </div>
            <div class="time">
              <BaseModalInput
                label="Часы работы до"
                v-model="unloadSchedule.end"
                placeholder="ЧЧ/ММ"
                type="time"
                class="new-specification-input display-flex flex-column"
                :class="{
                  error: validationErrors.unload_end?.$error
                }"
                :errors="validationErrors.unload_end?.$errors"
                :time-list="timeWithStep30"
                :disabled="
                  unloadSchedule.end === '24:00' && unloadSchedule.start === '24:00' ||
                    !checkIfIsEnabled('route.unload_start')"
                @update:modelValue="updateSchedule($event, `${addressTypes.UNLOAD}|end`)"
              />
            </div>
          </div>
        </div>
        <BaseCheckbox
          checkbox-name="Круглосуточно"
          :value="unloadSchedule.end === '24:00' && unloadSchedule.start === '24:00'"
          :disabled="!checkIfIsEnabled('route.unload_start')"
          @input="updateSchedule($event, `${addressTypes.UNLOAD}|all-day`)"
        />
      </div>
      <span class="route-arrow route-arrow--bottom"></span>
    </div>
  </form>

  <button
    class="build-route-btn"
    @click="triggerRouteBuilding"
  >
    Построить маршрут
  </button>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { loadYandexMaps } from '@/services/yandexMapLoader.js';
import { specificationService } from '@/services';
import { copyRouteLink, debounce, isValidUrl } from '@/utils/helper.js';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import SvgIcon from '@/components/base/SvgIcon.vue';
import Input from '@/components/Specifications/Input.vue';
import _get from 'lodash.get';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import BaseModalInput from '@/components/base/BaseModalInput.vue';
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import BaseDropDownExtended from "@/components/base/BaseDropDownExtended.vue";

const props = defineProps({
  isEdit: Boolean,
  address: Array,
  permissions: Object,
  specification: Object,
  addressTypes: Object,
  workingSchedule: Object,
  validationErrors: Object,
  isSyncMode: Object,
  addressLoadList: Array,
})

const emits = defineEmits([
  'updated:address',
  // событие с дебаунсом необходимо для работы с геокодером
  // при вводе адреса, вводе координат исплользуется яндекс геокодер
  // чтобы не запрашивать данные при каждлом вводе используется дебаунс
  'updated:address-debounced',
  'updated:working-schedule',
  'trigger:share-route',
  'toggled:is-sync-mode',
  'trigger:buildRoute',
  'trigger:update-local-address',
  'update:adress-coords',
  'updated:entity',
  'trigger:openLink',
]);

const isAddressFromDropdown = ref(false)
const isAddressToDropdown = ref(false)

const isAddressLoadDropdown = computed(() => {
  if (!props.address) return false
  return !!props.address.find(item => item.type === props.addressTypes.LOAD)?.entity_id
})
const isAddressUnloadDropdown = computed(() => {
  if (!props.address) return false
  return !!props.address.find(item => item.type === props.addressTypes.UNLOAD)?.entity_id
})
const addressLoadDropdownValue = computed({
  get() {
    if (!props.address) return []
    const result = props.addressLoadList?.find(el => el.id === props.address.find(item => item.type === props.addressTypes.LOAD)?.entity_id);

    return result ? [result] : [];
  },
  set(newValue) {
    if (newValue) {
      emits('updated:entity', { entity_id: newValue.id, entity_type: newValue.type, type: props.addressTypes.LOAD })
      emits('updated:address-debounced', { value: newValue.coordinates?.join(','), type: `${props.addressTypes.LOAD}|coords` })
    }
    else {
      emits('updated:entity', { entity_id: null, entity_type: null, type: props.addressTypes.LOAD })
      emits('updated:address', { value: null, type: props.addressTypes.LOAD })
      emits('updated:address-debounced', { value: null, type: `${props.addressTypes.LOAD}|coords` })
    }
  }
})
const addressUnloadDropdownValue = computed({
  get() {
    if (!props.address) return []
    const result = props.addressLoadList?.find(el => el.id === props.address.find(item => item.type === props.addressTypes.UNLOAD)?.entity_id);

    return result ? [result] : [];
  },
  set(newValue) {
    if (newValue) {
      emits('updated:entity', { entity_id: newValue.id, entity_type: newValue.type, type: props.addressTypes.UNLOAD })
      emits('updated:address-debounced', { value: newValue.coordinates?.join(','), type: `${props.addressTypes.UNLOAD}|coords` })
    }
    else {
      emits('updated:entity', { entity_id: null, entity_type: null, type: props.addressTypes.UNLOAD })
      emits('updated:address', { value: null, type: props.addressTypes.UNLOAD })
      emits('updated:address-debounced', { value: null, type: `${props.addressTypes.UNLOAD}|coords` })
    }
  }
})
const addressLoad = computed({
  get() {
    if (!props.address) return
    return props.address.find(item => item.type === props.addressTypes.LOAD)?.address
  },
  set(newValue) {
    if (typeof newValue === 'string') {
      emits('updated:address-debounced', { value: newValue, type: props.addressTypes.LOAD })
    } else { // при обновлении из списка опций
      emits('updated:address', { value: newValue.value, type: props.addressTypes.LOAD })
    }
  }
})
const addressUnload = computed({
  get() {
    if (!props.address) return
    return props.address.find(item => item.type === props.addressTypes.UNLOAD)?.address
  },
  set(newValue) {
    if (typeof newValue === 'string') {
      emits('updated:address-debounced', { value: newValue, type: props.addressTypes.UNLOAD })
    } else { // при обновлении из списка опций
      emits('updated:address', { value: newValue.value, type: props.addressTypes.UNLOAD })
    }
  }
})
const loadSchedule = computed({
  get() {
    if (!props.workingSchedule || !props.addressTypes) return { start: '', end: '' };
    return props.workingSchedule[props.addressTypes.LOAD]
  },
  set({ value, type }) {
    const updateEventObject = { value: value, type: type };
    emits('updated:working-schedule', updateEventObject);
  }
})
const unloadSchedule = computed({
  get() {
    if (!props.workingSchedule || !props.addressTypes) return { start: '', end: '' };
    return props.workingSchedule[props.addressTypes.UNLOAD]
  },
  set({ value, type }) {
    const updateEventObject = { value: value, type: type };
    emits('updated:working-schedule', updateEventObject);
  }
})

const addressLoadCoordinates = computed({
  get() {
    if (!props.address) return;
    return props.address.find(item => item.type === props.addressTypes.LOAD)?.coords
  },
  set(newValue) {
    if (!isValidCoordinate(newValue)) return;
    emits('updated:address-debounced', { value: newValue, type: `${props.addressTypes.LOAD}|coords` })
  }
})
const addressUnloadCoordinates = computed({
  get() {
    if (!props.address) return
    return props.address.find(item => item.type === props.addressTypes.UNLOAD)?.coords;
  },
  set(newValue) {
    if (!isValidCoordinate(newValue)) return;
    emits('updated:address-debounced', { value: newValue, type: `${props.addressTypes.UNLOAD}|coords` })
  }
})

const calculateTimeWithStep30 = () => {
  const times = []
  let startHourInMinute = 0
  let endHourInMinute = (23 * 60) + 30
  let interval = 30

  for (let i = 0; startHourInMinute < 24 * 60; i++) {
    if (startHourInMinute > endHourInMinute) break

    var hh = Math.floor(startHourInMinute / 60)
    var mm = startHourInMinute % 60

    times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2)

    startHourInMinute = startHourInMinute + interval
  }

  return times
}

const timeWithStep30 = calculateTimeWithStep30()

const searchRoutes = async () => {
  try {
    if (addressUnloadCoordinates.value && addressLoadCoordinates.value) {

      const { data } = await specificationService.searchRoutes({
        load_coordinates: addressLoadCoordinates.value,
        unload_coordinates: addressUnloadCoordinates.value,
      })
      const routeLine = Array.isArray(data.data) ? data.data[0] : data.data
      if (routeLine) {
        emits('update:adress-coords', routeLine)
      }
    }
  } catch (error) {
    throw new Error(error)
  }
}

watch(() => addressUnloadCoordinates.value, async () => searchRoutes())
watch(() => addressLoadCoordinates.value, async () => searchRoutes())
watch(() => isAddressLoadDropdown.value, (newValue) => {
  isAddressFromDropdown.value = !!newValue
}, {immediate: true})
watch(() => isAddressUnloadDropdown.value, (newValue) => {
  isAddressToDropdown.value = !!newValue
}, {immediate: true})


const toggleIsSyncOn = (type) => {
  isTooltipActive.value = false;
  if (!checkIfIsEnabled('route.load_address')) return;
  emits('toggled:is-sync-mode', type)
}

const isValidCoordinate = (string) => {
  const regex = /^-?\d*\.?\d+,\s?-?\d*\.?\d+$/;
  return regex.test(string) || string === '';
}

const updateSchedule = (newValue, type) => {
  const [addressType, processType] = type.split('|');
  const isAllDay = processType === 'all-day';
  const scheduleToUpdate = addressType === props.addressTypes.LOAD ? loadSchedule : unloadSchedule;
  const value = isAllDay ? newValue.target.checked ? '24:00' : '00:00' : newValue;
  scheduleToUpdate.value = { value: value, type: type };
}

const checkIfIsEnabled = (permission) => {
  const path = `${permission}.${props.isEdit ? 'edit' : 'create'}`;
  if (!props.isEdit) {
    return _get(props.permissions, path, false);
  }
  return _get(props.permissions, path, false);
}

const copyRoute = () => {
  emits('trigger:share-route')
}

const triggerUpdateLocalAddress = async ({ coords, type }) => {
  // если координата не валидна, либо же
  // если включена синхронизация, емит не должен уходить
  if (!isValidCoordinate(coords) || props.isSyncMode[type]) return;
  await emitUpdateLocalAddressDebounced({
    coords: coords,
    type: type,
  })
}

const emitUpdateLocalAddressDebounced = debounce((event) => {
  emits('trigger:update-local-address', event)
}, 500)

const triggerRouteBuilding = () => {
  emits('trigger:openLink');
  if (!checkIfIsEnabled('route.route_build')) return;
  emits('trigger:buildRoute', true, false);
}

const applyFocus = (type) => {
  if (type === props.addressTypes?.LOAD) {
    isLoadFocused.value = true;
    return;
  }
  isUnloadFocused.value = true;
}

const applyBlur = (type) => {
  if (type === props.addressTypes?.LOAD) {
    setTimeout(() => isLoadFocused.value = false, 200)
    return;
  }
  setTimeout(() => isUnloadFocused.value = false, 200)
}

const toggleAddressFromDropdown = (val) => {
  isAddressFromDropdown.value = val

  if (val) {
    emits('updated:address', { value: null, type: props.addressTypes.LOAD })
    emits('updated:address-debounced', { value: null, type: `${props.addressTypes.LOAD}|coords` })
  }
  else {
    emits('updated:entity', { entity_id: null, entity_type: null, type: props.addressTypes.LOAD })
  }
}
const toggleAddressToDropdown = (val) => {
  isAddressToDropdown.value = val

  if (val) {
    emits('updated:address', { value: null, type: props.addressTypes.UNLOAD })
    emits('updated:address-debounced', { value: null, type: `${props.addressTypes.UNLOAD}|coords` })
  }
  else {
    emits('updated:entity', { entity_id: null, entity_type: null, type: props.addressTypes.UNLOAD })
  }
}

const isLoadFocused = ref(null);
const isUnloadFocused = ref(null);
const isTooltipActive = ref(true);
const linkRouteMap = ref('');

const parseRoute = debounce(() => {
  try {
    emits('updated:address', { value: [], type: props.addressTypes.TRANSIT, index: undefined })
    emits('updated:address', { value: [], type: props.addressTypes.VIA_POINT, index: undefined })
    emits('updated:address', { value: '', type: props.addressTypes.LOAD })
    emits('updated:address', { value: '', type: props.addressTypes.UNLOAD })
    
    if (!isValidUrl(linkRouteMap.value)) return;

    const url = new URL(linkRouteMap.value);
    const getParam = url.searchParams;
    const arrayCoordinates = getParam.get('rtext') ? getParam.get('rtext').split('~') : [];
    const viaPoints = getParam.get('via') ? getParam.get('via').split('~').map(item => Number(item)) : [];
    
    if (arrayCoordinates.length > 0) {
      if (arrayCoordinates.length == 1) {
        emits('updated:address', { value: arrayCoordinates[0], type: `${props.addressTypes.LOAD}|coords`, index: 0 })
      }

      if (arrayCoordinates.length == 2) {
        emits('updated:address', { value: arrayCoordinates[0], type: `${props.addressTypes.LOAD}|coords`, index: 0 })
        emits('updated:address', { value: arrayCoordinates[1], type: `${props.addressTypes.UNLOAD}|coords`, index: 1 })
      }
      
      if (arrayCoordinates.length > 2) {
        arrayCoordinates.forEach((element, index, array) => {
          switch (index) {
          case 0:
            emits('updated:address', { value: element, type: `${props.addressTypes.LOAD}|coords`, index: index })
            break;
          case array.length - 1:
            emits('updated:address', { value: element, type: `${props.addressTypes.UNLOAD}|coords`, index: index })
            break;
          default:
            if (viaPoints.includes(index)) {
              emits('updated:address', { value: {
                coordinates: element.split(','),
                pathIndex: 0
              }, type: props.addressTypes.VIA_POINT, index: index })
            } else {
              emits('updated:address', { value: element, type: props.addressTypes.TRANSIT, index: null })
            }
            break;
          }
        });
      }
      
      setTimeout(() => {
        triggerRouteBuilding();
      }, 1000)
    }
    linkRouteMap.value = '';

  } catch (error) {
    throw new Error(error);
  }
}, 500)

onMounted(async () => {
  await loadYandexMaps({ apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY });
  const loadSuggest = new ymaps.SuggestView('load-suggest');
  loadSuggest.events.add('select', (event) => {
    addressLoad.value = { value: event.get('item').value, isImmediate: true };
    document.activeElement?.blur();
  });

  const unLoadSuggest = new ymaps.SuggestView('unload-suggest');
  unLoadSuggest.events.add('select', (event) => {
    addressUnload.value = { value: event.get('item').value, isImmediate: true };
    document.activeElement?.blur();
  });
})

</script>

<style lang="scss" scoped>
@import 'src/assets/scss/vuetify_variables';

.new-specification {
  &__route {
    display: flex;
    padding-left: 14px;
    position: relative;

    @include lg {
      display: grid;
    }

    .specification__checkbox {
      margin-top: unset;
    }

    &-addresses {
      width: 100%;
      margin-bottom: 20px;

      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 60% 40%;

      &_single {
        grid-template-columns: 1fr;
      }
    }

    .specification__coordinates {
      display: grid;
      grid-template-columns: 60px auto;
      grid-template-rows: auto 1fr;
      grid-column: 2 / 3;
      grid-row: 1 / 3;

      &-wrap {
        width: 100%;
        position: relative;
        grid-row: 2 / 3;

        &.error::after {
          content: 'Неверный формат';
          position: absolute;
          white-space: nowrap;
          font-size: 12px;
          line-height: 15px;
          bottom: -20px;
          left: 12px;
          color: #d72028;
        }
      }

      &-sync {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: #f0f0f0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: center;
        margin: 22px auto 0;
        grid-row: 1 / 3;
        grid-column: 1 / 2;

        &.active {
          background: #0055e8;
        }
      }
    }

    .route-arrow {
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      left: 14px;

      &:before,
      &:after {
        display: block;
        position: absolute;
        content: '';
      }

      &--top {
        top: 39px;

        &:after {
          width: 5px;
          height: 5px;
          background-color: #0055e8;
          left: -7px;
          transform: rotate3d(0, 0, 1, 45deg);
        }

        &:before {
          width: 7px;
          background-color: transparent;
          border-left: 1px solid #0055e8;
          border-top: 1px solid #0055e8;
          bottom: -70px;
          top: 2px;
          left: -14px;
          border-top-left-radius: 5px;

          @include lg {
            bottom: -120px;
          }
        }
      }

      &--bottom {
        top: 36px;

        &:after {
          left: -8px;
          top: -1px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 3.5px 0 3.5px 7px;
          border-color: transparent transparent transparent #0055e8;
        }

        &:before {
          width: 7px;
          background-color: transparent;
          border-left: 1px solid #0055e8;
          border-bottom: 1px solid #0055e8;
          bottom: 3px;
          top: -70px;
          left: -14px;
          border-bottom-left-radius: 5px;

          @include lg {
            top: -120px;
          }
        }
      }
    }
  }

  &__dropdown-custom-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 16px;
  }

  &__dropdown-title {
    font-size: 16px;
  }

  &__dropdown-additional {
    font-size: 12px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .specification-input-address {
    min-width: 150px;
    position: relative;

    &.inactive {
      background-color: #f0f0f0;
    }

    &_wrap {
      min-width: 150px;
      width: 100%;
      position: relative;
      grid-row: 2 / 3;
    }

    &_value {
      background: white;
      top: -11px;
      left: 15px;
      width: 100%;
      position: absolute;
      min-width: max-content;
      margin-top: 4px;
      font-size: 12px;
    }

    &__arrow {
      position: absolute;
      left: -14px;
      top: 16px;
      height: 166px;
    }
  }

  .new-specification-input {
    min-width: 115px;

    .v-input {
      margin: 0;
      padding: 0;
    }

    :deep(.modal-input-name) {
      margin-bottom: 8px;
    }
  }

  .specification-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0a2333;
    margin-bottom: 16px;
  }

  .checkbox-name {
    font-size: 12px;
  }

  .new-specification__input input {
    display: flex;
    align-items: center;
    padding: 14px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #afafaf;
  }

  .specification__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a4a4a4;
    display: block;
    margin-bottom: 6px;
    grid-row: 1 / 2;
  }

  .specification-input {
    width: 100%;
    font-size: 12px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #efefef;
    border-radius: 6px;
    position: relative;

    &.error {
      border: 1px solid red;
    }
  }

  .specification-input-start {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #efefef;
    border-radius: 6px 0 0 6px;
  }

  .specification-input-finish {
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #efefef;
    border-radius: 0 6px 6px 0;
  }

  .specification-input {
    &::placeholder {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0a2333;
    }

    &.coordinates {
      flex-direction: column;
      align-items: stretch;
      position: relative;
      padding: 0;
      border: none;
      width: 100%;

      .input-wrap {
        width: 100%;
      }

      &[disabled] {
        background: #f0f0f0;
        color: #9e9fab;
      }
    }
  }

  .specification-subtitle-img {
    margin-left: 14px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  select {
    padding-right: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
  }

  .custom-checkbox + label::before {
    border: 2px solid #8ba2b1;
  }

  .copy_input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    opacity: 1;
    background: url("@/assets/img/calendar.svg") no-repeat center;
    background-size: contain;
  }

  .specification-input__name {
    width: 345px;
    font-size: 14px;
  }

  .specification-input__name::placeholder {
    font-size: 14px;
  }

  .fake-placeholder {
    display: none;
  }

  .specification-input-address::placeholder {
    font-size: 0;
  }

  .specification-input-address:placeholder-shown {
    padding-left: 28px;
  }

  .specification-input-address:placeholder-shown + .fake-placeholder {
    display: block;
    padding-left: 18px;
    background-image: url("@/assets/img/address.svg");
    background-repeat: no-repeat;
    background-position: 0 50%;
    position: absolute;
    font-size: 12px;
    top: 12px;
    left: 12px;
    z-index: 2;
    pointer-events: none;
  }

  .specification-input-address[disabled] {
    background: #f0f0f0;
  }

  .text-error {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #d72028 !important;

    &-wrap {
      border: 1px solid red;
    }
  }

  .build-route-btn {
    color: #0a2333;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 16px;
  }

  .route-field-offset {
    margin-left: 12px;

    &_hours {
      margin-left: 18px;
      @include lg {
        margin-left: 0;
      }

      .time {
        max-width: 115px;
      }
    }
  }

  .specification-address-toggle {
    margin-left: 0;
    margin-top: 4px;
  }
}

.specification__route-parse-btn {
  flex-basis: 140px;
  font-size: 14px;
  height: 40px;
}
</style>
