<template>
  <AuthorizationBackground>
    <AuthorizationComponent />
  </AuthorizationBackground>
</template>

<script>
import AuthorizationBackground from '@/components/Authorization/AuthorizationBackground';
import AuthorizationComponent from '@/components/Authorization/AuthorizationComponent';

export default {
  name: 'AuthorizationPage',
  components: {
    AuthorizationBackground,
    AuthorizationComponent
  }
}
</script>
