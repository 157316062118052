<template>
  <div
    class="dashboard-modal__item"
    @click="() => emits('edit-entity')"
    v-if="permissions.edit"
  >
    <SvgIcon name="edit-mini" class="dashboard-modal__icon"/>
    <div class="dashboard-modal__text">Редактировать</div>
  </div>

  <div
    class="dashboard-modal__item"
    @click="() => emits('copy-entity-info')"
    v-if="!isSpecificationDashboard"
  >
    <SvgIcon name="copy-mini" class="dashboard-modal__icon"/>
    <div class="dashboard-modal__text">Копировать</div>
  </div>

  <div
    class="dashboard-modal__item"
    @click="() => emits('duplicate-info')"
    v-if="isSpecificationDashboard && permissions.copy"
  >
    <SvgIcon name="copy-mini" class="dashboard-modal__icon"/>
    <div class="client-card__mini-modal__text">Дублировать</div>
  </div>

  <div
    v-if="isSpecificationDashboard"
  >
    <div
      class="dashboard-modal__item"
      @click="() => emits('change-responsible')"
      v-if="permissions?.fields?.user?.edit"
    >
      <SvgIcon name="how-to-reg" class="dashboard-modal__icon"/>
      <div class="dashboard-modal__text">
        Сменить ответственного
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="dashboard-modal__item"
      @click="() => emits('change-responsible')"
      v-if="permissions.person"
    >
      <SvgIcon name="how-to-reg" class="dashboard-modal__icon"/>
      <div class="dashboard-modal__text">
        Сменить ответственного
      </div>
    </div>
  </div>

  <div
    class="dashboard-modal__item"
    @click="() => emits('link-specification')"
    v-if="permissions.spec_create && !isSpecificationDashboard"
  >
    <SvgIcon name="edit-plus" class="dashboard-modal__icon"/>
    <div class="dashboard-modal__text">
      Добавить спецификацию
    </div>
  </div>

  <div
    class="dashboard-modal__item"
    @click="() => emits('delete-entity')"
    v-if="permissions.delete"
  >
    <SvgIcon name="delete-mini" class="dashboard-modal__icon"/>
    <div class="dashboard-modal__text">Удалить</div>
  </div>
</template>
<script setup>
import SvgIcon from '@/components/base/SvgIcon.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const props = defineProps({
  currentEntity: String,
  permissions: Object,
})

const isSpecificationDashboard = computed(() => {
  return props.currentEntity === 'specs'
})

const emits = defineEmits([
  'delete-entity',
  'edit-entity',
  'copy-entity-info',
  'change-responsible',
  'link-specification',
  'duplicate-info'
])

</script>

<style scoped lang="scss">
.dashboard-modal {
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 12px;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;

    &:hover {
      background-color: #f4f4f4;
    }
  }

  &__icon {
    display: flex;
  }

  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #0a2333;
    margin-left: 10px;
  }
}
</style>
