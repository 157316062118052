<template>
  <div class="details-port-page">
    <v-app>
      <v-card>
        <v-layout>
          <v-main class="sidebar-content">
            <BackgroundComponent class="client-card">
              <div v-if="port" class="card__header">
                <div class="card__arrow" @click="backToDashboard">
                  <SvgIcon name="arrow-back"/>
                </div>
                <h1 class="card__title">Порт: {{ port.name }}</h1>
                <div class="card__id">{{ port.id }}</div>
                <div
                  v-if="canEditPort"
                  class="client-card__svg pointer"
                  @click="showEdit"
                >
                  <SvgIcon name="edit"/>
                  <BaseTooltip text="Редактировать порт" />
                </div>
              </div>
              <ScrollbarComponent>
                <div v-if="port" style="max-width: 800px;" class="client-card__content">
                  <div class="about-company">
                    <div class="item-name">Статус порта</div>
                    <div class="item-content">
                      {{ port.status === "Действующий" ? "Действителен" : "Недействителен" }}
                    </div>

                    <div class="client-card__info-block">
                      <p class="client-card__info-row" v-if="port.name">
                        <b>{{ port?.company?.name ? `${port.company.name}, ` : '' }}</b>
                        {{ `ИНН: ${port.inn}` }}
                      </p>
                      <p class="client-card__info-row" v-if="port?.company?.registration_date"><span>Дата регистрации: {{
                        port?.company?.registration_date ? port?.company?.registration_date : ''
                      }},</span></p>
                      <p class="client-card__info-row" v-if="port?.company?.head">
                        <span>
                          Генеральный директор: {{ port?.company?.head ? port?.company?.head : '' }}
                        </span>
                      </p>
                      <p
                        class="client-card__info-row"
                        v-if="port.legal_address_symbol !== 'plug' && port.legal_address_symbol"
                      >
                        Юридический адрес:
                        <span>{{ port.legal_address_symbol }}</span>
                      </p>
                    </div>

                    <div v-if="port.fact_addresses.length > 0">
                      <div class="item-name">Фактический адрес</div>
                      <div class="item-content address-list">
                        <p v-for="(fact_addresses, index) in port.fact_addresses" :key="`${fact_addresses}_${index}`">
                          {{ fact_addresses.address }}</p>
                      </div>
                    </div>

                    <div class="item-name">Комментарий</div>
                    <div class="item-content">
                      {{ port.comment ? port.comment : "Не указано" }}
                    </div>

                    <div class="item-name">График работы</div>
                    <div v-if="port.work_schedule" class="item-content">
                      <div
                        v-for="(day, index) of port.work_schedule.split(',')"
                        :key="`${day}_${index}`"
                        class="mb-1"
                      >
                        {{ weekDays[index] }} :
                        {{
                          day == "24:00-24:00"
                            ? "круглосуточно"
                            : day == ""
                              ? "выходной"
                              : day
                        }}
                      </div>
                    </div>
                  </div>

                  <div class="contact-faces">
                    <div class="client-card__subheader">
                      <h1 class="client-card__subtitle">Контактные лица</h1>
                      <div
                        v-if="isAddContactAvailable && contactsList?.length < 5"
                        class="client-card__svg pointer"
                        @click="toggleModalContact(true)"
                      >
                        <SvgIcon name="contact-faces"/>
                      </div>
                    </div>

                    <ContactFacesCard
                      v-for="item of contactsList"
                      :item="item"
                      :key="item"
                      :entity="port"
                      @copy="copyContactInfo"
                      @delete="deleteContact"
                      @edit="editContact"
                      @add-favorites="addToFavorites"
                      global
                      favorite
                    />
                  </div>
                </div>
              </ScrollbarComponent>
            </BackgroundComponent>
          </v-main>
        </v-layout>
      </v-card>
    </v-app>
    <PortEditModal
      v-if="isEditModal"
      :is-open="isEditModal"
      :company="company"
      :inn="company.inn"
      :fact_addresses="fact_addresses"
      :comment="port.comment"
      :port-name="portName"
      :work-schedule="port.work_schedule"
      :port-status="port.status"
      @close-modal="closeModal"
      @update-address="updateAddress"
    />

    <ContactPersonModal
      :is-open="isContactModalOpen"
      @close="toggleModalContact(false)"
      :page-id="getPageId"
      contact-type="seaport"
    />
    <ContactPersonModal
      v-if="isContactEditModalOpen"
      :is-open="isContactEditModalOpen"
      @close="toggleEditModalContact(false)"
      :page-id="getPageId"
      contact-type="seaport"
      :is-modal-edit="true"
    />
  </div>
</template>

<script setup>
import BackgroundComponent from '@/components/BackgroundComponent.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import BaseTooltip from '@/components/base/BaseTooltip.vue'
import ScrollbarComponent from '@/components/ScrollbarComponent.vue'
import ContactFacesCard from '@/components/Contacts/ContactFacesCard.vue'
import PortEditModal from '@/components/SeaPorts/PortEditModal.vue'
import contactsService from '@/services/contactsService.js'
import { computed, ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import _get from 'lodash.get'
import ContactPersonModal from '@/components/ClientsModals/ContactPersonModal.vue';
import { errorPrettier } from '@/utils/api/handleError.js';

const store = useStore()
const route = useRoute()
const router = useRouter()

const weekDays = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
]

const port = computed(() => store.getters['ports/portDetail'])
const account = computed(() => store.getters['account/account'])
const contactsList = computed(() => store.getters['contacts/contactsList'])
const company = computed(() => store.getters['ports/portDetail']?.company)
const portName = computed(() => port.value?.name)

const isEditModal = ref(false)
const fact_addresses = ref([])
const isContactModalOpen = ref(false)
const isContactEditModalOpen = ref(false)

const toggleModalContact = (val) => {
  isContactModalOpen.value = val
}
const toggleEditModalContact = (val) => {
  isContactEditModalOpen.value = val
}

const updateAddress = (value) => {
  fact_addresses.value = value
}

const getPageId = computed(() => route?.params?.id)

const canEditPort = computed(() => {
  const currentRole = account.value?.role
  return currentRole === 'coordinator' || currentRole === 'logistician' || currentRole === 'administrator'
})

const deleteContact = async (id) => {
  const params = {
    sea_port_id: route.params.id
  }

  const contactCurrent = contactsList.value?.find((item) => {
    if (item.id == id) {
      return item
    }
  })

  await contactsService.deleteContact(contactCurrent.id)
  await store.dispatch('contacts/getContacts', params)
}

const editContact = async (id) => {
  const contactCurrent = contactsList.value?.find((item) => {
    if (item.id == id) {
      return item
    }
  })

  await store.dispatch('contacts/setSelectedContact', contactCurrent)

  isContactEditModalOpen.value = true
}

const addToFavorites = async (id) => {
  try {
    const params = {
      sea_port_id: getPageId.value
    }

    await store.dispatch('contacts/addToFavorites', id);
    await store.dispatch('contacts/getContacts', params);
  } catch (e) {
    errorPrettier(e)
  }
}

const isAddContactAvailable = () => {
  if (!this.port) return false;
  return _get(this.port, 'dashboard_permissions.contacts_create', false);
}

const backToDashboard = () => router.push({ name: 'PortsPage' })
const showEdit = () => {
  isEditModal.value = !isEditModal.value
}

const getDetailsPage = () => {
  store.dispatch('ports/getPortDetails', route.params.id)
}

const closeModal = async () => {
  isEditModal.value = false

  getDetailsPage()
}

const copyContactInfo = async (id, text) =>  {
  const contactCurrent = contactsList.value.find((item) => {
    if (item.id == id) {
      return item
    }
  })

  const number = contactCurrent?.contact_numbers[0]?.number
  const extensionNumber = contactCurrent?.contact_numbers[0]?.extension_number
  const numberType = contactCurrent?.contact_numbers[0]?.telephone_type

  text = `${numberType} телефон: +7${number}`

  if (extensionNumber) {
    text = text + `(${extensionNumber})`
  }

  const copyInput = document.querySelector('.js-copyInput')

  copyInput.value = text
  copyInput.focus()
  copyInput.select()
  document.execCommand('copy')
}

const getPortContacts = async () => {
  try {
    const params = {
      sea_port_id: route?.params?.id
    }
    await store.dispatch('contacts/getContacts', params)
  } catch (e) {
    errorPrettier(e)
  }
}

watch(port, async () => {
  fact_addresses.value = port.value.fact_addresses
})

onMounted(() => {
  getDetailsPage()
  getPortContacts()
})
</script>
