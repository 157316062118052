<template>
  <div class="position-relative" @click.stop>
    <div class="flex-block mb-3 item-route">
      <div class="border-location">
        <SvgIcon name="location"/>
      </div>
      <div>
        <div class="specification-card-label">Погрузка</div>
        <div class="specification-text-subtitle">
          {{ route?.load_address }}
        </div>
        <div class="dates-works-text">
          {{ workingHours('load') }}
        </div>
      </div>
    </div>
    <div
      v-for="(point, id) in transitPoints"
      :key="point + id"
      class="flex-block mb-3"
    >
      <div class="border-location">
        <SvgIcon name="trip_origin"/>
      </div>
      <div>
        <div class="specification-card-label">
          Промежуточная точка
        </div>
        <div class="specification-text-subtitle">
          {{ point.address }}
        </div>
      </div>
    </div>
    <div class="flex-block item-route">
      <div class="border-location">
        <SvgIcon name="location"/>
      </div>
      <div>
        <div class="specification-card-label">Выгрузка</div>
        <div class="specification-text-subtitle">
          {{ route?.unload_address }}
        </div>
        <div class="dates-works-text">
          {{ workingHours('unload') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SvgIcon from '@/components/base/SvgIcon.vue';

const props = defineProps({
  route: Object,
  transitPoints: Array,
})

const workingHours = (type) => {
  if (!props.route) return '';
  return props.route[`${type}_start`] === props.route[`${type}_end`] ?
    'Круглосуточно' :
    'c ' + props.route[`${type}_start`] + ' до ' + props.route[`${type}_end`];
}
</script>
