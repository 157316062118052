<template>
  <div
    ref="body"
    :style="{ '--size-height': heightSize }"
    :class="{ scrollbar__start: start }"
    class="scrollbar__body"
  >
    <!-- <perfect-scrollbar watch-options options=""> -->
    <div class="scrollbar__contend">
      <div class="wrap">
        <slot></slot>
      </div>
    </div>
    <!-- </perfect-scrollbar> -->
  </div>
</template>

<script>
export default {
  name: 'ScrollbarComponent',
  data() {
    return {
      heightSize: '100%',
      start: true,
    };
  },
  mounted() {
  },
  watch: {
    '$el.parentNode.clientHeight': function (oldVal) {
      console.log(oldVal);
    },
  },
};
</script>

<style scoped lang="scss">
.ps {
  height: var(--size-height) !important;
}
.wrap {
  overflow: auto; // удалить посфе фикса скролла компоненты
  height: 85vh;
}
.scrollbar {
  &__body {
    width: 100%;
    position: relative;
    margin: 0 -18px;
    padding-left: 18px;
  }
  &__start {
    overflow: auto;
  }
  &__contend {
    // padding-right: 18px;
  }
}
</style>
