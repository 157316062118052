<template>
  <div v-if="permissions.view" class="specification__checkbox">
    <input
      :type="type"
      :id="'checkbox' + uid"
      :name="name"
      class="custom-checkbox"
      @input="(event) => toggleCheckbox(event)"
      :checked="value || modelValue"
      :disabled="isDisabled"
    />
    <label :for="'checkbox' + uid"><span class="checkbox-name">{{ checkboxName }}</span></label>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
export default {
  name: 'BaseCheckbox',
  setup() {
    const uid = getCurrentInstance().uid

    return {
      uid: uid,
    }
  },
  emits: ['update:modelValue', 'update'],
  props: {
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    checkboxName: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Boolean, String],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({
        edit: true,
        view: true
      })
    }
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.permissions.edit;
    }
  },
  methods: {
    toggleCheckbox (event) {
      this.$emit('update', event.target.checked)
      this.$emit('update:modelValue', event.target.checked)
    }
  }
}
</script>

<style lang="scss">
.specification__checkbox {
  margin-left: 12px;
  margin-top: 20px;
  position: relative;
  display: flex;
  gap: 5px;
  .custom-checkbox + label::before {
    width: 15px;
    height: 15px;
  }
  .custom-checkbox + label::before {
    border: 2px solid #8ba2b1;
  }
  .custom-checkbox:checked + label::before {
    border-color: #0055e8;
    background-color: #0055e8;
    background-image: url("~@/assets/img/check-mark.svg");
    background-size: 83%;
  }
  .custom-checkbox_disabled:checked + label::before {
    border-color: #d8d8d8;
    background-color: #f0f0f0;
    background-size: 83%;
  }
  
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox-name {
  font-size: 12px;
}
</style>
