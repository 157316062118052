<template>
  <div class="sidebar">
    <div class="sidebar-inner">
      <div class="sidebar-tabs" v-if="!isShareMode">
        <button
          :class="['sidebar-tabs__tab', {'active': isFilterActive }]"
          @click="isFilterActive = true"
        >
          Фильтры
        </button>
        <button
          :class="['sidebar-tabs__tab', {'active': !isFilterActive }]"
          @click="isFilterActive = false"
        >
          Маршрут и расчет показателей
        </button>
      </div>
      <div class="sidebar__search">
        <BaseDropDownExtended
          v-if="isFilterActive && !isShareMode"
          :taggable="true"
          v-model="mainSpec"
          placeholder="Базовая спецификация"
          :is-loading="isSearchLoading"
          :is-internal-search="false"
          :options="searchresults"
          track-by="id"
          label="id"
          :is-multiple="false"
          is-default
          is-single
          @search-updated="(searchQuery) => getSearchHintData(searchQuery)"
        />
      </div>
      <FilterWidget
        v-if="isFilterActive && !isShareMode"
        class="interactive-map__filters"
        :filters-array="filterModel"
        filter-page="interactiveMaps"
        @filter-reset="() => emits('filter-reset')"
        @filter-submit="handleFilterSubmit"
      />
      <MetaRoute
        :isShareMode="isShareMode"
        v-else
      />
    </div>
  </div>
</template>
<script setup>
import FilterWidget from '@/components/filter-templates/FilterWidget.vue';
import MetaRoute from '@/components/InteractiveMap/MetaRoute.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import filterService from '@/services/filterService.js';
import BaseDropDownExtended from '../base/BaseDropDownExtended.vue';

const store = useStore();
const isFilterActive = ref(true);
const isSearchLoading = ref(false)
const searchresults = ref([])

// const isSpecificationSelected = computed(() => store.getters['interactiveMap/getIsSpecificationSelected'])

const mainSpec = computed({
  get() {
    return store.getters['interactiveMap/getBaseSpecification']
  },
  set(newValue) {
    store.dispatch('interactiveMap/setBaseSpecification', newValue)
  }
})

const filterModel = computed(() => {
  return store.getters['interactiveMap/getIntegratedFilter'] ?
    store.getters['interactiveMap/getIntegratedFilter'] :
    [];
});

const props = defineProps({
  isShareMode: Boolean,
})

const emits = defineEmits([
  'calculate-route',
  'filter-reset'
])

const handleFilterSubmit = (filter) => {
  store.dispatch('interactiveMap/filterEntities', filter)
}

const getSearchHintData = async (searchString) => {
  if (!searchString && searchString !== '') return;

  try {
    if (searchString?.trim() === '') {
      searchresults.value = [];
      return;
    }
    isSearchLoading.value = true
    const url = new URL('http://alfadon.dev.amiga.agency/api/v1/specs/search');
    const query = `${process.env.VUE_APP_API_URL}${url.pathname}/${searchString}${url.search}`;
    const res = await filterService.getFilterSearchHintData(query);
    searchresults.value = res.data.data;
  } catch(e) {
    searchresults.value = [];
    console.error('Ошибка загрузки хинтинга', e)
  } finally {
    isSearchLoading.value = false
  }
}
</script>
<style lang="scss" scoped>

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f8;
  min-width: 370px;
  max-width: 370px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;

  &-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-tabs {
    padding: 25px 18px 0;
    display: flex;
    width: 100%;

    &__tab {
      padding: 8px 12px;
      border-radius: 0 4px 4px 0;
      font-size: 14px;
      line-height: 16px;
      color: #808191;
      text-align: center;
      flex: 1;
      border: 1px solid #808191;

      &:first-child {
        flex: 0;
        border-radius: 4px 0 0 4px;
      }

      &.active {
        border: 1px solid #0055E8;
        color: #FFFFFF;
        background-color: #0055E8;
      }
    }
  }

  &__search {
    padding: 14px 18px;
  }

  :deep(.filters-templates) {
    border: none;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    max-height: calc(100vh - 155px);
    padding: 14px 18px 14px 18px;
  }
}

</style>
