<template>
  <div class="authorization-background">
<!--    <img :src="require('@/assets/svg/close-modal.svg')" class="close-modal">-->
    <img :src="require('@/assets/img/car.png')" alt="" class="car"/>
    <slot></slot>
  </div>
</template>

<script>

import SvgIcon from "@/components/base/SvgIcon";
export default {
  name: "AuthorizationBackground",
  components: {
    SvgIcon
  }
}
</script>

<style lang="scss">
.authorization-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #FFFFFF;
  width: 100%;
  height: 100vh;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
}

.car {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

</style>