<template>
  <div class="map-inputs__wrap">
    <div class="map-inputs__srcoll">
      <div :class="['specification-input-address_wrap', { unfocused: !isLoadFocused }]">
        <input
          type="text"
          required
          placeholder="Адрес погрузки"
          @focus="() => applyFocus('load')"
          @blur="() => applyBlur('load')"
          id="suggestMapLoadValue"
          :disabled="!checkIfIsEnabled('load_address')"
          class="specification-input specification-input-address"
          v-model="addressLoad"
          @input="(event) => updateLocalValue(event, 'load')"
        />
        <div class="fake-placeholder">Адрес погрузки</div>
      </div>

      <div
        v-for="(empty, index) of transitPoints"
        :key="`transit_${index}`"
      >
        <TransitItem
          :index="index"
          :modelValue="transitPoints[index].address"
          @delete:transitPoint="deleteTransitPoint(index)"
          :isDisabled="!checkIfIsEnabled('transit')"
          @update:modelValue="transitPointUpdate($event, index)"
          @update:transitFromSuggest="handleInputTransitPoints($event, index)"
        />
      </div>

      <div :class="['specification-input-address_wrap', { unfocused: !isUnloadFocused }]">
        <input
          type="text"
          required
          placeholder="Адрес выгрузки"
          @focus="() => applyFocus('unload')"
          @blur="() => applyBlur('unload')"
          :disabled="!checkIfIsEnabled('unload_address')"
          v-model="addressUnload"
          id="suggestMapUnloadValue"
          class="specification-input specification-input-address"
          @input="(event) => updateLocalValue(event, 'unload')"
        />
        <div class="fake-placeholder">Адрес выгрузки</div>
      </div>

      <div
        :class="['specification-input-address_wrap', { unfocused: !isUnloadFocused }]"
        v-if="distance && isFullScreenMap"
      >
        <input
          type="text"
          required
          placeholder="Дистанция"
          disabled
          :value="distance"
          class="specification-input specification-input-address"
        />
        <div class="fake-placeholder">Адрес выгрузки</div>
      </div>
    </div>

    <div v-if="checkIfIsEnabled('transit')">
      <button class="map-transit__btn" @click="addTransitPointDraft">
        Добавить
        <BaseTooltip text="При добавлении транзитных точек рекомендуем пользоваться данной кнопкой, а не перетаскиванием маршрута по карте" />
      </button>
      <button class="map-transit__btn" @click="resetTransitPoints">
        Сбросить
      </button>
    </div>
    <div>
      <button class="map-transit__btn" @click="triggerRouteBuilding">
        Построить маршрут
      </button>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import _get from 'lodash.get';
import { computed, onMounted, ref, watch } from 'vue';
import TransitItem from '@/components/Specifications/TransitItem.vue'
import { loadYandexMaps } from '@/services/yandexMapLoader';
import { geocode } from '@/utils/specification/geocoder';
import BaseTooltip from '@/components/base/BaseTooltip.vue';

const store = useStore();
const props = defineProps({
  isEditMode: Boolean,
  specification: Object,
  address: Object,
  addressTypes: Object,
  isSyncMode: Object,
  distance: [Number, String],
  isFullScreenMap: Boolean,
});

const emits = defineEmits([
  'trigger:buildRoute',
  'updated:transitPoints',
  'updated:address',
  'updated:address-debounced',
  'trigger:openLink',
])

// локал адреса используются для состояния,
// когда синхронизация отключена
const localAddressLoad = ref(null)
const localAddressUnload = ref(null)

const addressLoad = computed({
  get () {
    if (!props.address) return ''
    if (props.isSyncMode[props.addressTypes.LOAD]) {
      return props.address.find(item => item.type === props.addressTypes.LOAD)?.address
    }
    return localAddressLoad.value;
  },
  set(newValue) {
    if (typeof newValue === 'string') {
      emits('updated:address-debounced', {
        value: newValue,
        type: props.addressTypes.LOAD,
        isFromMap: true
      })
    } else { // при обновлении из списка опций
      emits('updated:address', {
        value: newValue.value,
        type: props.addressTypes.LOAD,
        isFromMap: true
      })
    }
  }
})

const addressUnload = computed({
  get() {
    if (!props.address) return ''
    if (props.isSyncMode[props.addressTypes.UNLOAD]) {
      return props.address.find(item => item.type === props.addressTypes.UNLOAD)?.address
    }
    return localAddressUnload.value;
  },
  set(newValue) {
    if (typeof newValue === 'string') {
      emits('updated:address-debounced', {
        value: newValue,
        type: props.addressTypes.UNLOAD,
        isFromMap: true
      })
    } else { // при обновлении из списка опций
      emits('updated:address', {
        value: newValue.value,
        type: props.addressTypes.UNLOAD,
        isFromMap: true
      })
    }
  }
})

const transitPoints = computed({
  get() {
    if (!props.address) return

    return props.address.filter(item => item.type === props.addressTypes.TRANSIT)
  },
  set({ value, index } = {}) {
    emits('updated:address', { value: value, type: props.addressTypes.TRANSIT, index: index })
  }
})

const transitPointUpdate = (event, index) => {
  transitPoints.value = { value: event.target.value, index: index }
}

const handleInputTransitPoints = (value, index) => {
  transitPoints.value = { value: value, index: index }
}

const addTransitPointDraft = () => {
  transitPoints.value = undefined;
}

const deleteTransitPoint = (transitPointIndex) => {
  transitPoints.value = { value: undefined, index: transitPointIndex };
}

const resetTransitPoints = () => {
  transitPoints.value = { value: [] };
}

const triggerRouteBuilding = () => {
  emits('trigger:openLink');
  if (!checkIfIsEnabled('route_build')) return;
  emits('trigger:buildRoute', true, false);
}

const generalPermissions = computed(() => {
  return store.getters['account/getPermissions']
})

const checkIfIsEnabled = (permission) => {
  const path = `${props.isEditMode ? 'dashboard_permissions' : 'specs'}.fields.route.${permission}.${props.isEditMode ? 'edit' : 'create'}`;
  if (!props.isEditMode) {
    return _get(generalPermissions.value, path, false);
  }
  return _get(props.specification, path, false);
}

const updateLocalValue = async (event, type) => {
  // если включена синхронизация, не используем
  if (props.isSyncMode[type]) return;
  if (type === props.addressTypes.LOAD) {
    localAddressLoad.value = event.target.value
    return;
  }
  localAddressUnload.value = event.target.value
}

const recalculateLocalAddress = async (coords, type) => {
  // если синхронизация включена, локальные данные не активны
  if (props.isSyncMode[type]) return;
  const address = await geocode(coords, '');
  if (type === props.addressTypes.LOAD) {
    localAddressLoad.value = address
    return;
  }
  localAddressUnload.value = address
}

// при выключении синхронизации подставляем текущий адрес в поле локального адреса
watch(() => props.isSyncMode?.load, (newValue) => {
  if (newValue) return;
  localAddressLoad.value = props.address.find(address => address.type === props.addressTypes.LOAD)?.address;
})

// при выключении синхронизации подставляем текущий адрес в поле локального адреса
watch(() => props.isSyncMode?.unload, (newValue) => {
  if (newValue) return;
  localAddressUnload.value = props.address.find(address => address.type === props.addressTypes.UNLOAD)?.address;
})

const applyFocus = (type) => {
  if (type === props.addressTypes.LOAD) {
    isLoadFocused.value = true;
    return;
  }
  isUnloadFocused.value = true;
}

const applyBlur = (type) => {
  if (type === props.addressTypes.LOAD) {
    setTimeout(() => isLoadFocused.value = false, 200)
    return;
  }
  setTimeout(() => isUnloadFocused.value = false, 200)
}

let isDataReady = false;
watch(() => props.address, async () => {
  if (isDataReady || !props.address) return;
  await recalculateLocalAddress(props.address[0]?.coords, props.addressTypes?.LOAD);
  await recalculateLocalAddress(props.address[props.address.length - 1]?.coords, props.addressTypes?.UNLOAD);
  isDataReady = true;
}, { immediate: true })

const isLoadFocused = ref(null);
const isUnloadFocused = ref(null);

onMounted(async () => {
  await loadYandexMaps({ apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY });
  const loadSuggest = new ymaps.SuggestView('suggestMapLoadValue');
  loadSuggest.events.add('select', (event) => {
    addressLoad.value = { value: event.get('item').value, isImmediate: true };
    if (!props.isSyncMode[props.addressTypes.LOAD]) {
      localAddressLoad.value = event.get('item').value;
    }
    document.activeElement?.blur();
  });

  const unLoadSuggest = new ymaps.SuggestView('suggestMapUnloadValue');
  unLoadSuggest.events.add('select', (event) => {
    addressUnload.value = { value: event.get('item').value, isImmediate: true };
    if (!props.isSyncMode[props.addressTypes.UNLOAD]) {
      localAddressUnload.value = event.get('item').value;
    }
    document.activeElement?.blur();
  });
})

defineExpose({ recalculateLocalAddress });

</script>

<style lang="scss" scoped>
// check why it is not actually working
.map-inputs__wrap:not(:focus-within) {
  ymaps, ymaps canvas, ymaps input, ymaps svg {
    width: 0!important;
    height: 0!important;
  }
}

.map-inputs__srcoll {
  padding-right: 10px;
  max-height: 250px;
  overflow: auto;
}

.fake-placeholder {
  display: none;
}

.specification-input-address::placeholder {
  font-size: 0;
}

.specification-input-address {
  border: 1px solid transparent;
}

.specification-input-address_wrap {
  position: relative;
}

.specification-input-address:placeholder-shown + .fake-placeholder {
  display: block;
  padding-left: 18px;
  background-image: url("@/assets/img/address-black.svg");
  background-repeat: no-repeat;
  background-position: 0 50%;
  position: absolute;
  font-size: 14px;
  top: 16px;
  left: 15px;
  z-index: 2;
  pointer-events: none;
}

.specification-input-address:placeholder-shown {
  padding-left: 30px;
}

</style>

