<template>
  <div
    :class="['specification-input-address_wrap', { unfocused: !isTransitFocused }]"
  >
    <input
      :value="modelValue"
      @input="$emit('update:modelValue', $event)"
      type="text"
      required
      @focus="applyFocus"
      @blur="applyBlur"
      :disabled="isDisabled"
      placeholder="Транзитная точка"
      autocomplete="off"
      :id="`transit_input_${index}`"
      class="specification-input specification-input-address new-specification-input"
    />
    <span class="remove-transit-point" @click="deleteTransit(index)" v-show="!isDisabled">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
        <path d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"></path>
        <path d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"></path>
      </svg>
    </span>
  </div>
</template>

<script>
import { loadYandexMaps } from '@/services/yandexMapLoader';

export default {
  name: 'TransitItem',
  props: {
    modelValue: {
      type: [Array, Object, String],
      default: () => {}
    },
    index: {
      type: [Number, String],
      default: 0
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'update:modelValue',
    'delete:transitPoint',
    'update:transitFromSuggest'
  ],
  data() {
    return {
      suggestItem: null,
      isTransitFocused: false,
    }
  },
  methods: {
    deleteTransit(index) {
      this.$emit('delete:transitPoint', index)
    },
    applyFocus() {
      this.isTransitFocused = true;
    },
    applyBlur() {
      setTimeout(() => this.isTransitFocused = false, 200)
    },
    async initSuggest() {
      await loadYandexMaps({ apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY });
      const suggestItem = new ymaps.SuggestView(`transit_input_${this.index}`, { results: 5 })
      suggestItem.events.add('select', (event) => {
        this.$emit('update:transitFromSuggest', event.get('item').value)
        document.activeElement?.blur();
      })
    },
  },
  async mounted() {
    await this.initSuggest();
  },
}
</script>

<style lang="scss">
.hide-suggest {
  ymaps, ymaps canvas, ymaps input, ymaps svg {
    width: 0!important;
    height: 0!important;
  }
}

.specification-input {
  border: 1px solid transparent;
}
</style>
