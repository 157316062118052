import ApiUtil from '@/utils/api'

export default {
  getCompany: (data) =>
    ApiUtil({
      url: '/api/v1/company',
      method: 'post',
      requiresAuth: true,
      data
    }),
}
