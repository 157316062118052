<template>
  <BackgroundComponent class="table-specifications">
    <div class="table-specifications__header">
      <h4 class="table-specifications__title">Спецификации</h4>
      <PaginationComponent
        :total-pages="specificationsPagesCount"
        @update-page="updatePage"
        :total-visible="3"
      />
      <SpecificationHistory style="transform: translateY(50%);" />

      <PerPageSelect
        :items="['20', '50', '100']"
        @update-select="changePerPage"
        is-short-data
        :current-per-page="specificationsPerPage"
      />
    </div>
    <BaseNoResult
      v-if="specificationsTableList.length === 0"
      :type="type"
    />

    <div v-else class="table-wrap">
      <div class="flex-block width-100">
        <div class="table width-100">
          <perfect-scrollbar>
            <BaseTable
              :table-head="specificationsHeader.schemas"
              :table-body="specificationsTableList"
              :table-header-params="tempSpecificationTableHeader"
              :is-loading="isSpecificationsLoading"
              @update-width="updateWidth"
              @sortBy="sortBy"
            >
              <template #item="{ value, name }">
                <div v-if="name === 'user'">
                  {{ `${value?.name ? value?.name : ''} ${value?.family_name ? value?.family_name : ''}` }}
                </div>
                <div v-else-if="name === 'logist'">
                  {{ `${value?.name ? value?.name : ''} ${value?.family_name ? value?.family_name : ''}` }}
                </div>
                <div v-else-if="name === 'lpr_status'">
                  <span :class="`lpr-status-${value.id}`">
                    {{ value?.title ? value.title : 'нет данных' }}
                  </span>
                </div>
                <router-link
                  :to="{ name: 'ClientCardTablePage', params: { id: value?.client_id } }"
                  class="table-list__user"
                  v-else-if="name === 'company'"
                >
                  {{ value?.name }}
                </router-link>
                <div
                  class="table-list__id"
                  v-else-if="name === 'id'"
                >
                  <router-link
                    :to="{ name: 'SpecificationCardPage', params: { id: value } }"
                    class="table-list__id-link"
                  >
                    {{ value }}
                  </router-link>
                </div>
                <div v-else-if="name === 'material_type'">
                  {{ value === true ? "Нерудная продукция" : "Сельскохозяйственная продукция" }}
                </div>
                <div v-else-if="name === 'admin' && value?.name">
                  {{ value.name }} {{ value?.family_name || '' }}
                </div>
                <div v-else-if="!value"></div>
                <div v-else>
                  {{ formattedValue(name, value) }}
                </div>
              </template>
            </BaseTable>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </BackgroundComponent>
</template>

<script>
import BackgroundComponent from '@/components/BackgroundComponent';
import BaseTable from '@/components/base/BaseTable';
import { mapGetters } from 'vuex';
import BaseNoResult from '@/components/base/BaseNoResult';
import PaginationComponent from '@/components/Dashboard/PaginationComponent.vue';
import PerPageSelect from '@/components/Dashboard/PerPageSelect.vue';
import SpecificationHistory from '@/components/Specifications/SpecificationHistory.vue';
import { tableFormatter } from '@/utils/helper';

export default {
  name: 'TableSpecifications',
  components: {
    PaginationComponent,
    BaseNoResult,
    BaseTable,
    PerPageSelect,
    BackgroundComponent,
    SpecificationHistory,
  },
  props: {
    type: {
      type: String,
      default: 'client'
    },
  },
  async created() {
    if (this.type !== 'client' && this.type !== 'quarry') {
      throw 'Неверно указан тип таблицы!!!'
    } else {
      await this.getSpecificationsHeaderList(this.$route.query);
      await this.getSpecificationsList(this.$route.query);
    }
  },
  computed: {
    ...mapGetters({
      specifications: 'specifications/specificationsList',
      specificationsPagesCount: 'specifications/specificationsPagesCount',
      specificationsPerPage: 'specifications/specificationsPerPage',
      specificationsTableList: 'specifications/specificationsTableList',
      specificationsHeader: 'specifications/specificationsHeaderList',
      tempSpecificationTableHeader: 'specifications/tempSpecificationTableHeader',
      isSpecificationsLoading: 'specifications/getIsSpecificationsLoading',
      clientData: 'clients/getSelectedClient',
      isHistory: 'specifications/getIsHistory',
    }),
  },
  data() {
    return {
      isSortLoading: false,
    }
  },
  methods: {
    async updatePage(query) {
      await this.getSpecificationsList(query);
    },
    async changePerPage(value) {
      let query = { ...this.$route.query, per_page: value };
      delete query.page
      await this.$router.push({ query });
      await this.getSpecificationsList(query);
    },
    async updateWidth(params) {
      await this.$store.dispatch('specifications/updateColumnWidth', params)
    },
    formattedValue(name, value) {
      return tableFormatter(name, value);
    },
    async getSpecificationsHeaderList(params) {
      try {
        await this.$store.dispatch(
          'specifications/getSpecificationsHeader',
          params
        );
      } catch (e) {
        this.$refs.alert.show(this.$t('alert.error'), e, {
          type: 'error',
          nameButton: 'Понятно',
        });
      }
    },

    async getSpecificationsList(params) {
      if (this.$route.params?.id) {
        if(this.type === 'client')
          params.client_id = this.$route.params?.id;
        if(this.type === 'quarry')
          params.quarry_id = this.$route.params?.id;
      }

      try {
        await this.$store.dispatch('specifications/getSpecifications', params);
      } catch (error) {
        const data = error.response;

        if (data?.message) {
          this.$refs.alert.show('Ошибка', data?.message, {
            type: 'error',
            nameButton: 'Понятно',
            color: '#FFA500',
          });
        }
      }
    },

    async sortBy(item) {
      let query = {};
      this.isSortLoading = true;
      if (item.sort === 'default') {
        query = { ...this.$route.query, sort: item.id };
        await this.$router.push({ query });
      }
      if (item.sort === 'ASC') {
        query = { ...this.$route.query, sort: `-${item.id}` };
        await this.$router.push({ query });
      }
      if (item.sort === 'DESC') {
        this.$router.replace({ name: 'ClientCardTablePage' });
      }

      await this.getSpecificationsHeaderList(query);
      await this.getSpecificationsList(query);
      this.isSortLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ps {
  //max-height: 100%;
  max-width: 100%;
}

.lpr-status-1 {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(51, 206, 13, 0.16);
}

.lpr-status-2 {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(255, 31, 0, 0.16);
}

.lpr-status-3 {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: rgba(255, 184, 0, 0.15);
}

.text-left {
  vertical-align: center;
  display: table-cell;
}

.v-table__wrapper {
  overflow-y: inherit;
  overflow-x: inherit;
}

.table-specifications .v-table > .v-table__wrapper > table > tbody > tr > td,
.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > td,
.v-table > .v-table__wrapper > table > thead > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > td,
.v-table > .v-table__wrapper > table > tfoot > tr > th {
  padding: 0 18px;
}

.table-specifications {
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: -5px;

  .v-table--density-default > .v-table__wrapper > table > tbody > tr > td,
  .v-table--density-default > .v-table__wrapper > table > thead > tr > td,
  .v-table--density-default > .v-table__wrapper > table > tfoot > tr > td {
    height: 54px !important;
  }

  .flex-container {
    display: flex;
  }

  &__title {
    font-size: 18px;
    line-height: 21px;
  }

  .table .v-table {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    //overflow-x: auto;
    white-space: nowrap;
    //-webkit-overflow-scrolling: touch;
  }

  .content-header {
    display: flex;
    margin-top: 35px;
    align-items: center;
    margin-bottom: 24px;
  }

  .clients-page {
    background: #f2f4f6;
  }

  .content {
    background: #f4f4f8;
    border-radius: 12px;
    margin-left: 18px;
    padding: 20px;
    /*height: 100vh; */
  }

  .tr-title {
    background: #f4f4f8;
    font-family: "Manrope";
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #0a2333;
  }

  td {
    font-family: "Manrope";
    font-size: 10px;
    line-height: 19px;
  }

  .client-name {
    font-weight: 600;
    color: #0055e8;
  }

  .td-total-revenue {
    font-weight: 600;
    color: #0f537e;
  }

  .td-phone {
    font-weight: 400;
    color: #afafaf;
  }

  .td-items {
    font-weight: 400;
    color: #5a5a5a;
  }

  .td-contact-person {
    font-weight: 400;
    color: #0a2333;
  }

  .td-client-id {
    font-weight: 500;
    color: #11142d;
  }

  .flex-block {
    display: flex;
  }

  .td-route {
    color: #0055e8;
    display: flex;
    align-items: center;
  }

  .td-route:before {
    content: url("@/assets/svg/route-table.svg");
    margin-right: 10px;
    margin-top: 5px;
  }

  .table-specifications__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 14px 18px;
    flex-shrink: 0;
  }

  .specifications__search-settings {
    display: flex;
    align-items: center;
    //margin-bottom: 66px;
  }

  .specifications__search {
    background: #f7f7f7;
    border-radius: 6px;
    padding: 0 12px;
    height: 34px;
    font-size: 10px;
    width: 182px;
  }

  .specifications__search::placeholder {
    font-size: 10px;
  }

  .specifications-img {
    margin-left: 12px;
    width: 16px;
    margin-top: 4px;
  }
}

.table-list {
  &__user {
    color: #0055e8;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
  }
}

.table-list__id-link {
  color: #11142d;
  text-decoration: underline;
  display: block;
  line-height: 40px;
}

.page-select {
  justify-content: flex-end;
  width: unset !important;
}

.pagination-wrapper {
  justify-content: center;
}

</style>
