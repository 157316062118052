export const prepareExtendedData = (entityType, data, addressId) => {
  if (!entityType || !data) return [];
  const distanceFormater = new Intl.NumberFormat('ru', {
    style: 'unit',
    unit: 'kilometer',
  });
  const moneyFormater = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0
  });
  let extendedInfo = []
  switch (entityType) {
  case 'quarry':
    extendedInfo = [
      {
        title: 'Адрес фактический',
        data: getAddress(data?.fact_addresses, addressId),
      },
      {
        title: 'Ответственный',
        data: getResponsible(data.responsible),
      },
      {
        title: 'Имя СПД',
        data: data?.company?.name || ''
      },

      {
        title: 'ИНН',
        data: data?.inn || ''
      },
      {
        title: 'Фракции',
        data: getDataRecursively(data, 'fractions'),
      },
      {
        title: 'Тип породы',
        data: getDataRecursively(data, 'name'),
      },
      {
        title: 'Марка прочности',
        data: getDataRecursively(data, 'strength_grades'),
      },
      {
        title: 'График работы',
        data: getWorkSchedule(data?.work_schedule),
      },
    ]
    break;
  case 'ports':
      extendedInfo = [
        {
          title: 'Адрес фактический',
          data: getAddress(data?.fact_addresses, addressId),
        },
        {
          title: 'Ответственный',
          data: getResponsible(data.responsible),
        },
        {
          title: 'Имя СПД',
          data: data?.company?.name || ''
        },
        {
          title: 'ИНН',
          data: data?.inn || ''
        },
        {
          title: 'График работы',
          data: getWorkSchedule(data?.work_schedule),
        },
      ]
    break;
  case 'client':
    extendedInfo = [
      {
        title: 'Название',
        data: data?.name || ''
      },
      {
        title: 'ИНН',
        data: getCompanies(data?.companies),
      },
      {
        title: 'Адрес приемки',
        data: getAddress(data?.fact_addresses, addressId),
      },
      {
        title: 'Ответственный',
        data: getResponsible(data.responsible),
      },
    ]
    break;
  case 'specs':
    extendedInfo = [
      {
        title: 'Наименование клиента',
        data: data?.company_unload?.client_name
      },
      {
        title: 'ИНН:',
        data: data?.company_unload?.inn
      },
      {
        title: 'Статус спецификации',
        data: data.specification_status
      },
      {
        title: 'Точка погрузки',
        data: data?.route?.load_address
      },
      {
        title: 'Точка выгрузки',
        data: data?.route?.unload_address
      },
      {
        title: 'Длина марштрута',
        data: distanceFormater.format(data.distance),
      },
      {
        title: 'Коэффициент',
        data: data.coefficient === 0 ? data.coefficient.toString() : data.coefficient
      },
      {
        title: 'Дневной вал',
        data: moneyFormater.format(data.capital_service_daily),
      },
      {
        title: 'Количество груза',
        data: data.total_weight,
      },
      {
        title: 'Ответственный',
        data: getResponsible(data?.user),
      },
    ]
    break;
  }
  return extendedInfo.filter(detail => detail.data)
}

const getResponsible = (responsible) => {
  if (!responsible) return null;
  return `${responsible?.name || ''} ${responsible?.family_name || ''}`.trim();
}

const getCompanies = (companies) => {
  if (!companies || !companies.length) return '';
  return companies[0]?.inn || '';
}

const getAddress = (addressesArray, addressId = null) => {
  if (!addressesArray || addressesArray.length < 1) return ''
  if (!addressId) return addressesArray[0]?.address || '';

  const address = addressesArray.find(address => +address.id === +addressId);
  return address?.address;
}

export const getDataRecursively = (quarry, type) => {
  if (!quarry?.name_goods && !quarry.name_goods.length) return null;
  const list = quarry.name_goods.reduce((accum, item) => {
    if (!item[type]) return accum;

    // проверяем на примитивный тип, если примитивный, нет смысла идти ниже
    if (typeof item[type] === 'string' || typeof item[type] === 'number') {
      accum.push(item[type]);
      return accum;
    }

    const dataList = item[type].reduce((acc, entity) => {
      if (!entity.name) return acc;
      acc.push(entity.name);
      return acc;
    }, [])
    accum.push(dataList);
    return accum;
  }, [])

  return [...new Set(list.flat())].join(', ')
}

const getWorkSchedule = (schedule) => {
  const prepData = schedule.split(',');

  const weekDays = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ];

  const result = prepData.map((day, index) => {
    return {
      day: weekDays[index],
      schedule: day === '24:00-24:00' || day === '00:00-00:00'  ? 'круглосуточно' : day === '' ? 'выходной' : day
    }
  })

  return result.reduce((accumulator, day) => {
    return accumulator += `${day.day}: ${day.schedule} <br/>`
  }, '')
}

export const setPlacemark = (mapConstructor, coordinates, properties, options) => {
  return new mapConstructor.Placemark(coordinates, {
    ...properties,
  }, {
    iconLayout: 'default#imageWithContent',
    iconImageSize: [50, 20],
    iconColor: '#B34BE0',
    iconImageOffset: [-25, -16],
    ...options,
  })
}

export const setWayPointPlacemark = (mapCoonstructor, coordinates, content, imageHref) => {
  return new mapCoonstructor.Placemark(coordinates, {
    iconContent: content,
  }, {
    iconLayout: 'default#imageWithContent',
    iconImageHref: imageHref,
    iconImageSize: [20, 26],
    iconImageOffset: [-10, -25],
    iconContentOffset: [5, 2],
    iconContentLayout: mapCoonstructor.templateLayoutFactory.createClass(
        '<div style="color: #FFFFFF">$[properties.iconContent]</div>')
  })
}

export const createPolylineArrow = (mapConstructor, lineCoordinates, properties, options) => {
  if (!mapConstructor) {
    console.error('Map constructor was not found');
    return;
  }
  return new mapConstructor.Polyline(lineCoordinates, {
    balloonCloseButton: false,
    ...properties
  }, {
    strokeWidth: 3,
    ...options
  });
}

export const getRussianFilterName = (englishName) => {
  switch (englishName) {
  case 'specs':
    return 'Спецификации'
  case 'quarries':
    return 'Карьеры'
  case 'clients':
    return 'Клиенты'
  case 'seaports':
    return 'Порты'
  default:
    return englishName;
  }
};

export const getAdditionalSpecifications = (data, specs) => {
  const specIds = specs.map(spec => spec.id);
  const rawResult = data.reduce((acc, entity) => {
    if (!entity) return acc
    const ids = entity.reduce((accum, item) => {
      if (!item.specs) return accum;
      const itemIds = item.specs.map(el => el.id);
      if (!itemIds.length) return accum;
      accum.push(...itemIds);
      return accum;
    }, [])
    if (!ids.length) return acc;
    acc.push(...ids)
    return acc;
  }, [])

  const additionalSpecsClean = Array.from(new Set(rawResult));
  return additionalSpecsClean.filter(spec => !specIds.includes(spec));
}

export const getRouteDistance = (multiRoute) => {
  if (!multiRoute) {
    throw new Error('Ошибка рассчета расстояния предложенного маршрута')
  }
  const activeRoute = multiRoute.getActiveRoute();
  const activeRouteDistance = activeRoute.properties.get('distance').value;
  return { distance: Math.round(activeRouteDistance / 1000) };
};

export const getCoordsList = (multiRoute) => {
  let coordList = [];
  const activeRoute = multiRoute.getActiveRoute();
  const segmentList = activeRoute.getPaths().get(0).getSegments();
  const activeRouteDistance = activeRoute.properties.get('distance').value;

  // получаем координаты маршрута из сегментов, оптимизируем собирая только каждую 4 точку
  for (let j = 0; j < segmentList.getLength(); j++) {
    const segmentCoord = segmentList.get(j).geometry.getCoordinates();
    coordList.push(...segmentCoord);
  }

  return { coordinates: coordList, distance: Math.round(activeRouteDistance / 1000) };
};

export const createObjectIconTamplate = (mapConstructor) => {
  if (!mapConstructor) {
    console.error('Map constructor was not found');
    return;
  }
  return mapConstructor.templateLayoutFactory.createClass(
    `
    <div style="
       background: #FFFFFF;
       width: 24px;
       height: 24px;
       border-radius: 4px;
       border: 2px solid #0055E8;
       padding: 2px;
       box-sizing: border-box;
      ">
      <img style="display: block; width: 100%; height: auto; opacity: $[properties.opacity]" src="$[properties.href]" alt="icon">
    </div>
    `
  )
}

export const createSpecificationTamplate = (mapConstructor) => {
  if (!mapConstructor) {
    console.error('Map constructor was not found');
    return;
  }
  return mapConstructor.templateLayoutFactory.createClass(
    `
    <div style="
       background: #FFFFFF;
       border-radius: 20px;
       width: 50px;
       cursor: pointer;
       box-sizing: border-box;
       box-shadow: 12px 9px 17px rgba(214, 214, 214, 0.33);
      ">
      <p style="
      	display: block;
      	text-align: center;
      	padding: 10px;
      	color: #B34BE0;
      	font-size: 10px;
      	line-height: 12px;
      	font-weight: 700;
      	pointer-events: all;">
        ID $[properties.id]
      </p>
    </div>
    `
  )
}

export const createClusterBaloonTamplate = (mapConstructor) => {
  if (!mapConstructor) {
    console.error('Map constructor was not found');
    return;
  }
  return mapConstructor.templateLayoutFactory.createClass(
    `
    <ul>
      {% for geoObject in properties.geoObjects %}
      <li 
      	class="baloon-entity"
       	style="cursor: pointer; color: #0055E8;
       	 {% if geoObject.properties.isEntityNotActive %}
       	 opacity: .7; text-decoration: line-through;
       	 {% endif %}"
       	data-id="$[geoObject.properties.id]"
       	data-type="$[geoObject.properties.type]"
       	data-address-id="$[geoObject.properties.addressId]"
       	>
        {{ geoObject.properties.contentBody }}
      </li>
      {% endfor %}
    </ul>
    `
  )
}

export const isFilterEmpty = (object) => {
  if (!object) return false;
  return Object.values(object).reduce((acc, item) => {
    if (!item) return acc
    acc.push(item)
    return acc
  },[]).length === 0
}

export const multicolors = ['#E82A00', '#43D100', '#0055E8', '#944755'];

