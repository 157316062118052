import userService from "@/services/userService";
import { handleError } from "@/utils/api/handleError";

export default {
  namespaced: true,
  state: {
    users: [],
    user: {},
  },
  actions: {
    async getUsers({ commit }, params) {
      try {
        const data = await userService.getUsers(params);
        commit("SET_USERS", data);
      } catch (e) {
        handleError(e);
      }
    },
    async getUser({ commit }, params) {
      try {
        const data = await userService.getUser(params);
        commit("SET_USER", data);
      } catch (e) {
        handleError(e);
      }
    },
  },
  getters: {
    users(state) {
      return state.users;
    },
    user(state) {
      return state.user;
    },
    adminList(state) {
      return state.users.filter(item => item.position === 'administrator')
    },
    logistianList(state) {
      return state.users.filter(item => item.position === 'logistian')
    },
    responseList(state) {
      return state.users;
    }
  },
  mutations: {
    SET_USERS(state, value) {
      state.users = value.data.data.map(item => {
        item.name = item.name + ' ' + item.family_name
        item.text = item.name + ' ' + item.family_name
        return item
      });
    },
    SET_USER(state, value) {
      state.user = value.data;
    },
  },
};
