function getTable(el) {
  const nodeName = el.nodeName;
  if (['TABLE', 'THEAD'].indexOf(nodeName) < 0) return;
  const table = nodeName === 'TABLE' ? el : el.parentElement;
  if (!table) return;
  const thead = table?.querySelector('thead');
  const thSelectors = thead?.querySelectorAll('th');
  return [[...(thSelectors || '')], nodeName, table, thead];
}

export default {
  mounted: function (el, binding) {
    const [ths, nodeName, table, thead] = getTable(el);
    const barHeight = nodeName === 'TABLE' ? table?.offsetHeight : thead?.offsetHeight;
    const resizeContainer = document.createElement('div');
    const tableStart = binding.value.params;
    const theadRow = thead?.querySelector('tr')

    const cutPx = (str) => +str.replace('px', '');

    table.style.position = 'relative';
    table.style.tableLayout = 'fixed';
    table.classList.add('table-resize')

    resizeContainer.style.position = 'absolute';
    resizeContainer.style.top = '0';
    resizeContainer.style.left = '0';
    resizeContainer.style.width = table?.offsetWidth + 'px';
    resizeContainer.className = 'vue-columns-resizable';
    theadRow?.parentElement?.insertBefore(resizeContainer, theadRow.nextSibling)

    let moving = false;
    let movingIndex = 0;
    let offsetLeft = 0;

    ths.forEach((th, index) => {
      if (tableStart[index]?.width) {
        th.style.width = tableStart[index].width + 'px';
        th.style.minWidth = tableStart[index].width + 'px';
      } else {
        if (index + 1 >= ths.length) {
          th.style.width = 'auto';
          th.style.minWidth = '200px';
        } else {
          th.style.width = 200 + 'px';
          th.style.minWidth = 200 + 'px';
        }
      }


      if (index + 1 >= ths.length)
        offsetLeft = table.offsetWidth;
      else {
        const nextTh = ths[index + 1];
        offsetLeft = nextTh.offsetLeft;
      }
      const bar = document.createElement('div');
      bar.style.position = 'absolute';
      bar.style.left = -5 + offsetLeft + 'px';
      bar.style.top = '0';
      bar.style.height = barHeight + 'px';
      bar.style.width = '10px';
      bar.style.cursor = 'col-resize';
      bar.style.zIndex = '1';
      bar.className = 'columns-resize-bar';

      bar.addEventListener('mousedown', () => {
        moving = true;
        movingIndex = index;
        document.body.style.cursor = 'col-resize';
        document.body.style.userSelect = 'none';
      });

      resizeContainer.appendChild(bar);
    });

    const bars = resizeContainer.querySelectorAll('.columns-resize-bar');
    const addNewSize = (index, width) => {
      binding.value.cb({ index, width });
    };

    const handleResize = (e) => {
      if (moving) {
        const thSelectors = thead?.querySelectorAll('th');
        const th = thSelectors[movingIndex];
        const bar = bars[movingIndex];
        let direction = e.movementX > 0 ? 'right' : 'left'
        let isId = th.classList.contains('cell_id');

        const customWidth = cutPx(th.style.width) + e.movementX;

        // TODO: можно указать свое число для ограничения каждого поля по минимальной ширине
        // если колонка с ID то минимальная ширина будет первой, для остальных второе значение
        if (direction !== 'left' || customWidth > (isId ? 60 : 100)) {
          th.style.width = customWidth + 'px';
          th.style.minWidth = customWidth + 'px';

          bar.style.left = offsetLeft - 5 + e.movementX + 'px';

          bars.forEach((bar) => {
            bar.style.left = -5 + offsetLeft + 'px'
          })
          addNewSize(movingIndex, customWidth)
        }

      }
    };

    document.addEventListener('mouseup', () => {
      if (!moving) return;
      moving = false;
      document.body.style.cursor = '';
      document.body.style.userSelect = '';
    });

    resizeContainer.addEventListener('mousemove', handleResize);
    table.addEventListener('mousemove', handleResize);
  },
  updated(el, binding) {
    const [ths, nodeName, table, thead] = getTable(el);
    const barHeight = nodeName === 'TABLE' ? table?.offsetHeight : thead?.offsetHeight;

    const tableStart = binding.value.params;

    const columnsResizableList = table?.parentElement?.querySelectorAll('.vue-columns-resizable');
    let offsetLeft = 0;

    if (columnsResizableList.length <= 0) return;
    const columnsResizable = columnsResizableList[0];

    columnsResizable.style.width = 'auto';

    const columnsResizeBarList = columnsResizable.querySelectorAll('.columns-resize-bar');

    ths.forEach((th, index) => {
      if (tableStart[index]?.width) {
        th.style.width = tableStart[index].width + 'px';
        th.style.minWidth = tableStart[index].width + 'px';
      } else {
        if (index + 1 >= ths.length) {
          th.style.width = 'auto';
          th.style.minWidth = '200px';
        } else {
          th.style.width = 200 + 'px';
          th.style.minWidth = 200 + 'px';
        }
      }


      if (index + 1 >= ths.length)
        offsetLeft = table.offsetWidth;
      else {
        const nextTh = ths[index + 1];
        offsetLeft = nextTh.offsetLeft;
      }
      if (columnsResizeBarList.length > index) {
        columnsResizeBarList[index].style.left = offsetLeft - 5 + 'px';
        columnsResizeBarList[index].style.height = barHeight + 'px';
      }
    });
  },
  name: 'vResizes'
}
