<template>
  <div class="create-port-page">
    <v-app>
      <v-card>
        <v-layout>
          <v-main class="sidebar-content sidebar-content--double">
            <div class="card-block">
              <div class="create-port-page__header">
                <router-link to="/ports-page" class="card__arrow">
                  <SvgIcon name="arrow-back"/>
                </router-link>
                <h1 class="card__title">Создание порта</h1>
              </div>
              <div class="card-block__left g-card-block">
                <PortCardForm
                  :fact_addresses="fact_addresses"
                  :work_schedule="days"
                  @update-address="updateAddress"
                  @update-days="workDays"
                />
              </div>
            </div>
          </v-main>
        </v-layout>
      </v-card>
    </v-app>
  </div>
</template>

<script setup>
import PortCardForm from '@/components/SeaPorts/PortCardForm.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import { ref } from 'vue';

const fact_addresses = ref([])
const days = ref('')

const updateAddress = (value) => {
  fact_addresses.value = value
}

const workDays = (value) => {
  days.value = value
}

</script>

<style lang="scss" scoped>
.create-port-page {
  &__header {
    display: flex;
    align-items: center;

    h1 {
      margin-left: 10px;
      transform: translateY(-5px);
    }
  }
}
</style>
