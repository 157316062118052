import specificationService from '@/services/specificationService.js';
import { errorPrettier, handleError } from '@/utils/api/handleError.js';
import { errorFormat, formatDate, filterOrders } from '@/utils/helper.js';
import { getLprColor } from '@/utils/specification/specification.js'

export default {
  namespaced: true,
  state: {
    specificationsList: [],
    specificationsSchema: {},
    specificationsSchemaFull: {},
    specificationsTableList: [],
    specificationsHeaderList: [],
    specificationsHeaderListFull: [],
    selectedSpecification: null,
    specificationsCalculate: {},
    specificationDetail: {},
    specificationDetailGeneral: [],
    specificationsSearchResult: true,
    isSpecificationsLoading: false,
    isSearch: false,
    specificationsPagesCount: null,
    specificationsPerPage: null,
    isSearchMode: false,
    tempSpecificationTableHeader: [],
    isHistory: JSON.parse(localStorage.getItem('isHistorySpecification')) || false,
    portsList: [],
  },
  actions: {
    async getSpecifications({ commit, state }, params) {
      try {
        commit('SET_IS_SPECIFICATIONS_LOADING', true);

        if (!state.isHistory) {
          params.is_hide_history = 'y'
        }

        const data = await specificationService.getSpecifications(params);
        commit('SET_SPECIFICATIONS_LIST', data);
        commit('SET_SPECIFICATION_TABLE_LIST', data);
        commit('SET_SPECIFICATIONS_SEARCH_RESULT_SHOW', true);
        commit('SET_SPECIFICATIONS_PAGES_COUNT', data);
        commit('SET_SPECIFICATIONS_PER_PAGE', data);
        commit('SET_IS_SEARCH_MODE', false);
      } catch (e) {
        handleError(e);
      } finally {
        commit('SET_IS_SPECIFICATIONS_LOADING', false);
      }
    },
    async setLprStatus({ commit }, params) {
      try {
        const response = await specificationService.editLprStatus(params);
        if (response.status === 200) {
          const { lprStatuses, lprStatusId, specificationId } = params;
          const currentLprStatus = lprStatuses.find(status => status.id === lprStatusId);
          commit('SET_LPR_STATUS', { currentLprStatus, specificationId })
        }
      } catch (e) {
        throw errorFormat(e.response.data.errors);
      }
    },
    async createSpecification({ commit }, params) {
      try {
        return await specificationService.createSpecification(params);
      } catch (e) {
        if (e?.response?.status === 500) throw 'Серверная ошибка';
        throw errorFormat(e.response.data.errors);
      }
    },
    async editSpecification({ commit }, params) {
      try {
        return await specificationService.editSpecification(params);
      } catch (e) {
        if (e?.response?.status === 500) throw 'Серверная ошибка';
        throw errorFormat(e.response.data.errors);
      }
    },
    async getSpecificationsHeader({ commit }, params) {
      try {
        const data = await specificationService.getSpecificationsHeader();
        commit('SET_SPECIFICATIONS_HEADER_LIST', {
          data: data,
          params: params
        });
      } catch (e) {
        handleError(e);
      }
    },
    async getSpecificationsCalculate({ commit }, params) {
      try {
        const data = await specificationService.getSpecificationsCalculate(params);
        commit('SET_SPECIFICATIONS_CALCULATE', data);
      } catch (e) {
        if (e?.response?.data?.errors) {
          const error = Object.values(e.response.data.errors).flat()[0]
          throw new Error(error);
        }
        throw new Error(e);
      }
    },
    async setSelectedSpecification({ commit }, data) {
      commit('SET_SELECTED_SPECIFICATION', data);
    },
    async getSpecificationDetail({ commit }, id) {
      try {
        const data = await specificationService.getSpecificationDetail(id);
        commit('SET_SPECIFICATION_DETAIL', { data: data, type: 'api' });
        // commit("SET_SPECIFICATION_DETAIL_GENERAL", data);
      } catch (e) {
        handleError(e);
      }
    },
    setSpecificationDetail({ commit }, data) {
      commit('SET_SPECIFICATION_DETAIL', { data: data, type: 'local' });
    },
    clearSpecificationDetail({ commit }) {
      commit('CLEAR_SPECIFICATION_DETAIL');
    },
    async getSpecificationsSearch({ commit }, value) {
      try {
        const data = await specificationService.getSpecificationsSearch(value.search, {
          page: value.page,
          per_page: value.perPage
        });
        commit('SET_SPECIFICATIONS_SEARCH_RESULT', data);
        commit('SET_SPECIFICATIONS_LIST', data);
        commit('SET_SPECIFICATION_TABLE_LIST', data);
        commit('SET_IS_SEARCH_MODE', true);
        commit('SET_SPECIFICATIONS_PAGES_COUNT', data)
        commit('SET_SPECIFICATIONS_PER_PAGE', data);
      } catch (e) {
        handleError(e);
      }
    },
    async updateColumnWidth({ commit }, params) {
      try {
        await specificationService.specificationAddColumns(params)
        commit('SET_TEMP_SPECIFICATION_TABLE_HEADER', params.columns)
      } catch (e) {
        handleError(e)
      }
    },
    async createOrder1c({ commit }, id) {
      try {
        return await specificationService.createOrder1c(id)
      } catch (e) {
        throw errorFormat(e.response?.data);
      }
    },
    async deleteSpecification({ commit }, id) {
      try {
        await specificationService.deleteSpecification(id)
      } catch (e) {
        handleError(e)
      }
    },
    async changeResponse({ commit }, payload) {
      try {
        await specificationService.createManagerSpecification(payload)
      } catch (e) {
        handleError(e)
      }
    },
    async getPortsList({ commit }, query) {
      try {
        const data = await specificationService.getPortsList(query)
        commit('SET_PORTS_LIST', data.data.data)
      } catch (e) {
        handleError(e)
      }
    },
    async specFieldUpdate({ commit }, data) {
      const { id, field, value } = data
      try {
        await specificationService.specFieldUpdate(id, {
          field: field,
          value: value
        })
      } catch (e) {
        errorPrettier(e)
      }
    }
  },
  getters: {
    specificationsList(state) {
      return state.specificationsList;
    },
    specificationsCalculate(state) {
      return state.specificationsCalculate;
    },
    specificationsTableList(state) {
      return state.specificationsTableList;
    },
    specificationsHeaderList(state) {
      return state.specificationsHeaderList;
    },
    specificationsHeaderListFull(state) {
      return state.specificationsHeaderListFull;
    },
    specificationsSearchResult(state) {
      return state.specificationsSearchResult;
    },
    getSelectedSpecification(state) {
      return state.selectedSpecification;
    },
    specificationDetail(state) {
      return state.specificationDetail;
    },
    specificationDetailGeneral(state) {
      return state.specificationDetailGeneral;
    },
    specificationsPagesCount(state) {
      return state.specificationsPagesCount;
    },
    specificationsPerPage(state) {
      return state.specificationsPerPage;
    },
    tempSpecificationTableHeader(state) {
      return state.tempSpecificationTableHeader;
    },
    getIsSpecificationsLoading(state) {
      return state.isSpecificationsLoading;
    },
    getIsSearchMode(state) {
      return state.isSearchMode
    },
    getIsHistory (state) {
      return state.isHistory
    },
    portsList(state) {
      return state.portsList
    }
  },
  mutations: {
    SET_SPECIFICATIONS_LIST(state, value) {
      state.specificationsList = value.data.data;
    },
    SET_IS_SEARCH_MODE(state, value) {
      state.isSearchMode = value;
    },
    SET_LPR_STATUS(state, value) {
      const { currentLprStatus, specificationId } = value;
      const updatedSpecification = state.specificationsTableList.find(specification => specification.id === specificationId);
      updatedSpecification.meta.color = getLprColor(currentLprStatus);
      let cell = updatedSpecification.cells.find(cell => cell.name === 'lpr_status');
      cell.value = { title: currentLprStatus.name, id: currentLprStatus.id };
    },
    SET_SPECIFICATIONS_SEARCH_RESULT(state, value) {
      const data = value.data.data

      if (data && data[0]) {
        state.specificationsSearchResult = true
      } else {
        state.specificationsSearchResult = false
      }
    },
    SET_SPECIFICATIONS_SEARCH_RESULT_SHOW(state, value) {
      state.specificationsSearchResult = value
    },
    SET_SPECIFICATIONS_PAGES_COUNT(state, value) {
      if (value.data?.meta?.last_page) {
        state.specificationsPagesCount = value.data.meta.last_page
      }
    },
    SET_SPECIFICATIONS_PER_PAGE(state, value) {
      if (value.data?.meta?.per_page) {
        state.specificationsPerPage = value.data.meta.per_page
      }
    },
    SET_SPECIFICATION_TABLE_LIST(state, value) {
      const dataLocal = value.data.data;

      const dataAPI = dataLocal.map(item => {
        let data = {
          id: item.id,
          meta: {},
          cells: [],
          spec_permissions: item?.dashboard_permissions?.fields,
        }

        data.meta.color = getLprColor(item.lpr_status);

        for (let i = 0; i < Object.keys(state.specificationsSchema).length; i++) {
          const keySchema = Object.keys(state.specificationsSchema)[i];

          if(keySchema === 'company') {
            data.cells.push({ name: keySchema, value: item['company_unload'] })
          } else if(keySchema === 'inn') {
            data.cells.push({ name: keySchema, value: item['company_unload']['inn'] })
          } else if(keySchema === 'load' || keySchema === 'load_address') {
            data.cells.push({ name: keySchema, value: item['route']['load_address'] })
          } else if(keySchema === 'unload' || keySchema === 'unload_address') {
            data.cells.push({ name: keySchema, value: item['route']['unload_address'] })
          } else if(keySchema === 'created_at') {
            data.cells.push({ name: keySchema, value: formatDate(item['created_at']).date })
          } else if(keySchema === 'capital_material_total') {
            data.cells.push({ name: keySchema, value: item['capital_material_total'].toLocaleString() })
          } else if(keySchema === 'capital_service_total') {
            data.cells.push({ name: keySchema, value: item['capital_service_total'].toLocaleString() })
          } else {
            data.cells.push({ name: keySchema, value: item[keySchema] })
          }
        }
        return data
      })

      state.specificationsTableList = dataAPI
    },
    SET_SPECIFICATIONS_HEADER_LIST(state, value) {
      let { schemas } = value.data.data.data
      let temp = []

      if (schemas._order?.length) {
        schemas._order = schemas._order.map(item => {
          if (state.tempSpecificationTableHeader) {
            let index = state.tempSpecificationTableHeader.findIndex(el => el.includes(item))

            if (index === -1) {
              temp.push(item)
            } else {
              temp.push(state.tempSpecificationTableHeader[index])
            }
          } else {
            temp.push(item)
          }

          return item.split('?')[0]
        })
      }

      const schemasFull = schemas

      if (schemas._order?.length > 0) {
        const schemasOrders = schemas._order

        schemas = filterOrders(schemasOrders, schemas)
      }

      state.specificationsSchema = schemas
      state.specificationsSchemaFull = schemasFull

      const headerTable = []
      const headerTableFull = []

      function headerConstruct(vSchema, vTable) {
        for (let i = 0; i < Object.keys(vSchema).length; i++) {
          const key = Object.keys(vSchema)[i];

          if (key !== '_order' && key !== '_filters') {
            vTable.push(
              {
                id: key,
                value: vSchema[key],
                sort:
                  key === value.params.sort
                    ? 'ASC'
                    : `-${key}` === value.params.sort
                      ? 'DESC'
                      : 'default'
              });
          }
        }
      }

      headerConstruct(schemas, headerTable)
      headerConstruct(schemasFull, headerTableFull)

      const specificationsHeaderList = {
        success: value.data.data.data.success,
        schemas: headerTable
      }

      const specificationsHeaderListFull = {
        success: value.data.data.data.success,
        schemas: headerTableFull
      }

      state.tempSpecificationTableHeader = temp;
      state.specificationsHeaderList = specificationsHeaderList;
      state.specificationsHeaderListFull = specificationsHeaderListFull;
    },
    SET_SELECTED_SPECIFICATION(state, value) {
      state.selectedSpecification = value;
    },
    SET_SPECIFICATIONS_CALCULATE(state, value) {
      state.specificationsCalculate = value.data;
    },
    CLEAR_SPECIFICATION_DETAIL(state) {
      state.specificationDetail = {};
    },
    SET_SPECIFICATION_DETAIL(state, value) {
      if (value.type === 'api') {
        state.specificationDetail = {
          ...state.specificationDetail, ...value.data.data.data
        };
      } else {
        state.specificationDetail = {
          ...state.specificationDetail, ...value.data
        };
      }
    },
    SET_SPECIFICATION_DETAIL_GENERAL(state, value) {
      state.specificationDetailGeneral = value.data.data.companies.find((company) => {
        if (company.general == 1) {
          return company;
        }
      });

      if (!state.specificationDetailGeneral) {
        state.specificationDetailGeneral = value.data.data.companies[0];
      }
    },
    SET_TEMP_SPECIFICATION_TABLE_HEADER(state, value) {
      state.tempSpecificationTableHeader = value
    },
    SET_IS_SPECIFICATIONS_LOADING(state, value) {
      state.isSpecificationsLoading = value;
    },
    SET_IS_HISTORY(state, value = false) {
      state.isHistory = value
      localStorage.setItem('isHistorySpecification', value)
    },
    SET_PORTS_LIST(state, value) {
      state.portsList = value
    },
  },
};
