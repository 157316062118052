<template>
  <div
    :class="['combobox', { 'is-disabled': isDisabled }]"
    @click="openEditMode"
    v-click-outside="closeDropdown"
  >
    <div v-if="!isEditMode" class="item__text">{{ value }}</div>
    <BaseDropdown
      v-else
      placeholder="Выберите"
      @update:modelValue="itemSelected"
      :list="options"
    />
  </div>
</template>

<script setup>
import BaseDropdown from '@/components/base/BaseDropdown';
import { useStore } from 'vuex';
import { ref } from 'vue';

const store = useStore();

const props = defineProps({
  value: {
    default: ''
  },
  isDisabled: Boolean,
  options: Array
})

const emits = defineEmits(['updated'])
const isEditMode = ref(false)
const openEditMode = () => {
  if (props.isDisabled) return;
  isEditMode.value = true
}
const closeDropdown = () =>  isEditMode.value = false
const itemSelected = (item) => {
  emits('updated', item);
  closeDropdown();
}
</script>

<style scoped lang="scss">
.combobox {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-disabled {
    cursor: not-allowed;

    .item__text {
      cursor: not-allowed;
    }
  }

  .item__text {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

</style>
