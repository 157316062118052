import { errorPrettier } from '@/utils/api/handleError';
import filterService from '@/services/filterService.js';
import portsService from '@/services/portsService.js'
import { errorFormat } from '@/utils/helper';
import { createStringFromFilters } from '@/utils/filters/filter';

export default {
  namespaced: true,
  state: {
    currentFilterStr: '',
    filterSpecList: [],
    filterClientsList: [],
    filterQuarryList: [],
    filterModel: [],
    filterPortList: [],
    isLoading: false,
    specificationLocalString: localStorage.getItem("specification-query") || '',
  },
  actions: {
    /**
     *
     * @param commit
     * @param {('specs'|'quarries'|'clients')} model
     * @returns {Promise<void>}
     */
    async getFilterModel({ commit }, model) {
      try {
        commit('SET_FILTER_MODEL', []);
        const data = await filterService.getFilterModal(model);
        commit('SET_FILTER_MODEL', data.data);
        return data;
      } catch (e) {
        throw errorFormat(e.response.data)
      }
    },

    updateCurrentFilter({ commit }, params) {
      let str = createStringFromFilters(params);
      commit('SET_CURRENT_FILTER_STR', str);
    },

    updateCurrentSpecificationFilters({ commit }, params) {
      let str = createStringFromFilters(params);
      commit('SET_SPEC_LOCAL_STRING', str);
    },

    // работа со спецификациями
    async getFilterSpecList({ commit }) {
      try {
        const data = await filterService.getSpecFilter();
        commit('SET_FILTER_SPEC_LIST', data.data.data);
      } catch (e) {
        console.log(e)
      }
    },
    async createSpecFilter({ commit, state }, params) {
      if (state.currentFilterStr) {
        try {
          await filterService.createSpecFilter({
            name: params, url: state.currentFilterStr
          })
        } catch (error) {
          errorPrettier(error);
        }
      }
    },
    async deleteSpecItem({ commit }, id) {
      try {
        await filterService.deleteSpecFilter(id)
      } catch (e) {
        console.log(e)
      }
    },
    async updateSpecItem({ commit, state }, payload) {
      let item = state.filterSpecList.find(item => item.id === payload.id)
      let obj = { name: payload.name, url: state.currentFilterStr }
      try {
        commit('SET_IS_LOADING', true)
        await filterService.updateSpecFilter(obj, payload.id)
      } catch (error) {
        errorPrettier(error);
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },

    // работа с портами
    async createPortsFilters ({ dispatch }, body) {
      await portsService.createPortsFilters(body)

      dispatch('getPortsFilters')
    },
    async getPortsFilters ({ commit }) {
      const { data } = await portsService.getPortsFilters()

      commit('SET_PORTS_FILTERS', data.data)
    },

    // работа с клиентами
    async getFilterClientsList({ commit }) {
      try {
        const data = await filterService.getClientsFilters();
        commit('SET_FILTER_CLIENTS_LIST', data.data.data);
      } catch (e) {
        console.log(e)
      }
    },
    async createClientsFilter({ commit, state }, params) {
      if (state.currentFilterStr) {
        try {
          await filterService.createClientsFilters({
            name: params, url: state.currentFilterStr
          })
        } catch (error) {
          errorPrettier(error);
        }
      }
    },
    async deleteClientsItem({ commit }, id) {
      try {
        await filterService.deleteClientsFilter(id)
      } catch (e) {
        console.log(e)
      }
    },
    async updateClientsItem({ commit, state }, payload) {
      let item = state.filterClientsList.find(item => item.id === payload.id)
      let obj = { name: payload.name, url: state.currentFilterStr }

      try {
        commit('SET_IS_LOADING', true);
        await filterService.updateClientsFilter(obj, payload.id)
      } catch (error) {
        errorPrettier(error);
      } finally {
        commit('SET_IS_LOADING', false);
      }
    },

    // работа с карьерами
    async getFilterQuarryList({ commit }) {
      try {
        const data = await filterService.getQuarryFilters();
        commit('SET_FILTER_QUARRY_LIST', data.data.data);
      } catch (e) {
        console.log(e)
      }
    },
    async createQuarryFilter({ commit, state }, params) {
      if (state.currentFilterStr) {
        try {
          await filterService.createQuarryFilter({
            name: params, url: state.currentFilterStr
          })
        } catch (error) {
          errorPrettier(error);
        }
      }
    },
    async deleteQuarryItem({ commit }, id) {
      try {
        await filterService.deleteQuarryFilter(id)
      } catch (e) {
        console.log(e)
      }
    },
    async updateQuarryItem({ commit, state }, payload) {
      let item = state.filterQuarryList.find(item => item.id === payload.id)
      let obj = { name: payload.name, url: state.currentFilterStr }
      try {
        commit('SET_IS_LOADING', true);
        await filterService.updateQuarryFilter(obj, payload.id)
      } catch (error) {
        errorPrettier(error);
      } finally {
        commit('SET_IS_LOADING', false);
      }
    },
    async updatePortsItem({ commit, state }, payload) {
      let item = state.filterPortList.find(item => item.id === payload.id)
      let obj = { name: payload.name, url: state.currentFilterStr }
      try {
        commit('SET_IS_LOADING', true);
        await filterService.updatePortsFilter(obj, payload.id)
      } catch (error) {
        errorPrettier(error);
      } finally {
        commit('SET_IS_LOADING', false);
      }
    },
  },
  mutations: {
    SET_CURRENT_FILTER_STR(state, value) {
      state.currentFilterStr = value;
    },
    SET_SPEC_LOCAL_STRING(state, value) {
      state.specificationLocalString = value;
      localStorage.setItem("specification-query", value);
    },
    SET_FILTER_SPEC_LIST(state, value) {
      state.filterSpecList = value;
    },
    SET_FILTER_CLIENTS_LIST(state, value) {
      state.filterClientsList = value;
    },
    SET_FILTER_QUARRY_LIST(state, value) {
      state.filterQuarryList = value;
    },
    SET_FILTER_MODEL(state, value) {
      state.filterModel = value;
    },
    SET_IS_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_PORTS_FILTERS(state, value) {
      state.filterPortList = value
    }
  },
  getters: {
    currentFilterStr(state) {
      return state.currentFilterStr
    },
    specificationLocalString(state) {
      return state.specificationLocalString;
    },
    filterSpecList(state) {
      return state.filterSpecList
    },
    filterClientsList(state) {
      return state.filterClientsList
    },
    filterQuarryList(state) {
      return state.filterQuarryList
    },
    filterModel(state) {
      return state.filterModel
    },
    getIsLoading(state) {
      return state.isLoading;
    },
    filterPortList(state) {
      return state.filterPortList
    },
  },
}
