<template>
  <div class="contact-phone__wrapper">
    <ValidateEach
      v-for="(item, index) in collection"
      :key="`contact-${item.autoIncrement}`"
      :state="item"
      :rules="rules"
    >
      <template #default="{ v }">
        <div class="contact-block">
          <div class="contact-block__wrapper">
            <BaseModalInput
              class="input-group-item"
              label="Контактный телефон"
              :type="'tel'"
              :id="`common-${index}`"
              @focus="() => wasPhoneChanged = true"
              v-model="v.number.$model"
              placeholder="+7 (___) ___-__-__"
              :errors="v.number.$errors"
              :input-mask="{
                mask: '+7 (HHH) HHH-HH-HH',
                tokens: { H: { pattern: /[0-9]/ } },
            }"
              @update:modelValue="updatePhones(index)"
              @input="checkAvailabilityDebounced(index)"
            />
            <p v-if="v.contactError.$model && !v.number.$errors.length" class="input-errors input-errors--add mb-2 add-error">
              Контакт с таким номером уже существует
            </p>
            <p v-else-if="v.isRepeat.$model && !v.number.$errors.length" class="input-errors input-errors--add mb-2 add-error">
              Вы уже указали такой номер
            </p>
          </div>
          <BaseModalInput
            label="Добавочный"
            :id="`additional-${index}`"
            v-model="v.extension_number.$model"
            placeholder="0000"
            :errors="v.extension_number.$errors"
            class="input-group-item modal-input-style__additional ml-2"
            @update:modelValue="updatePhones(index)"
            :disabled="v.telephone_type.$model !== 1"
            @input="checkAvailabilityDebounced(index)"
          />
          <div class="select-flex">
            <DropdownForModal
              v-model="v.telephone_type.$model"
              class="mt-6 ml-2"
              :list="telephoneTypeList(v.telephone_type.$model)"
              @update:modelValue="onTypeChange(index, $event)"
            >
            </DropdownForModal>
          </div>

          <span v-if="collection.length > 1" @click="removeNumber(item.autoIncrement)" class="remove-number">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"><path
              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"></path><path
              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"></path>
            </svg>
          </span>
        </div>
      </template>
    </ValidateEach>
    <button
      v-if="collection.length < 5"
      class="add-button mb-2"
      @click.prevent="addAddress"
    >
      <SvgIcon name="plus" class="button-plus"/>
      Добавить номер
    </button>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import useVuelidate from '@vuelidate/core'
import { ValidateEach } from '@vuelidate/components'
import { required, minLength, helpers } from '@vuelidate/validators'
import BaseModalInput from '@/components/base/BaseModalInput.vue'
import DropdownForModal from '@/components/base/DropdownForModal.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import { getAddressTemplate, normalizePhone, formPhoneString, queryExistanceCheck } from '@/utils/contact/contact.js';
import { debounce, dumbCopy } from '@/utils/helper.js';

export default {
  name: 'ContactAddPhone',
  components: {
    SvgIcon,
    ValidateEach,
    BaseModalInput,
    DropdownForModal,
  },
  props: {
    contactNumbersList: {
      type: Array,
      required: false,
    },
    isModalEdit: {
      type: Boolean,
      default: false,
    },
    existingList: {
      type: Array,
      default: () => [],
    },
    isValidation: {
      type: Boolean,
      default: true
    },
    isEditLocal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update-phones'],
  setup(props, { emit }) {
    const autoIncrement = ref(1);
    const checkRequired = computed(() => {
      if (props.isValidation) {
        return helpers.withMessage('Поле обязательно для заполнения', required)
      }
      return false
    })

    let collection = reactive([])

    if (props.isModalEdit === false) {
      collection = reactive([getAddressTemplate(autoIncrement)])
    } else {
      if (props.contactNumbersList) {
        props.contactNumbersList.map((item) => {
          autoIncrement.value++;

          collection.push({
            number: '7' + item.number,
            extension_number: item.extension_number,
            telephone_type: item.telephone_type === 'личный' || item.telephone_type === 0 ? 0 : 1,
            id: item.id || null,
            contactError: false,
            isRepeat: false,
            autoIncrement: autoIncrement.value
          })
        })
      }
    }

    const wasPhoneChanged = ref(false);

    const isPhoneTheSameAsItWas = (index) => {
      if (!props.contactNumbersList || !props.contactNumbersList[index]) return false;
      const currentContactCopy = dumbCopy(props.contactNumbersList[index])
      const midifiedContactCopy = dumbCopy(collection[index]);
      currentContactCopy.telephone_type = currentContactCopy.telephone_type === 'Личный' ? 0 : 1;
      midifiedContactCopy.number = normalizePhone(midifiedContactCopy.number);
      return formPhoneString(currentContactCopy) === formPhoneString(midifiedContactCopy);
    }

    const checkAvailability = async (index) => {
      collection[index].contactError = false;
      collection[index].isRepeat = false;

      if (!wasPhoneChanged.value || isPhoneTheSameAsItWas(index) || !collection[index].number) return;
      const wasNumberRepeated = isLocalRepeat(index);

      if (wasNumberRepeated) return;

      const currentContact = collection[index]
      const query = {
        number: normalizePhone(currentContact?.number || ''),
        extension_number: currentContact?.extension_number || '',
        email: ''
      }
      const isNumberVacant = await queryExistanceCheck(query);
      isNumberVacant ? collection[index].contactError = false : collection[index].contactError = true;
    }

    const checkAvailabilityDebounced = debounce((val) => checkAvailability(val), 500);

    const addAddress = () => {
      autoIncrement.value++;
      collection.push(getAddressTemplate(autoIncrement))
    }

    const isLocalRepeat = (modifiedIndex) => {
      const collectionCopy = dumbCopy(collection);
      const numbers = collectionCopy.reduce((acc, number, index) => {
        if (index === modifiedIndex) return acc
        acc.push(formPhoneString( { ...number, number: normalizePhone(number.number)}));
        return acc;
      }, [])
      const newPhoneString = formPhoneString({
        ...collectionCopy[modifiedIndex],
        number: normalizePhone(collectionCopy[modifiedIndex].number)
      })

      if (numbers.includes(newPhoneString)) {
        collection[modifiedIndex].isRepeat = true;
        return collection[modifiedIndex].isRepeat;
      }
      collection[modifiedIndex].isRepeat = false;
      return collection[modifiedIndex].isRepeat;
    }

    const v = useVuelidate()

    const rules = {
      extension_number: {},
      telephone_type: {},
      contactError: {},
      isRepeat: {},
      number: {
        required: checkRequired,
        minLength: helpers.withMessage('Введите корректный номер', minLength(18)),
      }
    }

    return { wasPhoneChanged, rules, collection, v, addAddress, checkAvailabilityDebounced, checkAvailability }
  },
  methods: {
    onTypeChange(index, event) {
      this.checkAvailabilityDebounced(index);
      // если выбираем "личный", затираем доп. номер
      if (event === 0) {
        this.collection[index].extension_number = ''
      }
    },
    telephoneTypeList(value) {
      let telephoneTypeList = [
        { name: 'Личный', id: 0 },
        { name: 'Рабочий', id: 1 },
      ]

      if (value > 0) {
        telephoneTypeList.reverse()
      }

      return telephoneTypeList
    },
    updatePhones() {
      this.$emit('update-phones', this.collection)
    },
    removeNumber(el) {
      this.collection.forEach(v => {
        let index = this.collection.findIndex(vFilter => vFilter.autoIncrement === el);
        if (index !== -1) {
          this.collection.splice(index, 1);
        }
      })
    }
  },
  computed: {
    isLocalError() {
      return this.collection?.map(item => {
        return [item?.isRepeat, item?.contactError];
      })?.flat()?.includes(true);
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-item {
  margin-bottom: 16px;
}

.add-button {
  margin-left: 0;
}

.red {
  color: red;
}

.contact-block + .red {
  margin-top: -10px;
}

.contact-block {
  display: grid;
  position: relative;
  grid-template-columns: 45% 25% 25%;
  align-items: flex-start;

  .remove-number {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 37px;
    width: 24px;
    height: 24px;
    right: -6px;
    cursor: pointer;
  }
}

.input-errors--add {
  margin-top: -10px;
}
</style>
