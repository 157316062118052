import {
  clientsService,
  quarryService,
  specificationService,
  filterService,
  metaRouteService,
} from '@/services';
import {
  getCoordsList,
  getRouteDistance,
  prepareExtendedData,
  getAdditionalSpecifications,
  getRussianFilterName,
  isFilterEmpty,
} from '@/utils/map/map';
import portsService from "@/services/portsService";

const QUERY_PARAMS = { map_data: true, without_pagination: true };

export default {
  namespaced: true,
  state: {
    extendedDataId: null,
    extendedDataType: null,
    extendedDataAddressId: null,
    extendedDataDetailed: null,
    extendedData: null,
    isExtendedDataLoading: false,
    isMultirouteDetailedDataLoading: false,
    isMapDataLoading: false,
    specificationList: [],
    clientList: null,
    quarryList: null,
    portsList: null,
    sharedMetaRoute: null,
    integratedFilter: null,
    isMetaRouteMode: false,
    metaRouteMainSpecId: null,
    metaRouteData: [],
    multiroutes: [],
    multirouteDetailedData: [],
    createdMultirouteId: null,
    metaroutePolylines: [],
    specificationTotalPages: null,
    specificationPerPage: 50,
    specificationPage: null,
    forceIds: null,
    specificationQuery: null,
    isHistory: false,
    mapMode: [
      {
        title: 'схема',
        code: 'yandex#map'
      },
      {
        title: 'спутник',
        code: 'yandex#satellite'
      },
      {
        title: 'гибрид',
        code: 'yandex#hybrid'
      }
    ],
    activeMapMode: {
      title: 'схема',
      code: 'yandex#map'
    },
    colorArrowsForSpecs: '#B34BE0',
    widthArrowsForSpecs: 4,
    quickBaseSpecification: null,
    quickSecondSpecification: null,
    isQuickMetaMode: false,
    quickMetaRoute: [],
  },
  actions: {
    async handleExtendedData({ commit, dispatch }, data) {
      commit('SET_IS_EXTENDED_DATA_ACTIVE', data);
      await dispatch('quaryDataById');
    },
    setIsMetaRouteMode({ commit }, isActive = true) {
      commit('SET_META_ROUTE_MODE', isActive)
    },
    setMetaRouteData({ commit, state }, data) {
      commit('SET_META_ROUTE_DATA', data)
      const multiroutesIds = state.metaRouteData.map(route => route.id);
      const isMainSpecAvailable = multiroutesIds.includes(state.metaRouteMainSpecId);
      if (data.length >= 2 && (!state.metaRouteMainSpecId || !isMainSpecAvailable)) {
        commit('SET_METAROUTE_MAIN_SPEC_ID', data[0].id);
      }
    },
    setMultiRoutes({ commit }, data) {
      commit('SET_MULTIROUTES', data);
    },
    async saveMetaRoute({ state, commit }) {
      if (!state.metaRouteData || !state.metaroutePolylines) return;
      try {
        commit('SET_IS_MULTIROUTE_DETAILED_DATA_LOADING', true);
        const isUpdateMode = !!state.createdMultirouteId;

        const preparedData = {
          specs: state.metaRouteData,
          main_spec: state.metaRouteMainSpecId,
          routes: state.metaroutePolylines,
        }

        if (isUpdateMode) {
          const multiroute = await metaRouteService.updateMetaRoute(
            { ...preparedData, id: state.createdMultirouteId }
          );
          commit('SET_MULTIROUTE_DETAILED_DATA', multiroute.data.data);
          return;
        }

        const multiroute = await metaRouteService.createMetaRoute(preparedData);
        commit('SET_MULTIROUTE_DETAILED_DATA', multiroute.data.data);
        window.history.replaceState({}, 'meta', `?meta_route=${multiroute.data.data.url}`);
      } catch (e) {
        console.error(e, 'Ошибка сохранения мета-маршрута');
      } finally {
        commit('SET_IS_MULTIROUTE_DETAILED_DATA_LOADING', false);
      }
    },
    resetMapData({ commit, dispatch }) {
      commit('SET_QUARRY_LIST', []);
      commit('SET_CLIENT_LIST', []);
      commit('SET_SPECIFICATION_LIST', []);
      commit('SET_PORTS_LIST', []);
      commit('SET_FORCE_IDS', '');
      commit('SET_SPECIFICATION_PAGINATION', { current_page: null, last_page: null });
      commit('SET_SPECIFICATION_QUERY', null);
      dispatch('setBaseSpecification', null)
    },
    async calcMultirouteData({ commit, state }) {
      try {
        commit('SET_IS_MULTIROUTE_DETAILED_DATA_LOADING', true);
        const routesDistances = state.multiroutes.map(route => {
          return getRouteDistance(route);
        })
        const preparedData = {
          specs: state.isQuickMetaMode ? state.quickMetaRoute : state.metaRouteData,
          main_spec: null,
          routes: routesDistances,
        }
        const response = await metaRouteService.calculateMetaRoute(preparedData);
        commit('SET_MULTIROUTE_DETAILED_DATA', response.data.data);
      } catch (e) {
        console.error(e, 'Ошибка загрузки данных для работы интерактивных карт');
      } finally {
        commit('SET_IS_MULTIROUTE_DETAILED_DATA_LOADING', false);
      }
    },
    calculatePolylines({ commit, state, dispatch }) {
      if (state.multiroutes.length < 1) return []
      commit('SET_IS_MULTIROUTE_DETAILED_DATA_LOADING', true);
      const metaRoutePolylines = state.multiroutes.map(route => {
        return getCoordsList(route);
      })
      commit('SET_METAROUTE_POLYLINES', metaRoutePolylines);
      dispatch('saveMetaRoute');
    },
    setMetaRouteMainSpecId({ commit }, data) {
      commit('SET_METAROUTE_MAIN_SPEC_ID', data);
    },
    async getSharedMetaRoute({ commit }, slug) {
      try {
        if (!slug) {
          commit('SET_SHARED_METAROUTE', null)
          return;
        }
        let response = await metaRouteService.getMetaRoute(slug)
        const metaRoute = response.data.data;
        commit('SET_SHARED_METAROUTE', metaRoute);
        commit('SET_METAROUTE_MAIN_SPEC_ID', metaRoute.main_spec_id);
        commit('SET_MULTIROUTE_DETAILED_DATA', metaRoute);
      } catch (e) {
        // TODO добавить сюда алерт сообщающий об ошибке загрузки данных
        console.error(e, 'Ошибка загрузки данных для работы интерактивных карт');
      }
    },
    async getMapData({ commit }, specificationId) {
      try {
        commit('SET_IS_MAP_DATA_LOADING', true);
        if (specificationId) {
          const specList = await specificationService.getSpecifications({ id: specificationId });
          commit('SET_QUARRY_LIST', []);
          commit('SET_CLIENT_LIST', []);
          commit('SET_PORTS_LIST', []);
          commit('SET_SPECIFICATION_LIST', specList.data.data);
          return;
        }

        // при первой загрузке выводим только активные карьеры и клиенты => передаем status = true
        const quarryList = quarryService.getQuarry({ ...QUERY_PARAMS, status: true });
        const clietsList = clientsService.getClients({ ...QUERY_PARAMS, status: true });
        const portsList = portsService.getPorts({ ...QUERY_PARAMS, status: true });

        const [quarries, clients, ports] = await Promise.all([
          quarryList,
          clietsList,
          portsList
        ]);

        commit('SET_QUARRY_LIST', quarries.data.data);
        commit('SET_CLIENT_LIST', clients.data.data);
        commit('SET_PORTS_LIST', ports.data.data);
        commit('SET_SPECIFICATION_LIST', []);
      } catch (e) {
        // TODO добавить сюда алерт сообщающий об ошибке загрузки данных
        console.error(e, 'Ошибка загрузки данных для работы интерактивных карт');
      } finally {
        commit('SET_IS_MAP_DATA_LOADING', false);
      }
    },
    async filterEntities({ commit, state }, queries) {
      try {
        commit('SET_IS_MAP_DATA_LOADING', true);
        commit('SET_FORCE_IDS', '');
        commit('SET_SPECIFICATION_QUERY', null);
        const quarryQuery = queries.find(query => query.filterName === 'quarries');
        const clientQuery = queries.find(query => query.filterName === 'clients');
        const specQuery = queries.find(query => query.filterName === 'specs');
        const portsQuery = queries.find(query => query.filterName === 'seaports');
        const isQuarryShowSpecs = quarryQuery.query.quarry_specs;
        const isClientShowSpecs = clientQuery.query.client_specs;

        let getClients = [];
        let getQuarries = [];
        let getSpecs = [];
        let getPorts = [];
        let specifications = [];

        if (isQuarryShowSpecs || isClientShowSpecs) {
          getClients = clientQuery.isFilterActive ?
            clientsService.getClients({ ...clientQuery.query, ...QUERY_PARAMS }) : [];
          getQuarries = quarryQuery.isFilterActive ?
            quarryService.getQuarry({ ...quarryQuery.query, ...QUERY_PARAMS }) : [];
          getPorts = portsQuery.isFilterActive ?
              portsService.getPorts({ ...portsQuery.query, ...QUERY_PARAMS }) : [];
          const [clients, quarries, ports] = await Promise.all([getClients, getQuarries, getPorts])
          const fixedEntities = getAdditionalSpecifications([
            clients ? clients?.data?.data : null,
            quarries ? quarries?.data?.data : null
          ], []);
          specifications = await specificationService.getSpecifications({
            ...specQuery.query,
            map_data: true,
            force_ids:
              isFilterEmpty(specQuery.query) ?
                specQuery.isFilterActive ? null : fixedEntities.join(',')  :
                fixedEntities.join(','),
            page: 1,
            per_page: state.specificationPerPage,
          })
          commit('SET_QUARRY_LIST', quarries?.data?.data ? quarries.data.data : []);
          commit('SET_CLIENT_LIST', clients?.data?.data ? clients.data.data : []);
          commit('SET_PORTS_LIST', ports?.data?.data ? ports.data.data : []);
          commit('SET_FORCE_IDS', fixedEntities.join(','));
          commit('SET_SPECIFICATION_LIST', specifications?.data?.data ? specifications?.data?.data : []);
          commit('SET_SPECIFICATION_PAGINATION', specifications?.data?.meta || {});
          commit('SET_SPECIFICATION_QUERY', specQuery.query);
          return;
        }

        getClients = clientQuery.isFilterActive ?
          clientsService.getClients({ ...clientQuery.query, ...QUERY_PARAMS }) : [];
        getQuarries = quarryQuery.isFilterActive ?
          quarryService.getQuarry({ ...quarryQuery.query, ...QUERY_PARAMS }) : [];
        getPorts = portsQuery.isFilterActive ?
          portsService.getPorts({ ...portsQuery.query, ...QUERY_PARAMS }) : [];
        getSpecs = specQuery.isFilterActive ?
          specificationService.getSpecifications({
            ...specQuery.query,
            map_data: true,
            page: 1,
            per_page: state.specificationPerPage,
            is_hide_history: !state.isHistory ? 'Y' : undefined,
          }) : [];
        const [clients, quarries, specs, ports] = await Promise.all([getClients, getQuarries, getSpecs, getPorts])
        commit('SET_QUARRY_LIST', quarries?.data?.data ? quarries.data.data : []);
        commit('SET_CLIENT_LIST', clients?.data?.data ? clients.data.data : []);
        commit('SET_PORTS_LIST', ports?.data?.data ? ports.data.data : []);
        commit('SET_SPECIFICATION_LIST', specs?.data?.data ? specs?.data?.data : []);
        commit('SET_SPECIFICATION_PAGINATION', specs?.data?.meta || {});
        commit('SET_SPECIFICATION_QUERY', specQuery.query);
      } catch (e) {
        console.error(e, 'При фильтрации данных произошла ошибка');
      } finally {
        commit('SET_IS_MAP_DATA_LOADING', false);
      }
    },
    async quaryDataById({ state, commit }) {
      try {
        if (!state.extendedDataId) return;
        commit('SET_IS_EXTENDED_DATA_LOADING', true);

        let response;
        if (state.extendedDataType === 'specs') {
          response = await specificationService.getSpecificationDetail(state.extendedDataId);
        } else if (state.extendedDataType === 'client') {
          response = await clientsService.getClientDetail(state.extendedDataId);
        } else if (state.extendedDataType === 'quarry') {
          response = await quarryService.getQuarryDetail(state.extendedDataId);
        } else if (state.extendedDataType === 'ports') {
          response = await portsService.getPortDetail(state.extendedDataId)
        }

        commit('SET_EXTENDED_DATA_INFO', response.data.data);
      } catch (e) {
        console.error(e, `Ошибка загрузки детализированной информации по сущности ${state.extendedDataType}`);
      } finally {
        commit('SET_IS_EXTENDED_DATA_LOADING', false);
      }
    },
    async getIntegratedFilters({ commit }) {
      try {
        commit('SET_IS_MAP_DATA_LOADING', true);
        const integratedFiltersModel = [];
        const response = await filterService.getFilterModal('maps')
        const filterData = response.data;
        for (const filter in filterData) {
          integratedFiltersModel.push({
            filterData: filterData[filter],
            filterName: filter,
            filterNameRussian: getRussianFilterName(filter),
            isFilterActive: false,
          })
        }
        commit('SET_INTEGRATED_FILTER', integratedFiltersModel);
      } catch (e) {
        // TODO добавить сюда алерт сообщающий об ошибке загрузки данных для фильтра
        throw new Error('ошибка при загрузке данных интегрированного фильтра');
      } finally {
        commit('SET_IS_MAP_DATA_LOADING', false);
      }
    },
    async updateMapPagination({ commit, state }, { currentPage, perPage, lastPage, isHistory }) {
      try {
        // отключение пагинации, если мы смотрим конкретную спеку со страницы спеки
        if (state.specificationList.length < 2) {
          commit('SET_SPECIFICATION_PAGINATION', {
            per_page: perPage,
          })
          return;
        }
        commit('SET_IS_MAP_DATA_LOADING', true);
        commit('SET_SPECIFICATION_PAGINATION', {
          current_page: currentPage || 1,
          per_page: perPage,
        })
        
        const params = {
          ...state.specificationQuery,
          force_ids: isFilterEmpty(state.specificationQuery) ? null : state.forceIds,
          page: state.specificationPage,
          per_page: state.specificationPerPage
        }

        if (!state.isHistory) {
          params.is_hide_history = 'Y'
        }

        const specs = await specificationService.getSpecifications(params)
        
        commit('SET_SPECIFICATION_PAGINATION', { last_page: specs?.data?.meta.last_page })
        commit('SET_SPECIFICATION_LIST', specs?.data?.data ? specs?.data?.data : []);
      } catch(e) {
        console.log('Ошибка пагинации', e);
      } finally {
        commit('SET_IS_MAP_DATA_LOADING', false);
      }
    },
    updateMapMode({ commit }, mapMode) {
      commit('SET_MAP_MODE', mapMode)
    },
    setBaseSpecification({ commit }, spec) {
      commit('SET_IS_MAP_DATA_LOADING', true)
      commit('SET_IS_QUICK_META_MODE', !!spec)
      commit('SET_QUICK_BASE_SPECIFICATION', spec)
      if (!spec) commit('SET_QUICK_SECOND_SPECIFICATION', null)
      commit('SET_QUICK_META_ROUTE')
      commit('SET_IS_MAP_DATA_LOADING', false)
    },
    setQuickSecondSpecification({ commit }, spec) {
      commit('SET_IS_MAP_DATA_LOADING', true)
      commit('SET_QUICK_SECOND_SPECIFICATION', spec)
      commit('SET_QUICK_META_ROUTE')
      commit('SET_IS_MAP_DATA_LOADING', false)
    }
  },
  mutations: {
    SET_IS_EXTENDED_DATA_ACTIVE(state, value) {
      [state.extendedDataId, state.extendedDataType, state.extendedDataAddressId] = value
    },
    SET_SHARED_METAROUTE(state, value) {
      state.sharedMetaRoute = {
        ...value,
        specs: value.specs.map(item => {
          return { ...item, type: 'spec' }
        })
      }
    },
    SET_IS_MAP_DATA_LOADING(state, value) {
      state.isMapDataLoading = value;
    },
    SET_MULTIROUTES(state, value) {
      state.multiroutes = value;
    },
    SET_METAROUTE_MAIN_SPEC_ID(state, value) {
      state.metaRouteMainSpecId = value;
    },
    SET_METAROUTE_POLYLINES(state, value) {
      state.metaroutePolylines = value;
    },
    SET_MULTIROUTE_DETAILED_DATA(state, value) {
      state.multirouteDetailedData = value;
      state.createdMultirouteId = value.id || state.createdMultirouteId;
    },
    SET_META_ROUTE_MODE(state, value) {
      state.isMetaRouteMode = value;
    },
    SET_META_ROUTE_DATA(state, value) {
      state.metaRouteData = value.map(item => {
        return { ...item, type: 'spec' }
      })
    },
    SET_EXTENDED_DATA_INFO(state, value) {
      state.extendedData = value;
      state.extendedDataDetailed = prepareExtendedData(state.extendedDataType, value, state.extendedDataAddressId)
    },
    SET_IS_MULTIROUTE_DETAILED_DATA_LOADING(state, value) {
      state.isMultirouteDetailedDataLoading = value;
    },
    SET_IS_EXTENDED_DATA_LOADING(state, value) {
      state.isExtendedDataLoading = value;
    },
    SET_QUARRY_LIST(state, value) {
      value = value.map(item => {
        return { ...item, type: 'quarry' }
      })
      state.quarryList = value;
    },
    SET_PORTS_LIST(state, value) {
      value = value.map(item => {
        return { ...item, type: 'ports' }
      })
      state.portsList = value;
    },
    SET_INTEGRATED_FILTER(state, value) {
      state.integratedFilter = value;
    },
    SET_SPECIFICATION_LIST(state, value) {
      value = value.map(item => {
        return { ...item, type: 'spec' }
      })
      state.specificationList = value;
    },
    SET_CLIENT_LIST(state, value) {
      value = value.map(item => {
        return { ...item, type: 'client' }
      })
      state.clientList = value;
    },
    SET_SPECIFICATION_PAGINATION(state, { current_page, per_page, last_page }) {
      state.specificationTotalPages = last_page !== undefined ? last_page : state.specificationTotalPages;
      state.specificationPerPage = per_page !== undefined ? per_page : state.specificationPerPage;
      state.specificationPage = current_page !== undefined ? current_page : state.specificationPage;
    },
    SET_SPECIFICATION_QUERY(store, value) {
      store.specificationQuery = value;
    },
    SET_FORCE_IDS(state, value) {
      state.forceIds = value;
    },
    SET_MAP_MODE(state, value) {
      const mode = state.mapMode.find(el => el.code === value)
      state.activeMapMode = mode
    },
    SET_WIDTH_ARROWS_FOR_SPECS(store, value) {
      store.widthArrowsForSpecs = Number(value)
    },
    SET_COLOR_ARROWS_FOR_SPECS(store, value) {
      store.colorArrowsForSpecs = value
    },
    SET_IS_HISTORY(state, value) {
      state.isHistory = value
    },
    SET_QUICK_BASE_SPECIFICATION(state, value) {
      state.quickBaseSpecification = value !== null ? {...value, type: 'spec'} : value
    },
    SET_QUICK_SECOND_SPECIFICATION(state, value) {
      if (!state.quickBaseSpecification || !value || state.quickBaseSpecification?.id === value?.id) {
        state.quickSecondSpecification = null
        return
      }
      state.quickSecondSpecification = value !== null ? {...value, type: 'spec'} : value
    },
    SET_IS_QUICK_META_MODE(state, value) {
      state.isQuickMetaMode = value
    },
    SET_QUICK_META_ROUTE(state) {
      if (!state.quickBaseSpecification) state.quickMetaRoute = []
      else state.quickMetaRoute = [state.quickBaseSpecification, state.quickSecondSpecification]
    },
  },
  getters: {
    getExtendedDataId(state) {
      return state.extendedDataId;
    },
    getIsMapDataLoading(state) {
      return state.isMapDataLoading;
    },
    getMetaRouteMainSpecId(state) {
      return state.metaRouteMainSpecId;
    },
    getSharedMetaRoute(state) {
      return state.sharedMetaRoute;
    },
    getExtendedDataType(state) {
      return state.extendedDataType
    },
    getMultirouteDetailedData(state) {
      return state.multirouteDetailedData;
    },
    getExtendedData(state) {
      return state.extendedData
    },
    extendedDataDetailed(state) {
      return state.extendedDataDetailed
    },
    getIsExtendedDataLoading(state) {
      return state.isExtendedDataLoading
    },
    getIsMultirouteDetailedDataLoading(state) {
      return state.isMultirouteDetailedDataLoading;
    },
    getSpecificationList(state) {
      return state.specificationList
    },
    getClientList(state) {
      return state.clientList
    },
    getQuarryList(state) {
      return state.quarryList
    },
    getPortsList(state) {
      return state.portsList
    },
    getIntegratedFilter(state) {
      return state.integratedFilter
    },
    getIsMetaRouteMode(state) {
      return state.isMetaRouteMode
    },
    getMetaRouteData(state) {
      return state.metaRouteData;
    },
    getSpecificationTotalPages(state) {
      return state.specificationTotalPages
    },
    getSpecificationPerPage(state) {
      return state.specificationPerPage
    },
    getSpecificationPage(state) {
      return state.specificationPage
    },
    getActiveMapMode(state) {
      return state.activeMapMode
    },
    getMapModes(state) {
      return state.mapMode
    },
    getColorArrowsForSpecs(state) {
      return state.colorArrowsForSpecs
    },
    getWidthArrowsForSpecs(state) {
      return state.widthArrowsForSpecs
    },
    getIsHistory(state) {
      return state.isHistory
    },
    getIsQuickMetaMode(state) {
      return state.isQuickMetaMode
    },
    getBaseSpecification(state) {
      return state.quickBaseSpecification
    },
    getQuickSecondSpecification(state) {
      return state.quickSecondSpecification
    },
    getQuickMetaRoute(state) {
      return state.quickMetaRoute
    },
  },
}
