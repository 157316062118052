import axios from 'axios'
import { VueAxios } from './setup'
import httpAdapter from 'axios/lib/adapters/http'

import LsUtil from '@/utils/ls'
import {LS_REFRESH_TOKEN, LS_USER_LANG} from '@/utils/ls/keys'

const handleError = (error) => {
  // if (isAccessTokenExpired(error)) {
  //   return refreshAccessToken(error)
  // }
  if (error.response.status === 401) {
    LsUtil.setItem(LS_REFRESH_TOKEN, null)
  }
  return Promise.reject(error)
}

const serviceMain = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  adapter: httpAdapter,
  headers: {
    'Content-Type': 'application/json',
  },
})

serviceMain.interceptors.request.use((config) => {
  if (config.requiresAuth) {
    const refreshToken = LsUtil.getItem(LS_REFRESH_TOKEN)
    if (refreshToken) {
      config.headers['Authorization'] = `Bearer ${refreshToken}`
    }
  }
  const refreshLang = LsUtil.getItem(LS_USER_LANG)
  config.headers['Content-Language'] = refreshLang == null ? 'ru' : refreshLang
  return config
}, handleError)

serviceMain.interceptors.response.use((response) => response, handleError)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, serviceMain)
  },
}

export { installer as VueAxios, serviceMain as axiosMain }
