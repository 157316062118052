import ApiUtil from '@/utils/api'

export default {
  auth: (data) =>
    ApiUtil({
      url: '/api/v1/login',
      method: 'post',
      data,
    }
  ),
  setPass: (data) =>
    ApiUtil({
      url: '/api/v1/set-password',
      method: 'post',
      data,
      requiresAuth: true,
    }
  ),
  getUserData: () =>
    ApiUtil({
      url: '/api/v1/user',
      method: 'get',
      requiresAuth: true,
    },
  ),
}
